import { useQuery } from "@apollo/client";
import { map, set } from "lodash";
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, FloatingLabel, ListGroup } from "react-bootstrap";
import CustomScroll from "react-customscroll";
import { FiMapPin } from "react-icons/fi"
import { addClass } from "../../../commanFunctions";
import MapModel from "../../../components/Models/mapModel";
import { getGeoLoc } from "../../../queries/queries";

const HostPersonal06 = (params) => {
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [addressInput, setAddressInput] = useState(params.address || "");
  const [typingMode, setTypingMode] = useState(true);

  const onNextpage = (event) => {
    event.preventDefault();
    if (params.gender === "" || params.gender === "not selected") {
      setErrorMessage({
        error: "empty gender",
        message: "Please Enter Gender",
      });
    } else if (params.citizenship === "") {
      setErrorMessage({
        error: "empty citizenship",
        message: "Please Enter Citizenship",
      });
    } else if (params.address === "") {
      setErrorMessage({
        error: "empty address",
        message: "Please Enter Address",
      });
    } else {
      addClass()
      setTimeout(() => {
        params.setStepNo(7);
      }, 1400);
    }
  };

  const goBack = () => {
    addClass();
    setTimeout(() => {
      params.setStepNo(5);
    }, 1400);
  };

  const [suggestion, setSuggestion] = useState([])
  const [showMap, setShowMap] = useState(false)
  const GeoLocation = getGeoLoc();
  const { data, error } = useQuery(GeoLocation, { variables: { address: addressInput } })

  useEffect(() => {
    if (data?.getLatLong) {
      setSuggestion(data.getLatLong)
    }
  }, [data])

  useEffect(() => {
    console.log(error)
  }, [error])

  return (
    <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
      <div className="front">
        <div className="heading">
          <h2 className="small lh-1 fs-normal mb-2">Become A Host</h2>
          <p className="semibold m-0">Please confirm your personal registration details.</p>
        </div>
        <Col as="section" className="stepsBar2 pt-4">
          <Col as="ul" className="list-unstyled">
            <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(100%/2.1)" }}>6</span></Col>
          </Col>
        </Col>
        <Col as="section" className="signpUser pt-2">
          <Form onSubmit={onNextpage} className="accForm">

            <FloatingLabel controlId="floatingInput" label="Gender" className="mb-3">

              <Form.Select
                className="semibold"
                aria-label="Floating label select example"
                value={params.gender}
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrorMessage({
                      error: "empty gender",
                      message: "Please Enter Gender",
                    });
                  } else {
                    setErrorMessage(undefined);
                  }
                  params.setGender(e.target.value);
                }}>
                <option value="not selected">Please Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
                <option value="do not disclose">Do Not Disclose</option>
              </Form.Select>

              {errorMessage && errorMessage.error === "empty gender" && (
                <div className="invalid-feedback d-block">
                  {errorMessage.message}
                </div>
              )}
            </FloatingLabel>

            <FloatingLabel controlId="floatingInput" label="Citizenship" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Citizenship"
                value={params.citizenship}
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrorMessage({
                      error: "empty citizenship",
                      message: "Please Enter Citizenship",
                    });
                  } else {
                    setErrorMessage(undefined);
                  }
                  params.setCitizenship(e.target.value);
                }}
              />
              {errorMessage &&
                errorMessage.error === "empty citizenship" && (
                  <div className="invalid-feedback d-block">
                    {errorMessage.message}
                  </div>
                )}
            </FloatingLabel>
            <FloatingLabel controlId="floatingInput" label="Address" className="mb-2 pb-3">
              <Form.Control
                className="pe-5"
                type="text"
                placeholder="Address"
                value={typingMode ? addressInput : params?.address}
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrorMessage({
                      error: "empty address",
                      message: "Please Enter Address",
                    });
                  } else {
                    setErrorMessage(undefined);
                  }
                  setTypingMode(true)
                  setAddressInput(e.target.value);
                }}
              />
              <FiMapPin role="button" className="calendarIcon position-absolute top-0 end-0 mt-4 me-3" size="20" color="var(--theme-color)" onClick={() => setShowMap(true)} />
              {errorMessage && errorMessage.error === "empty address" && (
                <div className="invalid-feedback d-block">
                  {errorMessage.message}
                </div>
              )}

              {suggestion?.length !== 0 && <Col className="autosearch_results">
                <ListGroup>
                  {suggestion?.map((item, index) => (
                    <ListGroup.Item key={index} onClick={() => {
                      console.log(item)
                      setTypingMode(false)
                      params?.setAddress(item?.formattedAddress)
                      params?.setLatitude(item?.latitude)
                      params?.setLongitude(item?.longitude)
                    }}>{item.formattedAddress}</ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>}

            </FloatingLabel>

            {
              showMap && <MapModel show={showMap} onHide={() => setShowMap(false)} latitude={params?.latitude} longitude={params?.longitude}></MapModel>
            }

            <Col as="section" className="radiobutton">
              {params.registrationType === "nric" ? (
                <>
                  <div className="mb-2 lh-1 form-radio">
                    <input
                      name="group1"
                      type="radio"
                      id={`inline-radio-1`}
                      checked={params.profileAmendment === true}
                      onChange={(e) => params.setProfileAmendment(true)}
                    // defaultChecked
                    />
                    <label className="ms-3 pt-1 lh-sm semibold themeColor" htmlFor={`inline-radio-1`}>
                      Information is correct. No amendment required
                    </label>
                  </div>

                  <div className="lh-1 form-radio">
                    <input
                      name="group1"
                      type="radio"
                      id={`inline-radio-2`}
                      checked={params.profileAmendment === false}
                      onChange={(e) => params.setProfileAmendment(false)}
                    />
                    <label className="ms-3 pt-1 lh-sm semibold themeColor" htmlFor={`inline-radio-2`}>
                      Information is incorrect. Proceed to amend
                    </label>
                  </div>
                </>
              ) : null}
            </Col>

            <Row className="pt-4 mt-2">
              <Col className="align-self-center">
                <Button
                  className="fs26 themeColor semibold text-decoration-none lh-1 p-0"
                  variant="link"
                  type="button"
                  onClick={goBack}
                >
                  <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  Previous
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                  variant="primary"
                  type="submit"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
    </section>
  )
}

export default HostPersonal06