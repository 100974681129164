import React, { useState } from 'react'
import { Container, Row, Col, Breadcrumb, Figure, Form, Button } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import { useNavigate } from 'react-router';
// import "./dinerstyle.css"

import AfterLoginHeader from '../../components/afterLoginHeader';
import Footer from '../../components/footer';
import ProfileMenu from '../../components/profilemenu';

const NotificationAlert = () => {
    const navigate = useNavigate();

    const [alpha, setAlpha] = useState();
    const [alpha01, setAlpha01] = useState();    
    const [alpha02, setAlpha02] = useState();    
    const [alpha03, setAlpha03] = useState();
    const [alpha04, setAlpha04] = useState();
    const [alpha05, setAlpha05] = useState();

    const getCheckedValue = (e) => {
        switch (e.target.name) {
            case "case01":
              
                e.target.checked ? setAlpha("off") : setAlpha("On")
                break;
            case "case02":
                e.target.checked ? setAlpha01("off") : setAlpha01("On")
                break;
            case "case03":
                e.target.checked ? setAlpha02("On") : setAlpha02("Off")
                break;
            case "case04":
                e.target.checked ? setAlpha03("Off") : setAlpha03("On")
                break;
            case "case05":
                e.target.checked ? setAlpha04("Off") : setAlpha04("On")
                break;
            case "case06":
                e.target.checked ? setAlpha05("Off") : setAlpha05("On")
                break;
            default:
                break;
        }
    }

    const handleNotification = () => {
        navigate("/notificationcenter");
    }
      return (
        <div id="bodyWrap" className="">
            {console.log(alpha)}
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <AfterLoginHeader />
                    </Col>
                </Row>
                {/* <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col> */}
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <ProfileMenu />
                    </Col>
                    <Col lg="9" as="div" className="userAcc membership pt-5 pe-3">
                        <h2 className="fs52 semibold ms-md-4 mb-0">Alerts & Notification</h2>
                        <Breadcrumb className="ms-md-4 mb-5">
                            <Breadcrumb.Item href="/">Account</Breadcrumb.Item>
                            <Breadcrumb.Item>Alerts & Notification</Breadcrumb.Item>
                        </Breadcrumb>
                        <Col as="section">                            
                            <Col className="px-lg-5 outerspace">
                                <Col className="px-lg-4 innerspace">
                                    <h2 className="fs32 GrayComn semibold mb-1 lh-1">Notification Settings</h2>
                                    <p className="fs16 GrayComn semibold mb-0">You may configure your alerts and notification preferences below.</p>                                    
                                    <Figure className="mt-4 nofifyForm d-block">
                                        <Figure.Caption className="fs22 GrayComn semibold">
                                            Notify me of any marketing promotions
                                            <Form.Check 
                                                className="togglerBtn fs18 semibold GrayComn text-uppercase float-end pt-1 togglerSize"
                                                type="switch"
                                                id="custom-switch"
                                                label={alpha ? alpha : "On"}
                                                name="case01"
                                                checked
                                                onChange={getCheckedValue}
                                            />
                                        </Figure.Caption>
                                        <div className="form-check d-inline-block me-5">
                                            <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="notifi01" />
                                            <label className="form-check-label GrayComn semibold pt-1 ps-2 fs16" for="notifi01">Push Notifications</label>
                                        </div>
                                        <div className="form-check d-inline-block me-5">
                                            <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="notifi02" />
                                            <label className="form-check-label GrayComn semibold pt-1 ps-2 fs16" for="notifi02">Email</label>
                                        </div>
                                        <div className="form-check d-inline-block">
                                            <input className="form-check-input vertical-align-top" type="checkbox" name="flexRadioDefault" id="notifi03" />
                                            <label className="form-check-label GrayComn semibold pt-1 ps-2 fs16" for="notifi03">SMS</label>
                                        </div>
                                    </Figure>
                                    <Figure className="mt-5 nofifyForm d-block">
                                        <Figure.Caption className="fs22 GrayComn semibold">
                                            Notify me when I receive a new message
                                            <Form.Check 
                                                className="togglerBtn fs18 semibold GrayComn text-uppercase float-end pt-1 togglerSize"
                                                type="switch"
                                                id="custom-switch12"
                                                label={alpha01 ? alpha01 : "On"}
                                                checked
                                                name="case02"
                                                onChange={getCheckedValue}
                                            />
                                        </Figure.Caption>
                                        <div className="form-check d-inline-block me-5">
                                            <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="notifi04" />
                                            <label className="form-check-label GrayComn semibold pt-1 ps-2 fs16" for="notifi04">Push Notifications</label>
                                        </div>
                                        <div className="form-check d-inline-block me-5">
                                            <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="notifi05" />
                                            <label className="form-check-label GrayComn semibold pt-1 ps-2 fs16" for="notifi05">Email</label>
                                        </div>
                                        <div className="form-check d-inline-block">
                                            <input className="form-check-input vertical-align-top" type="checkbox" name="flexRadioDefault" id="notifi06" />
                                            <label className="form-check-label GrayComn semibold pt-1 ps-2 fs16" for="notifi06">SMS</label>
                                        </div>
                                    </Figure>
                                    <Figure className="mt-5 nofifyForm d-block">
                                        <Figure.Caption className="fs22 GrayComn semibold">
                                            Notify me when I have an unread message for 3 days
                                            <Form.Check 
                                                className="togglerBtn fs18 semibold GrayComn text-uppercase float-end pt-1 togglerSize"
                                                type="switch"
                                                id="custom-switch13"
                                                label={alpha02 ? alpha02 : "Off"}
                                                name="case03"
                                                onChange={getCheckedValue}
                                            />
                                        </Figure.Caption>
                                    </Figure>
                                    <Figure className="mt-5 nofifyForm d-block">
                                        <Figure.Caption className="fs22 GrayComn semibold">
                                            Notify me when my Order is cancelled by the Host
                                            <Form.Check 
                                                className="togglerBtn fs18 semibold GrayComn text-uppercase float-end pt-1 togglerSize"
                                                type="switch"
                                                id="custom-switch14"
                                                label={alpha03 ? alpha03 : "On"}
                                                checked
                                                name="case04"
                                                onChange={getCheckedValue}
                                            />
                                        </Figure.Caption>
                                        <div className="form-check d-inline-block me-5">
                                            <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="notifi07" />
                                            <label className="form-check-label GrayComn semibold pt-1 ps-2 fs16" for="notifi07">Push Notifications</label>
                                        </div>
                                        <div className="form-check d-inline-block me-5">
                                            <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="notifi08" />
                                            <label className="form-check-label GrayComn semibold pt-1 ps-2 fs16" for="notifi08">Email</label>
                                        </div>
                                        <div className="form-check d-inline-block">
                                            <input className="form-check-input vertical-align-top" type="checkbox" name="flexRadioDefault" id="notifi09" />
                                            <label className="form-check-label GrayComn semibold pt-1 ps-2 fs16" for="notifi09">SMS</label>
                                        </div>
                                    </Figure>
                                    <Figure className="mt-5 nofifyForm d-block">
                                        <Figure.Caption className="fs22 GrayComn semibold">
                                            Notify me before my Order Collection Time is due.
                                            <Form.Check 
                                                className="togglerBtn fs18 semibold GrayComn text-uppercase float-end pt-1 togglerSize"
                                                type="switch"
                                                id="custom-switch15"
                                                label={alpha04 ? alpha04 : "On"}
                                                checked
                                                name="case05"
                                                onChange={getCheckedValue}
                                            />
                                        </Figure.Caption>
                                        <div className="form-check d-inline-block me-5">
                                            <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="notifi10" />
                                            <label className="form-check-label GrayComn semibold pt-1 ps-2 fs16" for="notifi10">Push Notifications</label>
                                        </div>
                                        <div className="form-check d-inline-block me-5">
                                            <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="notifi11" />
                                            <label className="form-check-label GrayComn semibold pt-1 ps-2 fs16" for="notifi11">Email</label>
                                        </div>
                                        <div className="form-check d-inline-block">
                                            <input className="form-check-input vertical-align-top" type="checkbox" name="flexRadioDefault" id="notifi12" />
                                            <label className="form-check-label GrayComn semibold pt-1 ps-2 fs16" for="notifi12">SMS</label>
                                        </div>
                                    </Figure>
                                    <Figure className="mt-5 nofifyForm d-block">
                                        <Figure.Caption className="fs16 GrayComn semibold">Set Reminder                                        
                                            <Form.Select className="w-25 d-inline mx-3 border-0 shadowsm">
                                                <option>15 Minuts</option>
                                                <option>20 Minuts</option>
                                                <option>25 Minuts</option>
                                                <option>30 Minuts</option>
                                            </Form.Select>
                                            Before Collection Due
                                        </Figure.Caption>                                        
                                    </Figure>
                                    <Figure className="mt-5 nofifyForm d-block">
                                        <Figure.Caption className="fs22 GrayComn semibold">
                                            Notify me when I have an uncollected Order.
                                            <Form.Check 
                                                className="togglerBtn fs18 semibold GrayComn text-uppercase float-end pt-1 togglerSize"
                                                type="switch"
                                                id="custom-switch16"
                                                label={alpha05 ? alpha05 : "On"}
                                                checked
                                                name="case06"
                                                onChange={getCheckedValue}
                                            />
                                        </Figure.Caption>
                                        <div className="form-check d-inline-block me-5">
                                            <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="notifi13" />
                                            <label className="form-check-label GrayComn semibold pt-1 ps-2 fs16" for="notifi13">Push Notifications</label>
                                        </div>
                                        <div className="form-check d-inline-block me-5">
                                            <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="notifi14" />
                                            <label className="form-check-label GrayComn semibold pt-1 ps-2 fs16" for="notifi14">Email</label>
                                        </div>
                                        <div className="form-check d-inline-block">
                                            <input className="form-check-input vertical-align-top" type="checkbox" name="flexRadioDefault" id="notifi15" />
                                            <label className="form-check-label GrayComn semibold pt-1 ps-2 fs16" for="notifi15">SMS</label>
                                        </div>
                                    </Figure>
                                    <Figure className="mt-5 nofifyForm d-block">
                                        <Figure.Caption className="fs16 GrayComn semibold">Set Reminder                                        
                                            <Form.Select className="w-25 d-inline mx-3 border-0 shadowsm">
                                                <option>15 Minuts</option>
                                                <option>20 Minuts</option>
                                                <option>25 Minuts</option>
                                                <option>30 Minuts</option>
                                            </Form.Select>
                                            From Collection Due
                                        </Figure.Caption>                                        
                                    </Figure>
                                    <Figure className="pt-4 mt-5 nofifyButton d-block text-center">
                                        <Button href="#" variant="danger" className="rounded-pill py-2 fs26 px-5" onClick={handleNotification}>Go To Notification Centre</Button>
                                    </Figure>

                                </Col>
                            </Col>
                        </Col>                        
                        <Col lg="12" className="p-lg-4">&nbsp;</Col>                        
                    </Col>
                </Row>        
            </Container>
            <br /><br />
            <Footer />
        </div>
    )    
}
export default NotificationAlert