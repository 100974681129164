import React, { useState } from 'react'
import { Button, Col, Form } from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./hostcss/hostsignup.css";
import { addClass } from '../../commanFunctions';
import HostPersonal01 from './hostpersonal/hostpersonal01';
import HostBusiness01 from './hostbusiness/hostbusiness01';
import { useNavigate } from 'react-router-dom';
import AuthPageLayout from '../auth/authpagelayout';

const HostSignup = () => {
    const navigate = useNavigate();
    const UserRole = 1; // Host
    const [stepNo, setStepNo] = useState(1);
    const [userHostType, setUserHostType] = useState('personal');
    const [selectedSide, setSelectedSide] = useState("");

    const onSelect = async (hostType) => {
        if (hostType === "personal") {
            setSelectedSide("LEFT")
            setTimeout(() => {
                setUserHostType(hostType);
            }, 1400);
        } else if (hostType === "business") {
            setSelectedSide("RIGHT")
            setTimeout(() => {
                setUserHostType(hostType);
            }, 1400);
        } else {
            setSelectedSide('')
            setUserHostType(hostType);
        }
        addClass()
        setTimeout(() => {
            setStepNo(2)
        }, 1400);
    }

    return (
        <AuthPageLayout
            title="Start a rewarding food entreprenuer jouney with us."
            description={<>Save on rent. Work from home. The best part? A Crib <br className="d-none d-lg-inline" />Cafe to call your own.</>}
        >
            {stepNo === 1 &&
                <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
                    <div className="front">
                        <div className="heading">
                            <h2 className="small lh-1 fs-normal mb-2">Become A Host</h2>
                            <p className="semibold m-0">
                                Please choose your profile registration type.
                            </p>
                        </div>
                        <Col as="section" className="stepsBar2 pt-4">

                            <Col as="ul" className="list-unstyled">
                                <Col as="li" className="p2 active first"><span className="fs14">1</span></Col>
                            </Col>

                        </Col>
                        <Col as="section" className="signpUser pt-3">
                            <Form>
                                <Col as="section" className="mb-3 row text-center">

                                    <Col className="dineUser" onClick={() => onSelect("personal")}>
                                        <div className={`iconleft cursor-pointer ${selectedSide === "LEFT" ? 'bgtheme' : ''}`}>
                                            <svg
                                                width="82"
                                                height="93"
                                                viewBox="0 0 82 93"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M3.30321 72.7667C4.30425 66.7558 8.51891 61.8238 14.3014 59.8964L27.1378 55.6171V53.709H30.1216V56.5076L32.3487 65.4158L38.375 58.1847H42.7568L48.7824 65.4158L51.0083 56.5076V53.709H53.9921V55.6171L66.8285 59.8957C72.6096 61.8233 76.8243 66.7558 77.8267 72.766L81.1162 92.4984H0.0143585L3.30321 72.7667ZM41.3597 61.1685H39.7738L36.2379 65.4119L39.1411 69.767L36.3184 89.5148H44.8136L41.9923 69.767L44.8954 65.4119L41.3597 61.1685ZM74.8841 73.259C74.0649 68.3403 70.6156 64.3061 65.8848 62.7276L53.5572 58.6176L50.2509 71.8404L46.8869 67.8035L45.1072 70.4743L47.8265 89.5144H64.2795L62.9514 76.2365L65.9205 75.9394L67.2779 89.5158H77.5934L74.8841 73.259ZM13.8523 89.5148L15.2096 75.9384L18.1788 76.2355L16.8507 89.5135H33.3037L36.0237 70.4716L34.244 67.8008L30.88 71.8377L27.5737 58.6149L15.2461 62.7249C10.5153 64.3021 7.06721 68.3376 6.24675 73.2563L3.53659 89.5141L13.8523 89.5148Z"
                                                    fill="var(--bs-grayDark)"
                                                />
                                                <path
                                                    d="M59.3 40.1983C58.5394 42.8164 57.1341 45.2169 55.1526 47.1984L49.3342 53.0168C47.9255 54.4248 46.0534 55.2008 44.0598 55.2008H37.0671C35.0756 55.2008 33.2015 54.4249 31.7934 53.0168L25.975 47.1984C23.9936 45.2169 22.5883 42.8152 21.8277 40.1983C18.1112 39.7774 15.2021 36.6503 15.2021 32.822C15.2021 30.3737 16.4042 28.215 18.2319 26.8544H18.186V17.9029C18.186 14.1685 20.9461 11.0654 24.5326 10.5266C26.7778 4.27845 32.786 0 39.4904 0H40.5647C52.9043 0 62.9434 10.0391 62.9434 22.3786V26.8544H62.8974C64.725 28.2151 65.9272 30.3737 65.9272 32.822C65.9272 36.6503 63.0181 39.7773 59.3003 40.1983H59.3ZM28.0858 45.0884L33.9043 50.9068C34.7368 51.7394 35.8898 52.2169 37.0687 52.2169H44.0614C45.2402 52.2169 46.3918 51.7394 47.2257 50.9068L53.0442 45.0884C55.5804 42.5521 56.977 39.1808 56.977 35.594L56.9764 28.3463H51.1146C44.5489 28.3463 38.3408 26.0145 33.4351 21.7432L32.9788 22.5038C31.0753 25.6767 27.7812 27.7866 24.1525 28.2463V35.5927C24.1525 39.1806 25.5492 42.5519 28.0854 45.0882L28.0858 45.0884ZM21.2391 37.044C21.1978 36.5651 21.1692 36.0816 21.1692 35.5941V28.6208C19.4368 29.2382 18.1853 30.8793 18.1853 32.8222C18.1853 34.7903 19.4714 36.4474 21.2391 37.0442L21.2391 37.044ZM59.9588 22.3786C59.9588 11.6848 51.2578 2.98382 40.564 2.98382H39.4897C33.7579 2.98382 28.654 6.83277 27.0782 12.3455L26.7698 13.4271H25.6449C23.1772 13.4271 21.1692 15.4352 21.1692 17.9029V25.513C21.6514 25.4144 22.1509 25.3624 22.6611 25.3624C25.8207 25.3624 28.7946 23.6794 30.4204 20.9686L32.8088 16.9883L34.1602 18.3397C38.6886 22.8681 44.7087 25.3624 51.1134 25.3624H58.467C58.9772 25.3624 59.4767 25.4144 59.9589 25.5129L59.9588 22.3786ZM59.9588 28.6206V35.5939C59.9588 36.0814 59.9302 36.5649 59.8889 37.0458C61.6565 36.4471 62.9426 34.79 62.9426 32.8219C62.9426 30.8797 61.6911 29.2386 59.9588 28.6205L59.9588 28.6206Z"
                                                    fill="var(--bs-grayDark)"
                                                />
                                            </svg>
                                            <h4 className="GrayComn">Personal</h4>
                                        </div>
                                    </Col>
                                    <Col className="hostUser" onClick={() => onSelect("business")}>
                                        <div className={`iconright cursor-pointer ${selectedSide === "RIGHT" ? 'bgtheme' : null}`}>
                                            <svg
                                                width="94"
                                                height="96"
                                                viewBox="0 0 94 96"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M1.83301 94.2196L2.38254 2L66.058 21.2124V94.2196H1.83301Z"
                                                    stroke="#404040"
                                                    strokeWidth="3"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M66.0579 39.876H91.8574V94.2202H66.0579"
                                                    stroke="#404040"
                                                    strokeWidth="3"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <mask
                                                    id="path-3-inside-1_1330_76570"
                                                    fill="white"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M27.6328 94.2204V78.851H40.2583V94.2204H66.0576V21.2152L2.38451 2.00293L1.83496 94.2207L27.6328 94.2204ZM41.9044 73.362V66.775H49.5891V73.362H41.9044ZM53.432 73.362V66.775H61.1167V73.362H53.432ZM30.3779 73.362V66.775H38.0626V73.362H30.3779ZM26.535 73.362H18.8503V66.775H26.535V73.362ZM14.4591 73.362H6.77441V66.775H14.4591V73.362ZM49.5908 63.4815H41.9061V56.8946H49.5908V63.4815ZM53.4336 63.4815V56.8946H61.1183V63.4815H53.4336ZM30.3795 63.4815V56.8946H38.0642V63.4815H30.3795ZM26.5367 63.4815H18.852V56.8946H26.5367V63.4815ZM14.4608 63.4815H6.77605V56.8946H14.4608V63.4815ZM49.5924 53.0517H41.9077V46.4647H49.5924V53.0517ZM53.4353 53.0517V46.4647H61.12V53.0517H53.4353ZM38.0658 53.0517H30.3811V46.4647H38.0658V53.0517ZM26.5383 53.0517H18.8536V46.4647H26.5383V53.0517ZM14.4624 53.0517H6.77768V46.4647H14.4624V53.0517ZM53.4359 43.1712V36.5843H61.1206V43.1712H53.4359ZM41.9084 43.1712V36.5843H49.5931V43.1712H41.9084ZM38.0655 43.1712H30.3808V36.5843H38.0655V43.1712ZM26.538 43.1712H18.8533V36.5843H26.538V43.1712ZM14.4621 43.1712H6.77735V36.5843H14.4621V43.1712ZM53.4356 33.2908V26.7038H61.1203V33.2908H53.4356ZM41.908 33.2908V26.7038H49.5927V33.2908H41.908ZM38.0652 33.2908H30.3805V26.7038H38.0652V33.2908ZM26.5377 33.2908H18.8529V26.7038H26.5377V33.2908ZM14.4617 33.2908H6.77702V26.7038H14.4617V33.2908Z"
                                                    />
                                                </mask>
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M27.6328 94.2204V78.851H40.2583V94.2204H66.0576V21.2152L2.38451 2.00293L1.83496 94.2207L27.6328 94.2204ZM41.9044 73.362V66.775H49.5891V73.362H41.9044ZM53.432 73.362V66.775H61.1167V73.362H53.432ZM30.3779 73.362V66.775H38.0626V73.362H30.3779ZM26.535 73.362H18.8503V66.775H26.535V73.362ZM14.4591 73.362H6.77441V66.775H14.4591V73.362ZM49.5908 63.4815H41.9061V56.8946H49.5908V63.4815ZM53.4336 63.4815V56.8946H61.1183V63.4815H53.4336ZM30.3795 63.4815V56.8946H38.0642V63.4815H30.3795ZM26.5367 63.4815H18.852V56.8946H26.5367V63.4815ZM14.4608 63.4815H6.77605V56.8946H14.4608V63.4815ZM49.5924 53.0517H41.9077V46.4647H49.5924V53.0517ZM53.4353 53.0517V46.4647H61.12V53.0517H53.4353ZM38.0658 53.0517H30.3811V46.4647H38.0658V53.0517ZM26.5383 53.0517H18.8536V46.4647H26.5383V53.0517ZM14.4624 53.0517H6.77768V46.4647H14.4624V53.0517ZM53.4359 43.1712V36.5843H61.1206V43.1712H53.4359ZM41.9084 43.1712V36.5843H49.5931V43.1712H41.9084ZM38.0655 43.1712H30.3808V36.5843H38.0655V43.1712ZM26.538 43.1712H18.8533V36.5843H26.538V43.1712ZM14.4621 43.1712H6.77735V36.5843H14.4621V43.1712ZM53.4356 33.2908V26.7038H61.1203V33.2908H53.4356ZM41.908 33.2908V26.7038H49.5927V33.2908H41.908ZM38.0652 33.2908H30.3805V26.7038H38.0652V33.2908ZM26.5377 33.2908H18.8529V26.7038H26.5377V33.2908ZM14.4617 33.2908H6.77702V26.7038H14.4617V33.2908Z"
                                                    stroke="#404040"
                                                    strokeWidth="6"
                                                    mask="url(#path-3-inside-1_1330_76570)"
                                                />
                                                <path
                                                    d="M88.5636 83.643H90.0636V82.143V77.7518V76.2518H88.5636H69.3514H67.8514V77.7518V82.143V83.643H69.3514H88.5636ZM88.5636 72.6648H90.0636V71.1648V66.7736V65.2736H88.5636H69.3514H67.8514V66.7736V71.1648V72.6648H69.3514H88.5636ZM88.5636 61.6866H90.0636V60.1866V55.7954V54.2954H88.5636H69.3514H67.8514V55.7954V60.1866V61.6866H69.3514H88.5636ZM88.5636 50.7084H90.0636V49.2084V45.3656V43.8656H88.5636H69.3514H67.8514V45.3656V49.2084V50.7084H69.3514H88.5636ZM90.3571 92.7202H67.5579V41.376H90.3571V92.7202Z"
                                                    stroke="#404040"
                                                    strokeWidth="3"
                                                />
                                            </svg>

                                            <h4>Company / Business</h4>
                                        </div>
                                    </Col>
                                </Col>
                                <Button className="fs26 themeColor semibold text-decoration-none lh-1 p-0" type="button" variant="link" onClick={() => navigate("/diner/myprofile")}>
                                    <svg
                                        width="9"
                                        height="16"
                                        className="align-baseline me-3"
                                        viewBox="0 0 9 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8 15L1 8L8 1"
                                            stroke="#C32148"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    Back To My Profile
                                </Button>
                            </Form>
                        </Col>
                    </div>
                </section>}
            {stepNo === 2 && userHostType === "personal" ?
                <HostPersonal01
                    userRole={UserRole}
                    stepNo={stepNo}
                    setStepNo={setStepNo}
                    userHostType={userHostType}
                    setSelectedSide={setSelectedSide}
                /> :
                stepNo === 2 && userHostType === "business" ?
                    <HostBusiness01
                        userRole={UserRole}
                        stepNo={stepNo}
                        setStepNo={setStepNo}
                        userHostType={userHostType}
                        setSelectedSide={setSelectedSide}
                    />
                    : null}

            
        </AuthPageLayout>
    )
}

export default HostSignup