import React, { useEffect, useState } from 'react'
import { Form, Button } from 'react-bootstrap';
import AuthPageLayout from '../authpagelayout';
import OtpAndQnaPage from './otpandqnapage';
import NewPassword from './newpassword';
import { addClass } from '../../../commanFunctions';
import { checkUserExist } from '../../../queries/queries';
import { useQuery } from '@apollo/client';
import '../loginpage.css';


const ForgotPassword = () => {
    const [stepNo, setStepNo] = useState(1);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [email, setEmail] = useState('');

    const checkUser = checkUserExist();
    const { data, error } = useQuery(checkUser, { variables: { email: email } });

    useEffect(() => {
        if (error) {
            if (error.message === 'Failed to fetch') {
                setErrorMessage({
                    error: 'empty email',
                    message: error.message
                })
            } else {
                const errMessage = error.message ? JSON.parse(error.message) : ''
                setErrorMessage({
                    error: 'empty email',
                    message: errMessage
                })
            }
        }
    }, [error]);

    const hamdleResetPassword = () => {
        if (email !== "") {
            if (data?.checkUserExist?.message == "User Already Exist") {
                setErrorMessage(undefined)
                addClass()
                setTimeout(() => {
                    setStepNo(2)
                }, 1400)
            }
            else {
                setErrorMessage({
                    error: 'empty email',
                    message: data?.checkUserExist?.message
                })
            }
        } else {
            setErrorMessage({
                error: 'empty email',
                message: 'Please Enter Email'
            })
        }
    }

    return (
        <AuthPageLayout
            title="Enjoy a cozy hot meal at your neighbour's crib."
            description={<>Join us and be rewarded whenever you eat with us. A <br className=" d-none d-lg-inline" /> good taste deserves more treats.</>}
        >
            {stepNo === 1 && <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
                <div className="front">
                    <div className='heading mb-2 pb-2'>
                        <h2 className='small lh-1 fs-normal mb-0'>Forgot Password</h2>
                        <p className="semibold">Please enter your registered Email Address or Contact Number.</p>
                    </div>
                    <Form>
                        <Form.Group controlId="formBasicName" className="mb-4 pb-3" label="Email Address or Contact Number">
                            <Form.Control type="email" placeholder="Email Address or Contact Number" value={email} 
                            // onChange={(e) => setEmail(e.target.value)} 
                            onChange={(e) => {
                                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
                                    setErrorMessage({
                                        error: 'empty email',
                                        message: 'Invalid Email '
                                    })
                                } else {
                                    setErrorMessage(undefined)
                                }
                                setEmail(e.target.value);
                            }}
                            />
                            {errorMessage?.error === 'valid email' || errorMessage?.error === 'empty email' ? (
                                <div className="invalid-feedback d-block">
                                    {errorMessage.message}
                                </div>
                            ) : ''}
                        </Form.Group>
                        <Button className="w-100 py-2 lh-lg border-0" variant="primary" type="button" onClick={hamdleResetPassword}>Reset Password</Button>
                    </Form>
                </div>
            </section>}
            {stepNo === 2 && <OtpAndQnaPage setStepNo={setStepNo} email={email} />}
            {stepNo === 3 && <NewPassword setStepNo={setStepNo} email={email} />}

        </AuthPageLayout>
    )
}
export default ForgotPassword