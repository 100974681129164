import React, { useEffect, useState } from "react";
// import styles from "../../styles/checkoutform.module.css";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";

const Checkoutform = (props) => {

    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        console.log("clientSecret", clientSecret)
        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            console.log('paymentIntent', paymentIntent)
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("checkout form called click on pay now button");
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: "http://localhost:3042",
                payment_method_data: {
                    billing_details: {
                        name: 'Jenny Rosen',
                        email: 'jenny@mailinator.com',
                        // phone : '4574957',
                        // address:{
                        //   line1: '510 Townsend St',
                        //   postal_code: '98140',
                        //   city: 'San Francisco',
                        //   state: 'CA',
                        //   country: 'US',
                        //  }
                    }
                },
            },

            redirect: 'if_required'
        });
        console.log("stripe form error error", error);

        if (error) {
            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            } else {
                setMessage("An unexpected error occurred.");
            }
            setIsLoading(false);
            return false
        }

        // alert("called")
        setIsLoading(false);

        console.log("stripe form handel submit called", stripe);
    };

    const paymentElementOptions = {
        layout: "accordion",
        defaultValues: {
            billingDetails: {
                name: "nidhi gurjar",
                email: "nidhi@mailinator.com",
            }
        },
        fields: {
            billingDetails: {
                address: {
                    country: "auto",
                }
            }
        }
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement options={paymentElementOptions} />
            <button disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text" >
                    {isLoading ? <div id="spinner"></div> : "Pay now"}
                </span>
            </button>
            {/* {/ Show any error or success messages /} */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    )
}

export default Checkoutform