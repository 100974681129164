import React, { useState } from "react";
import { Row, Col, Form, Button, FloatingLabel } from "react-bootstrap";
import { BsCalendar2 } from "react-icons/bs";
import DatePicker from "react-datepicker";
import { addClass } from "../../../commanFunctions";

const HostBusiness04 = (params) => {
  const [errorMessage, setErrorMessage] = useState(undefined);

  const handleChangeRaw = (value) => {
    // if (value === "tomorrow") {
    //   setStartDate(addDays(new Date(), 1));
    // }
  };

  const onNextpage = (event) => {
    event.preventDefault();
    if (params.companyName === "") {
      setErrorMessage({
        error: "empty company name",
        message: "Please Enter company name",
      });
    } else if (params.registrationNo === "") {
      setErrorMessage({
        error: "empty business registration number",
        message: "Please Enter Business Registration Number",
      });
    } else if (params.dateInc === "") {
      setErrorMessage({
        error: "invalid date",
        message: "Invalid Date",
      });
    } else if (params.companyStatus === "" || params.companyStatus === "not selected") {
      setErrorMessage({
        error: "empty business status",
        message: "Please Enter Business Status",
      });
    } else {
      addClass();
      setTimeout(() => {
        params.setStepNo(5);
      }, 1400);
    }
  };

  const goBack = () => {
    addClass();
    setTimeout(() => {
      params.setStepNo(3);
    }, 1400);
  };

  return (
    <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
      <div className="front">
        <div className="heading">
          <h2 className="small lh-1 fs-normal mb-2">Become A Host</h2>
          <p className="semibold m-0">Please choose your method of registration type.</p>
        </div>
        <Col as="section" className="stepsBar2 pt-4">
          <Col as="ul" className="list-unstyled">
            <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(100%/2.6)" }}>4</span></Col>
          </Col>
        </Col>
        <Col as="section" className="signpUser pt-2">
          <Form onSubmit={onNextpage} className="accForm">

            <FloatingLabel controlId="floatingInput" label="Company Name" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Company Name"
                value={params.companyName}
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrorMessage({
                      error: "empty company name",
                      message: "Please Enter Company Name",
                    });
                  } else {
                    setErrorMessage(undefined);
                  }
                  params.setCompanyName(e.target.value);
                }}
              />
              {errorMessage &&
                errorMessage.error === "empty company name" && (
                  <div className="invalid-feedback d-block">
                    {errorMessage.message}
                  </div>
                )}
            </FloatingLabel>

            <FloatingLabel controlId="floatingInput" label="Business Registration Number" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Business Registration Number"
                value={params.registrationNo}
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrorMessage({
                      error: "empty business registration number",
                      message:
                        "Please Enter Business Registration Number",
                    });
                  } else {
                    setErrorMessage(undefined);
                  }
                  params.setRegistrationNo(e.target.value);
                }}
              />
              {errorMessage &&
                errorMessage.error ===
                "empty business registration number" && (
                  <div className="invalid-feedback d-block">
                    {errorMessage.message}
                  </div>
                )}
            </FloatingLabel>
            <Row className="mb-0">
              <Col>

                <FloatingLabel controlId="floatingInput" label="Date of Incorporation" className="mb-3 pb-2 datePickerBox">
                  <Form.Control type="text" />
                  <DatePicker
                    selected={params.dInc}
                    className="form-control bg-white grayBdb pe-5 text-truncate"
                    placeholderText="Date of Incorporation"
                    maxDate={new Date()}
                    onChange={(date) => params.handleDate(date)}
                    onChangeRaw={(event) => {
                      if (event.target.value === "") {
                        setErrorMessage({
                          error: "invalid date",
                          message: "Invalid Date",
                        });
                      } else {
                        setErrorMessage(undefined);
                      }
                      handleChangeRaw(event.target.value);
                    }}
                  />
                  <BsCalendar2
                    className="calendarIcon mt-1"
                    size="20"
                    color="var(--theme-color)"
                  />
                  {errorMessage &&
                    errorMessage.error === "invalid date" && (
                      <div className="invalid-feedback d-block">
                        {errorMessage.message}
                      </div>
                    )}
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingInput" label="Company Status" className="mb-3">

                  <Form.Select
                    className="semibold"
                    aria-label="Floating label select example"
                    value={params.companyStatus}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setErrorMessage({
                          error: "empty business status",
                          message: "Please Enter Business Status",
                        });
                      } else {
                        setErrorMessage(undefined);
                      }
                      params.setCompanyStatus(e.target.value);
                    }}>
                    <option value="not selected">Please Select Company Status</option>
                    <option value="LIVE">Live</option>
                    <option value="CLOSED">Closed</option>
                  </Form.Select>

                  {errorMessage &&
                    errorMessage.error === "empty business status" && (
                      <div className="invalid-feedback d-block">
                        {errorMessage.message}
                      </div>
                    )}
                </FloatingLabel>
              </Col>
            </Row>

            {params.registrationType === "acra" && (
              <>
                <div className="mb-2 lh-1 form-radio">
                  <input
                    name="group1"
                    type="radio"
                    id={`inline-radio-1`}
                    checked={params.companyAmendment === true}
                    onChange={(e) => params.setCompanyAmendment(true)}
                  />
                  <label className="ms-3 pt-1 lh-sm semibold themeColor" htmlFor={`inline-radio-1`}>
                    Information is correct. No amendment required
                  </label>
                </div>

                <div className="mb-4 lh-1 form-radio">
                  <input
                    name="group1"
                    type="radio"
                    id={`inline-radio-2`}
                    checked={params.companyAmendment === false}
                    onChange={(e) => params.setCompanyAmendment(false)}
                  />
                  <label className="ms-3 pt-1 lh-sm semibold themeColor" htmlFor={`inline-radio-2`}>
                    Information is incorrect. Proceed to amend
                  </label>
                </div>
              </>
            )}

            <Row>
              <Col className="align-self-center">
                <Button
                  className="fs26 themeColor semibold text-decoration-none lh-1 p-0"
                  variant="link"
                  type="button"
                  onClick={goBack}
                >
                  <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  Previous
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                  variant="primary"
                  type="submit"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
    </section>
  )
}

export default HostBusiness04