import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "./identity.css"
import { useNavigate, useOutletContext } from "react-router-dom";
import ProfileMenu from '../../components/profilemenu';
import AfterLoginHeader from '../../components/afterLoginHeader';
import Footer from '../../components/footer';
import IdentityStep02 from './identitystep02';
import SignPass from './signpass';
import IdentityStep03 from './identitystep03';
import IdentityStep04 from './identitystep04';
import AlertPopup from '../../components/alertPopup';
import { HiCheckCircle } from 'react-icons/hi';
import { GetHostById } from '../../queries/queries'
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";



const IdentityStep01 = ({ props }) => {
    const user = useOutletContext();
    const navigate = useNavigate();
    const [registrationType, setRegistrationType] = useState("");
    const [selectedSide, setSelectedSide] = useState('');  //LEFT RIGHT
    const UserRole = 1; // Host
    const [stepNo, setStepNo] = useState(1);
    const [userHostType, setUserHostType] = useState('personal');
    const [methodValue, setMethodValue] = useState('');
    const [fullname, setFullname] = useState("");
    const [nricNumber, setNricNumber] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState();
    const [age, setAge] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [profileAmendment, setProfileAmendment] = useState(true);
    const [gender, setGender] = useState("");
    const [citizenship, setCitizenship] = useState("");
    const [address, setAddress] = useState("");
    const [Popup, setPopup] = useState(false);
    const [userData, setUserData] = useState([]);
    const [nricFrontFile, setNricFrontFile] = useState(null)
    const [nricBackFile, setNricBackFile] = useState(null);
    const [updateFrontFile, setUpdateFrontFile] = useState(null)
    const [updateBackFile, setUpdateBackFile] = useState(null)
    const userId = user?.user?.id;
    const isUser = Object.keys(user?.user).length === 0;
    const getUserData = GetHostById();
    const { loading, data, error, refetch } = useQuery(getUserData, {
        skip: isUser,
        variables: { userId: Number(userId) },
    });

    useEffect(() => {
        if (data) {
            setUserData(data?.getUserById?.user);
            setFullname(data?.getUserById?.user?.first_name + " " + data?.getUserById?.user?.last_name)
            setGender(data?.getUserById?.user?.user_profile?.gender)
            setNricBackFile(data?.getUserById?.user?.user_document?.nric_back)
            setNricFrontFile(data?.getUserById?.user?.user_document?.nric_front)
            setNricNumber(data?.getUserById?.user?.user_document?.nric_no)
            setAddress(data?.getUserById?.user?.user_profile?.current_address)
            handleDate(new Date(data?.getUserById?.user?.user_profile?.dob))
            setCitizenship(data?.getUserById?.user?.user_profile?.citizenship)
        }
    }, [data])

    const handleDate = (dob) => {
        setStartDate(dob);
        let currYear = new Date().getFullYear();
        var y = dob.getFullYear()
        var m = dob.getMonth() + 1
        var d = dob.getDate()
        setAge(`${currYear - y}`);
        setDateOfBirth(d + "-" + m + "-" + y);
    };

    const onSelect = async (method) => {
        if (method === "nric") {
            setSelectedSide("LEFT")
            setMethodValue(method)
        } else if (method === "singpass") {
            setSelectedSide("RIGHT")
            setMethodValue(method)
        } else {
            setSelectedSide("")
        }

    };

    return (
        <>
            {stepNo === 1 &&
                <div id="bodyWrap" className="">
                    <Container className="mealOne" fluid>

                        <Row>
                            <Col className="px-0"><AfterLoginHeader /></Col>
                        </Row>
                        {/* <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col> */}
                        <Row className="dblock">
                            <Col lg="3" className="ps-0 profilemenu outline-none" tabindex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                                <ProfileMenu />
                            </Col>
                            <Col lg="9" as="div" className="userAcc pt-4 pe-xl-5">
                                <h2 className="h2_large ms-md-4 mb-0 GrayComn">My Profile</h2>
                                <Breadcrumb className="ms-md-4 mb-5">
                                    <Breadcrumb.Item href="/">Verify My Identity</Breadcrumb.Item>
                                </Breadcrumb>
                                <Col as="section" className="stepWrap ms-md-4 pt-md-5">
                                    <Row className="text-center justify-content-center pb-4">
                                        <Col lg="7" className="text-start">
                                            <h2 className="fs22 GrayComn semibold">Please choose your method of registration</h2>
                                            <Col lg="12" as="section" className="stepsBar2 pt-4">
                                                <Col as="ul" className="list-unstyled">
                                                    <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(100%/6)" }}>1</span></Col>
                                                </Col>
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row className="text-center justify-content-center">
                                        <Col xs={12} lg="10" xl="7">
                                            <Row >
                                                <Col className="dineUser" onClick={() => { onSelect("nric"); setStepNo(2) }}>
                                                    <div className={`iconleft cursor-pointer ${selectedSide === "LEFT" ? 'bgtheme' : null}`}>
                                                        <svg width="109" height="62" viewBox="0 0 109 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_1330_76537)">
                                                                <path d="M54.4823 62C38.7986 62 23.1149 62 7.43115 62C3.17223 62 0.00324111 58.8917 0.00324111 54.696C0.000140334 38.9078 0.000140334 23.1211 0.00324111 7.33595C0.00324111 3.13876 3.15673 0.00609172 7.41874 0.00456879C38.8373 -0.00152293 70.256 -0.00152293 101.675 0.00456879C105.828 0.00456879 108.986 3.09002 108.989 7.16843C109.003 23.0815 109.003 38.9946 108.989 54.9077C108.986 58.9298 105.794 61.997 101.687 61.997C85.9521 61.9985 70.2172 61.997 54.4808 61.997L54.4823 62ZM54.5071 2.9819C38.8761 2.9819 23.2451 2.98037 7.61409 2.9819C4.79239 2.9819 3.06681 4.6693 3.06681 7.41819C3.06526 23.179 3.06526 38.9383 3.07146 54.6991C3.07146 55.1011 3.09006 55.5169 3.19394 55.9007C3.75053 57.9566 5.33967 59.0562 7.71332 59.0562C38.8715 59.0562 70.0296 59.0562 101.188 59.0562C104.299 59.0562 106.008 57.3688 106.008 54.2955C106.008 38.789 105.983 23.2826 106.031 7.77608C106.04 4.8551 104.135 2.93925 101.166 2.95144C85.6125 3.01236 70.0591 2.98037 54.5056 2.98037L54.5071 2.9819Z" fill="var(--bs-grayDark)" />
                                                                <path d="M78.1535 34.8859C71.9023 34.8859 65.6512 34.8935 59.4015 34.8798C57.7891 34.8768 56.5442 34.1488 55.8434 32.734C55.1364 31.3025 55.276 29.8633 56.2899 28.5886C57.1256 27.5393 58.2806 27.119 59.6217 27.119C67.3442 27.1236 75.0682 27.1205 82.7907 27.1205C87.4667 27.1205 92.1411 27.1053 96.8171 27.1266C99.8155 27.1403 101.735 30.0887 100.434 32.6868C99.6961 34.161 98.431 34.8844 96.7504 34.8844C90.5519 34.8844 84.3519 34.8844 78.1535 34.8844V34.8859ZM78.2031 30.1252C72.1333 30.1252 66.062 30.1252 59.9922 30.1252C59.7349 30.1252 59.4139 30.0461 59.2326 30.1648C58.924 30.3659 58.5132 30.6887 58.5008 30.975C58.4899 31.2598 58.8636 31.6497 59.1674 31.8294C59.4217 31.9787 59.8202 31.9086 60.155 31.9086C72.1411 31.9116 84.1271 31.9116 96.1132 31.9101C96.3457 31.9101 96.5783 31.9101 96.8109 31.8995C97.414 31.872 97.8186 31.5827 97.7923 30.9674C97.7674 30.4024 97.386 30.1222 96.8031 30.1283C96.5705 30.1313 96.338 30.1252 96.1054 30.1252C90.138 30.1252 84.1705 30.1252 78.2047 30.1252H78.2031Z" fill="var(--bs-grayDark)" />
                                                                <path d="M78.1736 14.0174C84.3984 14.0174 90.6248 14.0128 96.8496 14.0204C98.7612 14.022 100.426 15.3423 100.753 17.08C101.118 19.0141 100.152 20.799 98.3054 21.4828C97.7876 21.6747 97.2015 21.7691 96.6465 21.7691C84.2992 21.7843 71.9519 21.7843 59.6046 21.7782C57.4031 21.7782 55.679 20.365 55.4775 18.4339C55.2527 16.2789 56.476 14.555 58.5767 14.0981C58.924 14.022 59.293 14.0189 59.6527 14.0189C65.8263 14.0143 72 14.0159 78.1736 14.0159V14.0174ZM78.1364 18.7583C84.1814 18.7583 90.2248 18.7583 96.2697 18.7583C97.369 18.7583 97.786 18.5298 97.8046 17.9176C97.8232 17.2704 97.3597 16.9917 96.2511 16.9917C84.1628 16.9917 72.0744 16.9917 59.9845 16.9917C59.7271 16.9917 59.3969 16.9186 59.2279 17.045C58.9178 17.2749 58.4899 17.6389 58.507 17.9268C58.5225 18.2146 58.9907 18.5207 59.3194 18.7339C59.4821 18.839 59.7721 18.7568 60.0046 18.7568C66.0496 18.7568 72.093 18.7568 78.138 18.7568L78.1364 18.7583Z" fill="var(--bs-grayDark)" />
                                                                <path d="M78.0946 47.9894C71.9209 47.9894 65.7473 47.9894 59.5752 47.9894C57.1752 47.9894 55.4698 46.4162 55.4465 44.1897C55.4233 41.9358 57.1349 40.2423 59.5302 40.2392C71.955 40.227 84.3783 40.2255 96.8031 40.2392C98.9628 40.2423 100.572 41.6921 100.797 43.7282C101.014 45.6959 99.7612 47.4381 97.769 47.898C97.3985 47.9833 97.0016 47.9833 96.6171 47.9848C90.4434 47.9894 84.2698 47.9879 78.0977 47.9879L78.0946 47.9894ZM78.1628 43.244C72.0403 43.244 65.9178 43.244 59.7954 43.244C58.9008 43.244 58.4465 43.5501 58.445 44.1349C58.445 44.7151 58.9163 45.0334 59.8031 45.0471C59.8543 45.0471 59.907 45.0471 59.9582 45.0471C72.0992 45.0471 84.2419 45.0471 96.383 45.0425C96.662 45.0425 97.0171 45.0715 97.2016 44.9237C97.4667 44.7121 97.8031 44.313 97.7535 44.0618C97.6946 43.7572 97.2977 43.4678 96.9814 43.2759C96.7923 43.1617 96.4775 43.244 96.2202 43.244C90.2016 43.244 84.1814 43.244 78.1628 43.244V43.244Z" fill="var(--bs-grayDark)" />
                                                                <path d="M48.7829 47.9645H14.3535C13.3488 43.5237 15.0744 39.3371 18.969 36.6811C22.2186 34.4653 25.8605 33.4449 29.7659 33.1693C33.8589 32.8814 37.7845 33.5013 41.5039 35.2207C43.8279 36.2943 45.8341 37.7746 47.2837 39.9037C48.2868 41.3779 48.7783 43.0028 48.7814 44.7725C48.783 45.8081 48.7814 46.8452 48.7814 47.963L48.7829 47.9645ZM45.676 45.0466C45.862 43.5983 45.386 42.4211 44.6109 41.3428C43.3488 39.5899 41.5767 38.4736 39.6124 37.6406C35.9876 36.104 32.1892 35.775 28.3194 36.2958C25.4295 36.6857 22.7054 37.5964 20.3628 39.3783C18.7721 40.5875 17.5209 42.0297 17.3209 44.1115C17.2326 45.0436 17.2357 45.0466 18.1767 45.0466C27.0837 45.0466 35.9923 45.0466 44.8992 45.0466C45.1504 45.0466 45.4016 45.0466 45.676 45.0466V45.0466Z" fill="var(--bs-grayDark)" />
                                                                <path d="M31.5209 31.3051C26.7426 31.3386 22.7829 27.4978 22.7535 22.8041C22.724 17.952 26.5581 14.0472 31.3783 14.0168C36.2651 13.9863 40.2573 17.8074 40.2946 22.5513C40.3333 27.3729 36.4263 31.2716 31.5209 31.3066V31.3051ZM37.231 22.7279C37.2666 19.5679 34.7612 17.0322 31.5705 16.9956C28.3984 16.9591 25.7798 19.4734 25.7395 22.5954C25.7008 25.6916 28.2992 28.308 31.4356 28.3323C34.6341 28.3567 37.1953 25.8804 37.2294 22.7295L37.231 22.7279Z" fill="var(--bs-grayDark)" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_1330_76537">
                                                                    <rect width="109" height="62" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>

                                                        <h4 className="GrayComn">NRIC / Passport</h4>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md="6" onClick={() => { onSelect("singpass"); setStepNo(2) }}>
                                                    <div className={`porright iconright border08  ${selectedSide === "RIGHT" ? 'bgtheme' : ''}`}>
                                                        <img src={process.env.REACT_APP_PUBLIC_URL + "assets/myinfored.png"} alt="myinfoticon" className="iconred" />
                                                        <img src={process.env.REACT_APP_PUBLIC_URL + "assets/myinfo.png"} alt="myinfoticon" className="iconblack " />
                                                        <h4 className="GrayComn">Singpass MyInfo*</h4>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="text-start pt-4">
                                                <Col>
                                                    <h2 className="fs16 GrayComn semibold">*For Singapore Citizens and Permanent Residents only.</h2>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                    <Footer />
                </div>
            }
            {stepNo === 2 && methodValue === "nric" ?
                <IdentityStep02
                    userData={userData}
                    userRole={UserRole}
                    stepNo={stepNo}
                    setStepNo={setStepNo}
                    userHostType={userHostType}
                    setRegistrationType={setRegistrationType}
                    setSelectedSide={setSelectedSide}
                    setNricFrontFile={setNricFrontFile}
                    nricFrontFile={nricFrontFile}
                    setNricBackFile={setNricBackFile}
                    nricBackFile={nricBackFile}
                    setUpdateFrontFile={setUpdateFrontFile}
                    updateFrontFile={updateFrontFile}
                    setUpdateBackFile={setUpdateBackFile}
                    updateBackFile={updateBackFile}
                /> :
                stepNo === 2 && methodValue === "singpass" ?
                    <SignPass
                        userData={userData}
                        setStepNo={setStepNo}
                        stepNo={stepNo}
                        registrationType={methodValue}
                        handleDate={handleDate}
                        startDate={startDate}
                        fullname={fullname}
                        setFullname={setFullname}
                        nricNumber={nricNumber}
                        setNricNumber={setNricNumber}
                        dateOfBirth={dateOfBirth}
                        setDateOfBirth={setDateOfBirth}
                        age={age}
                        setAge={setAge}
                        profileAmendment={profileAmendment}
                        setProfileAmendment={setProfileAmendment}
                        setUpdateFrontFile={setUpdateFrontFile}
                        updateFrontFile={updateFrontFile}
                        setUpdateBackFile={setUpdateBackFile}
                        updateBackFile={updateBackFile}
                    />
                    : null}
            {
                stepNo === 3 &&
                <IdentityStep03
                    setNricFrontFile={setNricFrontFile}
                    nricFrontFile={nricFrontFile}
                    setNricBackFile={setNricBackFile}
                    nricBackFile={nricBackFile}
                    age={age}
                    userData={userData}
                    registrationType={methodValue}
                    handleDate={handleDate}
                    startDate={startDate}
                    fullname={fullname}
                    setFullname={setFullname}
                    nricNumber={nricNumber}
                    setNricNumber={setNricNumber}
                    dateOfBirth={dateOfBirth}
                    setDateOfBirth={setDateOfBirth}
                    address={address}
                    setAddress={setAddress}
                    setAge={setAge}
                    profileAmendment={profileAmendment}
                    setProfileAmendment={setProfileAmendment}
                    methodValue={methodValue}
                    setStepNo={setStepNo}
                    stepNo={stepNo}
                />
            }

            {stepNo === 4 && (
                <IdentityStep04
                    address={address}
                    setAddress={setAddress}
                    dob={dateOfBirth}
                    fullname={fullname}
                    setFullname={setFullname}
                    setMethodValue={setMethodValue}
                    setDateOfBirth={setDateOfBirth}
                    setNricFrontFile={setNricFrontFile}
                    nricFrontFile={nricFrontFile}
                    setNricBackFile={setNricBackFile}
                    nricBackFile={nricBackFile}
                    userData={userData}
                    handleDate={handleDate}
                    setPopup={setPopup}
                    setStepNo={setStepNo}
                    methodValue={methodValue}
                    gender={gender}
                    setGender={setGender}
                    citizenship={citizenship}
                    setCitizenship={setCitizenship}
                    profileAmendment={profileAmendment}
                    setProfileAmendment={setProfileAmendment}
                    nricNumber={nricNumber}
                    setNricNumber={setNricNumber}
                    age={age}
                    setAge={setAge}
                    setUpdateBackFile={setUpdateBackFile}
                    updateBackFile={updateBackFile}
                    setUpdateFrontFile={setUpdateFrontFile}
                    updateFrontFile={updateFrontFile}
                />
            )}
            {
                Popup === true && (
                    <AlertPopup 
                    show={setPopup}
                    onHide={() => setPopup(false)}
                        icon={<HiCheckCircle size="120" className='mt-5 mb-3' color="var(--bs-white)" />}
                        title="Success!"
                        description="    We have received your application for Identity Verification, if you edited any fields,
                     your information will be manually verified prior to receiving your 'Verified' status."
                    />

                )
            }

        </>


    )
}
export default IdentityStep01