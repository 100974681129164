import React from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { FiMapPin, FiEdit } from "react-icons/fi";
import { IoDiamond, IoCloseCircle } from "react-icons/io5";
import { HiOutlineClock } from "react-icons/hi";
import { BsStarFill } from 'react-icons/bs';
import { FaCircle } from "react-icons/fa";
import { AiFillCaretRight } from "react-icons/ai";
import { TbTrash } from "react-icons/tb";
import "react-pro-sidebar/dist/css/styles.css";

import "./cartsummery.css"

import AfterLoginHeader from '../components/afterLoginHeader';
import Footer from '../components/footer';
import Quantity from './quantity';


const CartSummery = () => {    
    
    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <AfterLoginHeader />
                    </Col>
                </Row>
                <br /><br />                
                <Row>
                    <Col md={11} className="m-auto">
                        <div className="mx-4 checkoutAdd">
                            <h1 className="mb-4">My Cart</h1>
                            <Row>
                                <Col sm md={9}>
                                    <Col as="section" className="cartBox zindex topPart">
                                        <Row>
                                            <Col md>
                                                <Col as="div" className="cartHeader">
                                                    <Col as="span"  className="cafeImage position-relative float-start me-3">
                                                        <img src="./assets/black_scoop.png" width="56" height="56" className="img-fluid border border-2 rounded-pill" alt="cafepic" />
                                                        <IoDiamond className='diamondIcon' size="15" color='var(--diamondBlue)' />
                                                    </Col>
                                                    <Col as="div" className="itemHead">
                                                        <h4 className="d-inline align-middle me-3">City Home Cafe</h4>
                                                        <span className="rating"><BsStarFill size="18" color="var(--bs-border)" /> <strong className="position-relative">4.5</strong></span>
                                                    </Col>
                                                    <Col as="div" className="itemaddr">
                                                        <FiMapPin size="14" color="var(--bs-border)" className="me-1" />
                                                        <p className="d-inline semibold align-middle me-1">10 Bayfront Ave, Singapore 018956</p>
                                                        <p className="d-inline semibold align-middle me-md-5 ms-3">
                                                            <HiOutlineClock size="20" color="var(--bs-border)" className="me4" /> 1.2 KM
                                                        </p>                                                        
                                                    </Col>
                                                </Col>
                                                <Col as="div" className="cartList">
                                                    <Row>
                                                        <Col md={7} className="cartItemInfo d-md-flex">
                                                            <Col className="col-auto cartImage me-3"><img src='./assets/cartItem.png' alt='carpic' /></Col>
                                                            <Col md className="cartDetail">
                                                                <h2>Chiles en Nogada <span className="discountBadge ms-2">SAVE S$2.00 <AiFillCaretRight size="33" color="var(--bs-greenDark)" /></span></h2>
                                                                <span class="menuTag me-3">Dairy</span>
                                                                <span class="menuTag me-3">Wheat</span>
                                                                <ul className="listBullet list-unstyled mt-2">
                                                                    <li className="d-inline-block text-nowrap">Black Pepper Beef &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                    <li className="d-inline-block text-nowrap">French Fries &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                    <li className="d-inline-block text-nowrap">Green Salad &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                    <li className="d-inline-block text-nowrap">Pepsi(250ml)</li>
                                                                </ul>
                                                                <Col as="span" className="spcRequest mt-2">
                                                                    <span>Special Request</span>
                                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br />incididunt ut labore et dolore magna aliqua.
                                                                </Col>
                                                            </Col>
                                                        </Col>
                                                        <Col sm={2} className="cartItemQTY mt-4">
                                                            <div className="float-end m-auto">
                                                                <Quantity />
                                                            </div>
                                                        </Col>
                                                        <Col sm={2} className="cartItemPrice mt-4 text-center">
                                                            <div className="d-inline-block text-start">
                                                                <strong className="d-inline-block mb-1">S$11.99</strong>
                                                                <span>Takeaway Order</span>
                                                                <span>+ S$0.25</span>
                                                            </div>
                                                        </Col>
                                                        <Col sm={1} className="cartItemAction text-nowrap mt-4">
                                                            <span className="svgCircle d-inline-block me-3"><FiEdit size="17" color="var(--theme-color)" /></span>
                                                            <span className="svgCircle d-inline-block"><TbTrash size="19" color="var(--theme-color)" /></span>
                                                        </Col>
                                                        <Col md={12}>
                                                            <hr className="separator mb-4" />
                                                            <br />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={7} className="cartItemInfo d-md-flex">
                                                            <Col className="col-auto cartImage me-3"><img src='./assets/cartItem.png' alt="cartpic" /></Col>
                                                            <Col md className="cartDetail">
                                                                <h2>Chiles en Nogada <span className="discountBadge ms-2">SAVE S$2.00 <AiFillCaretRight size="33" color="var(--bs-greenDark)" /></span></h2>
                                                                <span class="menuTag me-3">Dairy</span>
                                                                <span class="menuTag me-3">Wheat</span>
                                                                <ul className="listBullet list-unstyled mt-2">
                                                                    <li className="d-inline-block text-nowrap">Black Pepper Beef &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                    <li className="d-inline-block text-nowrap">French Fries &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                    <li className="d-inline-block text-nowrap">Green Salad &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                    <li className="d-inline-block text-nowrap">Pepsi(250ml)</li>
                                                                </ul>                                                                
                                                            </Col>
                                                        </Col>
                                                        <Col md={2} className="cartItemQTY mt-4">
                                                            <div className="float-end me-3">
                                                                <Quantity />
                                                            </div>
                                                        </Col>
                                                        <Col md={2} className="cartItemPrice ps-5 mt-4">
                                                            <strong className="d-inline-block mb-1">S$11.99</strong>
                                                            <span>Takeaway Order</span>
                                                            <span>+ S$0.25</span>
                                                        </Col>
                                                        <Col md={1} className="cartItemAction text-nowrap mt-4">
                                                            <span className="svgCircle d-inline-block me-3"><FiEdit size="17" color="var(--theme-color)" /></span>
                                                            <span className="svgCircle d-inline-block"><TbTrash size="19" color="var(--theme-color)" /></span>
                                                        </Col>                                                        
                                                    </Row>
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Row>
                                        <Col>
                                            <Col as="section" className="cartBox d-md-flex cartFooter">
                                                <Col className="headText">
                                                    <h3>Disposable Cutleries</h3>
                                                    <h4>Lets do our part to save the earth!</h4>
                                                </Col>
                                                <Col className="switcherButton text-end">
                                                    <div class="switches">                                                   
                                                        <input className="clickInput" style={{opacity: "0"}} type="checkbox" id="1" />
                                                        <label for="1">
                                                            <span></span>
                                                            <span></span>
                                                        </label>                                                   
                                                    </div>
                                                </Col>
                                            </Col>
                                        </Col>
                                    </Row>
                                    <br /><br /><br /><br />
                                    <Col as="section" className="cartBox zindex">
                                        <Row>
                                            <Col md>
                                                <Col as="div" className="cartHeader">
                                                    <Col as="span"  className="cafeImage position-relative float-start me-3">
                                                        <img src="./assets/black_scoop.png" width="56" height="56" className="img-fluid border border-2 rounded-pill" alt="cafepic" />
                                                        <IoDiamond className='diamondIcon' size="15" color='var(--diamondBlue)' />
                                                    </Col>
                                                    <Col as="div" className="itemHead">
                                                        <h4 className="d-inline align-middle me-3">City Home Cafe</h4>
                                                        <span className="rating"><BsStarFill size="18" color="var(--bs-border)" /> <strong className="position-relative">4.5</strong></span>
                                                    </Col>
                                                    <Col as="div" className="itemaddr">
                                                        <FiMapPin size="14" color="var(--bs-border)" className="me-1" />
                                                        <p className="d-inline semibold align-middle me-1">10 Bayfront Ave, Singapore 018956</p>
                                                        <p className="d-inline semibold align-middle me-md-5 ms-3">
                                                            <HiOutlineClock size="20" color="var(--bs-border)" className="me4" /> 1.2 KM
                                                        </p>                                                        
                                                    </Col>
                                                </Col>
                                                <Col as="div" className="cartList">
                                                    <Row>
                                                        <Col md={7} className="cartItemInfo d-md-flex">
                                                            <Col className="col-auto cartImage me-3"><img src="./assets/cartItem.png" alt='carpic' /></Col>
                                                            <Col md className="cartDetail">
                                                                <h2>Chiles en Nogada <span className="discountBadge ms-2">SAVE S$2.00 <AiFillCaretRight size="33" color="var(--bs-greenDark)" /></span></h2>
                                                                <span class="menuTag me-3">Dairy</span>
                                                                <span class="menuTag me-3">Wheat</span>
                                                                <ul className="listBullet list-unstyled mt-2">
                                                                    <li className="d-inline-block text-nowrap">Black Pepper Beef &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                    <li className="d-inline-block text-nowrap">French Fries &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                    <li className="d-inline-block text-nowrap">Green Salad &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                    <li className="d-inline-block text-nowrap">Pepsi(250ml)</li>
                                                                </ul>
                                                                <Col as="span" className="spcRequest mt-2">
                                                                    <span>Special Request</span>
                                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br />incididunt ut labore et dolore magna aliqua.
                                                                </Col>
                                                            </Col>
                                                        </Col>
                                                        <Col md={2} className="cartItemQTY mt-4">
                                                            <div className="float-end m-auto">
                                                                <Quantity />
                                                            </div>
                                                        </Col>
                                                        <Col md={2} className="cartItemPrice mt-4 text-center">
                                                            <div className="d-inline-block text-start">
                                                                <strong className="d-inline-block mb-1">S$11.99</strong>
                                                                <span>Takeaway Order</span>
                                                                <span>+ S$0.25</span>
                                                            </div>
                                                        </Col>
                                                        <Col md={1} className="cartItemAction text-nowrap mt-4">
                                                            <span className="svgCircle d-inline-block me-3"><FiEdit size="17" color="var(--theme-color)" /></span>
                                                            <span className="svgCircle d-inline-block"><TbTrash size="19" color="var(--theme-color)" /></span>
                                                        </Col>
                                                        <Col md={12}>
                                                            <hr className="separator mb-4" />
                                                            <br />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={7} className="cartItemInfo d-md-flex">
                                                            <Col className="col-auto cartImage me-3"><img src="./assets/cartItem.png" alt='carpic' /></Col>
                                                            <Col md className="cartDetail">
                                                                <h2>Chiles en Nogada <span className="discountBadge ms-2">SAVE S$2.00 <AiFillCaretRight size="33" color="var(--bs-greenDark)" /></span></h2>
                                                                <span class="menuTag me-3">Dairy</span>
                                                                <span class="menuTag me-3">Wheat</span>
                                                                <ul className="listBullet list-unstyled mt-2">
                                                                    <li className="d-inline-block text-nowrap">Black Pepper Beef &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                    <li className="d-inline-block text-nowrap">French Fries &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                    <li className="d-inline-block text-nowrap">Green Salad &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                    <li className="d-inline-block text-nowrap">Pepsi(250ml)</li>
                                                                </ul>                                                                
                                                            </Col>
                                                        </Col>
                                                        <Col md={2} className="cartItemQTY mt-4">
                                                            <div className="float-end m-auto">
                                                                <Quantity />
                                                            </div>
                                                        </Col>
                                                        <Col md={2} className="cartItemPrice mt-4 text-center">
                                                            <div className="d-inline-block text-start">
                                                                <strong className="d-inline-block mb-1">S$11.99</strong>
                                                                <span>Takeaway Order</span>
                                                                <span>+ S$0.25</span>
                                                            </div>
                                                        </Col>
                                                        <Col md={1} className="cartItemAction text-nowrap mt-4">
                                                            <span className="svgCircle d-inline-block me-3"><FiEdit size="17" color="var(--theme-color)" /></span>
                                                            <span className="svgCircle d-inline-block"><TbTrash size="19" color="var(--theme-color)" /></span>
                                                        </Col>
                                                        <Col md={12}>
                                                            <hr className="separator mb-4" />
                                                            <br />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={7} className="cartItemInfo d-md-flex">
                                                            <Col className="col-auto cartImage me-3"><img src="./assets/cartItem.png" alt='carpic' /></Col>
                                                            <Col md className="cartDetail">
                                                                <h2>Chiles en Nogada <span className="discountBadge ms-2">SAVE S$2.00 <AiFillCaretRight size="33" color="var(--bs-greenDark)" /></span></h2>
                                                                <span class="menuTag me-3">Dairy</span>
                                                                <span class="menuTag me-3">Wheat</span>
                                                                <ul className="listBullet list-unstyled mt-2">
                                                                    <li className="d-inline-block text-nowrap">Black Pepper Beef &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                    <li className="d-inline-block text-nowrap">French Fries &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                    <li className="d-inline-block text-nowrap">Green Salad &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                    <li className="d-inline-block text-nowrap">Pepsi(250ml)</li>
                                                                </ul>                                                                
                                                            </Col>
                                                        </Col>
                                                        <Col md={2} className="cartItemQTY mt-4">
                                                            <div className="float-end me-3">
                                                                <Quantity />
                                                            </div>
                                                        </Col>
                                                        <Col md={2} className="cartItemPrice ps-5 mt-4">
                                                            <strong className="d-inline-block mb-1">S$11.99</strong>
                                                            <span>Takeaway Order</span>
                                                            <span>+ S$0.25</span>
                                                        </Col>
                                                        <Col md={1} className="cartItemAction text-nowrap mt-4">
                                                            <span className="svgCircle d-inline-block me-3"><FiEdit size="17" color="var(--theme-color)" /></span>
                                                            <span className="svgCircle d-inline-block"><TbTrash size="19" color="var(--theme-color)" /></span>
                                                        </Col>                                                        
                                                    </Row>
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Row>
                                        <Col>
                                            <Col as="section" className="cartBox d-md-flex cartFooter">
                                                <Col md={7} className="headText">
                                                    <h3>Disposable Cutleries</h3>
                                                    <h4>Lets do our part to save the earth!</h4>
                                                </Col>
                                                <Col md={2} className="cartItemQTY">
                                                    <div className="float-end me-3">
                                                        <Quantity />
                                                    </div>
                                                </Col>
                                                <Col className="switcherButton text-end">
                                                    <div class="switches colorActive">                                                   
                                                        <input className="clickInput" style={{opacity: "0"}} type="checkbox" id="2" />
                                                        <label for="2">
                                                            <span></span>
                                                            <span></span>
                                                        </label>                                                   
                                                    </div>
                                                </Col>
                                            </Col>
                                        </Col>
                                    </Row>

                                    <h5 className="noteRemark mt-5">
                                       <span className="text-transform-upppercase">REMARKS:</span> Please note that an additional S$0.25 is applicable for each Pickup / Takeaway or Delivery order item, except drinks.</h5>
                                </Col>
                                <Col sm md={3}>
                                    <Col className="cartBox colTotal">
                                        <Col as="div" className="cartHeader rightCol">
                                            <Form className="ColPromo position-relative">
                                                <Form.Group className="mb-2" controlId="formBasicNumber">
                                                    <Form.Control className="shadow-none" type="number" placeholder="Promo Code" />
                                                </Form.Group>                                                
                                                <Button variant="primary" type="submit">Apply</Button>
                                            </Form>
                                        </Col>
                                        <Col as="div" className="cartMid rightCol bg-white">
                                            <div className="card py-2">
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item">Total Item          <span className="float-end">5</span></li>
                                                    <li className="list-group-item pt-0 mt-0 pb-0 mb-0">Total Price         <span className="float-end">S$221.71</span></li>
                                                    <li className="list-group-item ps-4 ms-2 fs14">Discount  <span className="float-end textGreen">(S$10.00)</span></li>
                                                    <li className="list-group-item filterCol ps-4 ms-2 fs14">Promo Code<span className="float-end textGreen">(S$20.00)</span></li>
                                                    <li className="list-group-item filterCol ps-4 ms-2"><span className="badge rounded-pill bgLightGreen mt-2">DEC20OFF <IoCloseCircle role="button" className="align-top" size="13" color="var(--bs-greenDark)" /></span></li>
                                                    <li className="list-group-item">Takeaway Charges    <span className="float-end">S$1.40</span></li>
                                                    <li className="list-group-item pt-0">Delivery Charges    <span className="float-end">S$0.00</span></li>
                                                    <li className="list-group-item pt-0">Tax Inclusive       <span className="float-end">S$15.45</span></li>
                                                    <li className="list-group-item GrandTotal">Grand Total       <span className="float-end">S$208.56</span></li>
                                                </ul>
                                            </div>
                                        </Col>
                                    </Col>
                                    <Col as="div" className="cartCheckout">
                                        <Form className="position-relative">
                                            <Button variant="primary" type="submit">Checkout - S$208.56</Button>
                                        </Form>
                                    </Col>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <br /><br /><br />
            </Container>
            <br /><br />
            <Footer />
        </div>
    )    
}
export default CartSummery