import React, { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";

const Quantity = ({ max, value, updateVal }) => {
  const [qty, setQty] = useState(Number(value) || 1);

  const decreaseQty = () => {
    if (qty > 1) {
      setQty(qty - 1);
      updateVal(qty - 1)
    } else {
      setQty(1);
    }
  };

  const increaseQty = () => {
    if (qty < max) {
      setQty(qty + 1);
      updateVal(qty + 1)
    } else {
      setQty(max);
    }
  };

  const onChange = (e) => {
    const inputValue = parseInt(e.target.value);
    if (inputValue > 1 && inputValue < max) {
      setQty(inputValue);
      updateVal(inputValue)
    } else {
      setQty(0);
      updateVal(0)
    }
  };
  return (
    <div className="qtyBox">
      <button className="decrement" type="button" onClick={decreaseQty}> <FaMinus size="10" className="minusIcon" color="var(--bs-grayDark)" /> </button>
      <input type="text" onChange={onChange} value={qty} />
      <button className="increment" type="button" onClick={increaseQty}> <FaPlus size="10" className="plusIcon" color="var(--bs-grayDark)" /> </button>
    </div>
  );
};

export default Quantity;
