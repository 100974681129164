import React, { useState } from "react";
import { Row, Col, Form, Button, FloatingLabel } from "react-bootstrap";
import { FiMapPin } from "react-icons/fi";
import { addClass } from "../../../commanFunctions";

const HostBusiness09 = (params) => {
  const [errorMessage, setErrorMessage] = useState(undefined);

  const onNextpage = (event) => {
    event.preventDefault();
    if (params.gender === "" || params.gender === "not selected") {
      setErrorMessage({
        error: "empty gender",
        message: "Please Enter Gender",
      });
    } else if (params.citizenship === "") {
      setErrorMessage({
        error: "empty citizenship",
        message: "Please Enter Citizenship",
      });
    } else if (params.address === "") {
      setErrorMessage({
        error: "empty address",
        message: "Please Enter Address",
      });
    } else {
      addClass();
      setTimeout(() => {
        params.setStepNo(10);
      }, 1400);
    }
  };

  const goBack = () => {
    addClass();
    setTimeout(() => {
      params.setStepNo(8);
    }, 1400);
  };

  return (
    <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
      <div className="front">
        <div className="heading">
          <h2 className="small lh-1 fs-normal mb-2">Become A Host</h2>
          <p className="semibold m-0">Please confirm your personal registration details.</p>
        </div>
        <Col as="section" className="stepsBar2 pt-4">
          <Col as="ul" className="list-unstyled">
            <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(100%/2)" }}>9</span></Col>
          </Col>
        </Col>
        <Col as="section" className="signpUser pt-3">
          <Form onSubmit={onNextpage} className="accForm">
            <FloatingLabel controlId="floatingInput" label="Gender" className="mb-3 ">
              <Form.Select
                className="semibold"
                aria-label="Floating label select example"
                value={params.gender}
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrorMessage({
                      error: "empty gender",
                      message: "Please Enter Gender",
                    });
                  } else {
                    setErrorMessage(undefined);
                  }
                  params.setGender(e.target.value);
                }}>
                <option value="not selected">Please Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
                <option value="do not disclose">Do Not Disclose</option>
              </Form.Select>
              {errorMessage && errorMessage.error === "empty gender" && (
                <div className="invalid-feedback d-block">
                  {errorMessage.message}
                </div>
              )}
            </FloatingLabel>
            <FloatingLabel controlId="floatingInput" label="Citizenship" className="mb-3 ">
              <Form.Control
                type="text"
                placeholder="Citizenship"
                value={params.citizenship}
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrorMessage({
                      error: "empty citizenship",
                      message: "Please Enter Citizenship",
                    });
                  } else {
                    setErrorMessage(undefined);
                  }
                  params.setCitizenship(e.target.value);
                }}
              />
              {errorMessage &&
                errorMessage.error === "empty citizenship" && (
                  <div className="invalid-feedback d-block">
                    {errorMessage.message}
                  </div>
                )}
            </FloatingLabel>
            <FloatingLabel controlId="floatingInput" label="Address" className="mb-4">
              <Form.Control
                type="text"
                placeholder="Address"
                value={params.address}
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrorMessage({
                      error: "empty address",
                      message: "Please Enter Address",
                    });
                  } else {
                    setErrorMessage(undefined);
                  }
                  params.setAddress(e.target.value);
                }}
              />
              <FiMapPin className="calendarIcon position-absolute top-0 end-0 mt-4 me-3" size="20" color="var(--theme-color)" />
              {errorMessage && errorMessage.error === "empty address" && (
                <div className="invalid-feedback d-block">
                  {errorMessage.message}
                </div>
              )}
            </FloatingLabel>

            {params.registrationType === "acra" &&
              <>
                <div className="mb-2 lh-1 form-radio">
                  <input
                    name="group1"
                    type="radio"
                    id={`inline-radio-1`}
                    checked={params.profileAmendment === true}
                    onChange={(e) => params.setprofileAmendment(true)}
                    defaultChecked
                  />
                  <label htmlFor={`inline-radio-1`} className="ms-3 pt-1 lh-sm semibold themeColor">
                    Information is correct. No amendment required.
                  </label>
                </div>

                <div className="lh-1 form-radio">
                  <input
                    name="group1"
                    type="radio"
                    id={`inline-radio-2`}
                    checked={params.profileAmendment === false}
                    onChange={(e) => params.setprofileAmendment(false)}
                  />
                  <label htmlFor={`inline-radio-2`} className="ms-3 pt-1 lh-sm semibold themeColor">
                    Information is incorrect. Proceed to amend.
                  </label>
                </div>
              </>
            }

            <Row className="pt-4 mt-2">
              <Col className="align-self-center">
                <Button
                  className="fs26 themeColor semibold text-decoration-none lh-1 p-0"
                  variant="link"
                  type="button"
                  onClick={goBack}
                >
                  <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  Previous
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                  variant="primary"
                  type="submit"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
    </section>
  )
}

export default HostBusiness09