// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  setDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import moment from "moment";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCoazu3WfK2hmYJvHgujhkJRtl1XBImwGo",
  authDomain: "cribbly-4a72e.firebaseapp.com",
  projectId: "cribbly-4a72e",
  storageBucket: "cribbly-4a72e.appspot.com",
  messagingSenderId: "395110096971",
  appId: "1:395110096971:web:c676a9e5bff80c81e02dce",
  measurementId: "G-80136JM2YS",
  databaseURL: "https://cribbly-4a72e-default-rtdb.asia-southeast1.firebasedatabase.app",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth();
const authUser = auth.currentUser;

const registerWithEmailAndPassword = async (name, email, password, userid) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      userid: userid,
      usertype: "diner",
      ishost: false,
      email,
      reportedUsers:[],
      mutedUsers:[],
      msg_timestamp:"0"
    });
    console.log(user);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const loginWithEmailAndPassword = async (email, password) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    return res.user;
   
    
  } catch (err) {
    return err
  }
};

const UpdateFirebaseUser = async (name) => {
  try {
    const user = await fetchCurrentUser();
    if(!user)
    {
      console.log("User not Found");
    }
    await updateDoc(
      doc(db, "users", user?.id),
      {
          name: name
      }
    );
    
  } catch (err) {
    console.log(err);
    return err
  }
};


const SignOut = async () => {
  signOut(auth);
}

const fetchCurrentUser = async (userData) => {
  try {
    const user = userData ? userData : authUser;
    console.log(user);
    const q = query(collection(db, "users"), where("uid", "==", user?.uid));
    const doc = await getDocs(q);
    const id = doc.docs[0].id;
    const data = {
      ...doc.docs[0].data(),
      id
    }
    return data;
  } catch (err) {
    console.error(err);
    // alert("An error occured while fetching user data");
  }

}

// linkWithCredential(auth.currentUser, credential)
//   .then((usercred) => {
//     const user = usercred.user;
//     console.log("Anonymous account successfully upgraded", user);
//   }).catch((error) => {
//     console.log("Error upgrading anonymous account", error);
//   });

export {
  registerWithEmailAndPassword,
  SignOut,
  fetchCurrentUser,
  loginWithEmailAndPassword,
  UpdateFirebaseUser,
  auth,
  db
}
