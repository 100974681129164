import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap';
import { RiArrowRightSLine } from "react-icons/ri";
import { gql, useQuery } from '@apollo/client';
import { addClass } from '../../commanFunctions';
import 'react-phone-number-input/style.css'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import { checkUserExist } from '../../queries/queries';

const DinerSignupFive = params => {
    const [selected, setSelected] = useState(params.phoneNumber.split("-")[0] || "+65");
    const [mobileNo, setMobileNo] = useState(params.phoneNumber.split("-")[1] || "");
    const [errorMessage, setErrorMessage] = useState(undefined);

    const countryName = parsePhoneNumber(String(params.phoneNumber));

    const checkUser = checkUserExist();

    const apiSkip = mobileNo.length < 10

    const { data, error, refetch } = useQuery(checkUser, { skip: apiSkip, variables: { email: (selected + "-" + mobileNo) } });
    const onNextPage = async (event) => {
        refetch()
        if (mobileNo.length < 10) {
            setErrorMessage({
                error: 'empty phone',
                message: 'Please Enter Phone Number'
            })
        } else if (data.checkUserExist?.status === "success") {
            addClass()
            setTimeout(() => {
                params.setStepNo(6)
            }, 1400)
        } else if (data.checkUserExist?.status === "error") {
            setErrorMessage({
                error: 'empty phone',
                message: data.checkUserExist.message
            })
        } else {
            console.log("else log....")
        }

        params.setPhoneNumber(selected + "-" + mobileNo)
    };

    const onSkip = () => {
        addClass()
        setTimeout(() => {
            params.setStepNo(7)
        }, 1400)
    }

    useEffect(() => {
        if (error) {
            if (error.message === 'Failed to fetch') {
                setErrorMessage({
                    error: 'empty phone',
                    message: error.message
                })
            } else {
                const errMessage = JSON.parse(error.message)
                setErrorMessage({
                    error: 'empty phone',
                    message: errMessage
                })
            }
        }
    }, [error]);

    const handlePhoneNo = (e) => {
        if (!e.target.value) {
            setErrorMessage({
                error: 'empty phone',
                message: 'Please Enter Phone Number'
            })
        } else if (!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(e.target.value)) {
            setErrorMessage({
                error: 'empty phone',
                message: 'Invalid Phone Number'
            })
        } else {
            setErrorMessage(undefined)
        }
        setMobileNo(e.target.value);
    }

    const goBack = () => {
        addClass()
        setTimeout(() => {
            params.setStepNo(3)
        }, 1400)
    }

    return (

        <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
            <div className="front overflow-visible">
                <div className="heading">
                    <h2 className='small lh-1 fs-normal mb-2'>Signup <span className="skipCont float-end" onClick={onSkip}>Skip <RiArrowRightSLine size="30" /></span></h2>
                    <p className="semibold m-0">Please input your mobile number.</p>
                </div>
                <Col as="section" className="stepsBar pt-4">
                    <Col as="ul" className="d-flex justify-content-between list-unstyled">
                        <li className="p2 active first"><span>1</span></li>
                        <li className="p2 active"><span>2</span></li>
                        <li className="p2 active"><span>3</span></li>
                        <li className="p2"><span>4</span></li>
                        <li className="p2 last"><span>5</span></li>
                    </Col>
                </Col>
                <Col as="section" className="signpUser pt-3">
                    <Form>
                        <Form.Group controlId="formPhone" label="Email or Contact Number" className="mb-5 pb-1 d-lg-flex justify-content-between">
                            <Col as="div" className="w-25 bg-white flagBox">
                                <PhoneInput
                                    className="countryCode semibold"
                                    international
                                    defaultCountry={countryName ? countryName.country : 'SG'}
                                    value={selected}
                                    onChange={setSelected}
                                />
                            </Col>
                            <Form.Control className="w70" type="text" placeholder="9123 4567 20"
                                value={mobileNo}
                                onChange={handlePhoneNo} />
                        </Form.Group>
                        {errorMessage && errorMessage.error === 'empty phone' && (
                            <div className="invalid-feedback d-block">
                                {errorMessage.message}
                            </div>
                        )}
                        <Row>
                            <Col className="align-self-center">
                                <Button className="fs26 themeColor semibold text-decoration-none lh-1 p-0" variant="link" type="button" onClick={goBack}>
                                    <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    Previous
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                                    variant="primary"
                                    type="button"
                                    onClick={onNextPage}>
                                    Next
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </div>
        </section>


    )
}
export default DinerSignupFive