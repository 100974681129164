import { useQuery } from "@apollo/client";
import { GetItemDetails, verifyToken } from "./queries/queries";

export const addClass = () => {
  document.getElementById("signUpFlip").classList.add("scalIn");
  setTimeout(function () {
    document.getElementById("scalOut").classList.add("scalOut");
  }, 100);

  setTimeout(function () {
    document.getElementById("signUpFlip").classList.add("flip");
  }, 1000);

  setTimeout(function () {
    document.getElementById("scalOut").classList.remove("scalOut");
  }, 1000);

  setTimeout(function () {
    document.getElementById("signUpFlip").classList.remove("scalIn");
  }, 1000);
}

export const arrayFilterWithSameData = (primary_array, secondary_array) => {
  if (primary_array) {
    let selected_array = primary_array?.map((data) =>
      secondary_array && (secondary_array?.find((item) => (item.value === data)))
    )
    return selected_array
  }
}

export const arrayFilterWithoutSameData = (primary_array, secondary_array) => {

  if (primary_array) {
    let u_arr = [...secondary_array];

    u_arr.forEach((el, index) => {
      if (primary_array.indexOf(el.value) >= 0) {
        delete u_arr[index]
      }
    })
    /* primary_array.forEach(element => {
      u_arr.forEach((el, index) => {
        if (el.value == element) {
          delete u_arr[index]
        }
      })
    }); */
    let unselected_array = u_arr.filter(() => true);
    return unselected_array
  }
}

export const ItemDetailsGetApi = (cribId, itemId) => {
  let result = (null);
  const skipApi = !cribId || !itemId
  const queryGetItemDetails = GetItemDetails();
  const { data, error } = useQuery(queryGetItemDetails, {
    skip: skipApi,
    variables: { crib_id: cribId, item_id: itemId }
  })

  result = { data: data, error: error }

  return (result)
}