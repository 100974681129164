import { useMutation } from '@apollo/client';
import React, { useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { HiCheckCircle } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';
import { genrateNewPassword } from '../../../queries/queries';

const NewPassword = (params) => {
    console.log("params1=============>", params)
    const navigate = useNavigate()
    const NewPassword = genrateNewPassword();
    const [generatePassword] = useMutation(NewPassword);
    // const body = document.body.classList.add('modalWhite');
    // const handleClick = event => {
    //     event.currentTarget.classList.toggle('switchActive');
    // };
    const [newPass, setNewPass] = useState(true)
    const [confirmPass, setConfirmPass] = useState(true)
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [newPassword, setNewPassword] = useState('')
    const [ConfirmPassword, setConfirmPassword] = useState('')
    const [show, setShow] = useState(false);

    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

    const handleClose = () => setShow(false);
    const handleResetPass = async () => {
        if (newPassword === '') {
            setErrorMessage({
                error: 'empty password',
                message: 'Please Enter Password'
            })
        } else if (!strongRegex.test(newPassword)) {
            setErrorMessage({
                error: 'password strength',
                message: ' Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character '
            })
        } else if (ConfirmPassword === '') {
            setErrorMessage({
                error: 'empty conf-password',
                message: 'Please Enter Confirm Password'
            })
        } else if (newPassword !== ConfirmPassword) {
            setErrorMessage({
                error: 'not matched',
                message: 'Password and Confirm Password not matched'
            })
        } else {
            const passwordGenerate = await generatePassword({
                variables: {
                    "text": params.email,
                    "password": newPassword,
                    "confirm_password": ConfirmPassword
                }
            })
            if (passwordGenerate?.data?.genrateNewPassword?.status === "success") {
                setShow(true)
                setTimeout(() => {
                    navigate("/loginpage")
                }, 2000)
            }
        }
    }

    return (
        <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
            <div className="front">
                <div className='heading mb-4'>
                    <h2 className='small lh-1 fs-normal mb-0'>New Password</h2>
                    <p className="semibold">Please enter your new password.</p>
                </div>

                <Form>
                    <Form.Group controlId="formBasicPassword" label="New Password" className="mb-3 position-relative">
                        <Form.Control type={newPass ? 'password' : 'text'} placeholder="New Password" value={newPassword} onChange={e => {
                            if (!e.target.value) {
                                setErrorMessage({
                                    error: 'empty password',
                                    message: 'Please Enter Password'
                                })
                            } else if (!strongRegex.test(e.target.value)) {
                                setErrorMessage({
                                    error: 'password strength',
                                    message: ' Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character '
                                })
                            } else {
                                setErrorMessage(undefined)
                            }
                            setNewPassword(e.target.value)
                        }} />
                        <span className="showPass" onClick={() => { setNewPass(!newPass) }}>
                            {newPass ? <FiEye size="20" color="var(--bs-border)" /> : <FiEyeOff size="20" color="var(--bs-border)" />}
                        </span>
                        {errorMessage && (errorMessage.error === 'empty password' || errorMessage.error === 'password strength') && (
                            <div className="invalid-feedback d-block">
                                {errorMessage.message}
                            </div>
                        )}
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword" label="Re-enter Password" className="mb-5 position-relative">
                        <Form.Control type={confirmPass ? 'password' : 'text'} placeholder="Re-enter Password" value={ConfirmPassword} onChange={e => {
                            if (!e.target.value) {
                                setErrorMessage({
                                    error: 'empty conf-password',
                                    message: 'Please Enter Confirm Password'
                                })
                            } else {
                                setErrorMessage(undefined)
                            }
                            setConfirmPassword(e.target.value)
                        }} />
                        <span className="showPass" onClick={() => { setConfirmPass(!confirmPass) }}>
                            {confirmPass ? <FiEye size="20" color="var(--bs-border)" /> : <FiEyeOff size="20" color="var(--bs-border)" />}
                        </span>
                        {errorMessage && (errorMessage.error === 'empty conf-password' || errorMessage.error === 'not matched') && (
                            <div className="invalid-feedback d-block">
                                {errorMessage.message}
                            </div>
                        )}
                    </Form.Group>
                    <Button onClick={handleResetPass} className="w-100 py-2 lh-lg border-0 hvr-float-shadow" variant="primary">Reset Password</Button>
                </Form>
            </div>
            <Modal
                className="successfullyPop" centered
                aria-labelledby="contained-modal-title-vcenter"
                show={show} onHide={handleClose}>
                <Modal.Body className='text-center'>
                    <br /><br /><br />
                    <HiCheckCircle size="100" className='mb-3' color="var(--bs-white)" />
                    <p>Your password has been successfully reset.</p>
                    <br /><br /><br />
                </Modal.Body>
            </Modal>

        </section>
    )
}
export default NewPassword