import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { HiCheckCircle } from 'react-icons/hi';
import { RiArrowRightSLine, RiCheckboxBlankCircleFill } from "react-icons/ri";
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumber } from 'react-phone-number-input';
import { Link, useNavigate } from 'react-router-dom';
import AlertPopup from '../../components/alertPopup';
import CountdownTimer from '../../components/CountdownTimer';
import { checkUserExist, SignUp_EmailPhoneVerification, SignUp_OTPVerification, UpdateAccountTwoSetup } from '../../queries/queries';


const DinerSignupEleven = (params) => {
    const navigate = useNavigate()
    const [selectedSide, setSelectedSide] = useState("LEFT");

    const [onShow, setOnShow] = useState(false)
    const [mobPop, setMobPop] = useState(false);
    const [otpPop, setOtpPop] = useState(false);

    const [selected, setSelected] = useState(params.phoneNumber.split("-")[0] || "+65");
    const [mobileNo, setMobileNo] = useState(params.phoneNumber.split("-")[1] || "");
    const [errorMessage, setErrorMessage] = useState(undefined);
    const countryName = parsePhoneNumber(String(params.phoneNumber));


    const [otpone, setOtpOne] = useState('');
    const [otptwo, setOtpTwo] = useState('');
    const [otpthree, setOtpThree] = useState('');
    const [otpfour, setOtpFour] = useState('');
    const [otpfive, setOtpFive] = useState('');
    const [otpsix, setOtpSix] = useState('');

    const [ResetCountdown, setResetCountdown] = useState(false);
    const [popupMessage, setPopupMessage] = useState({});

    const handleMobPopClose = () => setMobPop(false);
    const handleOtpPopClose = () => setOtpPop(false);

    // api call

    const checkUser = checkUserExist();

    const SendOTPByEmail = SignUp_EmailPhoneVerification();
    const [OTPData] = useMutation(SendOTPByEmail);

    const verifyOtp = SignUp_OTPVerification();
    const [verifyOTPData] = useMutation(verifyOtp);

    const UpdateAccountTwo = UpdateAccountTwoSetup();
    const [UpdateAccount] = useMutation(UpdateAccountTwo);

    //  api function call
    const apiSkip = mobileNo.length < 10

    const { data, error, refetch } = useQuery(checkUser, { skip: apiSkip, variables: { email: (selected + "-" + mobileNo) } });

    const OTPSend = async () => {
        const dataOtp = await OTPData({ variables: { text: (selected + "-" + mobileNo) } })
        if (dataOtp?.data?.SignUp_EmailPhoneVerification?.status === "success") {
            console.log("OTP successfully send on your email")
        }
    }

    const onSelect = async (data) => {
        if (data === "email") {
            setSelectedSide("LEFT")
            handleUpdateAccount();
        } else if (data === "mobile" && params?.addUser?.addUser?.user?.phone_number !== "") {
            setSelectedSide("RIGHT")
            handleUpdateAccount();
        } else {
            setSelectedSide('')
        }
        setOnShow(true)
    }

    const skipBtn = () => {
        setOnShow(true)
    }

    const closePopup = () => {
        setOnShow(false)
        // window.location.reload();
        // navigate('/diner')
    }

    const onNextPage = async (event) => {
        refetch()
        if (mobileNo.length < 10) {
            setErrorMessage({
                error: 'empty phone',
                message: 'Please Enter Phone Number'
            })
        } else if (data.checkUserExist?.status === "success") {
            setTimeout(() => {
                setMobPop(false);
                setOtpPop(true);
                OTPSend();
            }, 1400)
        } else if (data.checkUserExist?.status === "error") {
            setErrorMessage({
                error: 'empty phone',
                message: data.checkUserExist.message
            })
        } else {
            console.log("else log....")
        }

        params.setPhoneNumber(selected + "-" + mobileNo)
    };

    const inputfocus = (elmnt) => {
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        } else if (elmnt.key === "Tab") {
            const next = elmnt.target.tabIndex - 1;
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        } else {
            const next = elmnt.target.tabIndex;
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        }
    }


    const onResend = () => {
        setErrorMessage({
            error: 'resend otp',
            message: 'Resend OTP on your email.'
        })
        setOtpOne('')
        setOtpTwo('')
        setOtpThree('')
        setOtpFour('')
        setOtpFive('')
        setOtpSix('')
        OTPSend();
        setResetCountdown(true)
    }

    const backOnMobileNo = () => {
        setOtpPop(false);
        setTimeout(() => {
            setMobPop(true);
        }, 500)
    }

    const handleUpdateAccount = async () => {
        let data = await UpdateAccount({
            variables: {
                auth_method: selectedSide === "LEFT" ? "email" : "mobile",
                phone_number: String(selected + "-" + mobileNo)
            }
        })
        if (data?.data?.updateAccountTwoSetup?.status === "success") {
            setPopupMessage({
                status: data?.data?.updateAccountTwoSetup?.status,
                message: data?.data?.updateAccountTwoSetup?.message
            })
            setOtpPop(false);
            setTimeout(() => {
                navigate('/diner');
            }, 500)
        } else {
            setPopupMessage({
                status: data?.data?.updateAccountTwoSetup?.status,
                message: data?.data?.updateAccountTwoSetup?.message
            })
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        const otp = otpone + otptwo + otpthree + otpfour + otpfive + otpsix;
        const dataVerify = await verifyOTPData({ variables: { otp: Number(otp), text: params.phoneNumber } })
        if (dataVerify?.data?.SignUp_OTPVerification?.status === "success") {
            handleUpdateAccount();
        } else {
            console.log(dataVerify?.data?.SignUp_OTPVerification?.message)
            setErrorMessage({
                error: 'invalid otp',
                message: dataVerify?.data?.SignUp_OTPVerification?.message
            })
        }
    }


    return (
        <>
            <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
                <div className="front">
                    <div className="heading">
                        <h2 className='small lh-1 fs-normal mb-2'>Account Setup
                            <span className="skipCont float-end" onClick={skipBtn}>Skip <RiArrowRightSLine size="30" /></span>
                        </h2>
                        <p className="semibold m-0 lh-1">Welcome aboard! Your account has been successfully created. Just a few more things to help you get going.</p>
                    </div>
                    <br /><br />
                    <p className="semibold mb-2 pb-3 lh-1">Preferred 2FA Method</p>
                    <Col as="section" className="signpUser mb-5 row text-center">
                        <Col className="dineUser" onClick={() => onSelect("email")}>
                            <div className={`iconleft cursor-pointer ${selectedSide === "LEFT" ? 'bgtheme' : ''}`}>
                                <svg width="82" height="64" viewBox="0 0 82 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.83205 13.4189C2.14276 12.9594 1.21145 13.1457 0.751925 13.8349C0.292396 14.5242 0.478657 15.4555 1.16795 15.9151L2.83205 13.4189ZM36.1926 37.4621L37.0247 36.214L37.0247 36.214L36.1926 37.4621ZM45.8074 37.4621L44.9753 36.214L44.9753 36.214L45.8074 37.4621ZM80.8321 15.9151C81.5213 15.4555 81.7076 14.5242 81.2481 13.8349C80.7886 13.1457 79.8572 12.9594 79.168 13.4189L80.8321 15.9151ZM1.16795 15.9151L35.3606 38.7101L37.0247 36.214L2.83205 13.4189L1.16795 15.9151ZM35.3605 38.7101C38.7755 40.9869 43.2245 40.9869 46.6395 38.7101L44.9753 36.214C42.568 37.819 39.432 37.819 37.0247 36.214L35.3605 38.7101ZM46.6395 38.7101L80.8321 15.9151L79.168 13.4189L44.9753 36.214L46.6395 38.7101ZM10.6667 63.8337H71.3333V60.8337H10.6667V63.8337ZM71.3333 63.8337C76.9484 63.8337 81.5 59.282 81.5 53.667H78.5C78.5 57.6252 75.2915 60.8337 71.3333 60.8337V63.8337ZM81.5 53.667V10.3337H78.5V53.667H81.5ZM81.5 10.3337C81.5 4.71878 76.9484 0.166992 71.3333 0.166992V3.16699C75.2915 3.16699 78.5 6.3756 78.5 10.3337H81.5ZM71.3333 0.166992H10.6667V3.16699H71.3333V0.166992ZM10.6667 0.166992C5.05177 0.166992 0.5 4.71876 0.5 10.3337H3.5C3.5 6.37562 6.70862 3.16699 10.6667 3.16699V0.166992ZM0.5 10.3337V53.667H3.5V10.3337H0.5ZM0.5 53.667C0.5 59.282 5.05178 63.8337 10.6667 63.8337V60.8337C6.70861 60.8337 3.5 57.6252 3.5 53.667H0.5Z" fill="#404040" />
                                </svg>                                                  <h4>Email</h4>
                            </div>
                        </Col>
                        <Col className="hostUser" onClick={() => { onSelect("mobile"); setMobPop(true); }}>
                            <div className={`iconright cursor-pointer ${selectedSide === "RIGHT" ? 'bgtheme' : null}`}>
                                <svg width="52" height="89" viewBox="0 0 52 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M44.3965 0H7.6049C3.66131 0 0.451172 3.21014 0.451172 7.15373V81.2478C0.451172 85.1914 3.66131 88.4016 7.6049 88.4016H44.3965C48.3401 88.4016 51.5502 85.1914 51.5502 81.2478V7.15373C51.5502 3.21014 48.3401 0 44.3965 0ZM7.6049 4.08759H44.3965C46.0867 4.08759 47.4626 5.46346 47.4626 7.15373V12.1525H4.53912V7.15373C4.53912 5.46349 5.91499 4.08759 7.60527 4.08759H7.6049ZM47.4624 16.2401V65.9179H4.53894V16.2401H47.4624ZM44.3963 84.3136H7.60472C5.91448 84.3136 4.53857 82.9378 4.53857 81.2475V70.006H47.4621V81.2482C47.4628 82.9377 46.0869 84.3136 44.3966 84.3136H44.3963Z" fill="#404040" />
                                </svg>                                                  <h4>Mobile</h4>
                            </div>
                        </Col>
                    </Col>
                    <p className="themeColor mt-4 lh-sm fs14 regular">
                        Enhance your Cribbly account security by choosing one of the 2FA method. You can change your preferences from your profile settings at a later time.
                    </p>
                </div>
            </section>
            {params?.addUser?.addUser?.user?.phone_number === "" &&
                <Modal
                    show={mobPop}
                    centered
                    onHide={handleMobPopClose}>
                    {/* <Modal.Body className='modal-input text-center m-0'> */}
                    <Form>
                        <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
                            <div className="front overflow-visible">
                                <div className="heading">
                                    <h1 className="semibold m-0">Please input your mobile number.</h1>
                                </div>
                                <Col as="section" className="signpUser pt-3">
                                    <Form>
                                        <Form.Group controlId="formPhone" label="Email or Contact Number" className="mb-5 pb-1 d-lg-flex justify-content-between">
                                            <Col as="div" className="w-25 bg-white flagBox">
                                                <PhoneInput
                                                    className="countryCode semibold"
                                                    international
                                                    defaultCountry={countryName ? countryName.country : 'SG'}
                                                    value={selected}
                                                    onChange={setSelected}
                                                />
                                            </Col>
                                            <Form.Control className="w70" type="text" placeholder="9123 4567 20"
                                                value={mobileNo}
                                                onChange={(e) => {
                                                    if (!e.target.value) {
                                                        setErrorMessage({
                                                            error: 'empty phone',
                                                            message: 'Please Enter Phone Number'
                                                        })
                                                    } else {
                                                        setErrorMessage(undefined)
                                                    }
                                                    if (!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(e.target.value)) {
                                                        setErrorMessage({
                                                            error: 'empty phone',
                                                            message: 'Invalid Phone Number'
                                                        })
                                                    } else {
                                                        setErrorMessage(undefined)
                                                    }
                                                    setMobileNo(e.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                        {errorMessage && errorMessage.error === 'empty phone' && (
                                            <div className="invalid-feedback d-block">
                                                {errorMessage.message}
                                            </div>
                                        )}
                                        <Row>
                                            <Col>
                                                <Button
                                                    className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                                                    variant="primary"
                                                    type="button"
                                                    onClick={onNextPage}>
                                                    Next
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </div>
                        </section>
                    </Form>
                    {/* </Modal.Body> */}
                </Modal>
            }
            {params?.addUser?.addUser?.user?.phone_number === "" &&
                <Modal
                    show={otpPop}
                    centered
                    onHide={handleOtpPopClose}>
                    <Form>
                        <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
                            <div className="front overflow-visible">
                                <div className="heading">
                                    <h1 className="semibold m-0">OTP sent to your mobile {params.phoneNumber.split("-")[0]} <RiCheckboxBlankCircleFill color="var(--bs-gray55)" size="12" /><RiCheckboxBlankCircleFill color="var(--bs-gray55)" size="12" /><RiCheckboxBlankCircleFill color="var(--bs-gray55)" size="12" /><RiCheckboxBlankCircleFill color="var(--bs-gray55)" size="12" />  {params.phoneNumber.slice(-4)}</h1>
                                </div>

                                <Col as="section" className="signpUser pt-3">
                                    <Form.Group controlId="formMobileOTP" className="mb-1 pb-1 row otpScreem">
                                        <Col md={2}><input type="text" inputMode="numeric" id="input1" placeholder="." value={otpone} onChange={(e) => {
                                            setOtpOne(e.target.value)
                                        }} maxLength="1" tabIndex="1" onKeyUp={e => inputfocus(e)} /></Col>

                                        <Col md={2}><input type="text" inputMode="numeric" id="input2" placeholder="." value={otptwo} onChange={(e) => {
                                            setOtpTwo(e.target.value)
                                        }} maxLength="1" tabIndex="2" onKeyUp={e => inputfocus(e)} /></Col>

                                        <Col md={2}><input type="text" inputMode="numeric" id="input3" placeholder="." value={otpthree} onChange={(e) => {
                                            setOtpThree(e.target.value)
                                        }} maxLength="1" tabIndex="3" onKeyUp={e => inputfocus(e)} /></Col>

                                        <Col md={2}><input type="text" inputMode="numeric" id="input4" placeholder="." value={otpfour} onChange={(e) => {
                                            setOtpFour(e.target.value)
                                        }} maxLength="1" tabIndex="4" onKeyUp={e => inputfocus(e)} /></Col>

                                        <Col md={2}><input type="text" inputMode="numeric" id="input5" placeholder="." value={otpfive} onChange={(e) => {
                                            setOtpFive(e.target.value)
                                        }} maxLength="1" tabIndex="5" onKeyUp={e => inputfocus(e)} /></Col>

                                        <Col md={2}><input type="text" id="input6" placeholder="." value={otpsix} onChange={(e) => {
                                            setOtpSix(e.target.value)
                                        }} maxLength="1" tabIndex="6" inputMode="numeric" onKeyUp={e => inputfocus(e)} /></Col>
                                        {errorMessage && (errorMessage.error === 'invalid otp' || errorMessage.error === 'resend otp') && (
                                            <div className="invalid-feedback d-block">
                                                {errorMessage.message}
                                            </div>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="mb-4 pb-4" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label className='float-start'>
                                            <Link to="#" className="forgotPass text-decoration-none" onClick={onResend}>Resend</Link>
                                            <span className="text-black px-1">or</span>
                                            <Link to="#" className="forgotPass text-decoration-none" onClick={backOnMobileNo}>Change Number</Link>
                                        </Form.Label>
                                        <Form.Label className='float-end mt-1 fs14'>
                                            <CountdownTimer
                                                countdown={120}
                                                ResetCountdown={ResetCountdown}
                                                setResetCountdown={setResetCountdown}
                                            />
                                        </Form.Label>
                                    </Form.Group>
                                    <Button className="w-100 py-2 lh-lg border-0 hvr-float-shadow" variant="primary" onClick={handleSubmit}>Submit</Button>
                                    <br /><br />
                                </Col>
                            </div>
                        </section>
                    </Form>
                </Modal>
             }

            {onShow == true && popupMessage.status === "success" ? (
                <AlertPopup
                    show={onShow}
                    onHide={() => setOnShow(false)}
                    icon={
                        <HiCheckCircle
                            size="120"
                            className="mt-5 mb-3"
                            color="var(--bs-white)"
                        />
                    }
                    title="Success!"
                    description={popupMessage.message}
                />
            ) : onShow == true && popupMessage.status === "error" ? (
                <AlertPopup
                    show={onShow}
                    onHide={() => setOnShow(false)}
                    icon={
                        <svg
                            width="94"
                            height="86"
                            viewBox="0 0 94 86"
                            fill="none"
                            className="mt-5 mb-3"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M36.3676 6.90288C41.0317 -1.38885 52.9702 -1.38885 57.6343 6.90288L91.6741 67.4184C96.2485 75.5509 90.3717 85.5995 81.0406 85.5995H12.9609C3.63005 85.5995 -2.24687 75.5509 2.3277 67.4184L36.3676 6.90288ZM53.1001 67.3001C53.1001 70.6691 50.3692 73.4001 47.0001 73.4001C43.6314 73.4001 40.9003 70.6691 40.9003 67.3001C40.9003 63.931 43.6314 61.2001 47.0001 61.2001C50.3692 61.2001 53.1001 63.931 53.1001 67.3001ZM47.0001 18.5001C43.6314 18.5001 40.9003 21.2312 40.9003 24.6001V42.9001C40.9003 46.269 43.6314 49.0001 47.0001 49.0001C50.3692 49.0001 53.1001 46.269 53.1001 42.9001V24.6001C53.1001 21.2312 50.3692 18.5001 47.0001 18.5001Z"
                                fill="white"
                            />
                        </svg>
                    }
                    title="Error!"
                    description={popupMessage.message}
                />
            ) : null}
        </>
    )
}
export default DinerSignupEleven