import React, { useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { addClass } from '../../commanFunctions';

const DinerSignupOne = (params) => {
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage] = useState(undefined);

    const onNextPage = () => {
        if (params.firstName == '') {
            setErrorMessage({
                error: 'empty first name',
                message: 'Please Enter First Name'
            })
        }
        else if (params.lastName == '') {
            setErrorMessage({
                error: 'empty last name',
                message: 'Please Enter Last Name'
            })
        }
        else {
            addClass()
            setTimeout(() => {
                params.setStepNo(2)
            }, 1400)
        }
    };

    return (
        <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
            <div className="front">
                <div className="heading">
                    <h2 className='small lh-1 fs-normal mb-2'>Signup </h2>
                    <p className="semibold m-0">Please input your first and last name.</p>
                </div>
                <Col as="section" className="stepsBar pt-4">
                    <Col as="ul" className="d-flex justify-content-between list-unstyled">
                        <li className="p2 active first"><span>1 </span></li>
                        <li className="p2" ><span>2</span></li>
                        <li className="p2" ><span>3</span></li>
                        <li className="p2"  ><span>4</span></li>
                        <li className="p2 last"><span>5</span></li>
                    </Col>
                </Col>
                <Col as="section" className="signpUser pt-3">
                    <Form>
                        <Form.Group className="mb-3" controlId="formFirstName">
                            <Form.Control type="text" placeholder="First Name"
                                value={params.firstName}
                                onChange={(e) => {
                                    if (!e.target.value) {
                                        setErrorMessage({
                                            error: 'empty first name',
                                            message: 'Please Enter First Name'
                                        })
                                    } else {
                                        setErrorMessage(undefined)
                                    }
                                    params.setFirstName(e.target.value);
                                }} />
                            {errorMessage && errorMessage.error === 'empty first name' && (
                                <div className="invalid-feedback d-block">
                                    {errorMessage.message}
                                </div>
                            )}
                        </Form.Group>
                        <Form.Group className="mt-1 mb-5" controlId="formLastName">
                            <Form.Control type="text" placeholder="Last Name"
                                value={params.lastName}
                                onChange={(e) => {
                                    if (!e.target.value) {
                                        setErrorMessage({
                                            error: 'empty last name',
                                            message: 'Please Enter Last Name'
                                        })
                                    } else {
                                        setErrorMessage(undefined)
                                    }
                                    params.setLastname(e.target.value);
                                }} />
                            {errorMessage && errorMessage.error === 'empty last name' && (
                                <div className="invalid-feedback d-block">
                                    {errorMessage.message}
                                </div>
                            )}
                        </Form.Group>
                        {/* <Button type="submit" className="w-100 py-2 lh-lg border-0 hvr-float-shadow" variant="primary">Continue  </Button> */}
                        <Row>
                            <Col className="align-self-center">
                                <Button className="fs26 themeColor semibold text-decoration-none lh-1 p-0" variant="link" type="button" onClick={() => navigate('/')}>
                                    <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    Previous
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                                    variant="primary"
                                    type="button"
                                    onClick={onNextPage}>
                                    Next
                                </Button>
                            </Col>
                        </Row>

                    </Form>
                </Col>
            </div>
        </section>
    )
}
export default DinerSignupOne