import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { HiCheckCircle } from "react-icons/hi";
import { addClass } from "../../../commanFunctions"

import HostBusiness02 from "./hostbusiness02";
import HostBusiness03A from "./hostbusiness03a";
import HostBusiness03B from "./hostbusiness03b";
import HostBusiness04 from "./hostbusiness04";
import HostBusiness05 from "./hostbusiness05";
import HostBusiness06 from "./hostbusiness06";
import HostBusiness07 from "./hostbusiness07";
import HostBusiness08 from "./hostbusiness08";
import HostBusiness09 from "./hostbusiness09";
import HostBusiness10 from "./hostbusiness10";
import HostBusiness11 from "./hostbusiness11";
import HostBusiness12 from "./hostbusiness12";
import HostBusiness13 from "./hostbusiness13";
import HostBusiness14 from "./hostbusiness14";
import HostBusiness15 from "./hostbusiness15";

const HostBusiness01 = (params) => {
    const [stepNo, setStepNo] = useState(2);

    // -------------------API data variables------------------------

    const [registrationType, setRegistrationType] = useState("");
    const [acradoc, setAcraDoc] = useState(null);
    const [companyName, setCompanyName] = useState("");
    const [registrationNo, setRegistrationNo] = useState("");
    const [dateInc, setDateInc] = useState("");
    const [companyStatus, setCompanyStatus] = useState("");
    const [primaryBusiness, setPrimaryBusiness] = useState("");
    const [secBusiness, setSecBusiness] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [shareCapital, setShareCapital] = useState("");
    const [currency, setCurrency] = useState("");
    const [companyAmendment, setCompanyAmendment] = useState(true);
    const [representative, setRepresentative] = useState("");
    const [repNricNO, setRepNricNO] = useState("");
    const [repCitizenship, setrepCitizenship] = useState("");
    const [designation, setDesignation] = useState("");
    const [nricFile, setNricFile] = useState({ front: null, back: null });
    const [fullname, setFullname] = useState("");
    const [nricNumber, setNricNumber] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    console.log("dateOfBirth", dateOfBirth);
    const [age, setAge] = useState("");
    const [gender, setGender] = useState("");
    const [citizenship, setCitizenship] = useState("");
    const [address, setAddress] = useState("");
    const [profileAmendment, setprofileAmendment] = useState(true);
    const [propertyUser, setPropertyUser] = useState("");
    const [outlets,] = useState([]);
    const [outletData, setOutletData] = useState([]);
    // const [postalCode, setPostalCode] = useState(0);
    // const [floorNumber, setFloorNumber] = useState(0);
    // const [unitNumber, setUnitNumber] = useState(0);
    // const [propertyFile, setPropertyFile] = useState({});
    const [referralcode, setReferralcode] = useState("");
    const [selectedSide, setSelectedSide] = useState("");  //LEFT RIGHT
    // const [amendOne, setAmendOne] = useState(true);
    // const [amendTwo, setAmendTwo] = useState(true);
    const [pushAddress, setPushAddress] = useState(false);
    // const [startDate, setStartDate] = useState(null);

    // const [mainDivClass, setMainDivClass] = useState("switchUser");
    // const [firstName, setFirstName] = useState("");
    // const [amendThree,] = useState(true);
    // const [amendFour, setAmendFour] = useState(true);
    // const [registrationAddress, setRegistrationAddress] = useState("");

    // const [pageSwitch, setPageSwitch] = useState(false);
    const [createAcc, setCreateAcc] = useState(false);
    // const [errorMessage, setErrorMessage] = useState(undefined);

    const [dob, setDob] = useState(null);
    console.log('dob========>', dob);
    const [dInc, setDInc] = useState(null);
    const [result, setResult] = useState({ front: null, back: null });
    console.log("result===========>", result)

    const handleDate = (date) => {
        let currYear = new Date().getFullYear();
        let dd = new Date(date).getDate();
        let mm = new Date(date).getMonth() + 1;
        let yyyy = new Date(date).getFullYear();
        setAge(`${currYear - yyyy}`);
        if (stepNo === 4) {
            setDInc(date);
            setDateInc(yyyy + "-" + mm + "-" + dd);
        } else if (stepNo === 8) {
            setDob(date);
            setDateOfBirth(yyyy + "-" + mm + "-" + dd);
        } else {
        }
    };

    const compareAge = (date) => {
        let currYear = new Date().getFullYear();
        const reverseDate = date.split("-").reverse().join("-");
        // setStartDate(reverseDate);
        const getYear = reverseDate.split("-")[0]
        setAge(`${currYear - getYear }`)
        setDateOfBirth(reverseDate);
    }

    // const onSelect = async (profile) => {
    //     if (profile === "personal") {
    //         // setMainDivClass("switchUser");
    //         setSelectedSide("LEFT")
    //         setTimeout(() => {
    //             params.setUserHostType(profile);
    //             params.setPageSwitch(true);
    //         }, 1400);
    //     } else {
    //         // setMainDivClass("");
    //     }
    //     addClass();
    //     setTimeout(() => {
    //         params.setUserHostType(profile);
    //         setStepNo(2);
    //     }, 1400);
    // };

    // useEffect(() => {
    //     if (params.autoClick === true) {
    //     } else {
    //         <></>;
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    useEffect(() => {
        if (pushAddress === true) {
            outlets.push(outletData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pushAddress]);

    const goBack = () => {
        params.setStepNo(1);
        params.setSelectedSide('')
    };

    return (
        <>
            {stepNo === 1 && goBack()}

            {stepNo === 2 && (
                <HostBusiness02
                    setStepNo={setStepNo}
                    setRegistrationType={setRegistrationType}
                />
            )}
            {stepNo === 3 && registrationType === "acra" ? (
                <HostBusiness03A
                    setStepNo={setStepNo}
                    acradoc={acradoc}
                    setAcraDoc={setAcraDoc}
                />
            ) : stepNo === 3 && registrationType === "singpass" ? (
                <HostBusiness03B setStepNo={setStepNo} />
            ) : null}

            {stepNo === 4 && (
                <HostBusiness04
                    setStepNo={setStepNo}
                    registrationType={registrationType}
                    handleDate={handleDate}
                    dInc={dInc}
                    companyName={companyName}
                    setCompanyName={setCompanyName}
                    registrationNo={registrationNo}
                    setRegistrationNo={setRegistrationNo}
                    dateInc={dateInc}
                    setDateInc={setDateInc}
                    companyStatus={companyStatus}
                    setCompanyStatus={setCompanyStatus}
                    companyAmendment={companyAmendment}
                    setCompanyAmendment={setCompanyAmendment}
                />
            )}
            {stepNo === 5 && (
                <HostBusiness05
                    setStepNo={setStepNo}
                    registrationType={registrationType}
                    primaryBusiness={primaryBusiness}
                    setPrimaryBusiness={setPrimaryBusiness}
                    secBusiness={secBusiness}
                    setSecBusiness={setSecBusiness}
                    // registrationAddress={registrationAddress}
                    // setRegistrationAddress={setRegistrationAddress}
                    companyAddress={companyAddress}
                    setCompanyAddress={setCompanyAddress}
                    shareCapital={shareCapital}
                    setShareCapital={setShareCapital}
                    currency={currency}
                    setCurrency={setCurrency}
                    companyAmendment={companyAmendment}
                    setCompanyAmendment={setCompanyAmendment}
                />
            )}

            {stepNo === 6 && (
                <HostBusiness06
                    setStepNo={setStepNo}
                    registrationType={registrationType}
                    representative={representative}
                    setRepresentative={setRepresentative}
                    repCitizenship={repCitizenship}
                    setrepCitizenship={setrepCitizenship}
                    repNricNO={repNricNO}
                    setRepNricNO={setRepNricNO}
                    designation={designation}
                    setDesignation={setDesignation}
                />
            )}

            {stepNo === 7 && (
                <HostBusiness07
                    setStepNo={setStepNo}
                    nricFile={nricFile}
                    setNricFile={setNricFile}
                    result={result}
                    setResult={setResult}
                    setFullname={setFullname}
                    setAge={setAge}
                    setGender={setGender}
                    setDob={setDob}
                    setAddress={setAddress}
                    setDateOfBirth={setDateOfBirth}
                    setCitizenship={setCitizenship}
                    setNricNumber={setNricNumber}
                    compareAge={compareAge}
                    handleDate={handleDate}

                />
            )}
            {stepNo === 8 && (
                <HostBusiness08
                    setStepNo={setStepNo}
                    registrationType={registrationType}
                    handleDate={handleDate}
                    dob={dob}
                    fullname={fullname}
                    setFullname={setFullname}
                    nricNumber={nricNumber}
                    setNricNumber={setNricNumber}
                    dateOfBirth={dateOfBirth}
                    setDateOfBirth={setDateOfBirth}
                    age={age}
                    setAge={setAge}
                    profileAmendment={profileAmendment}
                    setprofileAmendment={setprofileAmendment}
                />
            )}
            {stepNo === 9 && (
                <HostBusiness09
                    setStepNo={setStepNo}
                    registrationType={registrationType}
                    gender={gender}
                    setGender={setGender}
                    citizenship={citizenship}
                    setCitizenship={setCitizenship}
                    address={address}
                    setAddress={setAddress}
                    profileAmendment={profileAmendment}
                    setprofileAmendment={setprofileAmendment}
                />
            )}
            {stepNo === 10 && (
                <HostBusiness10
                    setStepNo={setStepNo}
                    address={address}
                    setAddress={setAddress}
                    outletData={outletData}
                />
            )}
            {stepNo === 11 && (
                <HostBusiness11
                    setStepNo={setStepNo}
                    setPropertyUser={setPropertyUser}
                />
            )}
            {stepNo === 12 && (
                <HostBusiness12
                    setStepNo={setStepNo}
                    propertyUser={propertyUser}
                    outletData={outletData}
                    setOutletData={setOutletData}
                    setPushAddress={setPushAddress}
                />
            )}
            {stepNo === 13 && (
                <HostBusiness13
                    setStepNo={setStepNo}
                    address={address}
                    setAddress={setAddress}
                    propertyUser={propertyUser}
                    outletData={outletData}
                    setOutletData={setOutletData}
                    setPushAddress={setPushAddress}
                />
            )}
            {stepNo === 14 && (
                <HostBusiness14
                    setStepNo={setStepNo}
                    outletData={outletData}
                    referralcode={referralcode}
                    setReferralCode={setReferralcode}
                />
            )}
            {stepNo === 15 && (
                <HostBusiness15
                    setStepNo={setStepNo}
                    setCreateAcc={setCreateAcc}
                    createAcc={createAcc}
                    registrationType={registrationType}
                    userHostType={params.userHostType}
                    nricfront={nricFile.front}
                    nricback={nricFile.back}
                    acradoc={acradoc}
                    fullname={fullname}
                    dob={dateOfBirth}
                    nricNumber={nricNumber}
                    age={age}
                    gender={gender}
                    citizenship={citizenship}
                    address={address}
                    userRole={1}
                    representative={representative}
                    company_name={companyName}
                    registration_no={registrationNo}
                    date_inc={dateInc}
                    company_status={companyStatus}
                    primary_business={primaryBusiness}
                    sec_business={secBusiness}
                    share_capital={shareCapital}
                    rep_citizenship={repCitizenship}
                    designation={designation}
                    currency={currency}
                    outlets={outletData}
                    companyAddress={companyAddress}
                    profileAmendment={profileAmendment}
                    companyAmendment={companyAmendment}
                    repNricNO={repNricNO}
                />
            )}
            {createAcc === true && (
                <Modal centered show={createAcc} style>
                    <Modal.Body
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{
                            backgroundColor: "var(--theme-color)",
                            color: "#fff",
                            padding: 85,
                            textAlign: "center",
                        }}
                    >
                        <HiCheckCircle size={94}></HiCheckCircle>
                        <h1>Success</h1>
                        <h5>
                            Your account has been successfully created, Please login to
                            Proceed.
                        </h5>
                    </Modal.Body>
                </Modal>
            )}
        </>
    )
}

export default HostBusiness01