import React from 'react'
import { Container, Row, Col, Image, Card, ListGroup, Form, Button } from 'react-bootstrap';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import { useNavigate } from 'react-router';
import MyAccount02 from './myaccount02';
import MyAccount03 from './myaccount03';
import MyAccount04 from './myaccount04';
import { useState } from 'react';
import AfterLoginHeader from '../../../components/afterLoginHeader';
import { FiChevronDown } from 'react-icons/fi';
import { User_CloseAccount } from '../../../queries/queries';
import { useMutation } from '@apollo/client';


const MyAccount01 = (params) => {
    const CloseAccount = User_CloseAccount();
    const [CloseAccountManagement] = useMutation(CloseAccount);
    
    const navigate = useNavigate();
    const [enable, setEnable] = useState(false);
    const [vaild, setVaild] = useState(false);


    // const handleCloseAccount = async () => {
    //     console.log(params.question);
    //     let data = await CloseAccountManagement({
    //         variables: {
    //             reasion: params.question,
    //             status: true
    //         }
    //     })
    //     params.setQuestion(data);
    // }

    const handleOtherQues = (e) => {
        setEnable(true);
        params.setQuestion(e.target.value);
    }

    const handleCustomQues = (e) => {
        setVaild(true);
        params.setQuestion(e.target.value)
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        // handleCloseAccount();
        setTimeout(() => {
            params.setStepNo(2);
        }, 1000);
    }
    
    

    return (
                <Row className="ps-0 pinkgradiant pb-3">
                    <Col lg="12" className="text-center mb-5"><Image src={process.env.PUBLIC_URL + "/assets/sadIcon.png"} alt="mainbanner" className="img-fluid" /></Col>
                    <Col lg="12" className="text-center">
                        <h2 className="fs60 text-white semibold mb-5">We’re Sad To See You Go!</h2>
                        <Card className="d-inline-block px-md-5 py-3 mb-5 border08 col-lg-8" body >
                            <Card.Text className="fs30 GrayComn semibold text-start">Please tell us why you are leaving. We would like to help you out.</Card.Text>
                            <Form onSubmit={handleSubmit}>
                            <ListGroup variant="flush" className="text-start" >
                                <ListGroup.Item className="px-0 py-4">
                                    <div className="form-radio fs22 GrayComn semibold ps-5">
                                        <input className="form-check-input position-absolute" type="radio" name="flexRadioDefault" id="AcRad01" value="I have a duplicate account."  onChange={handleOtherQues}/>
                                        <label className="form-check-label" for="AcRad01">I have a duplicate account.</label>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item className="px-0 py-4">
                                    <div className="form-radio fs22 GrayComn semibold ps-5">
                                        <input className="form-check-input position-absolute" type="radio" name="flexRadioDefault" id="AcRad02" value="The choices of food offered on Cribbly, does not suit me" onChange={handleOtherQues}/>
                                        <label className="form-check-label" for="AcRad02">The choices of food offered on Cribbly, does not suit me.</label>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item className="px-0 py-4">
                                    <div className="form-radio fs22 GrayComn semibold ps-5">
                                        <input className="form-check-input position-absolute" type="radio" name="flexRadioDefault" id="AcRad03" value="The quality of food offered does not appeal to me" onChange={handleOtherQues} />
                                        <label className="form-check-label" for="AcRad03">The quality of food offered does not appeal to me.</label>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item className="px-0 py-4">
                                    <div className="form-radio fs22 GrayComn semibold ps-5">
                                        <input className="form-check-input position-absolute" type="radio" name="flexRadioDefault" id="AcRad04" value="App is difficult to navigate or buggy" onChange={handleOtherQues}/>
                                        <label className="form-check-label" for="AcRad04">App is difficult to navigate or buggy.</label>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item className="px-0 py-4">
                                    <div className="form-radio fs22 GrayComn semibold ps-5">
                                        <input className="form-check-input position-absolute" type="radio" name="flexRadioDefault" id="AcRad05" value="Others (Please specify)" 
                                        onChange={(e) => {
                                            handleOtherQues(e)
                                            handleCustomQues(e)
                                        }}
                                         />
                                        <label className="form-check-label" for="AcRad05">Others (Please specify):</label>
                                    </div>

                                    <Form className="mt-4">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Control className="fs20 semibold grayBdb bgLight border border-1 p-4 border08" as="textarea" placeholder="Reason" rows={7} onChange={handleOtherQues}
                                            disabled={vaild ? false : true}
                                            />
                                        </Form.Group>
                                    </Form>
                                </ListGroup.Item>
                            </ListGroup>
                            <Form.Group className="mt-5 mb-2">
                                <Button className="w-50 py-2 lh-lg border-0 rounded-pill" variant="primary" type="submit" disabled={enable ? false : true}>process</Button>
                            </Form.Group>
                            </Form>
                        </Card>
                    </Col>
                </Row>
             
    )
}
export default MyAccount01