import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { FiMapPin } from "react-icons/fi";
import { BiCalendar } from "react-icons/bi";
import { Col } from 'react-bootstrap';
import DatePicker, { CalendarContainer } from "react-datepicker";


const MyContainer = ({ className, children }) => {
  return (
   
      <CalendarContainer className={className}>
        <span className="headFilter">
          <h2 className="semibold fs28">Scheduler</h2>
        </span>
        <div style={{ position: "relative" }}>{children}</div>
      </CalendarContainer>
   
  );
};

const MobileHeader = () => {
    const navigate = useNavigate()
    const [isActive, setActive] = useState("false");
    const [datePickerShow, setDatePickerShow] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const handleToggle = () => {
        setActive(!isActive);
    };
    return (
        <div className="sidebarmobile">
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <header className="p-4 shadow-none mobileheader">
                    <section className="tobbleButton d-inline-block me-2">
                        <div className={`app ${isActive ? "left" : "right"}`}>
                            <span className="leftButton" onClick={handleToggle}>Pickup</span>
                            <span className="rightButton" onClick={handleToggle}>Dine in</span>
                        </div>
                    </section>
                    <section className={`calenderButton me-2 position-relative ${startDate !== new Date() ? 'activeDate' : ''}`}>
        <Link to="" className="btn text-decoration-none btnCommon text-white rounded-pill">
          <BiCalendar size="20" /> <span className="Hidexs">Now</span>
        </Link>


        <DatePicker
          popperClassName="calWrapper"
          selected={startDate}
          onChange={(date) =>{ setStartDate(date); setDatePickerShow(false) }}
          timeInputLabel="Time:"
          timeCaption="Time"
          dateFormat="MM/dd/yyyy h:mm aa"
          timeFormat='hh:mm'
          showTimeInput
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          calendarContainer={MyContainer}
          todayButton="Scheduler"
          open={datePickerShow}
          onButton={(e) => console.log("button click",e)}
          onCalendarOpen={() => setDatePickerShow(true)}         
          
        />
      </section> 
                    <section className="locationButton me-2">
                        <Link to="/" className="btn text-decoration-none btngray text-black rounded-pill">
                            <FiMapPin size="18" color="var(--theme-color)" /> <span className="Hidexs">10 Bayfront Ave</span>
                        </Link>
                    </section>
                </header>
            </div>
            <Col as="span" className="d-none Allfilter" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">Book Table</Col>
        </div>
    )
}

export default MobileHeader;