import React, { useState } from 'react'
import { Container, Row, Col, Button, Dropdown, ListGroup, Nav } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import "./hoststep.css";
// import Header from "../header";
// import Footer from '../footer';
import AfterLoginHeader from '../components/afterLoginHeader';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';
import MenuSidebar from '../components/menusidebar';

import { Line } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';



import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );


const HostBusiness = () => {
    
    const [data] = useState({
        labels: ['12 AM', '2 AM', '4 AM', '6 AM', '8 AM', '10 AM', '12 PM', '2 PM', '4 PM', '6 PM', '8 PM', '10 PM'],
         datasets: [
           {
             label: 'My First dataset',
             backgroundColor: 'rgba(194, 116, 161, 0.5)',
             borderColor: 'rgb(194, 116, 161)',
             data: [65, 59, 90, 81, 56, 55, 40, 65, 59, 90, 81, 56],
           },
           {
             label: 'My Second dataset',
             backgroundColor: 'rgba(71, 225, 167, 0.5)',
             borderColor: 'rgb(71, 225, 167)',
             data: [28, 48, 40, 19, 96, 27, 100, 28, 48, 40, 19, 96],
           }, 
         ],
     });

    const DoughnutData =  {        
        labels: false,
        datasets: [{
            backgroundColor: ["#ccc", "#d33258"],
            hoverBackgroundColor: ["#d33258"],
            data: [20, 10]
          }       
        ]
    };
    

    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <AfterLoginHeader />
                    </Col>
                </Row>
                <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="const(--bs-white)" /></Col>
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none rightshadow menuside-bar" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <MenuSidebar />
                    </Col>
                    <Col lg="9">
                        <Col as="section" className='page-heading-top my-4 py-1 px-0'>
                            <Col as="div" className='container px-xl-5 px-0'>
                                <Row className='align-items-center justify-content-between align-items-md-start'>
                                    <Col md="auto" className='d-flex text-center flex-column flex-md-row text-md-start col-md-auto'>
                                        <span className="user-profile-circle me-md-3 mx-auto rounded-pill border border-2 border-danger p-1 d-inline-block position-relative">
                                            <img src={process.env.PUBLIC_URL+"assets/black_scoop.png"} className='rounded-pill border-2' alt="food" />
                                        </span>
                                        <Col className="itemDetails">
                                            <Col as="div" className="itemHead mb-2">
                                                <h4 className="d-inline-block align-middle GrayDark fs46 semibold">City Home Cafe</h4>
                                            </Col>
                                            <Col as="p" className="d-inline-flex border04 grayf7f py-2 px-3 shadowMD align-items-center semibold align-middle">
                                                <Col className='border-end pe-3 me-3'>
                                                    <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M20.0013 26.0947L15.8379 14.4258H24.1647L20.0013 26.0947Z" fill="#6992F2"/>
                                                    <path d="M0 14.3711L19.3602 31.4961L13.5297 14.4017L0 14.3711Z" fill="#3169ED"/>
                                                    <path d="M12.8381 12.0727L0.0273438 12.0743L6.42691 0.878906L12.8381 12.0727Z" fill="#4377EF"/>
                                                    <path d="M39.9998 14.3867L20.6396 31.5117L26.4701 14.4173L39.9998 14.3867Z" fill="#7CA0F4"/>
                                                    <path d="M27.1631 12.0845L39.9738 12.086L33.5859 0.890625L27.1631 12.0845Z" fill="#A1BBF7"/>
                                                    <path d="M24.2077 11.9639L15.8809 11.9649L20.0559 4.72656L24.2077 11.9639Z" fill="#8EADF6"/>
                                                    <path d="M19.5222 0.00155315L8.61328 0L14.0628 9.53337L19.5222 0.00155315Z" fill="#5684F0"/>
                                                    <path d="M20.5225 0.00155315L31.4314 0L25.9918 9.53337L20.5225 0.00155315Z" fill="#7CA0F4"/>
                                                    </svg>
                                                </Col>    
                                                    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6758 0.928603C12.0926 -0.309534 13.9074 -0.309534 14.3242 0.928603L16.4386 7.20961C16.6249 7.76332 17.1596 8.13821 17.7627 8.13821H24.605C25.9538 8.13821 26.5146 9.80412 25.4234 10.5693L19.8878 14.4512C19.3998 14.7934 19.1957 15.4 19.3821 15.9537L21.4965 22.2348C21.9132 23.4729 20.445 24.5025 19.3539 23.7372L13.8184 19.8554C13.3304 19.5131 12.6696 19.5131 12.1816 19.8554L6.64609 23.7372C5.55492 24.5025 4.08674 23.4729 4.50353 22.2348L6.61791 15.9537C6.8043 15.4 6.6001 14.7934 6.11212 14.4512L0.57661 10.5693C-0.514582 9.80412 0.0462183 8.13821 1.39499 8.13821H8.23727C8.84045 8.13821 9.37505 7.76332 9.56144 7.20961L11.6758 0.928603Z" fill="#EF9309"/>
                                                    </svg>
                                                <Col as="span" className='col-auto px-2 lh-1 align-middle fs26 GrayComn semibold'>
                                                    4.5
                                                </Col>
                                                <Link to="/" className='d-inline-block themeColor ps-3 semibold fs20 text-decoration-none'>See Reviews (55)</Link>
                                            </Col>
                                        </Col>
                                    </Col>
                                    <Col md="auto" className='text-md-end py-md-4 mt-2 text-center'>
                                        <Button constiant="light" className="text-nowrap rounded-pill bgtheme py-2 px-4 text-white border-0 fs20 btnlnHT">
                                            <svg className="me-2 align-text-top" width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.84533 16.1299V12.9642C2.84533 12.4924 2.46307 12.1086 1.98971 12.1086V12.1084L1.98472 12.1086C1.51365 12.1112 1.13398 12.4938 1.13398 12.9642V16.9855C1.13398 17.4579 1.5166 17.8411 1.9896 17.8411H20.3484C20.8211 17.8411 21.204 17.4576 21.204 16.9855V12.9642C21.204 12.4918 20.8211 12.1086 20.3484 12.1086C19.8756 12.1086 19.4928 12.4921 19.4928 12.9642V16.1297L2.84533 16.1299Z" fill="white" stroke="white" stroke-width="0.4"/>
                                            <path d="M5.3042 8.4894L5.30409 8.4893C4.96469 8.16092 4.95488 7.61935 5.28354 7.27953C5.61118 6.93982 6.15414 6.92986 6.49292 7.25885C6.49299 7.25892 6.49306 7.25899 6.49313 7.25905L10.3127 10.9487V1.65561C10.3127 1.18358 10.695 0.8 11.1684 0.8C11.641 0.8 12.024 1.18313 12.024 1.65537V10.9481L15.8414 7.25729L15.8415 7.25721C16.1815 6.92887 16.7228 6.93856 17.0513 7.27801C17.3798 7.61753 17.3702 8.15903 17.0318 8.48764L17.0314 8.48797L11.7635 13.5785C11.7634 13.5786 11.7632 13.5787 11.7631 13.5789C11.7312 13.6099 11.6984 13.6294 11.6783 13.6409C11.6659 13.6479 11.6592 13.6516 11.6547 13.654C11.6509 13.6561 11.6488 13.6572 11.6463 13.6587C11.6445 13.6601 11.6421 13.6619 11.6393 13.6641C11.6144 13.6833 11.5538 13.7301 11.4901 13.7555M5.3042 8.4894L10.6902 13.6603C10.7206 13.6834 10.7792 13.728 10.844 13.7546M5.3042 8.4894L10.5635 13.5703L10.5636 13.5705L10.5633 13.5708L10.5727 13.5791L10.5778 13.5835C10.6084 13.6122 10.6394 13.6297 10.6559 13.6388C10.6685 13.6458 10.6682 13.6455 10.6751 13.6493C10.6766 13.6502 10.6775 13.6507 10.6779 13.651L5.3042 8.4894ZM11.4901 13.7555C11.3901 13.7955 11.2811 13.8196 11.1681 13.8196C11.0563 13.8196 10.9466 13.7969 10.844 13.7546M11.4901 13.7555C11.49 13.7555 11.4899 13.7556 11.4898 13.7556L11.416 13.5697L11.4904 13.7554C11.4903 13.7554 11.4902 13.7555 11.4901 13.7555ZM10.844 13.7546L10.9202 13.5697L10.8443 13.7547C10.8442 13.7547 10.8441 13.7547 10.844 13.7546Z" fill="white" stroke="white" stroke-width="0.4"/>
                                            </svg>
                                            Download Report
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="mt5">
                                    <Col as="section" lg="12">
                                        <ListGroup horizontal className="justify-content-end pt-4">
                                            <ListGroup.Item className="border-0 p-0 pe-first">
                                                <h2 className="fs18 m-0 semibold GrayDark">&nbsp;</h2>    
                                            </ListGroup.Item>                                                                    
                                            <ListGroup.Item className="border-0 p-0 me-4">
                                            <b className="fs14">Crib :</b>
                                            <Dropdown className='d-inline-block ps-2'>
                                                <Dropdown.Toggle variant="success" className='fs16 medium grayf2f border-0 px-3 pe-4 rounded-pill black position-relative' id="dropdown-basic">
                                                All Outlets
                                                <svg width="24" height="24" className='svg-down-icon grayf2f position-absolute' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5 9L12 16L19 9" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="border-0 p-0">
                                                <b className="fs14">Menu :</b>
                                                <Dropdown className='d-inline-block ps-2 aroset'>
                                                    <Dropdown.Toggle variant="success" className='fs16 medium grayf2f border-0 px-3 pe-4 rounded-pill black position-relative' id="dropdown-basic">
                                                    Daily
                                                    <svg width="24" height="24" className='svg-down-icon grayf2f position-absolute' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 9L12 16L19 9" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </ListGroup.Item>
                                        </ListGroup>

                                        <ListGroup horizontal className="py-5 flex-wrap justify-content-center busspage">
                                            <ListGroup.Item className="border-0 p-0 pe-first">
                                                <h2 className="fs28 bold themeColor">Sales Performance</h2>
                                                <p className="fs16 semibold textGreen m-0"><span className="fs20">+</span> S$44.20 (70.95 %) 
                                                <svg className="ms-1" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5 0.5L9.33013 8H0.669873L5 0.5Z" fill="#00676B"/>
                                                </svg>
                                                </p>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="border-0 p-0 pe-first text-center">
                                                <h2 className="fs28 bold color606">Total Revenue</h2>
                                                <p className="fs30 bold black m-0 lh-1">S$5,271.57</p>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="border-0 p-0 text-center">
                                                <Nav constiant="pills" className="flex-row grayf2f rounded-pill mapTabs">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="first" className="fs16 medium gray82 border-0">1D</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="second" className="fs16 medium gray82 border-0">30D</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="third" className="fs16 medium gray82 border-0">6M</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="four" className="fs16 medium gray82 border-0">1Y</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="five" className="fs16 medium gray82 border-0">5Y</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </ListGroup.Item>
                                        </ListGroup>

                                        <Col as="section" className="cartSec">
                                            <Line data={data} options={{ responsive: true }} />
                                        </Col>
                                    </Col>
                                    <Col as="section" lg="12">
                                        <hr className="mt-5" />
                                        <ListGroup horizontal className="py-5 busspage flex-wrap justify-content-center">
                                            <ListGroup.Item className="border-0 p-0 pe-first">
                                                <h2 className="fs28 bold themeColor">Best Hour</h2>
                                                <p className="fs16 semibold textGreen m-0"><span className="fs20">+</span> S$44.20 (70.95 %) 
                                                <svg className="ms-1" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5 0.5L9.33013 8H0.669873L5 0.5Z" fill="#00676B"/>
                                                </svg>
                                                </p>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="border-0 p-0 pe-first text-center">
                                                <h2 className="fs28 bold color606">Average Hourly Revenue</h2>
                                                <p className="fs30 bold black m-0 lh-1">S$40.81</p>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="border-0 p-0 text-center">
                                                <Nav constiant="pills" className="flex-row grayf2f rounded-pill mapTabs">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="first" className="fs16 medium gray82 border-0">1D</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="second" className="fs16 medium gray82 border-0">30D</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="third" className="fs16 medium gray82 border-0">6M</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="four" className="fs16 medium gray82 border-0">1Y</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="five" className="fs16 medium gray82 border-0">5Y</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </ListGroup.Item>
                                        </ListGroup>

                                        <Col as="section" className="cartSec">
                                            <Line data={data} options={{ responsive: true }} />
                                        </Col>
                                    </Col>
                                    <Col as="section" lg="12">
                                        <hr className="mt-5" />
                                        <ListGroup horizontal className="py-5 busspage flex-wrap justify-content-center">
                                            <ListGroup.Item className="border-0 p-0 pe-first">
                                                <h2 className="fs28 bold themeColor">Best Day</h2>
                                                <p className="fs16 semibold textGreen m-0"><span className="fs20">+</span> S$44.20 (70.95 %) 
                                                <svg className="ms-1" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5 0.5L9.33013 8H0.669873L5 0.5Z" fill="#00676B"/>
                                                </svg>
                                                </p>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="border-0 p-0 pe-first text-center">
                                                <h2 className="fs28 bold color606">Average Daily Revenue</h2>
                                                <p className="fs30 bold black m-0 lh-1">S$585.70</p>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="border-0 p-0 text-center">
                                                <Nav constiant="pills" className="flex-row grayf2f rounded-pill mapTabs">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="first" className="fs16 medium gray82 border-0">1D</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="second" className="fs16 medium gray82 border-0">30D</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="third" className="fs16 medium gray82 border-0">6M</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="four" className="fs16 medium gray82 border-0">1Y</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="five" className="fs16 medium gray82 border-0">5Y</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </ListGroup.Item>
                                        </ListGroup>

                                        <Col as="section" className="cartSec">
                                            <Line data={data} options={{ responsive: true }} />
                                        </Col>
                                    </Col>
                                    <Col as="section" lg="12">
                                        <hr className="mt-5" />
                                        <ListGroup horizontal className="py-5 busspage flex-wrap justify-content-center">
                                            <ListGroup.Item className="border-0 p-0 pe-first">
                                                <h2 className="fs28 bold themeColor">Menu Performance</h2>
                                                <p className="fs16 semibold textGreen m-0">&nbsp;</p>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="border-0 p-0 pe-first text-center">
                                                <h2 className="fs28 bold color606">Total Revenue</h2>
                                                <p className="fs30 bold black m-0 lh-1">S$5271.57</p>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="border-0 p-0 text-center">
                                                <Nav constiant="pills" className="flex-row grayf2f rounded-pill mapTabs">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="first" className="fs16 medium gray82 border-0">Revenue</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="second" className="fs16 medium gray82 border-0">Volume</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </ListGroup.Item>
                                        </ListGroup>

                                        <Col as="section" lg="12">
                                                <Row className="text-center">
                                                    <Col>
                                                        <h2 className="fs32 textGreen bold mb-4">Best Seller</h2>
                                                        <Doughnut 
                                                            className="mb-4"
                                                            data={DoughnutData} 
                                                            options={{    
                                                                responsive:true,
                                                                cutoutPercentage: 60,
                                                                circumference: 700,                                                    
                                                                cutout:"75%",
                                                                aspectRatio: 2
                                                            }}
                                                        />
                                                        <Doughnut 
                                                            className="mb-4"
                                                            data={DoughnutData} 
                                                            options={{    
                                                                responsive:true,
                                                                cutoutPercentage: 60,
                                                                circumference: 700,                                                    
                                                                cutout:"75%",
                                                                aspectRatio: 2
                                                            }}
                                                        />
                                                        <Doughnut 
                                                            data={DoughnutData} 
                                                            options={{    
                                                                responsive:true,
                                                                cutoutPercentage: 60,
                                                                circumference: 700,                                                    
                                                                cutout:"75%",
                                                                aspectRatio: 2
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <h2 className="fs32 textGreen semibold mb-4">Lowest Seller</h2>
                                                        <Doughnut 
                                                            className="mb-4"
                                                            data={DoughnutData} 
                                                            options={{    
                                                                responsive:true,
                                                                cutoutPercentage: 60,
                                                                circumference: 700,                                                    
                                                                cutout:"75%",
                                                                aspectRatio: 2
                                                            }}
                                                        />
                                                        <Doughnut 
                                                            className="mb-4"
                                                            data={DoughnutData} 
                                                            options={{    
                                                                responsive:true,
                                                                cutoutPercentage: 60,
                                                                circumference: 700,                                                    
                                                                cutout:"75%",
                                                                aspectRatio: 2
                                                            }}
                                                        />
                                                        <Doughnut 
                                                            data={DoughnutData} 
                                                            options={{    
                                                                responsive:true,
                                                                cutoutPercentage: 60,
                                                                circumference: 700,                                                    
                                                                cutout:"75%",
                                                                aspectRatio: 2
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )    
}
export default HostBusiness;