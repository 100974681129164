import React, { useState } from 'react'
import { Container, Row, Col, ListGroup, Tabs, Tab, Card, FloatingLabel, Form, Button } from 'react-bootstrap';
import { BiChevronDown } from 'react-icons/bi';
import { FiSearch } from 'react-icons/fi';
import { HiOutlinePrinter } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';
import "./identity.css"
import "./account.css"
import QRCode from 'react-qr-code';
import { from } from '@apollo/client/core';
import { create } from 'lodash';

const SignPass = (params) => {
    const [value, setValue] = useState("https://fivesocnet.com/login");
    const [back, setBack] = useState('#FFFFFF');
    const [fore, setFore] = useState('#000000');
    const [size, setSize] = useState(256);
    const [time, setTime] = useState(10);

    console.log(params);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const navigate = useNavigate();

    // const goBack = () => navigate(-1);

    const handleOcr = (event) => {
        event.preventDefault();
        console.log(event)
        if (params.updateFrontFile === null && params?.nricFrontFile === null) {
            setErrorMessage({
                error: "empty nric-front",
                message: "Please Upload NRIC Front",
            });
        }
        else if (params.updateBackFile === null && params?.nricBackFile === null) {
            setErrorMessage({
                error: "empty nric-back",
                message: "Please Upload NRIC Back",
            });
        }
        else {
            setTimeout(() => {
                params.setStepNo(3);
            }, 1400)
        }
    };


    // const handleOcr = () => {
    //     navigate('/identitystep03')
    // }
    return (
        <div id="bodyWrap">
            <Container fluid className="grayf3f vh-100">
                <Row className="py-1">
                    <Col className="p-0">
                        <Container>
                            <Row>
                                <Col className="scanTopHeader">
                                    <svg className="me-1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330.55 399">
                                        <path class="cls-1" d="M8.59,120s-14.5,20.5,4.5,47c0,0,3-13.5,33-13.5h36c34,0,59.5-32.5,43.5-66,0,0,24,2.5,32-12.5s-.5-21-12.5-21h-60.5c0,10.75-20.5,12.25-20.5,0H30.09s-25.5,0-26,21.5c0,0,5.75-3.5,11.5-4v5.75s-7,1.25-10.25,3-8,6.5-3.5,18.75,6.25,16.5,6.25,16.5c0,0,7.25-6.25,19-6.25h13.75c24.5,0,20,23.75-4.5,23.75s-27.75-13-27.75-13Z" />
                                        <path class="cls-1" d="M136.34,95.25s8.25,.5,14.25-4.75c0,0,54.25,42.25-26.25,127.25s-18.25,140-18.25,140c0,0-14.5,14-6.25,41.25,0,0-33.48-18.92-59.08-51C3.74,301.6-18.87,230.61,82.76,174c0,0,67.08-33.75,53.58-78.75Z" />
                                        <path class="cls-1" d="M53.43,46s10.83-19.33,36-19.33c19.83,0,24.33-10.17,24.33-10.17,0,0,8.67-16.5,52.33-16.5,40.02,0,67,13.33,88.84,31.17,0,0-58.83-36.5-116.83,14.83H53.43Z" />
                                        <path class="cls-1" d="M298.09,168.33c-1.67-60.33-48.33-125-148.67-121.33,98-83,268,102.33,128,199.67,0,0,23.41-33.39,20.67-78.33Z" />
                                        <path class="cls-1" d="M164.43,53.67c122.33-3.67,166,143,87,209l-79.33,38.33s-10.33-33.33,28-72.33c38.33-39,75.33-112-30.67-160,0,0,2-10-5-15Z" />
                                        <path class="cls-1" d="M158.09,86.67s6-5.67,8-10.33c91,38,76.67,102.33,22.33,156.33-33.33,34.33-23,71.33-23,71.33,0,0-40.67,25.33-54.67,47.33,0,0-58.85-50.12,22.67-133.33,80-81.67,24.67-131.33,24.67-131.33Z" />
                                    </svg>
                                    <span className="pt-1 d-inline-block medium fs14">A Singapore Government Agency Website</span>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Col className="bg-white">
                        <Container>
                            <Row className="py-4" >
                                <Col className="logoPass">
                                    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 697.52 122.19" fill="#ed1a3b">
                                        <path class="cls-1" d="M287.73,78.19c-19.9,14.43-41.56,7.43-51.99-4.45-15.01-17.1-15.07-43.78,.07-60.45,10.63-11.71,31.26-19.75,52.25-3.13,.85-7.26,5.89-7.59,11.41-7.31,3.49,.18,7.01,.2,10.49,0,4.13-.24,5.6,1.54,5.56,5.59-.14,13.33-.05,26.66-.05,39.99,0,10.16,.08,20.33-.03,30.49-.25,22.99-13.81,38.83-36.52,42.31-15.92,2.44-31.23,.34-45.38-7.78-5.35-3.07-5.35-3.67-1.97-8.8,2.75-4.17,5.49-8.35,8.22-12.53,1.58-2.42,3.08-2.9,5.59-.78,7.99,6.77,17.51,8.58,27.65,7.5,10.3-1.1,16.62-9.89,14.69-20.66Zm-17.17-16.16c11.53-.01,18.83-7.2,18.82-18.52,0-10.55-8.42-19.15-18.84-19.28-10.07-.12-19.01,8.92-19,19.27,0,9.17,7.55,19.84,19.02,18.53Z" />
                                        <path class="cls-1" d="M359.5,80.91c0,11.25,.02,21.72,0,32.18-.01,6.77-.22,6.96-7.07,6.99-4.5,.02-9,.04-13.49,0-5.87-.07-6.51-.69-6.52-6.74-.03-31.66-.02-63.31-.01-94.97,0-3.5,.1-7,0-10.5-.11-3.62,1.51-5.15,5.11-5.02,3.83,.14,7.66,.02,11.49,.02q8.73,0,11.32,7.78c.44-.17,.98-.24,1.3-.53,12.17-11.09,26.31-12.04,40.53-5.97,14.78,6.31,22.49,18.69,24.33,34.65,1.79,15.54-1.63,29.44-13.04,40.74-13.59,13.47-36.73,14.58-51.66,2.55-.37-.3-.85-.45-2.28-1.18Zm40.74-35.3c.04-11.68-9.31-21.32-20.72-21.38-11.05-.06-20.93,10.04-20.88,21.35,.05,11.75,9.72,21.33,21.38,21.2,11.23-.13,20.18-9.49,20.21-21.17Z" />
                                        <path class="cls-1" d="M503.67,11.03c1.56-7.64,2.12-8.11,9.34-8.14,4.33-.02,8.67,.07,13,0,3.18-.05,4.49,1.44,4.49,4.56-.03,25.66-.04,51.33,.02,76.99,0,3.55-1.79,4.65-4.99,4.6-5-.07-10-.16-14.99,.04-4.26,.17-6.84-1.01-6.29-5.86,.1-.89-.27-1.83-.49-3.15-2.91,2.13-5.24,4.27-7.95,5.74-21.01,11.39-47.8,1.2-55.71-21.89-6.45-18.85-4.77-36.95,9.9-51.96,13.91-14.23,36.42-15.01,51.67-2.25,.48,.4,1.06,.7,2,1.32Zm1.47,34.42c-.08-11.37-10.01-21.4-21.02-21.22-11.56,.19-20.69,9.74-20.56,21.48,.13,11.74,9.11,21.05,20.32,21.08,11.73,.03,21.35-9.62,21.27-21.33Z" />
                                        <path class="cls-1" d="M156.11,10.94c2.83-2.09,5.2-4.18,7.86-5.76,21.2-12.54,45.7-.48,47.39,24.02,1.25,18.23,.74,36.58,1.04,54.88,.06,3.69-1.89,4.99-5.31,4.95-5.33-.07-10.66-.14-15.98,.03-4.36,.14-5.84-1.87-5.8-6.05,.13-13.49,.1-26.98-.01-40.46-.02-2.79-.31-5.7-1.15-8.33-2.26-7.1-6.3-9.47-14.62-9.14-6.87,.27-11.5,3.72-13.09,10.17-.67,2.71-.93,5.59-.95,8.39-.1,12.99-.18,25.98,.02,38.96,.07,4.72-1.64,6.7-6.41,6.48-5.15-.24-10.32-.12-15.48-.05-3.47,.04-5.25-1.38-5.25-5.02,.04-25.48,.04-50.96,0-76.43,0-3.41,1.54-4.8,4.88-4.71,4.16,.11,8.32,0,12.49,.02,7.28,.03,7.96,.54,10.37,8.06Z" />
                                        <path class="cls-1" d="M37.62,91.04c-11.93-.61-23.01-3.07-33.22-9.02-5.33-3.11-5.5-3.79-2.21-8.76,2.21-3.34,4.69-6.52,6.6-10.01,2.31-4.22,4.51-3.63,8.37-1.63,5.82,3.01,12.17,5.16,18.51,6.92,2.64,.73,5.95-.12,8.72-.98,1.39-.43,2.94-2.44,3.13-3.9,.15-1.14-1.45-3.16-2.72-3.69-3.82-1.58-7.87-2.62-11.83-3.86-6.65-2.08-13.68-3.36-19.87-6.38C.24,43.44-3.05,23.23,6.41,12.44,13.08,4.82,21.56,1.4,31.52,.93c11.34-.54,22.33,.76,32.54,5.99,6.89,3.53,6.87,3.99,2.63,10.26-2.15,3.18-4.43,6.27-6.36,9.58-1.91,3.27-3.62,4.02-6.98,1.43-5.69-4.38-12.33-6.88-19.59-5.9-2.37,.32-5.66,2.17-6.41,4.13-1.27,3.32,2.07,4.86,4.86,5.63,8.02,2.21,16.27,3.71,24.1,6.44,12.6,4.39,17.25,11.22,17.18,23.31-.07,12.12-6.54,21.84-17.94,25.57-5.76,1.88-11.94,2.49-17.94,3.67Z" />
                                        <path class="cls-1" d="M601.28,29.99c-5.92-2.63-10.9-5.45-16.24-6.98-3.47-1-7.51-.39-11.23,.09-1.19,.15-2.95,2.16-2.99,3.36-.05,1.48,1.05,3.72,2.32,4.39,2.74,1.46,5.88,2.2,8.89,3.11,7.76,2.34,15.91,3.8,23.24,7.08,15.67,7.03,13.41,29.2,6.07,37.61-4.18,4.78-9.09,8.29-15.34,9.69-17.42,3.92-34,2.07-49.55-7.19-3.24-1.93-4.11-3.79-1.58-7.07,2.94-3.81,5.45-7.96,8.15-11.96,1.66-2.46,3.36-3.18,6.15-1.15,6.31,4.59,13.45,7.2,21.28,7.82,5.67,.45,10.46-1.5,10.36-4.61-.17-5.03-4.49-5.6-8.15-6.53-5.96-1.53-12-2.78-17.96-4.31-11.77-3.03-19.73-12.14-20.31-23.07-.64-12.03,5.6-22.2,17.33-26.35,16.59-5.87,32.75-3.82,48.18,4.45,2.37,1.27,4.28,2.96,2.24,5.93-3.65,5.33-7.37,10.63-10.87,15.68Z" />
                                        <path class="cls-1" d="M661.45,91.07c-12.3-.68-23.61-3.37-34.08-9.63-3.49-2.08-4.23-4.15-1.66-7.55,2.81-3.71,5.36-7.65,7.79-11.62,1.84-3.01,3.58-2.75,6.56-1.32,6.08,2.92,12.39,5.52,18.84,7.45,2.79,.83,6.26,.05,9.24-.72,1.42-.37,3.26-2.35,3.36-3.71,.09-1.33-1.49-3.5-2.86-4.08-3.8-1.61-7.86-2.65-11.85-3.8-5.43-1.56-11.04-2.63-16.32-4.58-8.91-3.3-13.98-10.01-15.03-19.46-1.17-10.43,1.94-19.24,11.09-25.01,3.45-2.18,7.39-4.1,11.35-4.89,14.45-2.86,28.48-1.42,41.69,5.61,3.97,2.11,5.51,4.36,1.95,8.42-2.61,2.98-4.71,6.47-6.7,9.93-2.16,3.76-4.06,4.87-8.03,1.73-5.34-4.22-11.76-6.15-18.71-5.72-3.15,.19-6.78,.81-6.7,4.4,.05,1.92,2.93,4.78,5.1,5.47,7.9,2.55,16.22,3.86,24.04,6.59,12.3,4.28,17.03,11.2,17.01,22.93-.02,12.21-6.34,21.98-17.66,25.76-5.9,1.97-12.26,2.58-18.41,3.81Z" />
                                        <path class="cls-2" fill="#000" d="M98.24,89.01c-3.99,0-7.98-.03-11.96,0-3.05,.02-4.61-1.03-4.16-4.49,1.96-14.97,3.8-29.97,5.63-44.96,.47-3.86,2.58-4.86,6.23-4.41,2.92,.36,5.94,.31,8.87,0,3.36-.35,5.29,.29,5.76,4.15,1.82,14.99,3.84,29.96,5.9,44.93,.49,3.57-.88,4.89-4.31,4.8-3.99-.1-7.98-.02-11.96-.03Z" />
                                        <path class="cls-2" fill="#000" d="M83.23,15.09C83.27,6.39,89.71-.01,98.39,0c8.66,.01,15.19,6.84,15.09,15.8-.09,8.65-7.13,15.43-15.79,15.21-8.41-.21-14.51-6.93-14.47-15.92Z" />
                                    </svg>
                                </Col>
                                <Col lg="auto">
                                    <ListGroup horizontal className="incrementalText">
                                        <ListGroup.Item role="button" className="border-0 p-0 pe-2">
                                            <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.88 12.71">
                                                <polygon points="6.7 1.37 9.99 1.37 10.58 2.92 11.7 2.92 11.7 0 0 0 0 2.92 1.12 2.92 1.71 1.37 4.88 1.37 4.88 11.32 3.24 11.92 3.24 12.71 8.33 12.71 8.33 11.92 6.7 11.32 6.7 1.37" />
                                                <polygon points="11.6 6.35 11.6 4.06 10.6 4.06 10.6 6.35 8.32 6.35 8.32 7.34 10.6 7.34 10.6 9.62 11.6 9.62 11.6 7.34 13.88 7.34 13.88 6.35 11.6 6.35" />
                                            </svg>
                                        </ListGroup.Item>
                                        <ListGroup.Item role="button" className="border-0 p-0 px-2 border-start border-end">
                                            <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.7 12.71">
                                                <path d="M8.33,12.71H3.24v-.79l1.64-.59V1.37H1.71l-.59,1.55H0V0H11.7V2.92h-1.12l-.59-1.55h-3.29V11.32l1.64,.59v.79Z" />
                                            </svg>
                                        </ListGroup.Item>
                                        <ListGroup.Item role="button" className="border-0 p-0 ps-2 me-3">
                                            <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.42 12.71">
                                                <path d="M8.33,12.71H3.24v-.79l1.64-.59V1.37H1.71l-.59,1.55H0V0H11.7V2.92h-1.12l-.59-1.55h-3.29V11.32l1.64,.59v.79Z" /><path d="M12.42,7.42h-3.78v-.88h3.78v.88Z" />
                                            </svg>
                                        </ListGroup.Item>
                                        <ListGroup.Item role="button" className="border-0 p-0 ps-4">
                                            <HiOutlinePrinter size="24" className="w-auto h-auto GrayComn" />
                                        </ListGroup.Item>
                                        <ListGroup.Item role="button" className="border-0 p-0 ps-4">
                                            <FiSearch size="22" className="w-auto h-auto GrayComn" />
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row className="grayf3f">
                    <Col className="mt-5">
                        <Container className="h100">
                            <Row>
                                <Col>
                                    <a className="btn shadow-none p-0 fs26 semibold GrayComn singpassclass"
                                        data-bs-toggle="collapse"
                                        href="#collapseExample"
                                        aria-expanded="false"
                                        aria-controls="collapseExample">
                                        Beware of phishing SMSes, emails and websites
                                        <BiChevronDown color="var(--theme-color)" size="40" />
                                    </a>
                                    <Col as="div" className="collapse" id="collapseExample">
                                        <Col as="div" className="pt-3 medium GrayComn">
                                            We are aware of fake SMSes and emails requesting users to reactivate their Singapass. They contain hyperlinks to fake Singapass websites phishing for Singapass IDs.
                                            passwords, OTPs or credit card details. Singapass does not send SMSes with hyperlinks or requests for your credit cart details.
                                        </Col>
                                    </Col>
                                </Col>
                            </Row>
                            <Row className="mt-5 h-100 align-items-center">
                                <Col>
                                    <ListGroup horizontal className="singpassLeft">
                                        <ListGroup.Item className="border-0 bg-transparent">
                                            <img src={process.env.PUBLIC_URL + "assets/singpassIcon.svg"} width="60" alt="usericon" />
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0 bg-transparent">
                                            <h2 className="fs36 bold">Log in with Singapass</h2>
                                            <p className="fs20 semibold GrayComn">Your trusted digital identity</p>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col lg="4">
                                    <Card className="bg-white pt-4 singaTabs shadowSM border12 border-0" >
                                        <Tabs defaultActiveKey="singapassapp" id="uncontrolled-tab-example" className="ps-3 pe-3 border-0" fill>
                                            <Tab eventKey="singapassapp" title="Singapass app" tabClassName="border-0 p-0 fs18 semibold" className="text-center pt-5 p-4">
                                                <h2 className="fs22 bold m-0" onClick={handleOcr}>Scan with Singapass app</h2>
                                                <p className="fs22 medium m-0 gray82">to log in</p>
                                                <img src={process.env.PUBLIC_URL + "/assets/singpassCapcha.png"} alt="usericon" />
                                                <div className="App">
                                                    <center>
                                                        <br />
                                                        <br />
                                                        <input
                                                            type="text"
                                                            onChange={(e) => setValue(e.target.value)}
                                                            placeholder="Value of Qr-code"
                                                        />
                                                        <br />
                                                        <br />
                                                        {value && (
                                                            <QRCode
                                                                title="www.google.com"
                                                                value={value}
                                                                bgColor={back}
                                                                fgColor={fore}
                                                                size={size === '' ? 0 : size}
                                                            />
                                                        )}
                                                    </center>
                                                </div>

                                            </Tab>
                                            <Tab eventKey="passwordlogin" title="Password login" tabClassName="border-0 p-0 fs18 semibold" className="text-center pt-5 p-4">

                                                <h2 className="fs18 bold m-0 text-start pb-4">
                                                    Logging in as <Link to="/" className="themeColor fs16">Business User</Link>
                                                </h2>
                                                <Form className="accForm">
                                                    <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3">
                                                        <Form.Control type="email" placeholder="name@example.com" />
                                                    </FloatingLabel>
                                                    <FloatingLabel controlId="floatingPassword" label="Password">
                                                        <Form.Control type="password" placeholder="Password" />
                                                    </FloatingLabel>
                                                    <Button className="w-100 py-2 lh-lg border-0 hvr-float-shadow mt-4" variant="primary" onClick={handleOcr}>Log In</Button>
                                                </Form>
                                                <Row className="fs18 textGreen semibold text-start mt-4">
                                                    <Col><span role="button">Forgot Singpass ID</span></Col>
                                                    <Col className="text-end"><span role="button">Reset Password</span></Col>
                                                </Row>
                                            </Tab>
                                        </Tabs>
                                        <Card.Footer className="py-4 fs18 gray82 semibold text-center">
                                            Don't have Singpass app? <Link to="/" className="themeColor">Download now</Link>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col className="darkfooter py-3 mt-5">
                        <Container>
                            <Row className="align-items-end justify-content-between">
                                <Col>
                                    <h2 className="text-white semibold fs28">singpass</h2>
                                    <p className="text-white m-0">
                                        <Link className="text-white medium fs16 me-2 text-decoration-none" to="/">Contact us</Link>|
                                        <Link className="text-white medium fs16 ms-2 me-2 text-decoration-none" to="/">Share feedback</Link>|
                                        <Link className="text-white medium fs16 ms-2 text-decoration-none" to="/">Read FAQs</Link>
                                    </p>
                                </Col>
                                <Col lg="auto" className="text-white medium fs16">© 2021 Government of Singpass</Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default SignPass