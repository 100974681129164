import React, { useState } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { HiHome } from "react-icons/hi";
import { BsClock } from "react-icons/bs";
import { Link } from 'react-router-dom';
import '../loginpage.css';


const addClass = () => {
    document.getElementById("signUpFlip").classList.add("scalIn");
    setTimeout(function () {
        document.getElementById("scalOut").classList.add("scalOut");
    }, 100);

    setTimeout(function () {
        document.getElementById("signUpFlip").classList.add("flip");
    }, 1000);

    setTimeout(function () {
        document.getElementById("scalOut").classList.remove("scalOut");
    }, 1000);

    setTimeout(function () {
        document.getElementById("signUpFlip").classList.remove("scalIn");
    }, 1000);
}

const ForgotPassTwo = () => {
    return (
        <div className="front">
            <div className='heading mb-4'>
                <h2 className='small lh-1 fs-normal mb-0'>User Verification</h2>
                <p className="semibold">A 6-Digit Code has been send your number +61 XXXX X567</p>
            </div>
            <Form>
                <Form.Group controlId="formBasicName" className="mb-3 pb-1 row otpScreem">
                    <Col md={2}><input type="text" id="input1" placeholder="." /></Col>
                    <Col md={2}><input type="text" id="input1" placeholder="." /></Col>
                    <Col md={2}><input type="text" id="input1" placeholder="." /></Col>
                    <Col md={2}><input type="text" id="input1" placeholder="." /></Col>
                    <Col md={2}><input type="text" id="input1" placeholder="." /></Col>
                    <Col md={2}><input type="text" id="input1" placeholder="." /></Col>
                </Form.Group>
                <Form.Group className="mt-4 mb-4 pb-4" controlId="exampleForm.ControlTextarea1">
                    <Form.Label className='float-start'><Link to="" className="forgotPass text-decoration-none">Resend?</Link></Form.Label>
                    <Form.Label className='float-end'><BsClock size="20" /> <b>00.25</b></Form.Label>
                </Form.Group>
                <Button className="w-100 py-2 lh-lg border-0 hvr-float-shadow" variant="primary" type="submit">Continue</Button>
            </Form>
        </div>

    )
}
export default ForgotPassTwo