import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import "../../hoststep.css";
import Footer from '../../../components/footer';
import { Link, useOutletContext } from 'react-router-dom';
import MenuSidebar from '../../../components/menusidebar';
import AfterLoginHeader from '../../../components/afterLoginHeader';
import { GetCompanyDetails } from '../../../queries/queries';
import { useQuery } from '@apollo/client';
import { BiErrorAlt } from 'react-icons/bi';

const HostProfile = () => {
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState({});
  const user = useOutletContext();
  // console.log(user);
  const companyGql = GetCompanyDetails();

  const { loading, data, error } = useQuery(companyGql);

  useEffect(() => {
    if(data) {
    if (data?.getCompanyDetails?.status == "success" ) {
        let newdata = (data?.getCompanyDetails?.company_details);
      // console.log(newdata);
        setDetails(newdata);
    }
  }
  if(error) {
    console.log("Something went wrong");
  }
}, [data,error]);

  const handleClose = () => setShow(false);
  return (
    <>
      <Col md="auto" className='d-flex text-center flex-column flex-md-row text-md-start col-md-auto'>
        <span className="user-profile-circle me-md-3 mx-auto rounded-pill border border-2 border-danger p-1 d-inline-block position-relative">
          <img src="/assets/black_scoop.png" className='rounded-pill border-2' alt="food" />
        </span>
        <Col className="itemDetails">
          <Col as="div" className="itemHead mb-2">
            <h4 className="d-inline-block align-middle GrayDark fs46 semibold">{loading ? 'Loading...' : details?.company_name}</h4>
          </Col>
          <Col as="div" className="d-inline-flex border04 grayf7f py-2 px-3 shadowMD align-items-center semibold align-middle">
            <Col className='border-end pe-3 me-3'>
              <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.0013 26.0947L15.8379 14.4258H24.1647L20.0013 26.0947Z" fill="#6992F2" />
                <path d="M0 14.3711L19.3602 31.4961L13.5297 14.4017L0 14.3711Z" fill="#3169ED" />
                <path d="M12.8381 12.0727L0.0273438 12.0743L6.42691 0.878906L12.8381 12.0727Z" fill="#4377EF" />
                <path d="M39.9998 14.3867L20.6396 31.5117L26.4701 14.4173L39.9998 14.3867Z" fill="#7CA0F4" />
                <path d="M27.1631 12.0845L39.9738 12.086L33.5859 0.890625L27.1631 12.0845Z" fill="#A1BBF7" />
                <path d="M24.2077 11.9639L15.8809 11.9649L20.0559 4.72656L24.2077 11.9639Z" fill="#8EADF6" />
                <path d="M19.5222 0.00155315L8.61328 0L14.0628 9.53337L19.5222 0.00155315Z" fill="#5684F0" />
                <path d="M20.5225 0.00155315L31.4314 0L25.9918 9.53337L20.5225 0.00155315Z" fill="#7CA0F4" />
              </svg>
            </Col>
            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M11.6758 0.928603C12.0926 -0.309534 13.9074 -0.309534 14.3242 0.928603L16.4386 7.20961C16.6249 7.76332 17.1596 8.13821 17.7627 8.13821H24.605C25.9538 8.13821 26.5146 9.80412 25.4234 10.5693L19.8878 14.4512C19.3998 14.7934 19.1957 15.4 19.3821 15.9537L21.4965 22.2348C21.9132 23.4729 20.445 24.5025 19.3539 23.7372L13.8184 19.8554C13.3304 19.5131 12.6696 19.5131 12.1816 19.8554L6.64609 23.7372C5.55492 24.5025 4.08674 23.4729 4.50353 22.2348L6.61791 15.9537C6.8043 15.4 6.6001 14.7934 6.11212 14.4512L0.57661 10.5693C-0.514582 9.80412 0.0462183 8.13821 1.39499 8.13821H8.23727C8.84045 8.13821 9.37505 7.76332 9.56144 7.20961L11.6758 0.928603Z" fill="#EF9309" />
            </svg>
            <Col as="span" className='col-auto px-2 lh-1 align-middle fs26 GrayComn semibold'>
              4.5
            </Col>
            <Link to="/" className='d-inline-block themeColor ps-3 semibold fs20 text-decoration-none'>See Reviews (55)</Link>
          </Col>
        </Col>
      </Col>
    </>

  )
}
export default HostProfile;