import React, { useState } from "react";
import { Row, Col, Form, Button, FloatingLabel } from "react-bootstrap";
import { addClass } from "../../../commanFunctions";

const HostBusiness05 = (params) => {
  const [errorMessage, setErrorMessage] = useState(undefined);

  const onNextpage = (event) => {
    event.preventDefault();
    if (params.primaryBusiness === "") {
      setErrorMessage({
        error: "empty primary business activity",
        message: "Please Enter Primary Business Activity",
      });
    } else if (params.secBusiness === "") {
      setErrorMessage({
        error: "empty secondary business activity",
        message: "Please Enter Secondary Business Activity",
      });
    } else if (params.companyAddress === "") {
      setErrorMessage({
        error: "empty registered business address",
        message: "Please Enter Registered Business Address",
      });
    } else if (params.shareCapital === "") {
      setErrorMessage({
        error: "empty total share capital",
        message: "Please Enter Total Share Capital",
      });
    } else if (params.currency === "") {
      setErrorMessage({
        error: "empty currency",
        message: "Please Enter Currency",
      });
    } else {
      addClass();
      setTimeout(() => {
        params.setStepNo(6);
      }, 1400);
    }
  };

  const goBack = () => {
    addClass();
    setTimeout(() => {
      params.setStepNo(4);
    }, 1400);
  };

  return (
    <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
      <div className="front">
        <div className="heading">
          <h2 className="small lh-1 fs-normal mb-2">Become A Host</h2>
          <p className="semibold m-0">Please confirm your personal registration details.</p>
        </div>
        <Col as="section" className="stepsBar2 pt-4">
          <Col as="ul" className="list-unstyled">
            <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(100%/2.6)" }}>5</span></Col>
          </Col>
        </Col>
        <Col as="section" className="signpUser pt-2">
          <Form onSubmit={onNextpage} className="accForm">
            <FloatingLabel controlId="floatingInput" label="Primary Business Activity" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Primary Business Activity"
                value={params.primaryBusiness}
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrorMessage({
                      error: "empty primary business activity",
                      message: "Please Enter Primary Business Activity",
                    });
                  } else {
                    setErrorMessage(undefined);
                  }
                  params.setPrimaryBusiness(e.target.value);
                }}
              />
              {errorMessage &&
                errorMessage.error ===
                "empty primary business activity" && (
                  <div className="invalid-feedback d-block">
                    {errorMessage.message}
                  </div>
                )}
            </FloatingLabel>
            <FloatingLabel controlId="floatingInput" label="Secondary Business Activity" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Secondary Business Activity"
                value={params.secBusiness}
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrorMessage({
                      error: "empty secondary business activity",
                      message: "Please Enter Secondary Business Activity",
                    });
                  } else {
                    setErrorMessage(undefined);
                  }
                  params.setSecBusiness(e.target.value);
                }}
              />
              {errorMessage &&
                errorMessage.error ===
                "empty secondary business activity" && (
                  <div className="invalid-feedback d-block">
                    {errorMessage.message}
                  </div>
                )}
            </FloatingLabel>

            <FloatingLabel controlId="floatingInput" label="Registered Business Address" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Registered Business Address"
                value={params.companyAddress}
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrorMessage({
                      error: "empty registered business address",
                      message: "Please Enter Registered Business Address",
                    });
                  } else {
                    setErrorMessage(undefined);
                  }
                  params.setCompanyAddress(e.target.value);
                }}
              />
              {errorMessage &&
                errorMessage.error ===
                "empty registered business address" && (
                  <div className="invalid-feedback d-block">
                    {errorMessage.message}
                  </div>
                )}
            </FloatingLabel>
            <Row className="mb-3">
              <Col>
                <FloatingLabel controlId="floatingInput" label="Total Share Capital" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Total Share Capital"
                    value={params.shareCapital}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setErrorMessage({
                          error: "empty total share capital",
                          message: "Please Enter Total Share Capital",
                        });
                      } else {
                        setErrorMessage(undefined);
                      }
                      params.setShareCapital(e.target.value);
                    }}
                  />
                  {errorMessage &&
                    errorMessage.error ===
                    "empty total share capital" && (
                      <div className="invalid-feedback d-block">
                        {errorMessage.message}
                      </div>
                    )}
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingInput" label="Currency" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Currency"
                    value={params.currency}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setErrorMessage({
                          error: "empty currency",
                          message: "Please Enter Currency",
                        });
                      } else {
                        setErrorMessage(undefined);
                      }
                      params.setCurrency(e.target.value);
                    }}
                  />
                  {errorMessage &&
                    errorMessage.error === "empty currency" && (
                      <div className="invalid-feedback d-block">
                        {errorMessage.message}
                      </div>
                    )}
                </FloatingLabel>
              </Col>
            </Row>

            {params.registrationType === "acra" && (
              <>
                <div className="mb-2 lh-1 form-radio">
                  <input
                    name="group1"
                    type="radio"
                    id={`inline-radio-1`}
                    checked={params.companyAmendment === true}
                    onChange={(e) =>
                      params.setCompanyAmendment(true)
                    }
                    defaultChecked
                  />
                  <label className="ms-3 pt-1 lh-sm semibold themeColor" htmlFor={`inline-radio-1`}>
                    Information is correct. No amendment required
                  </label>
                </div>

                <div className="mb-4 lh-1 form-radio">
                  <input
                    name="group1"
                    type="radio"
                    id={`inline-radio-2`}
                    checked={params.companyAmendment === false}
                    onChange={(e) =>
                      params.setCompanyAmendment(false)
                    }
                  />
                  <label className="ms-3 pt-1 lh-sm semibold themeColor" htmlFor={`inline-radio-2`}>
                    Information is incorrect. Proceed to amend
                  </label>
                </div>
              </>
            )}

            <Row>
              <Col className="align-self-center">
                <Button
                  className="fs26 themeColor semibold text-decoration-none lh-1 p-0"
                  variant="link"
                  type="button"
                  onClick={goBack}
                >
                  <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  Previous
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                  variant="primary"
                  type="submit"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
    </section>
  )
}

export default HostBusiness05