import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Badge,
    Form,
    FloatingLabel,
    ListGroup,
} from "react-bootstrap";
import { FiEye, FiChevronDown } from "react-icons/fi";
import { HiCheckCircle, HiDownload, HiOutlineCamera, HiOutlineCheckCircle } from "react-icons/hi";
import { BsArrowRight, BsCalendar2, BsPatchCheckFill } from "react-icons/bs";
import { BiUpArrow } from "react-icons/bi";
import { RiCloseCircleFill, RiAddCircleFill } from "react-icons/ri";
import { TbCirclePlus, TbTrash } from "react-icons/tb";
import { AiFillCloseCircle, AiFillEye, AiTwotoneEyeInvisible } from "react-icons/ai";
import "react-pro-sidebar/dist/css/styles.css";

import "./../account.css";
import Footer from "../../components/footer";
import ProfileMenu from "../../components/profilemenu";
import { GetHostById, GetUserById, HostUserResolver, ManageAccountStatus, UpdateHostProfile, UserChangePassword } from "../../queries/queries";
import { gql, useMutation, useQuery } from "@apollo/client";
import DatePicker from "react-datepicker";
import { allergiesOptions, dietaryPrefrences } from "../../utils/staticData";
import { arrayFilterWithoutSameData, arrayFilterWithSameData } from "../../commanFunctions";
import AfterLoginHeader from "../../components/afterLoginHeader";
import { useOutletContext } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import AlertPopup from "../../components/alertPopup";
import _ from "lodash";

const HostMyProfile = () => {
    const navigate = useNavigate();
    const user = useOutletContext();
    const [onShow, setOnShow] = useState(false)
    const [popupMessage, setPopupMessage] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    // -----------------Initial-Data---------------------------
    const [userData, setUserData] = useState({
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        is_warn_me: "",
        show_quick_view: "",
        user_profile: {
            gender: "",
            dob: "",
            account_verified: false,
            profile_image: "",
            home_address: "",
            current_address: ""
        },
        auth_method: "",
        auth_login: false,
        auth_account_changes: false,
        auth_order: false,
        emergency_contact: [{ id: null, first_name: "", last_name: "", relationship: "", phone_number: null },],
        user_host_type: "",
        user_document: {
            nric_no: "",
            nric_front: null,
            nric_back: null,
            acra_doc: null,
            busniess_certificate: null,
            is_verified: null,
        },
    });


    // ----------------------Profile Image---------------------------------------
    const [profileImage, setProfileImage] = useState('')
    const [Dob, setDob] = useState(new Date())

    // --------------------Dietary Prefrences & Allergies (static data)-------------------------
    const [dietaryItems, setDietaryItems] = useState(dietaryPrefrences || []);
    const [allergyItems, setAllergyItems] = useState(allergiesOptions || []);

    // -------------------Dietary Prefrences & Allergies (selected data)------------------------
    const [selectedDietaries, setSelectedDietaries] = useState([]);
    const [selectedAllergies, setSelectedAllergies] = useState([]);

    //-----------------------Emergency Contact Form (dynamic)-----------------------------------
    const [formValues, setFormValues] = useState([{ first_name: "", last_name: "", relationship: "", phone_number: null }]);

    // ----------------------------------------------------------------------------------------
    const [passOneHide, setPassOneHide] = useState(true);
    const [passTwoHide, setPassTwoHide] = useState(true);
    const [errorMessage, setErrorMessage] = useState(undefined);


    const [securityQuestions, setSecurityQuestions] = useState([{
        id: null,
        question_id: '',
        answer: ""
    }]);

    // -----------------------------GET USER PROFILE DATA---------------------------------------
    const userId = user?.user?.id;
    const isUser = Object.keys(user?.user).length === 0;
    const getUserData = GetHostById();
    const { loading, data } = useQuery(getUserData, {
        skip: isUser,
        variables: { userId: Number(userId) },
    });
    const updateHostProfile = UpdateHostProfile();
    const [hostDataUpdate] = useMutation(updateHostProfile);
    const updatePassword = UserChangePassword();
    const ManageAccountApi = ManageAccountStatus();
    const [AccountManagementStatus] = useMutation(ManageAccountApi)

    const getquestion = HostUserResolver();
    const getQuestions = useQuery(getquestion);
    const SecurityQuetions = getQuestions?.data?.getQuestions?.security_question;

    const [userChangePassword] = useMutation(updatePassword);

    const [passwordData, setPasswordData] = useState({
        current_password: "",
        password: "",
        confirm_password: ""
    })
    // -------------------------------------------------------------------------
    const [accountStatus, setAccountStatus] = useState("")

    const handleAccountStatus = async (status) => {
        let StatusAccount = await AccountManagementStatus({
            variables: {
                "userId": Number(userId),
                "status": status
            }
        })
        if (StatusAccount?.data?.manageAccountStatus?.status === "success") {
            setPopupMessage({
                status: StatusAccount?.data?.manageAccountStatus?.status,
                message: StatusAccount?.data?.manageAccountStatus?.message
            })
            setOnShow(true)
        } else {
            setPopupMessage({
                status: StatusAccount?.data?.manageAccountStatus?.status,
                message: StatusAccount?.data?.manageAccountStatus?.message
            })
            setOnShow(true)
        }
    }
    // -----------------------------useEffect & Render Method-------------------------------
    useEffect(() => {
        if (data?.getUserById?.user) {
            let newdata = (data?.getUserById?.user);
            setUserData(newdata);
            // var quetions = [];
            var quetions = [
                {
                    id: null,
                    question_id: null,
                    answer: "",
                },
            ];
            newdata?.security_question.map((data) => quetions.push({
                id: data.id,
                question_id: data.question_id,
                answer: data.answer
            }))
            setSecurityQuestions(quetions);
            // setSecurityQuestions(newdata?.security_question);
            setDob(newdata.user_profile.dob ? new Date(newdata.user_profile.dob) : new Date());
            setProfileImage(newdata?.user_profile?.profile_image ? process.env.REACT_APP_BACKEND_IMAGE_URL + newdata?.user_profile?.profile_image : "/assets/frame02_old.png")
        }
    }, [data]);

    useEffect(() => {
        if (userData?.emergency_contact.length !== 0) {
            const EmergencyContactDetails = userData?.emergency_contact?.map(({ __typename, ...rest }) => {
                return rest;
            });

            setFormValues(EmergencyContactDetails);
        }
    }, [userData?.user_profile?.dietary_prefrence, userData?.user_profile?.allergies, userData?.emergency_contact]);

    // --------------------------Functions and Methods-----------------------------------------------

    const sureLogout = () => {
        localStorage.removeItem("token")
        navigate('/')
    }

    const handleProfileImage = (e) => {
        let ImageUrl = URL.createObjectURL(e.target.files[0]);
        let ImageName = (e.target.files[0]);
        setUserData({ ...userData, user_profile: { ...userData.user_profile, profile_image: ImageName } })
        setProfileImage(ImageUrl)
    }

    const handleInputChange = (e) => {
        let type = e.target.type;
        let key = e.target.id;
        let name = e.target.name;
        if (type === "checkbox") {
            setUserData({ ...userData, [key]: e.target.checked });
        } else if (key === "nric_no") {
            setUserData({ ...userData, user_document: { ...userData.user_document, [key]: e.target.value } });
        } else {
            setUserData({ ...userData, [key]: e.target.value });
        }
        if (e.target.value === "") {
            setErrorMessage({
                error: `invalid ${name}`,
                message: `Please enter ${name}`,
            })
        } else {
            setErrorMessage(undefined);
        }

    };

    const handleUserProfile = (e) => {
        let key = e.target.id;
        let value = e.target.value;
        let name = e.target.name;
        setUserData({
            ...userData,
            user_profile: { ...userData.user_profile, [key]: value },
        });
        if (e.target.value === "not selected") {
            setErrorMessage({
                error: name,
                message: `Please select ${name}`,
            })
        } else {
            setErrorMessage(undefined);
        }
    };

    const handleMultiSelect = (name, item, index) => {
        let selectedDiets = [...selectedDietaries];
        let unselectedDiets = [...dietaryItems];
        let selectedallergy = [...selectedAllergies];
        let unselectedallergy = [...allergyItems];

        let newSelectedDiets = [];
        let newselectedAllergy = [];


        if (name === "selectedDietaries") {
            //  add data in unselected dietery state
            setDietaryItems([...dietaryItems, item]);
            // remove data from selected dietery state
            selectedDiets.splice(index, 1);
            // push data in new array 
            selectedDiets.map((data) => newSelectedDiets.push(data.value))
            // set selected data 
            setSelectedDietaries(selectedDiets);
            // set user data for api
            setUserData({ ...userData, "user_profile": { ...userData.user_profile, "dietary_prefrence": newSelectedDiets } })


        } else if (name === "dietaryItems") {
            setSelectedDietaries([...selectedDietaries, item]);
            unselectedDiets.splice(index, 1);
            [...selectedDietaries, item].map((data) => newSelectedDiets.push(data.value))
            setDietaryItems(unselectedDiets)
            setUserData({ ...userData, "user_profile": { ...userData.user_profile, "dietary_prefrence": newSelectedDiets } })

        } else if (name === "selectedAllergies") {
            setAllergyItems([...allergyItems, item]);
            selectedallergy.splice(index, 1);
            selectedallergy.map(data => newselectedAllergy.push(data.value))
            setSelectedAllergies(selectedallergy);
            setUserData({ ...userData, "user_profile": { ...userData.user_profile, "allergies": newselectedAllergy } })


        } else if (name === "allergyItems") {
            setSelectedAllergies([...selectedAllergies, item]);
            unselectedallergy.splice(index, 1);
            [...selectedAllergies, item].map(data => newselectedAllergy.push(data.value));
            setAllergyItems(unselectedallergy);
            setUserData({ ...userData, "user_profile": { ...userData.user_profile, "allergies": newselectedAllergy } });
        }

    }
    // -------------------------------------------
    const handlePasswordChange = (e) => {
        setPasswordData({ ...passwordData, [e.target.name]: e.target.value })
        let name = e.target.name;
        if (e.target.value === "") {
            setErrorMessage({
                error: name,
                message: `Please Enter ${name}`,
            })
        } else {
            setErrorMessage(undefined);
        }

    }
    // ----------------------------------------
    const handleSecurityQuestions = (e, id) => {
        let QnA = [...securityQuestions];
        if (e.target.name === "question_id") {
            QnA[id][e.target.name] = Number(e.target.value);
        } else {
            QnA[id][e.target.name] = e.target.value;
        }
        setSecurityQuestions(QnA)
    }

    const AddSecurityQuestion = () => {
        setSecurityQuestions([...securityQuestions, { id: null, question_id: null, answer: "" },
        ]);
        // setSecurityQuestions([...securityQuestions, { [`question`]: "", [`answer`]: "" }])
    }

    const handleRemoveQna = (id) => {
        let QnaList = [...securityQuestions]
        QnaList.splice(id, 1)
        setSecurityQuestions(QnaList)
    }
    // ----------------------------------------

    const handleChange = (i, e) => {
        let id = e.target.id;
        let newFormValues = [...formValues];
        if (e.target.name === "phone_number") {
            newFormValues[i][e.target.name] = Number(e.target.value);
        }
        else {
            newFormValues[i][e.target.name] = e.target.value;
        }
        setFormValues(newFormValues);
        setUserData({ ...userData, emergency_contact: newFormValues })
        if (e.target.value === "") {
            setErrorMessage({
                error: `invalid ${id + i}`,
                message: `Please enter ${id}`,
            })
        } else {
            setErrorMessage(undefined);
        }
    };

    const addFormFields = () => {
        setFormValues([...userData.emergency_contact, { id: null, first_name: "", last_name: "", relationship: "", phone_number: null }]);
        setUserData({ ...userData, emergency_contact: [...userData.emergency_contact, { id: null, first_name: "", last_name: "", relationship: "", phone_number: null }] })
    };

    const removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
        setUserData({ ...userData, emergency_contact: newFormValues })
    };

    const changePassword = async () => {
        try {
            const updatePassword = await userChangePassword({
                variables: passwordData
            })
            if (updatePassword.data.changePassword.status === "success") {
                setPopupMessage({
                    status: updatePassword.data.changePassword.status,
                    message: updatePassword.data.changePassword.message
                })
                setOnShow(true)
            } else {
                setPopupMessage({
                    status: updatePassword.data.changePassword.status,
                    message: updatePassword.data.changePassword.message
                })
                setOnShow(true)
            }

        } catch (err) {
            if (err.message == 'Failed to fetch') {
                setPopupMessage({
                    status: 'error',
                    message: 'Failed to fetch'
                })
                setOnShow(true)
            }
            else {
                const errorObject = JSON.parse(JSON.stringify(err)).graphQLErrors;
                let errors = _.at(errorObject[0], 'extensions.exception.validationErrors[0].constraints');
                let errorsValues = _.values(errors[0]);
                if (errorsValues.length > 0) {
                    setPopupMessage({
                        status: 'error',
                        message: errorsValues[0]
                    })
                    setOnShow(true)
                }
            }

        }

    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (userData?.first_name === "") {
            setErrorMessage({
                error: "invalid first name",
                message: "Please Enter first name"
            })
        } else if (userData?.last_name === "") {
            setErrorMessage({
                error: "invalid last name",
                message: "Please Enter last name"
            })
        } else if (!userData.user_profile || userData?.user_profile?.gender === "false") {
            setErrorMessage({
                error: "invalid gender",
                message: "Please set gender"
            })
        } else if (!userData.user_profile || userData.user_profile.dob === "" || userData.user_profile.dob === null) {
            setErrorMessage({
                error: "invalid dob",
                message: "Please select date of birth"
            })
        }

        else {
            setDisabled(true);
            DataSubmit()
        }
    }
    const DataSubmit = async () => {
        try {
            const EmergencyContactDetails = userData?.emergency_contact?.map(({ __typename, ...rest }) => {
                return rest;
            });
            const profileImage = (typeof userData?.user_profile?.profile_image !== "string" ? userData?.user_profile?.profile_image : null)
            setIsLoading(true);
            const updateData = await hostDataUpdate({
                variables: {
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    email: userData.email,
                    phone_number: userData.phone_number,
                    gender: userData.user_profile.gender,
                    dob: userData.user_profile.dob,
                    current_address: userData.user_profile.current_address,
                    auth_method: userData.auth_method,
                    auth_login: userData.auth_login,
                    auth_order: userData.auth_order,
                    auth_account_changes: userData.auth_account_changes,
                    nric_no: userData.user_document.nric_no,
                    nric_front: null,
                    nric_back: null,
                    busniess_certificate: null,
                    allergies: null,
                    dietary_prefrence: null,
                    profile_image: profileImage,
                    emergency_contact: EmergencyContactDetails,
                    questions: securityQuestions,
                    is_warn_me: userData.is_warn_me,
                    show_quick_view: userData.show_quick_view
                },
                update(cache, { data: { updateHostProfile } }) {
                    cache.modify({
                        fields: {
                            user() {
                                const newTodoRef = cache.writeFragment({
                                    data: updateHostProfile,
                                    fragment: gql`
                                    fragment User {
                                        first_name
                                    }
                                  `
                                });
                                return newTodoRef;
                            }
                        }
                    });
                }
            })
            setIsLoading(false);
            if (updateData.data.updateHostProfile.status === "success") {
                setPopupMessage({
                    status: updateData.data.updateHostProfile.status,
                    message: updateData.data.updateHostProfile.message
                })
                setOnShow(true)
                user.setUser({
                    ...user.user,
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    is_warn_me: userData.is_warn_me,
                    show_quick_view: userData.show_quick_view,
                    user_profile: {
                        profile_image: updateData.data.updateHostProfile.user.user_profile.profile_image
                    }
                })
            } else {
                setPopupMessage({
                    status: updateData.data.updateHostProfile.status,
                    message: updateData.data.updateHostProfile.message
                })
                setOnShow(true)
            }
            console.log("save button click", userData)
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }


    };

    const handleSuspend = () => {
        setAccountStatus("suspend")
        setPopupMessage({
            status: "account-management",
            message: "Do you want to suspend your account ?",
            button: () => { handleAccountStatus("suspend"); sureLogout() }
        })
        setOnShow(true)
    }
    const handleClose = () => {
        setAccountStatus("close")
        setPopupMessage({
            status: "account-management",
            message: "Do you want to close your account ?",
            button: () => handleAccountStatus("close")
        })
        setOnShow(true)
    }

    const handleVerify = () => {
        navigate('/host/identity-verify')
    }
    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0"><AfterLoginHeader /></Col>
                    <Col
                        as="span"
                        className="d-none proMenu"
                        data-bs-toggle="offcanvas"
                        href="#myprofile01"
                        role="button"
                        aria-controls="myprofile01">Profile Menu{" "}
                        <FiChevronDown className="float-end" size="28" color="var(--bs-white)" />
                    </Col>
                </Row>
                <Row className="dblock">
                    <Col
                        lg="3"
                        className="ps-0 profilemenu outline-none"
                        tabIndex="-1"
                        id="myprofile01"
                        aria-labelledby="myprofile01Label"
                    >
                        <ProfileMenu />
                    </Col>
                    <Col lg="9" as="div" className="userAcc pt-4 pe-xl-5">
                        <h2 className="h2_large ms-md-4 GrayComn">My Profile</h2>
                        <Col as="section" className="ms-md-4 me-xl-5 py-4 px-5" >
                            <Badge bg="white" className="d-block p-0">
                                <span className="rounded-pill border border-3 border-danger p-1 d-inline-block position-relative">
                                    <img
                                        src={loading ? "Loading..." : profileImage ? profileImage : "/avtarImage.png"
                                        }
                                        width={150}
                                        height={150}
                                        className="rounded-pill border border-2 border-danger"
                                        alt="navimg"
                                    />
                                    <span className="position-absolute cameraIcon bgLight d-inline-block rounded-pill d-flex align-items-center justify-content-center">
                                        <HiOutlineCamera size="28" color="var(--theme-color)" />
                                    </span>
                                    <FloatingLabel controlId="formFile" className="cameraChooseFile">
                                        <Form.Control type="file" onChange={handleProfileImage} />
                                    </FloatingLabel>
                                </span>

                                <h5 className="semibold fs26 GrayComn mt-3 mb-1">
                                    {loading ? "Loading..." : userData?.first_name || userData?.last_name
                                        ? `${userData?.first_name} ${userData?.last_name} ` : ""
                                    }
                                    <BsPatchCheckFill className="checkfill" size="22" color="var(--bs-greenDark)" />
                                </h5>
                                <p className="semibold fs14 gray82">
                                    <FiEye size="19" color="var(--theme-color)" /> Total Reviews 2048
                                </p>
                            </Badge>
                            <Form className="mt-4 pt-5 accForm" onSubmit={handleSubmit}>
                                <Row className="pb-4">
                                    <Col className="fs24 semibold GrayComn">Personal Details</Col>
                                    <Col className="fs16 semibold themeColor text-end" onClick={handleVerify} >Verify my Identity</Col>
                                </Row>
                                <Row className="g_flutter gx-4">
                                    <Col xs="12" md="6" className="position-relative">
                                        <FloatingLabel className="mb-4 pb-1 position-relative" controlId="first_name" label="First Name">
                                            <Form.Control
                                                type="text"
                                                placeholder="John"
                                                name="first name"
                                                value={
                                                    loading ? "Loading..." : userData?.first_name
                                                        ? userData?.first_name : ""
                                                }
                                                onChange={handleInputChange}
                                            />
                                            {errorMessage && errorMessage.error === 'invalid first name' && (
                                                <div className="invalid-feedback d-block">
                                                    {errorMessage.message}
                                                </div>
                                            )}
                                        </FloatingLabel>
                                        <Col as="div" className="fs22 semibold textGreen position-absolute end-0 top-0 mt-4 me-5 pe-2">
                                            <HiOutlineCheckCircle size="22" color="var(--bs-greenDark)" className="align-text-top" /> Verified
                                        </Col>
                                    </Col>
                                    <Col xs="12" md="6" className="position-relative">
                                        <FloatingLabel
                                            className="mb-4 pb-1 position-relative"
                                            controlId="last_name"
                                            label="Last Name"
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="Doe"
                                                name="last name"
                                                value={
                                                    loading ? "Loading..." : userData?.last_name
                                                        ? userData?.last_name : ""
                                                }
                                                onChange={handleInputChange}
                                            />
                                            {errorMessage && errorMessage.error === 'invalid last name' && (
                                                <div className="invalid-feedback d-block">
                                                    {errorMessage.message}
                                                </div>
                                            )}
                                        </FloatingLabel>
                                        <Col as="div" className="fs22 semibold textGreen position-absolute end-0 top-0 mt-4 me-5 pe-2">
                                            <HiOutlineCheckCircle size="22" color="var(--bs-greenDark)" className="align-text-top" /> Verified
                                        </Col>
                                    </Col>
                                    <Col xs="12" md="6" className="position-relative">
                                        <FloatingLabel controlId="gender" label="Gender" className="mb-4 pb-1 position-relative" >
                                            <Form.Select
                                                className="semibold"
                                                aria-label="Floating label select example"
                                                name="gender"
                                                value={
                                                    loading ? "Loading..." : userData?.user_profile?.gender
                                                        ? userData?.user_profile?.gender : "not selected"
                                                }
                                                onChange={handleUserProfile}
                                            >
                                                <option value="not selected">Please Select Gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="other">Other</option>
                                                <option value="do not disclose">Do Not Disclose</option>
                                            </Form.Select>
                                            {errorMessage && errorMessage.error === 'gender' && (
                                                <div className="invalid-feedback d-block">
                                                    {errorMessage.message}
                                                </div>
                                            )}
                                        </FloatingLabel>
                                        <Col as="div" className="fs22 semibold textGreen position-absolute end-0 top-0 mt-4 me-5 pe-2">
                                            <HiOutlineCheckCircle size="22" color="var(--bs-greenDark)" className="align-text-top" /> Verified
                                        </Col>
                                    </Col>
                                    <Col xs="12" md="6" className="position-relative">
                                        <FloatingLabel controlId="dob" label="Date of Birth" className="mb-4 datePickerBox" >
                                            <DatePicker
                                                selected={Dob}
                                                className="form-control bg-white grayBdb"
                                                placeholderText="Date of Birth"
                                                popperClassName="end-0 float-end text-start"
                                                value={Dob}
                                                onChange={(date) => {
                                                    setDob(new Date(date))
                                                    setUserData({ ...userData, user_profile: { ...userData.user_profile, dob: date, }, })
                                                }
                                                }
                                                onChangeRaw={(event) => {
                                                    // if (event.target.value === "") {
                                                    //     setErrorMessage({
                                                    //         error: "invalid date",
                                                    //         message: "Invalid Date",
                                                    //     });
                                                    // } else {
                                                    //     setErrorMessage(undefined);
                                                    // }
                                                    // handleChangeRaw(event.target.value);
                                                }}
                                            />
                                            <BsCalendar2 className="calendarIcon mt-1" size="20" color="var(--theme-color)" />
                                            {errorMessage && errorMessage.error === 'invalid dob' && (
                                                <div className="invalid-feedback d-block">
                                                    {errorMessage.message}
                                                </div>
                                            )}
                                        </FloatingLabel>
                                        <Col as="div" className="fs22 semibold textGreen position-absolute end-0 top-0 mt-4 me-5 pe-3 zindex10">
                                            <HiOutlineCheckCircle size="22" color="var(--bs-greenDark)" className="align-text-top" /> Verified
                                        </Col>
                                    </Col>
                                    <Col xs="12" md="6" className="position-relative">
                                        <FloatingLabel className="mb-4 pb-1 position-relative" controlId="phone_number" label="Mobile Number">
                                            <Form.Control
                                                name="mobile number"
                                                type="text"
                                                placeholder="+65 9215 2547"
                                                value={
                                                    loading ? "Loading..." : userData?.phone_number
                                                        ? userData?.phone_number : ""
                                                }
                                                maxlength="10"
                                                onChange={handleInputChange}
                                                disabled={user?.user?.phone_number === null ? disabled : userData?.phone_number?.length >= 10}
                                            />
                                            {errorMessage && errorMessage.error === 'invalid mobile number' && (
                                                <div className="invalid-feedback d-block">
                                                    {errorMessage.message}
                                                </div>
                                            )}
                                        </FloatingLabel>
                                        <Col as="div" className="fs22 semibold textGreen position-absolute end-0 top-0 mt-4 me-5 pe-2">
                                            <HiOutlineCheckCircle size="22" color="var(--bs-greenDark)" className="align-text-top" /> Verified
                                        </Col>
                                    </Col>
                                    <Col xs="12" md="6" className="position-relative">
                                        <FloatingLabel className="mb-4 pb-1 position-relative" controlId="email" label="Email Address">
                                            <Form.Control
                                                name="email"
                                                type="email"
                                                placeholder="johndoe123@hotmail.com"
                                                value={
                                                    loading ? "Loading..." : userData?.email
                                                        ? userData?.email : ""
                                                }
                                                onChange={handleInputChange}
                                                disabled
                                            />
                                            {errorMessage && errorMessage.error === 'invalid email' && (
                                                <div className="invalid-feedback d-block">
                                                    {errorMessage.message}
                                                </div>
                                            )}
                                        </FloatingLabel>
                                        <Col as="div" className="fs22 semibold textGreen position-absolute end-0 top-0 mt-4 me-5 pe-2">
                                            <HiOutlineCheckCircle size="22" color="var(--bs-greenDark)" className="align-text-top" /> Verified
                                        </Col>
                                    </Col>
                                    <Col xs="12" md="6" className=" position-relative">
                                        <FloatingLabel className="mb-4 pb-1 position-relative" controlId="nric_no" label="NRIC">
                                            <Form.Control
                                                type="text"
                                                name="nric no"
                                                placeholder="John"
                                                value={
                                                    loading ? "Loading..." : userData?.user_document?.nric_no
                                                        ? userData?.user_document?.nric_no : ""
                                                }
                                                onChange={handleInputChange}
                                            />
                                            {errorMessage && errorMessage.error === 'invalid nric no' && (
                                                <div className="invalid-feedback d-block">
                                                    {errorMessage.message}
                                                </div>
                                            )}
                                        </FloatingLabel>
                                        <Col as="div" className="fs22 semibold textGreen position-absolute end-0 top-0 mt-4 me-5 pe-2">
                                            <HiOutlineCheckCircle size="22" color="var(--bs-greenDark)" className="align-text-top" /> Verified
                                        </Col>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <FloatingLabel
                                            className="mb-4 pb-1 position-relative"
                                            controlId="account_type"
                                            label="Account Type"
                                        >
                                            <Form.Select
                                                className="semibold"
                                                aria-label="Floating label select example"
                                                value={
                                                    loading ? "Loading..." : userData?.user_host_type
                                                        ? userData?.user_host_type : "not selected"
                                                }
                                                onChange={handleUserProfile}
                                            >
                                                <option value="not selected">Please Select Account Type</option>
                                                <option value="business">Business</option>
                                                <option value="personal">Personal</option>

                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs="12" md="12" className=" position-relative">
                                        <FloatingLabel className="mb-4 pb-1 position-relative" controlId="current_address" label="Registered Address">
                                            <Form.Control
                                                type="text"
                                                placeholder="John"
                                                value={
                                                    loading ? "Loading..." : userData?.user_profile?.current_address
                                                        ? userData?.user_profile?.current_address : ""
                                                }
                                                onChange={handleUserProfile}
                                            />
                                        </FloatingLabel>
                                        <Col as="div" className="fs22 semibold textGreen position-absolute end-0 top-0 mt-4 me-5 pe-2">
                                            <HiOutlineCheckCircle size="22" color="var(--bs-greenDark)" className="align-text-top" /> Verified
                                        </Col>
                                    </Col>
                                    <Col lg="12" className="py-5">
                                        <h5 className="fs24 semibold GrayComn mb-4 mt-3">Documents</h5>

                                        <ListGroup horizontal>
                                            <ListGroup.Item className="border-0 p-0 text-center me-4">
                                                <img src={userData?.user_document?.nric_front ? process.env.REACT_APP_BACKEND_IMAGE_URL + userData?.user_document?.nric_front : "/assets/frame02_old.png"} className="img-fluid shadowMD p-2 documentImage" alt="navimg" />
                                                <p className="fs12 GrayComn mt-2 my-0 semibold">{userData?.user_document?.nric_front}</p>
                                                <a href={process.env.REACT_APP_BACKEND_IMAGE_URL + userData?.user_document?.nric_front} target="_blank"><FiEye className="me-1" role="button" size="18" color="var(--theme-color)" /></a>
                                                <a href={process.env.REACT_APP_BACKEND_IMAGE_URL + userData?.user_document?.nric_front} target="_blank" download><HiDownload role="button" size="18" color="var(--theme-color)" /></a>
                                            </ListGroup.Item>

                                            <ListGroup.Item className="border-0 p-0 text-center me-4">
                                                <img src={userData?.user_document?.nric_back ? process.env.REACT_APP_BACKEND_IMAGE_URL + userData?.user_document?.nric_back : "/assets/frame02_old.png"} className="img-fluid shadowMD p-2 documentImage" alt="navimg" />
                                                <p className="fs12 GrayComn mt-2 my-0 semibold">{userData?.user_document?.nric_back}</p>
                                                <a href={process.env.REACT_APP_BACKEND_IMAGE_URL + userData?.user_document?.nric_back} target="_blank"><FiEye className="me-1" role="button" size="18" color="var(--theme-color)" /></a>
                                                <a href={process.env.REACT_APP_BACKEND_IMAGE_URL + userData?.user_document?.nric_back} target="_blank" download><HiDownload role="button" size="18" color="var(--theme-color)" /></a>
                                            </ListGroup.Item>

                                            <ListGroup.Item className="border-0 p-0 text-center me-4">
                                                <img src={userData?.user_document?.acra_doc ? process.env.REACT_APP_BACKEND_IMAGE_URL + userData?.user_document?.acra_doc : "/assets/frame02_old.png"} className="img-fluid shadowMD p-2 documentImage" alt="navimg" />
                                                <p className="fs12 GrayComn mt-2 my-0 semibold">{userData?.user_document?.acra_doc}</p>
                                                <a href={process.env.REACT_APP_BACKEND_IMAGE_URL + userData?.user_document?.acra_doc} target="_blank"><FiEye className="me-1" role="button" size="18" color="var(--theme-color)" /></a>
                                                <a href={process.env.REACT_APP_BACKEND_IMAGE_URL + userData?.user_document?.acra_doc} target="_blank" download><HiDownload role="button" size="18" color="var(--theme-color)" /></a>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Col>


                                    <Col lg="12" className="my-5 pb-lg-4">
                                        <h5 className="fs24 semibold GrayComn mb-4">Change Password</h5>
                                        <FloatingLabel
                                            className="mb-4 pb-1 position-relative"
                                            controlId="form.ControlInput7"
                                            label="Current Password"
                                        >
                                            <Form.Control
                                                type="text"
                                                name="current_password"
                                                placeholder="**********"
                                                value={passwordData?.current_password}
                                                onChange={handlePasswordChange}
                                            />
                                            {errorMessage && errorMessage.error === 'current_password' && (
                                                <div className="invalid-feedback d-block">
                                                    {errorMessage.message}
                                                </div>
                                            )}

                                        </FloatingLabel>
                                        <FloatingLabel
                                            className="mb-4 pb-1 position-relative"
                                            controlId="form.ControlInput8"
                                            label="New Password"
                                        >
                                            <Form.Control
                                                name="password"
                                                type={passOneHide ? "password" : "text"}
                                                value={passwordData.password}
                                                onChange={handlePasswordChange}
                                                placeholder="**********"
                                            />
                                            <span className="showPass" onClick={() => { setPassOneHide(!passOneHide) }}>
                                                {passOneHide ? <AiFillEye size="23" color="var(--theme-color)" /> : <AiTwotoneEyeInvisible size="23" color="var(--bs-gray01)" />}
                                            </span>

                                            {errorMessage && errorMessage.error === 'password' && (
                                                <div className="invalid-feedback d-block">
                                                    {errorMessage.message}
                                                </div>
                                            )}
                                        </FloatingLabel>
                                        <FloatingLabel
                                            className="mb-4 pb-1 position-relative"
                                            controlId="form.ControlInput9"
                                            label="Re-enter Password"
                                        >
                                            <Form.Control
                                                name="confirm_password"
                                                type={passTwoHide ? "password" : "text"}
                                                value={passwordData.confirm_password}
                                                onChange={handlePasswordChange}
                                                placeholder="**********"
                                            />
                                            <span className="showPass" onClick={() => { setPassTwoHide(!passTwoHide) }}>
                                                {passTwoHide ? <AiFillEye size="23" color="var(--theme-color)" /> : <AiTwotoneEyeInvisible size="23" color="var(--bs-gray01)" />}
                                            </span>

                                            {errorMessage && errorMessage.error === 'confirm_password' && (
                                                <div className="invalid-feedback d-block">
                                                    {errorMessage.message}
                                                </div>
                                            )}
                                        </FloatingLabel>
                                        <Col as="div" className="text-center">
                                            <Button className="border-0 mt-3 mb-4 w-50" type="button" onClick={changePassword}><span className="regular">Update Password</span></Button>
                                        </Col>
                                    </Col>

                                    <Col xs="12" md="12" xl="6">
                                        <h5 className="fs24 semibold GrayComn mt-3">
                                            Account Security
                                        </h5>
                                        {
                                            securityQuestions.map((qna, id) => (
                                                <div key={id}>
                                                    <FloatingLabel
                                                        className="my-4 pb-1 position-relative"
                                                        controlId={'question' + (id + 1)}
                                                        label="Choose Security Questions"
                                                    >
                                                        <Form.Select
                                                            name="question_id"
                                                            className="semibold"
                                                            aria-label="Floating label select example"
                                                            value={qna.question_id}
                                                            onChange={(e) => handleSecurityQuestions(e, id)}
                                                        // disabled
                                                        >
                                                            {
                                                                SecurityQuetions && SecurityQuetions?.map((data, id) => (
                                                                    <option key={id} value={data.id}>{data.question || ''}</option>
                                                                ))
                                                            }
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                    <FloatingLabel
                                                        className="pb-1 position-relative"
                                                        controlId={"answer" + (id + 1)}
                                                        label="Enter Your Answer"
                                                    >
                                                        <Form.Control
                                                            name="answer"
                                                            type="text"
                                                            placeholder="Enter Your Answer"
                                                            value={qna.answer || ''}
                                                            onChange={(e) => handleSecurityQuestions(e, id)}
                                                        // disabled
                                                        />
                                                    </FloatingLabel>
                                                    {securityQuestions.length > 1 ? (

                                                        <Col as="div" role="button" onClick={() => handleRemoveQna(id)}>
                                                            <Col as="div" className="fs22 semibold themeColor text-end pt-1">
                                                                <TbTrash size="18" color="var(--theme-color)" className="align-top mt-1" /> Remove
                                                            </Col>
                                                        </Col>)
                                                        : (""
                                                        )}
                                                </div>
                                            ))
                                        }

                                        {securityQuestions.length < 3 ? <Col
                                            as="div"
                                            role="button"
                                            onClick={AddSecurityQuestion}
                                        >
                                            <Col as="div" className="mt-4 mb-4 fs24 semibold themeColor">
                                                <TbCirclePlus size="24" color="var(--theme-color)" className="align-text-top" /> Add More
                                            </Col>
                                        </Col> : null}

                                    </Col>

                                    <Col xs="12" md="12" xl="6">
                                        <h5 className="fs24 semibold GrayComn mb-4 mt-3">
                                            <span className="fs12 semibold d-block">
                                                Set Account 2FA Method
                                            </span>
                                        </h5>
                                        <Col as="section" className={`signpUser row text-center pt-1`}>
                                            <Col sm="12" md="6" className="dineUser" onClick={() => setUserData({ ...userData, auth_method: "email" })}>
                                                <div className={`iconleft cursor-pointer ${userData?.auth_method === "email" ? "bgtheme" : null}`}>
                                                    <svg width="82" height="64" viewBox="0 0 82 64" fill="none" >
                                                        <path
                                                            d="M2.83205 13.4189C2.14276 12.9594 1.21145 13.1457 0.751925 13.8349C0.292396 14.5242 0.478657 15.4555 1.16795 15.9151L2.83205 13.4189ZM36.1926 37.4621L37.0247 36.214L37.0247 36.214L36.1926 37.4621ZM45.8074 37.4621L44.9753 36.214L44.9753 36.214L45.8074 37.4621ZM80.8321 15.9151C81.5213 15.4555 81.7076 14.5242 81.2481 13.8349C80.7886 13.1457 79.8572 12.9594 79.168 13.4189L80.8321 15.9151ZM1.16795 15.9151L35.3606 38.7101L37.0247 36.214L2.83205 13.4189L1.16795 15.9151ZM35.3605 38.7101C38.7755 40.9869 43.2245 40.9869 46.6395 38.7101L44.9753 36.214C42.568 37.819 39.432 37.819 37.0247 36.214L35.3605 38.7101ZM46.6395 38.7101L80.8321 15.9151L79.168 13.4189L44.9753 36.214L46.6395 38.7101ZM10.6667 63.8337H71.3333V60.8337H10.6667V63.8337ZM71.3333 63.8337C76.9484 63.8337 81.5 59.282 81.5 53.667H78.5C78.5 57.6252 75.2915 60.8337 71.3333 60.8337V63.8337ZM81.5 53.667V10.3337H78.5V53.667H81.5ZM81.5 10.3337C81.5 4.71878 76.9484 0.166992 71.3333 0.166992V3.16699C75.2915 3.16699 78.5 6.3756 78.5 10.3337H81.5ZM71.3333 0.166992H10.6667V3.16699H71.3333V0.166992ZM10.6667 0.166992C5.05177 0.166992 0.5 4.71876 0.5 10.3337H3.5C3.5 6.37562 6.70862 3.16699 10.6667 3.16699V0.166992ZM0.5 10.3337V53.667H3.5V10.3337H0.5ZM0.5 53.667C0.5 59.282 5.05178 63.8337 10.6667 63.8337V60.8337C6.70861 60.8337 3.5 57.6252 3.5 53.667H0.5Z"
                                                            fill="var(--bs-grayDark)"
                                                        />
                                                    </svg>
                                                    <h4 className="mt-4 pb-5 lh-base GrayComn">Email</h4>
                                                </div>
                                            </Col>
                                            <Col xs="12" md="6" className="hostUser" onClick={() => setUserData({ ...userData, auth_method: "mobile" })}>
                                                <div className={`iconright cursor-pointer ${userData?.auth_method === "mobile" ? "bgtheme" : null}`}>
                                                    <svg width="52" height="89" viewBox="0 0 52 89" fill="none" >
                                                        <path className="changecolor" d="M44.3965 0H7.6049C3.66131 0 0.451172 3.21014 0.451172 7.15373V81.2478C0.451172 85.1914 3.66131 88.4016 7.6049 88.4016H44.3965C48.3401 88.4016 51.5502 85.1914 51.5502 81.2478V7.15373C51.5502 3.21014 48.3401 0 44.3965 0ZM7.6049 4.08759H44.3965C46.0867 4.08759 47.4626 5.46346 47.4626 7.15373V12.1525H4.53912V7.15373C4.53912 5.46349 5.91499 4.08759 7.60527 4.08759H7.6049ZM47.4624 16.2401V65.9179H4.53894V16.2401H47.4624ZM44.3963 84.3136H7.60472C5.91448 84.3136 4.53857 82.9378 4.53857 81.2475V70.006H47.4621V81.2482C47.4628 82.9377 46.0869 84.3136 44.3966 84.3136H44.3963Z"
                                                            fill="var(--bs-grayDark)"
                                                        />
                                                    </svg>
                                                    <h4 className="mt-4 pb-5 lh-base GrayComn">Mobile</h4>
                                                </div>
                                            </Col>
                                        </Col>
                                        {/* checkbox */}
                                        <Col as="section" className="row profilecheckbox mt-2">
                                            <Col className="pt-1">
                                                <div className="form-check position-relative">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="flexRadioDefault"
                                                        id="auth_login"
                                                        checked={userData?.auth_login}
                                                        onChange={handleInputChange}
                                                    />
                                                    <label
                                                        className="form-check-label text-truncate overflow-hidden w-100"
                                                        htmlFor="auth_login"
                                                    >
                                                        <span>Authenticate when logging in.</span>
                                                    </label>
                                                </div>
                                                <div className="form-check position-relative">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="flexRadioDefault"
                                                        id="auth_order"
                                                        checked={userData?.auth_order}
                                                        onChange={handleInputChange}
                                                    />
                                                    <label
                                                        className="form-check-label text-truncate overflow-hidden w-100"
                                                        htmlFor="auth_order"
                                                    >
                                                        <span>Authenticate when placing an order.</span>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-check position-relative">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="flexRadioDefault"
                                                        id="auth_account_changes"
                                                        checked={userData?.auth_account_changes}
                                                        onChange={handleInputChange}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="auth_account_changes"
                                                    >
                                                        <span className="lh-sm d-inline-block">
                                                            Authenticate when making any account related
                                                            changes
                                                        </span>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Col>
                                    </Col>

                                    <Col lg="12" className="py-5">
                                        <h5 className="fs24 semibold GrayComn mb-4 mt-3">Business Certifications</h5>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className="border-0 p-0 text-center me-4">
                                                <img src={userData?.user_document?.busniess_certificate ? process.env.REACT_APP_BACKEND_IMAGE_URL + userData?.user_document?.busniess_certificate : "/assets/frame02_old.png"} className="img-fluid shadowMD p-2 certiBadge" alt="navimg" />
                                                <ListGroup horizontal className="align-items-center mt-2">
                                                    <ListGroup.Item className="border-0 p-0"><img src="/assets/certicateBadge.png" alt="navimg" /></ListGroup.Item>
                                                    <ListGroup.Item className="border-0 p-0 fs10 semibold GrayDark mx-2">Certificatename.png</ListGroup.Item>
                                                    <ListGroup.Item className="border-0 p-0">
                                                        <a href={process.env.REACT_APP_BACKEND_IMAGE_URL + userData?.user_document?.busniess_certificate} target="_blank"><FiEye className="me-1" role="button" size="14" color="var(--theme-color)" /></a>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className="border-0 p-0">
                                                        <a href={process.env.REACT_APP_BACKEND_IMAGE_URL + userData?.user_document?.busniess_certificate} target="_blank" download><HiDownload role="button" size="14" color="var(--theme-color)" /></a>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Col>

                                    <Col as="section" md="12" className="mt-4">
                                        <h5 className="fs24 semibold GrayComn pb-2">
                                            Emergency Contact
                                            <span className="fs12 semibold d-block pt-1">
                                                Please provide your emergency contact details.
                                            </span>
                                        </h5>
                                        <Col className="mt-2 pt-2 accForm">
                                            <span className="fs12 semibold d-block mb-4">
                                                Contact 1 :
                                            </span>
                                            {formValues?.map((element, index) => (
                                                <div
                                                    key={index}
                                                    className="form-inline position-relative"
                                                >
                                                    <Row className="g_flutter gx-4">
                                                        <Col xs="12" md="6">
                                                            <FloatingLabel
                                                                className="mb-4 pb-1 position-relative"
                                                                controlId="firstName"
                                                                label="First Name"
                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    name="first_name"
                                                                    placeholder="Michael"
                                                                    value={element.first_name || ""}
                                                                    onChange={(e) => handleChange(index, e)}
                                                                />
                                                                {errorMessage && errorMessage.error === `invalid firstName${index}` && (
                                                                    <div className="invalid-feedback d-block">
                                                                        {errorMessage.message}
                                                                    </div>
                                                                )}
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col xs="12" md="6">
                                                            <FloatingLabel
                                                                className="mb-4 pb-1 position-relative"
                                                                controlId="lastName"
                                                                label="Last Name"
                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    name="last_name"
                                                                    placeholder="Doe"
                                                                    value={element.last_name || ""}
                                                                    onChange={(e) => handleChange(index, e)}
                                                                />
                                                                {errorMessage && errorMessage.error === `invalid lastName${index}` && (
                                                                    <div className="invalid-feedback d-block">
                                                                        {errorMessage.message}
                                                                    </div>
                                                                )}
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col xs="12" md="6">
                                                            <FloatingLabel
                                                                className="mb-4 pb-1 position-relative"
                                                                controlId="relationShip"
                                                                label="Relationship"
                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    name="relationship"
                                                                    placeholder="Relationship"
                                                                    value={element.relationship || ""}
                                                                    onChange={(e) => handleChange(index, e)}
                                                                />
                                                                {errorMessage && errorMessage.error === `invalid relationship${index}` && (
                                                                    <div className="invalid-feedback d-block">
                                                                        {errorMessage.message}
                                                                    </div>
                                                                )}
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col xs="12" md="6">
                                                            <FloatingLabel
                                                                className="mb-4 pb-1 position-relative"
                                                                controlId="phoneNumber"
                                                                label="Mobile Number"
                                                            >
                                                                <Form.Control
                                                                    name="phone_number"
                                                                    placeholder="Doe"
                                                                    value={element.phone_number || ""}
                                                                    onChange={(e) => handleChange(index, e)}
                                                                />
                                                                {errorMessage && errorMessage.error === `invalid phoneNumber${index}` && (
                                                                    <div className="invalid-feedback d-block">
                                                                        {errorMessage.message}
                                                                    </div>
                                                                )}
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    {index ? (
                                                        <AiFillCloseCircle
                                                            size="35"
                                                            color="var(--theme-color)"
                                                            className="removeField position-absolute"
                                                            onClick={() => removeFormFields(index)}
                                                        />
                                                    ) : null}
                                                </div>
                                            ))}
                                            <Col
                                                as="section"
                                                md="12"
                                                className="fs22 themeColor bold"
                                            >
                                                <Col
                                                    as="div"
                                                    role="button"
                                                    onClick={() => addFormFields()}
                                                >
                                                    <TbCirclePlus
                                                        size="24"
                                                        color="var(--theme-color)"
                                                        className="align-text-top"
                                                    />{" "}
                                                    Add Emergency Contact
                                                </Col>
                                            </Col>
                                            <br />
                                        </Col>
                                        <Button
                                            className="button button col-md-5 m-auto d-block mt-5 mb-3"
                                            type="submit"
                                            disabled={isLoading}
                                        >
                                            {isLoading ? "Loading..." : "Save"}
                                        </Button>
                                    </Col>

                                </Row>
                            </Form>
                        </Col>
                        <Col as="section" className="shadowMD border08 shadowSMacc mt-5 ms-md-4 me-md-5 text-center py-5">
                            <h5 className="fs24 semibold GrayComn mb-4 pb-3">
                                Account Management
                                <span className="fs12 semibold d-block pt-2">
                                    Decide what you would like to do with your Cribbly Account.
                                </span>
                            </h5>
                            <Button
                                className={`button rounded-pill fs20 susAcc border-0 px-4 me-2 ${accountStatus === "suspend" ? '' : 'bgLight GrayComn'}`}
                                variant="danger"
                                type="button"
                                onClick={handleSuspend}
                            >
                                Suspend Account
                            </Button>
                            <Button
                                className={`button rounded-pill fs20 cloAcc border-0 px-4 ${accountStatus === "close" ? '' : 'bgLight GrayComn'}`}
                                variant="danger"
                                type="button"
                                onClick={handleClose}
                            >
                                Close Account
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </Container>
            {onShow == true && popupMessage.status === "success" ? (
                <AlertPopup
                    show={onShow}
                    onHide={() => setOnShow(false)}
                    icon={<HiCheckCircle size="120" className='mt-5 mb-3' color="var(--bs-white)" />}
                    title="Success!"
                    description={popupMessage.message}
                />
            ) : onShow == true && popupMessage.status === "account-management" ?
                <AlertPopup
                    show={onShow}
                    onHide={() => setOnShow(false)}
                    icon={<HiCheckCircle size="120" className='mt-5 mb-3' color="var(--bs-white)" />}
                    title="Account Management"
                    description={popupMessage.message}
                    button="Confirm"
                    onButton={popupMessage?.button}
                    cancelButton="Cancel"
                    onCancelButton={() => setOnShow(false)}
                /> : onShow == true && popupMessage.status === "error" ?
                    <AlertPopup
                        show={onShow}
                        onHide={() => setOnShow(false)}
                        icon={<svg width="94" height="86" viewBox="0 0 94 86" fill="none" className='mt-5 mb-3' xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M36.3676 6.90288C41.0317 -1.38885 52.9702 -1.38885 57.6343 6.90288L91.6741 67.4184C96.2485 75.5509 90.3717 85.5995 81.0406 85.5995H12.9609C3.63005 85.5995 -2.24687 75.5509 2.3277 67.4184L36.3676 6.90288ZM53.1001 67.3001C53.1001 70.6691 50.3692 73.4001 47.0001 73.4001C43.6314 73.4001 40.9003 70.6691 40.9003 67.3001C40.9003 63.931 43.6314 61.2001 47.0001 61.2001C50.3692 61.2001 53.1001 63.931 53.1001 67.3001ZM47.0001 18.5001C43.6314 18.5001 40.9003 21.2312 40.9003 24.6001V42.9001C40.9003 46.269 43.6314 49.0001 47.0001 49.0001C50.3692 49.0001 53.1001 46.269 53.1001 42.9001V24.6001C53.1001 21.2312 50.3692 18.5001 47.0001 18.5001Z" fill="white" />
                        </svg>}
                        title="Error!"
                        description={popupMessage.message}
                    /> : null}
            <br />
            <br />
            <Footer />
        </div>
    );
};
export default HostMyProfile;
