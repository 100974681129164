import { useMutation } from '@apollo/client';
import React, { useState } from 'react'
import { Container, Row, Col, Image, Card, Form, Button, Modal } from 'react-bootstrap';
import { BsCircleFill, BsClock } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { login_OTPVerification } from '../../../queries/queries';

const MyAccount03 = (params) => {
    const OTPNumber = login_OTPVerification();
    const [OTPVerification] = useMutation(OTPNumber);


    const [show, setShow] = useState(false);
    const [show01, setShow01] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [otpone, setOtpOne] = useState('');
    const [otptwo, setOtpTwo] = useState('');
    const [otpthree, setOtpThree] = useState('');
    const [otpfour, setOtpFour] = useState('');
    const [otpfive, setOtpFive] = useState('');
    const [otpsix, setOtpSix] = useState('');
    
    const [isLoading, setLoading] = useState(false);
    const handleClose = () => setShow(false);
    const handleClose01 = () => setShow01(false);




    const inputfocus = (elmnt) => {
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        } else if (elmnt.key === "Tab") {
            const next = elmnt.target.tabIndex - 1;
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        } else {
            const next = elmnt.target.tabIndex;
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        }
    }

    const onResend = () => {
        setErrorMessage({
            error: 'resend otp',
            message: 'Resend OTP on your email.'
        })
        setOtpOne('')
        setOtpTwo('')
        setOtpThree('')
        setOtpFour('')
        setOtpFive('')
        setOtpSix('')
    }

    const onAutoSubmit = async () => {
        setLoading(true);
        const Otp = otpone + otptwo + otpthree + otpfour + otpfive + otpsix;
        let OtpVerificationMessage = await OTPVerification({
            variables:{
                otp: Number(Otp),
                text:params.email
            }
        })
        setLoading(false);
        if(OtpVerificationMessage?.data) {
            if(OtpVerificationMessage?.data?.login_OTPVerification?.status === "success") {
                params.setStepNo(4);
            } else if(OtpVerificationMessage?.data?.login_OTPVerification?.status === "error") {
                setErrorMessage({
                    error: OtpVerificationMessage?.data?.login_OTPVerification?.status,
                    message: OtpVerificationMessage?.data?.login_OTPVerification?.message
                })
            }
        } else {
                setErrorMessage({
                    error: "error",
                    message: "something went wrong"
                })
            }
            console.log("OtpVerificationMessage", OtpVerificationMessage)
        // if (Otp == "123456") {
        //     setTimeout(() => {
        //         params.setStepNo(4);
        //     }, 1000)

        // }
        // else {
        //     setErrorMessage({
        //         error: 'invalid otp',
        //         message: 'Please Enter OTP'
        //     })
        // }
    };

    return (
        <>
            <Row className="ps-0 pinkgradiant pb-3">
                <Col lg="12" className="text-center mb-5"><Image src={process.env.PUBLIC_URL + "/assets/sadIcon.png"} alt="mainbanner" className="img-fluid" /></Col>
                <Col lg="12" className="text-center">
                    <h2 className="fs60 text-white semibold mb-5">We’re Sad To See You Go!</h2>
                    <Card style={{ maxWidth: '995px', paddingLeft: '2.5rem', paddingRight: '2.5rem' }} className="d-inline-block py-3 mb-5 border08 w-100" body>
                        <Card.Text className="fs52 GrayComn semibold text-start m-0">Please tell us why you are leaving.</Card.Text>
                        <Card.Text className="GrayComn semibold text-start lh-1">Please key in the OTP sent to your mobile +65 <BsCircleFill size="9" color="var(--bs-gray55)" className="me-1" />
                            <BsCircleFill size="9" color="var(--bs-gray55)" className="me-1" />
                            <BsCircleFill size="9" color="var(--bs-gray55)" className="me-1" />
                            <BsCircleFill size="9" color="var(--bs-gray55)" /> 4567.</Card.Text>
                        <Form className="mt-5">
                            <Form.Group controlId="formBasicName" className="mb-0 row otpScreem">
                                <Col xs={4} md={2}><input className="otpFsize mb-3" type="text" id="input1" placeholder="."
                                    value={otpone}
                                    onChange={(e) => { setOtpOne(e.target.value) }}
                                    maxLength="1" tabIndex="1" onKeyUp={e => inputfocus(e)}
                                /></Col>
                                <Col xs={4} md={2}><input className="otpFsize mb-3" type="text" id="input1" placeholder="."
                                    value={otptwo}
                                    onChange={(e) => { setOtpTwo(e.target.value) }}
                                    maxLength="1" tabIndex="2" onKeyUp={e => inputfocus(e)}
                                /></Col>
                                <Col xs={4} md={2}><input className="otpFsize mb-3" type="text" id="input1" placeholder="."
                                    value={otpthree}
                                    onChange={(e) => { setOtpThree(e.target.value) }}
                                    maxLength="1" tabIndex="3" onKeyUp={e => inputfocus(e)}
                                /></Col>
                                <Col xs={4} md={2}><input className="otpFsize mb-3" type="text" id="input1" placeholder="."
                                    value={otpfour}
                                    onChange={(e) => { setOtpFour(e.target.value) }}
                                    maxLength="1" tabIndex="4" onKeyUp={e => inputfocus(e)}
                                /></Col>
                                <Col xs={4} md={2}><input className="otpFsize mb-3" type="text" id="input1" placeholder="."
                                    value={otpfive}
                                    onChange={(e) => { setOtpFive(e.target.value) }}
                                    maxLength="1" tabIndex="5" onKeyUp={e => inputfocus(e)}
                                /></Col>
                                <Col xs={4} md={2}><input className="otpFsize mb-3" type="text" id="input1" placeholder="."
                                    value={otpsix}
                                    onChange={(e) => { setOtpSix(e.target.value)
                                    }}
                                    maxLength="1" tabIndex="6" onKeyUp={e => inputfocus(e)}
                                /></Col>
                                {errorMessage && (errorMessage.error === 'error' || errorMessage.error === 'resend otp') && (
                                <div className="invalid-feedback d-block">
                                    {errorMessage.message}
                                </div>
                            )}
                            </Form.Group>
                            <Form.Group className="pb-5" controlId="exampleForm.ControlTextarea1">
                                <Form.Label className='float-start fs20 text-black semibold'>
                                    <Link to="#" className="forgotPass text-decoration-none pe-1" onClick={onResend}>Resend</Link>
                                    or
                                    <Link to="#" className="forgotPass text-decoration-none ps-1">Change Number</Link>
                                </Form.Label>
                                <Form.Label className='float-end'><BsClock size="20"/> <b>00.25</b></Form.Label>
                            </Form.Group>
                            <Form.Group className="mt-5 mb-2">
                                <Button className="w-50 py-2 lh-lg border-0 rounded-pill" variant="primary" onClick={(e) => {onAutoSubmit(e.target.value)}}>
                                    {isLoading ? "Loading..." : "Suspend"}    
                                </Button>
                            </Form.Group>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default MyAccount03