import React from 'react'
import { Container, Row, Col, Breadcrumb, ListGroup } from 'react-bootstrap';
import { BsCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import "react-pro-sidebar/dist/css/styles.css";
import "./careers.css";
// import Header from "../components/header";
import BeforeLoginHeader from '../components/beforeLoginHeader';
import Footer from "../components/footer";

const PrivacyPolicy = () => {    
    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>                
                <Row>
                    <Col className="px-0">
                        <BeforeLoginHeader />
                    </Col>
                </Row>                
            </Container>
            <br /><br />
            <Col as="div">
                <Col md={11} className="m-auto">
                    <Row className="mx-md-4 hypart">
                        <Col>
                            <h1 className="mb-3 fs52 GrayComn">Privacy Policy</h1>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/">Legal</Breadcrumb.Item>
                                <Breadcrumb.Item>Privacy Policy</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col lg="12" className="pt-md-5 pe-md-5 contactus">
                            <h2 className="mb-md-5 fs32 GrayComn semibold">
                            Amet minim mollit non <span className="themeColor">Deserunt Ullamco Non</span> sit aliqua dolor do amet sintduis enim Exercitation 
                            veniam con sunt nostrud amet.
                            </h2>
                            <ListGroup as="ul" className="tearmList border-0 p-0">
                                <ListGroup.Item as="li" className="border-0 p-0"><BsCircleFill size="12" color="var(--bs-grayDark)" /> <span>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim  Exercitation veniam consequat sunt nostrud amet veniam consequat sunt consequat sunt nostrud amet nostrud amet.</span></ListGroup.Item>
                                <ListGroup.Item as="li" className="border-0 p-0"><BsCircleFill size="12" color="var(--bs-grayDark)" /> <span>Mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam consequat.</span></ListGroup.Item>
                                <ListGroup.Item as="li" className="border-0 p-0"><BsCircleFill size="12" color="var(--bs-grayDark)" /> <span>Deserunt amet minim mollit no ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam consequat sunt est nostrud amet veniam consequat sunt nostrud amet. Mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam consequat sunt nostrud amet veniam consequat sunt nostrud amet.</span></ListGroup.Item>
                                <ListGroup.Item as="li" className="border-0 p-0"><BsCircleFill size="12" color="var(--bs-grayDark)" /> <span>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim  Exercitation veniam consequat sunt nostrud amet veniam consequat sunt consequat sunt nostrud amet nostrud amet.</span></ListGroup.Item>
                                <ListGroup.Item as="li" className="border-0 p-0"><BsCircleFill size="12" color="var(--bs-grayDark)" /> <span>Serunt amet minim mollit no ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam consequat sunt nostrud amet veniam consequat sunt nostrud
                                amet. Mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim Exercitation.</span></ListGroup.Item>
                            </ListGroup>


                            <h2 className="mt-md-5 mb-md-5 fs32 GrayComn semibold">
                                <span className="themeColor">Amet Minim</span> mollit non deserunt ullamco est sit aliqua dolor do amet minim mollit non deserunt sintduis.
                            </h2>
                            <ListGroup as="ul" className="tearmList border-0 p-0">
                                <ListGroup.Item as="li" className="border-0 p-0"><BsCircleFill size="12" color="var(--bs-grayDark)" /> <span>Mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam consequat.</span></ListGroup.Item>
                                <ListGroup.Item as="li" className="border-0 p-0"><BsCircleFill size="12" color="var(--bs-grayDark)" /> <span>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim  Exercitation veniam consequat sunt nostrud amet veniam consequat sunt consequat sunt nostrud amet nostrud amet.</span></ListGroup.Item>
                                <ListGroup.Item as="li" className="border-0 p-0"><BsCircleFill size="12" color="var(--bs-grayDark)" /> <span>Deserunt amet minim mollit no ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam consequat sunt est nostrud amet veniam consequat sunt nostrud amet. Mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam consequat sunt nostrud amet veniam consequat sunt nostrud amet.</span></ListGroup.Item>
                                <ListGroup.Item as="li" className="border-0 p-0"><BsCircleFill size="12" color="var(--bs-grayDark)" /> <span>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim  Exercitation veniam consequat sunt nostrud amet veniam consequat sunt consequat sunt nostrud amet nostrud amet.</span></ListGroup.Item>
                            </ListGroup>


                            <h2 className="mt-md-5 mb-md-5 fs32 GrayComn semibold">
                                Deserunt amet minim mollit no ullamco est sit aliqua dolor do amet sintduis enim sunt nostrud amet veniam <span className="themeColor">Exercitation veniam</span> consequat sunt nostrud amet veniam.
                            </h2>
                            <h2 className="mt-md-5 mb-md-5 fs32 GrayComn semibold">
                                Mollit non deserunt ullamco est sit aliqua veniam <Link to="/" className="themeColor text-decoration-underline">privacypolicy456@cribbly.com</Link>
                            </h2>
                            <h2 className="mt-md-5 mb-md-5 fs32 GrayComn semibold">
                            Exercitation deserunt amet minim mollit no ullamco est sit aliqua dolor do amet sunt nostrud amet veniam sintduis enim veniam consequat sunt nostrud amet veniam.
                            </h2>
                            <h2 className="mt-md-5 mb-md-5 fs32 GrayComn semibold">
                            Thank you for being a number of the Cribbly Food Ordering System Discussion.
                            </h2>
                        </Col>
                    </Row>
                </Col>
            </Col>
            <br /><br />
            <Footer />
        </div>
    )    
}
export default PrivacyPolicy