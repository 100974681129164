import React from "react";
import { Row, Col, Form, Button, FloatingLabel } from "react-bootstrap";
import CustomScroll from "react-customscroll";
import { FiMapPin } from "react-icons/fi";
import { addClass } from "../../../commanFunctions";

const HostBusiness13 = (params) => {
  const onNextpage = (event) => {
    // event.preventDefault();
    addClass();
    setTimeout(() => {
      params.setStepNo(14);
    }, 1400);
  };

  const goBack = () => {
    addClass();
    setTimeout(() => {
      params.setStepNo(12);
    }, 1400);
  };

  const nextOutlet = () => {
    addClass();
    setTimeout(() => {
      params.setStepNo(11);
    }, 1400);
  };
  return (
    <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
      <div className="front">
        <div className="heading">
          <h2 className="small lh-1 fs-normal mb-2">Become A Host</h2>
          <p className="semibold m-0">Please tell us where your Crib Outlet will be.</p>
        </div>
        <Col as="section" className="stepsBar2 pt-4">
          <Col as="ul" className="list-unstyled">
            <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(100%/1.12)" }}>13</span></Col>
          </Col>
        </Col>
        <Col as="section" className="signpUser pt-0">
          <Col as="div" className="px-0 pb-1 semibold ms-2">{params.outletData.length === 2? "2nd" : params.outletData.length === 3 ? "3rd" : `${params.outletData.length}th`} Outlet</Col>
          <Form className="accForm">
            <Row>
              <Col className="overflowscroll">

                <CustomScroll
                  autoHide
                  autoHideTimeout={1000}
                  autoHideDuration={200}
                  autoHeight
                  autoHeightMin={0}
                  autoHeightMax={200}
                  thumbMinSize={30}
                  universal={true}
                >
                  {params.outletData?.map((item, index) => (
                    <FloatingLabel controlId="floatingInput" label="Address" className="mt-1 mb-3 mx-2">
                      <Form.Control
                        type="text"
                        placeholder="Address"
                        value={item.address}
                        disabled
                      />
                      <FiMapPin className="calendarIcon position-absolute top-0 end-0 mt-4 me-3" size="20" color="var(--theme-color)" />
                    </FloatingLabel>
                  ))}
                </CustomScroll>

              </Col>
            </Row>

            <Row>
              <Col><img src={process.env.REACT_APP_PUBLIC_URL + "/mapOutlet.png"} className="img-fluid" alt="mapImage" /></Col>
            </Row>
            <Row>
              <Button
                variant="link"
                className="fs13 themeColor text-decoration-none signup-link text-start mb-3"
                onClick={nextOutlet}
                type="button"
              >
                Add Another Outlet?
              </Button>
            </Row>
            <Row>
              <Col className="align-self-center">
                <Button
                  className="fs26 themeColor semibold text-decoration-none lh-1 p-0"
                  variant="link"
                  type="button"
                  onClick={goBack}
                >
                  <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  Previous
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100 py-2 lh-lg border-0 hvr-float-shadow  border08"
                  variant="primary"
                  type="button"
                  onClick={onNextpage}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
    </section>
  )
}

export default HostBusiness13