import React from 'react'
import { Container, Row, Col, Breadcrumb, Card } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
// import "../dinerstyle.css"
import { Link } from 'react-router-dom';
import Footer from "../../components/footer";
import ProfileMenu from "../../components/profilemenu";
import AfterLoginHeader from '../../components/afterLoginHeader';
import { useNavigate } from 'react-router';

const HelpCenter = () => {
    const navigate = useNavigate();


    const handleHelpQue = () => {
        navigate("/helpcenter02");
    }
    
      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <AfterLoginHeader />
                    </Col>
                </Row>
                {/* <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col> */}
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <ProfileMenu />
                    </Col>
                    <Col lg="9" as="div" className="userAcc membership pt-5 pe-3">
                        <h2 className="fs52 semibold ms-md-4 mb-0">Help Centre</h2>
                        <Breadcrumb className="ms-md-4 mb-5">
                            <Breadcrumb.Item href="/">Support</Breadcrumb.Item>
                            <Breadcrumb.Item>Help Centre</Breadcrumb.Item>
                        </Breadcrumb>
                        <Col as="section">
                            <Col className="outerspace">
                                <Col as="section" className="innerspace me-md-5">
                                    <Row className="ms-md-4 me-md-5">
                                        <Col className="faq_wrapper p-lg-0">
                                            <Row>
                                                <Col md="6" xl="6" xxl="4" className="mb-4 pb-1" onClick={handleHelpQue}>
                                                    <Card className="helpesBox border-0 shadowMD border08">
                                                        <Card.Body role="button" className="py-5">
                                                            <Card.Text className="semibold GrayComn lh-sm text-center px-0 py-5">
                                                                <img src={process.env.PUBLIC_URL+"assets/icon01_black.png"} className="img-fluid mt-2 imgblack" alt="paymentmethod" />                                                                
                                                                <img src={process.env.PUBLIC_URL+"assets/icon01_white.png"} className="img-fluid mt-2 imgwhite" alt="paymentmethod" />                                                                
                                                                <h2 className="mt-3 mb-3 fs28 semibold GrayDark">Issue with a User</h2>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md="6" xl="6" xxl="4" className="mb-4 pb-1"  onClick={handleHelpQue}>
                                                    <Card className="helpesBox border-0 shadowMD border08">
                                                        <Card.Body role="button" className="py-5">
                                                            <Card.Text className="semibold GrayComn lh-sm text-center px-0 py-5">
                                                                <img src={process.env.PUBLIC_URL+"assets/icon02_black.png"} className="img-fluid mt-2 imgblack" alt="paymentmethod" />                                                                
                                                                <img src={process.env.PUBLIC_URL+"assets/icon02_white.png"} className="img-fluid mt-2 imgwhite" alt="paymentmethod" />                                                                
                                                                <h2 className="mt-3 mb-3 fs28 semibold GrayDark">Issue with My Account</h2>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md="6" xl="6" xxl="4" className="mb-4 pb-1"  onClick={handleHelpQue}>
                                                    <Card className="helpesBox border-0 shadowMD border08">
                                                        <Card.Body role="button" className="py-5">
                                                            <Card.Text className="semibold GrayComn lh-sm text-center px-0 py-5">
                                                                <img src={process.env.PUBLIC_URL+"assets/icon03_white.png"} className="img-fluid mt-2 imgwhite" alt="paymentmethod" />                                                                
                                                                <img src={process.env.PUBLIC_URL+"assets/icon03_black.png"} className="img-fluid mt-2 imgblack" alt="paymentmethod" />                                                                
                                                                <h2 className="mt-3 mb-3 fs28 semibold GrayDark">Issue with a Listing</h2>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md="6" xl="6" xxl="4" className="mb-4 pb-1" onClick={handleHelpQue}>
                                                    <Card className="helpesBox border-0 shadowMD border08">
                                                        <Card.Body role="button" className="py-5">
                                                            <Card.Text className="semibold GrayComn lh-sm text-center px-0 py-5">
                                                                <img src={process.env.PUBLIC_URL+"assets/icon04_white.png"} className="img-fluid mt-2 imgwhite" alt="paymentmethod" />                                                                
                                                                <img src={process.env.PUBLIC_URL+"assets/icon04_black.png"} className="img-fluid mt-2 imgblack" alt="paymentmethod" />                                                                
                                                                <h2 className="mt-3 mb-3 fs28 semibold GrayDark">Issue with an Order</h2>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md="6" xl="6" xxl="4" className="mb-4 pb-1" onClick={handleHelpQue}>
                                                    <Card className="helpesBox border-0 shadowMD border08">
                                                        <Card.Body role="button" className="py-5">
                                                            <Card.Text className="semibold GrayComn lh-sm text-center px-0 py-5">
                                                                <img src={process.env.PUBLIC_URL+"assets/icon05_white.png"} className="img-fluid mt-2 imgwhite" alt="paymentmethod" />                                                                
                                                                <img src={process.env.PUBLIC_URL+"assets/icon05_black.png"} className="img-fluid mt-2 imgblack" alt="paymentmethod" />                                                                
                                                                <h2 className="mt-3 mb-3 fs28 semibold GrayDark">Issue with Billing</h2>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md="6" xl="6" xxl="4" className="mb-4 pb-1" onClick={handleHelpQue}>
                                                    <Card className="helpesBox border-0 shadowMD border08">
                                                        <Card.Body role="button" className="py-5">
                                                            <Card.Text className="semibold GrayComn lh-sm text-center px-0 py-5">
                                                                <img src={process.env.PUBLIC_URL+"assets/icon06_white.png"} className="img-fluid mt-2 imgwhite" alt="paymentmethod" />                                                                
                                                                <img src={process.env.PUBLIC_URL+"assets/icon06_black.png"} className="img-fluid mt-2 imgblack" alt="paymentmethod" />                                                                
                                                                <h2 className="mt-3 mb-3 fs28 semibold GrayDark">Issue with Promo Code</h2>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md="6" xl="6" xxl="4" className="mb-4 pb-1" onClick={handleHelpQue}>
                                                    <Card className="helpesBox border-0 shadowMD border08">
                                                        <Card.Body role="button" className="py-5">
                                                            <Card.Text className="semibold GrayComn lh-sm text-center px-0 py-5">
                                                                <img src={process.env.PUBLIC_URL+"assets/icon07_white.png"} className="img-fluid mt-2 imgwhite" alt="paymentmethod" />                                                                
                                                                <img src={process.env.PUBLIC_URL+"assets/icon07_black.png"} className="img-fluid mt-2 imgblack" alt="paymentmethod" />                                                                
                                                                <h2 className="mt-3 mb-3 fs28 semibold GrayDark">Host Account Registration</h2>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md="6" xl="6" xxl="4" className="mb-4 pb-1" onClick={handleHelpQue}>
                                                    <Card className="helpesBox border-0 shadowMD border08">
                                                        <Card.Body role="button" className="py-5">
                                                            <Card.Text className="semibold GrayComn lh-sm text-center px-0 py-5">
                                                                <img src={process.env.PUBLIC_URL+"assets/icon08_white.png"} className="img-fluid mt-2 imgwhite" alt="paymentmethod" />                                                                
                                                                <img src={process.env.PUBLIC_URL+"assets/icon08_black.png"} className="img-fluid mt-2 imgblack" alt="paymentmethod" />                                                                
                                                                <h2 className="mt-3 mb-3 fs28 semibold GrayDark">Report a Bug</h2>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md="6" xl="6" xxl="4" className="mb-4 pb-1" onClick={handleHelpQue}>
                                                    <Card className="helpesBox border-0 shadowMD border08">
                                                        <Card.Body role="button" className="py-5">
                                                            <Card.Text className="semibold GrayComn lh-sm text-center px-0 py-5">
                                                                <img src={process.env.PUBLIC_URL+"assets/icon09_white.png"} className="img-fluid mt-2 imgwhite" alt="paymentmethod" />                                                                
                                                                <img src={process.env.PUBLIC_URL+"assets/icon09_black.png"} className="img-fluid mt-2 imgblack" alt="paymentmethod" />                                                                
                                                                <h2 className="mt-3 mb-3 fs28 semibold GrayDark">Feedback</h2>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row className="mt-5">
                                                <Col className="text-center">
                                                    <Card className="border-0">
                                                        <Card.Body className="p-0">
                                                            <blockquote className="blockqoute mb-1">
                                                                <svg width="106" height="105" viewBox="0 0 106 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M96 59.809C96 53.222 91.8355 47.7177 86.0419 45.552V42.9355C86.0419 24.7984 71.1956 10 53 10C34.8043 10 19.9581 24.7984 19.9581 42.9355V45.552C14.1645 47.7177 10 53.3121 10 59.809C10 68.2004 16.8798 75.0587 25.299 75.0587H30.8211C32.2696 75.0587 33.446 73.8855 33.446 72.4422V47.1767C33.446 45.7328 32.2689 44.5602 30.8211 44.5602H28.2863V42.9363C28.2863 29.3111 39.4208 18.3024 52.9998 18.3024C66.5788 18.3024 77.7133 29.401 77.7133 42.9363V44.5602H75.1786C73.7301 44.5602 72.5537 45.7334 72.5537 47.1767V72.4422C72.5537 73.8861 73.7307 75.0587 75.1786 75.0587H76.3556C73.7307 81.1044 67.9371 85.3454 61.0566 85.8867C60.6948 84.1721 59.1553 82.8189 57.2546 82.8189H48.6548C46.482 82.8189 44.7625 84.5335 44.7625 86.6986V91.1203C44.7625 93.286 46.4827 95 48.6548 95H57.2546C59.1559 95 60.7851 93.6468 61.0566 91.7516C71.2862 91.2103 79.7954 84.2621 82.6923 74.8778C90.2059 73.8858 95.9995 67.4795 95.9995 59.8093L96 59.809Z" fill="#C32148"/>
                                                                </svg>
                                                            </blockquote>
                                                            <Card.Text className="fs22 semibold lh-sm GrayDark">
                                                                Cannot find what you are looking for? 
                                                                <br className="d-none d-lg-inline" /><Link to="/contactus" className="themeColor text-decoration-none">Contact</Link> our support team.
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>                                        
                                    </Row>
                                </Col>
                            </Col>
                        </Col>                        
                        <Col lg="12" className="p-lg-4">&nbsp;</Col>
                    </Col>
                </Row>        
            </Container>
            <br /><br />
            <Footer />
        </div>
    )    
}
export default HelpCenter