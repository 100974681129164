import React, { useState } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './dinesignup.css';
import DinerSignupOne from './dinersignup01';
import DinerSignupTwo from './dinersignup02';
import DinerSignupThree from './dinersignup03';
import DinerSignupFour from './dinersignup04';
import DinerSignupFive from './dinersignup05';
import DinerSignupSix from './dinersignup06';
import DinerSignupSeven from './dinersignup07';
import DinerSignupEight from './dinersignup08';
import DinerSignupNine from './dinersignup09';
import DinerSignupTen from './dinersignup10';
import DinerSignupEleven from './dinersignup11';
import AuthPageLayout from '../auth/authpagelayout';

const DinerSignup = () => {

  const [userRole,] = useState(3);
  const [stepNo, setStepNo] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastname] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [addUser, setAddUser] = useState({});
  const handleDate = (date) => {
    setStartDate(date);
    let dd = new Date(date).getDate();
    let mm = new Date(date).getMonth() + 1;
    let yyyy = new Date(date).getFullYear();
    setDateOfBirth(yyyy + "-" + mm + "-" + dd);
  };

  return (
    <div>

      <AuthPageLayout
        title="Enjoy a cozy hot meal at your neighbour's crib."
        description={<>Save on rent. Work from home. The best part? A Crib <br className="d-none d-lg-inline" />Cafe to call your own.</>}
      >
        {stepNo == 1 && (
          <DinerSignupOne setStepNo={setStepNo} firstName={firstName} setFirstName={setFirstName} setLastname={setLastname} lastName={lastName} />
        )}
        {stepNo == 2 && (
          <DinerSignupTwo setStepNo={setStepNo} startDate={startDate} setStartDate={setStartDate} handleDate={handleDate} setDateOfBirth={setDateOfBirth} dateOfBirth={dateOfBirth} gender={gender} setGender={setGender} />
        )}
        {stepNo == 3 && (
          <DinerSignupThree setStepNo={setStepNo} setEmail={setEmail} email={email} />
        )}
        {stepNo == 4 && (
          <DinerSignupFour setStepNo={setStepNo} email={email} />
        )}
        {stepNo == 5 && (
          <DinerSignupFive setStepNo={setStepNo} setPhoneNumber={setPhoneNumber} phoneNumber={phoneNumber} />
        )}
        {stepNo == 6 && (
          <DinerSignupSix setStepNo={setStepNo} phoneNumber={phoneNumber} />
        )}
        {stepNo == 7 && (
          <DinerSignupSeven setStepNo={setStepNo} setPassword={setPassword} password={password} />
        )}
        {stepNo == 8 && (
          <DinerSignupEight setStepNo={setStepNo} />
        )}
        {stepNo == 9 && (
          <DinerSignupNine setStepNo={setStepNo} firstName={firstName} lastName={lastName} dateOfBirth={dateOfBirth} email={email} phoneNumber={phoneNumber} password={password} userRole={userRole} setAddUser={setAddUser} addUser={addUser} />
        )}

        {stepNo == 10 && (
          <DinerSignupTen setStepNo={setStepNo} />
        )}
        {stepNo == 11 && (
          <DinerSignupEleven setStepNo={setStepNo} setPhoneNumber={setPhoneNumber} phoneNumber={phoneNumber} setAddUser={setAddUser} addUser={addUser} />
        )}
      </AuthPageLayout>
    </div>
  )
}
export default DinerSignup
