import { BrowserRouter, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from "./components/home"
import FindMyMeal from "./pages/findmymeal/findmeal_one"
import OutletProfile from "./pages/findmymeal/outletprofile"
import LoginPage from "./pages/auth/loginpage"
import LoginThree from "./pages/auth/loginthree"
import ForgotPassThree from "./pages/auth/forgot_password/otpandqnapage";
import ForgotPassTwo from "./pages/auth/forgot_password/forgotpasstwo"
import ForgotPassword from "./pages/auth/forgot_password/forgotpassword";
import NewPassword from "./pages/auth/forgot_password/newpassword";

import DinerSignup from "./pages/diner/dinersignup";
import DinerSignupOne from "./pages/diner/dinersignup01";
import DinerSignupTwo from "./pages/diner/dinersignup02";
import DinerSignupThree from "./pages/diner/dinersignup03";
import DinerSignupFour from "./pages/diner/dinersignup04";
import DinerSignupFive from "./pages/diner/dinersignup05";
import DinerSignupSix from "./pages/diner/dinersignup06";
import DinerSignupSeven from "./pages/diner/dinersignup07";
import DinerSignupEight from "./pages/diner/dinersignup08";
import DinerSignupNine from "./pages/diner/dinersignup09";
import DinerSignupTen from "./pages/diner/dinersignup10";
import CloseAccount from "./pages/diner/closeaccount/myaccount";

import HostSignup from "./pages/host/hostsignup"
import DinerMyProfile from "./pages/diner/dinermyprofile"
import HostMyProfile from "./pages/host/hostmyprofile"
// import { authentication } from "./commanFunctions";
// import { verifyToken } from "./queries/queries";
// import { client } from ".";
import HostAppLayout from "./pages/auth/HostAppLayout";
import DinerAppLayout from "./pages/auth/DinerAppLayout";
// import CommonLayout from "./pages/auth/commonLayout";
import GuestLayout from "./pages/auth/guestLayout";
import AuthLayout from "./pages/auth/Authlayout";
import HostStepOne from "./pages/host/hoststepone";
import HostingLanding from "./pages/hostinglanding";
import AboutUs from "./pages/aboutus";
import Career from "./pages/career";
import Career02 from "./pages/career02";
import ContactUs from "./pages/contactus";
import Faq from "./pages/faq";
import Faq02 from "./pages/faq02";
import ReferAndEarn from "./pages/referandearn";
import MyOrderA from "./pages/myordera";
// import { Inbox } from "react-bootstrap-icons";
import InboxMessage from "./pages/inbox/inbox";
import CartSummery from "./pages/cartsummery";
import HostMarketingone from "./pages/hostmarketingone";
// import HostingLanding from "./pages/hostinglanding";
import HostMarketingtwo from './pages/hostmarketingtwo'
import HostBusiness from "./pages/hostbusiness";
import IdentityStep01 from "./pages/dinerverifyidentity/identitystep01";
import IdentityStep02 from "./pages/dinerverifyidentity/identitystep02";
import IdentityStep03 from "./pages/dinerverifyidentity/identitystep03";
import IdentityStep04 from "./pages/dinerverifyidentity/identitystep04";
import SignPass from "./pages/dinerverifyidentity/signpass";
import OtpAndQnaPage from "./pages/auth/forgot_password/otpandqnapage";
import HostCribOne from "./pages/host/cribs/hostcribone";
import HostCribTwo from "./pages/host/cribs/hostcribtwo";
// import HostCribThree from "./pages/host/cribs/hostcribthree";
import AddHostCrib from "./pages/host/cribs/addhostcrib";

import HostMenuone from './pages/host/hostmenu/hostmenuone';
import HostMenutwo from "./pages/host/hostmenu/hostmenutwo";
import HostMenuthree from "./pages/host/hostmenu/hostmenuthree";
// import HostMenufour from "./pages/host/hostmenu/hostmenufour";
import HostMenufive from "./pages/host/hostmenu/hostmenufive";
import AddHostMenuItem from "./pages/host/hostmenu/AddHostMenuItem";
import MenuTempateEdit from "./pages/host/hostmenu/menutempateedit";
import MenutemplateSavechange from "./pages/host/hostmenu/menutemplatesavechange";
import HostMenuEdit from "./pages/host/hostmenu/hostmenuedit";
import HostMenuAdd from "./pages/host/hostmenu/hostMenuAdd";
import HostPayment from "./pages/diner/dinerpanel/dinerpayment";
import StripPayment from './pages/diner/stripe/stripPayment';

import HelpCenter from "./pages/helpcentre/helpcenter";
import HelpCenter02 from "./pages/helpcentre/helpcenter02";
import HelpCenter03 from "./pages/helpcentre/helpcenter03";

// import NotificationAlert from "./components/dinerpanel/notificationalert";
// import NotificationCenter from "./components/dinerpanel/notificationcenter";

import NotificationAlert from "./pages/notificationalert/notificationalert";
import NotificationCenter from "./pages/notificationalert/notificationcenter";
import CartSummery01 from "./pages/checkout/cartsummery01";
import CartCheckout01 from "./pages/checkout/cartcheckout01";
import CartCheckout02 from "./pages/checkout/cartcheckout02";

// ***************** Add file Track order ******************************
import TrackYourOrder from "./pages/checkout/trackyourorder";


// ****************** trem and condition page ***************************
import TermsOfUse from "./pages/termsofuse";
import PrivacyPolicy from "./pages/privacypolicy";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<GuestLayout />}>
            <Route path="/" exact element={<Home />} />
            <Route path="/outletprofile/:id" exact element={<OutletProfile />} />
            <Route path="/findmeal_one" exact element={<FindMyMeal />} />
            <Route path="/aboutus" exact element={<AboutUs />} />
            <Route path="/career" exact element={<Career />} />
            <Route path="/career02" exact element={<Career02 />} />
            <Route path="/contactus" exact element={<ContactUs />} />
            <Route path="/faq" exact element={<Faq />} />
            <Route path="/faq02" exact element={<Faq02 />} />
            <Route path="/referandearn" exact element={<ReferAndEarn />} />
            <Route path="/myOrder" exact element={<MyOrderA />} />
            <Route path="/signpass" exact element={<SignPass/>} />
            
            <Route path="/messageinbox" exact element={<InboxMessage />} />
            <Route path="/cartsummery" exact element={<CartSummery />} />
            <Route path="/cartsummery01" exact element={<CartSummery01 />} />
            <Route path="/cartcheckout01" exact element={<CartCheckout01 />} />
            <Route path="/cartcheckout02" exact element={<CartCheckout02 />} />
            <Route path="/signpass" exact element={<SignPass />} />

            <Route path="/helpcenter" exact element={<HelpCenter />} />
            <Route path="/helpcenter02" exact element={<HelpCenter02 />} />
            <Route path="/helpcenter03" exact element={<HelpCenter03 />} />

            <Route path="/notificationalert" exact element={<NotificationAlert />} />
            <Route path="/notificationcenter" exact element={<NotificationCenter />} />

            {/* ************************ this route is tracking order **************************** */}
            <Route path="/tracking-order/:id" exact element={<TrackYourOrder />} />
            {/* ************************ this route is terms & condition or privacy policy ************************** */}
            <Route path="/terms-conditions" exact element={<TermsOfUse />} />
            <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />

          </Route>
          <Route path="/otpandqnapage" exact element={<OtpAndQnaPage />} />

          <Route element={<HostAppLayout />}>
            <Route path="/host" exact element={<HostStepOne />} />
            <Route path="/host/myprofile" exact element={<HostMyProfile />} />
            <Route path="/host/hostlandingpage" exact element={<HostingLanding />} />
            <Route path="/hostmarketingone" exact element={<HostMarketingone />} />
            <Route path="/hostmarketingtwo" exact element={<HostMarketingtwo />} />
            <Route path="/hostbusiness" exact element={<HostBusiness />} />
            <Route path="/host/cribs" exact element={<HostCribOne />} />
            <Route path="/host/view-crib/:id" exact element={<HostCribTwo />} />
            <Route path="/host/add-crib" exact element={<AddHostCrib />} />
            <Route path="/host/menu" exact element={<HostMenuone />} />
            <Route path="/host/add-menu" exact element={<HostMenutwo formType="add" />} />
            <Route path="/host/view-menu/:id" exact element={<HostMenutwo formType="view" />} />
            <Route path="/host/add-menu-item/:id" exact element={<AddHostMenuItem />} />
            {/* <Route path="/host/edit-menu-item" exact element={<HostMenufour formType="edit" />} /> */}
            <Route path="/host/edit-menu-item/:menuid/:id" exact element={<HostMenuEdit formType="edit" />} />
            <Route path="/host/view-menu-item" exact element={<HostMenuthree />} />
            <Route path="/host/add-category" exact element={<HostMenufive />} />
            <Route path="/host/menu-tempate-page/:id" exact element={<MenuTempateEdit />} />
            <Route path="host/menu-template-savechange" exact element={<MenutemplateSavechange />} />
            <Route path="/host/menu-add" exact element={<HostMenuAdd />} />
            <Route path="/host/payment" exact element={<HostPayment />} />
            <Route path="/strip/payment" exact element={<StripPayment />} />
            <Route path="/host/identity-verify" exact element={<IdentityStep01/>} />
            <Route path="/host/chat" exact element={<InboxMessage/>} />

            {/* <Route path="/host/menu/menu-items/edit-item" exact element={<HostMenusix />} /> */}
          </Route>

          <Route element={<DinerAppLayout />}>
            <Route path="/hostsignup" exact element={<HostSignup />} />
            <Route path="/diner" exact element={<FindMyMeal />} />
            <Route path="/diner/myprofile" exact element={<DinerMyProfile />} />
            <Route path="/diner/hostlandingpage" exact element={<HostingLanding />} />
            <Route path="/diner/close_account" exact element={<CloseAccount />} />
            <Route path="/diner/identity-verify" exact element={<IdentityStep01/>} />
            <Route path="/diner/chat" exact element={<InboxMessage/>} />

          </Route>

          <Route path="/loginthree" exact element={<LoginThree />} />
          <Route path="/dinersignup01" exact element={<DinerSignupOne />} />
          <Route path="/dinersignup02" exact element={<DinerSignupTwo />} />
          <Route path="/dinersignup03" exact element={<DinerSignupThree />} />
          <Route path="/dinersignup04" exact element={<DinerSignupFour />} />
          <Route path="/dinersignup05" exact element={<DinerSignupFive />} />
          <Route path="/dinersignup06" exact element={<DinerSignupSix />} />
          <Route path="/dinersignup07" exact element={<DinerSignupSeven />} />
          <Route path="/dinersignup08" exact element={<DinerSignupEight />} />
          <Route path="/dinersignup09" exact element={<DinerSignupNine />} />
          <Route path="/dinersignup10" exact element={<DinerSignupTen />} />



          <Route element={<AuthLayout />}>
            <Route path="/loginpage" exact element={<LoginPage />} />
            <Route path="/dinersignup" exact element={<DinerSignup />} />
            <Route path="/forgot_password" exact element={<ForgotPassword />} />
            <Route path="/forgotpasstwo" exact element={<ForgotPassTwo />} />
            <Route path="/forgotpassthree" exact element={<ForgotPassThree />} />
            <Route path="/newpassword" exact element={<NewPassword />} />
          </Route>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
