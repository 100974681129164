import React, { useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom';
import { client } from '..';
import HostHeader from './hostHeader';
import DinerHeader from './dinerHeader';
import { SignOut } from '../firebase';

const AfterLoginHeader = () => {
const navigate = useNavigate();
const user = useOutletContext();

const [, setToken] = useState(localStorage.getItem("token"));

  const logout = () => {
    localStorage.removeItem("token")
    setToken(localStorage.getItem("token"));
    SignOut();
    client.cache.reset()
    navigate('/loginpage')
  }

  return (
    <div className="headwraper">
      <header className="headerone d-flex align-items-center justify-content-between">
        {user?.user?.last_login === "host" ? <>
          <HostHeader user={user?.user} logout={logout} />
        </>
          : <DinerHeader user={user?.user} logout={logout} />}
      </header>
    </div>
  )
}

export default AfterLoginHeader;