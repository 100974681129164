import { useEffect, useState } from 'react'

const DeviceScreenSize = () => {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
        const handleDeviceSize = () => {
            setSize([window.innerHeight, window.innerWidth]);
        }

        window.addEventListener('resize', handleDeviceSize);

        return () => {
            window.removeEventListener('resize', handleDeviceSize)
        }
    }, [])
    return (size)
}

export default DeviceScreenSize;