import React, { createRef, useEffect, useLayoutEffect, useState } from "react";
import './index.css';
import Scrollbars from 'react-custom-scrollbars-2';
import DeviceScreenSize from '../DeviceScreenSize';

const reducer = (acc, e) => (e != null ? (acc + e.offsetWidth) : acc);

export const Tabs = (props) => {
    const { tabs, selectedTab, onTabSelection } = props;
    const [deviceHeight, deviceWidth] = DeviceScreenSize();
    let tilesContainerWidth = createRef();
    let tabTiles = createRef();
    let tilesRef = [];

    const tabsNO = (tabs?.length || 0);
    const [tabMove, setTabMove] = useState(0);
    const [viewScreenWidth, setViewScreenWidth] = useState(0);
    const [tabsContainerWidth, setTabsContainerWidth] = useState(0);
    const [viewTabsNo, setViewTabsNo] = useState(0);
    const [swipe, setSwipe] = useState(0);
    const [floatMove, setFloatMove] = useState(0);

    const slidetoPrev = () => {
        if (viewScreenWidth < tabsContainerWidth) {
            if ((tabMove + swipe) <= 0) {
                setTabMove(tabMove + swipe);
            }
        } else {
            setTabMove(0)
        }
    };

    const slideToNext = () => {
        if (viewScreenWidth < tabsContainerWidth) {
            let singleTabLength = (tabsContainerWidth / tabsNO);
            if ((tabMove - swipe) >= -(singleTabLength * (tabsNO - 1))) {
                setTabMove(tabMove - swipe);
            }
        } else {
            setTabMove(0)
        }
    };

    useEffect(() => {
        let viewScreen = (tilesContainerWidth.offsetWidth);
        let TabsScreen = (tilesRef.reduce(reducer, 0));
        let oneTabLength = (TabsScreen / tabsNO);
        let TabsNo = (viewScreen / oneTabLength);
        let swipeLength = (Math.floor(TabsNo) * oneTabLength);

        setViewScreenWidth(viewScreen);
        setTabsContainerWidth(TabsScreen);
        setViewTabsNo(TabsNo)
        setSwipe(swipeLength)
        setFloatMove((viewScreen - swipeLength) / 2)

    }, [deviceWidth])
    // console.log('tabsContainerWidth', ((tabsContainerWidth / tabsNO) * (tabsNO - 1)))
    // console.log("tab move", tabMove)
    // console.log("view Screen Width", viewScreenWidth)
    // console.log("swipe", swipe)
    // console.log("float Move", floatMove)

    return (
        <React.Fragment>
            <div className="tabs-wrapper d-flex cribstabs">
                {/* --------------------Left Button-------------------- */}
                {(tabsNO > viewTabsNo) && <div className="add-btn left bgwhite" onClick={slidetoPrev}>
                    <svg width="26" height="26" viewBox="0 0 36 36" preserveAspectRatio="xMidYMid meet">
                        <path d="M29.52,22.52,18,10.6,6.48,22.52a1.7,1.7,0,0,0,2.45,2.36L18,15.49l9.08,9.39a1.7,1.7,0,0,0,2.45-2.36Z" />{" "}
                        <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
                    </svg>
                </div>}
                {/* --------------------Tab Container Button-------------------- */}
                <div className="tabs-container tabcontainerbox" style={{ left: floatMove }} ref={(el) => (tilesContainerWidth = el)} >
                    <Scrollbars className="meattabs" autoHeight>
                        <div className="tabs-tiles" style={{ left: tabMove }} ref={(el) => (tabTiles = el)} >
                            {tabs?.map((item, index) => {
                                const { title, id } = item;
                                return (
                                    <TabTile
                                        key={Math.random(1)}
                                        forwardRef={(e) => (tilesRef[index] = e)}
                                        id={id}
                                        label={title}
                                        activeTab={selectedTab}
                                        onTabClick={onTabSelection}
                                    />
                                );
                            })}
                        </div>
                    </Scrollbars>
                </div>
                {/* --------------------Right Button-------------------- */}
                {(tabsNO > viewTabsNo) && <div className="add-btn right bgwhite" onClick={slideToNext}>
                    <svg width="26" height="26" viewBox="0 0 36 36" preserveAspectRatio="xMidYMid meet">
                        <path d="M29.52,22.52,18,10.6,6.48,22.52a1.7,1.7,0,0,0,2.45,2.36L18,15.49l9.08,9.39a1.7,1.7,0,0,0,2.45-2.36Z" />{" "}
                        <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
                    </svg>
                </div>}
            </div>
            {props?.children}
        </React.Fragment>
    );
};

export const TabTile = (props) => {
    return (
        <div
            ref={props.forwardRef}
            className={`${props.activeTab == props.id ? "active themeColor" : "GrayComn"} tab fs22 semibold`}
            onClick={() => props.onTabClick(props.id)}
        >
            {props.label}
        </div>
    );
};
