import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import Header from "../components/header";
import AfterLoginHeader from '../components/afterLoginHeader';
import Footer from "../components/footer";
import ProfileMenu from '../components/profilemenu';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';


const HostingLanding = () => {    

    const navigate = useNavigate();

    //for mobile view
    // const [formValues, setFormValues] = useState([{ name: "", email : ""}])

    // let handleChange = (i, e) => {
    //     let newFormValues = [...formValues];
    //     newFormValues[i][e.target.name] = e.target.value;
    //     setFormValues(newFormValues);
    //   }
    
    // let addFormFields = () => {
    //     setFormValues([...formValues, { name: "", email: "" }])        
    //   }
    
    // let removeFormFields = (i) => {
    //     let newFormValues = [...formValues];
    //     newFormValues.splice(i, 1);
    //     setFormValues(newFormValues);        
    // }
    
    // let handleSubmit = (event) => {
    //     event.preventDefault();
    // }

    
    const routeChange = () =>{ 
        //localStorage.removeItem("token");
        navigate('/hostsignup');
      }
      
      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <AfterLoginHeader />
                    </Col>
                </Row>
                {/* <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col> */}
                <Row className="dblock">
                    <Col lg="3" className="ps-0 profilemenu outline-none" tabindex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <ProfileMenu/>
                    </Col>                    
                    <Col as="div" className="userAcc pt-4 pe-xl-5">
                        <h2 className="fs52 semibold ms-md-4 mb-0">Hosting</h2>
                        <p className="ms-md-4 semibold">Hosting</p>
                        <Col as="section" className="shadowSM mt-5 ms-md-4 me-xl-5 position-relative">                        
                        <Row onClick={routeChange}>
                            <Col className="aboutbanner"  >
                                <figure className="figure position-absolutes m-0">
                                    <img src={process.env.REACT_APP_PUBLIC_URL+"assets/bannerHost.png"} className="figure-img img-fluid d-block" alt="careerpicture" />
                                </figure>
                            </Col>
                        </Row>
                        <Col lg={12} className="m-auto">
                            <div className="mx-md-4 checkoutAdd">
                                <Col as="section" className="hostCanvas inner mt-5">
                                    <Row className="justify-content-between">
                                        <Col xl="4" lg="5" md="6">
                                            <h2 className='fs26 GrayComn semibold'>Coming Soon</h2>
                                            <p className='fs18 GrayComn semibold lh-sm'>Stay tuned for more exciting features! We'd love to hear your feedback. Let us know what you think.</p>
                                        </Col>
                                        <Col xl="4" lg="5" md="6">
                                            <h2 className='fs26 GrayComn semibold'>Coming Soon</h2>
                                            <p className='fs18 GrayComn semibold lh-sm'>Stay tuned for more exciting features! We'd love to hear your feedback. Let us know what you think.</p>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-between">
                                        <Col className="midCricle innergpage"><img src={process.env.REACT_APP_PUBLIC_URL+"assets/hostcanvas.svg"} className="figure-img m-auto img-fluid d-block" alt="svgcanvas" /></Col>
                                    </Row>
                                    <Row className="justify-content-between">
                                    <Col xl="4" lg="5" md="6">
                                            <h2 className='fs26 GrayComn semibold'>Coming Soon</h2>
                                            <p className='fs18 GrayComn semibold lh-sm'>Stay tuned for more exciting features! We'd love to hear your feedback. Let us know what you think.</p>
                                        </Col>
                                        <Col xl="4" lg="5" md="6">
                                            <h2 className='fs26 GrayComn semibold'>Coming Soon</h2>
                                            <p className='fs18 GrayComn semibold lh-sm'>Stay tuned for more exciting features! We'd love to hear your feedback. Let us know what you think.</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                        </Col>
                        
                            
                        <Row className="mx-md-0 rylBox coreSection inner">
                            <Col md="6" className='fs62 semibold GrayComn lh-1 vlBox ps-md-5'>
                                Cribbly <br />Royalty Program
                                <p className="fs30 semibold pt-4">Amet minim mollit non deserunt</p>
                                <p className='fs20 gray82 semibold pt-2 lh-sm'>Amet minim mollit non deserunt ullamco est sit <br />aliqua dolor do amet sintduis enim Exercitation <br />veniam consequat sunt.</p>
                                <Button variant="danger" className="mt-4 px-5 fs26 border08">Learn More</Button>
                            </Col>
                            <Col md="6" className="royaltyright inner">
                            <img src={process.env.REACT_APP_PUBLIC_URL+"assets/royaltyImg.png"} className="figure-img m-auto img-fluid d-block w-100 h-100" alt="svgcanvas" />
                            </Col>
                        </Row>

                        
                        <Row className="mx-md-4 hypart inner">
                            <Col md="12" className="mt-5 pt-5 text-center">
                                <h2 className="fs52vw semibold GrayDark">Why Partners With <br />Cribbble</h2>
                                <p className="fs20 GrayComn semibold lh-sm pt-4">Ordering your next meal should not be rocket science. With Cribbly, we make it <br />easy for you. Follow these 5 steps and you are all set for your next meal!</p>
                            </Col>
                            <Col md="12" className="pt-5 mt-4 text-center orderStepSec inner">
                                <Row>
                                    <Col sm="12" md="12" lg="4">
                                        <img src={process.env.REACT_APP_PUBLIC_URL+"assets/orderStep01.png"} className="figure-img m-auto img-fluid d-block" width={100} alt="svgcanvas" />
                                        <p className="fs20 GrayComn semibold lh-sm pt-5 px-2">Search and browse for your favourite food, offered by our lovely Hosts. You may wish to search by location to find the nearest to you.</p>
                                    </Col>
                                    <Col sm="12" md="12" lg="4">
                                        <img src={process.env.REACT_APP_PUBLIC_URL+"assets/orderStep02.png"} className="figure-img m-auto img-fluid d-block" width={100} alt="svgcanvas" />
                                        <p className="fs20 GrayComn semibold lh-sm pt-5">Select your desired food and check if ingredient may contain any possible food allergen(Please note not all food content possible allergens are indicated, please check directly with your Hosts prior to ordering.)</p>
                                    </Col>
                                    <Col sm="12" md="12" lg="4">
                                        <img src={process.env.REACT_APP_PUBLIC_URL+"assets/orderStep03.png"} className="figure-img m-auto img-fluid d-block" width={100} alt="svgcanvas" />
                                        <p className="fs20 GrayComn semibold lh-sm pt-5 px-2">Once you are set and ready, proceed to checkout by selecting "Buy Now". In put your order details as shown on your screen. Check to ensure 
                                        all your order details are correct and confirm your order.</p>
                                    </Col>
                                    </Row>
                                    <Row className="mt-5">
                                    <Col sm="12" md="12" lg="4">
                                        <img src={process.env.REACT_APP_PUBLIC_URL+"assets/orderStep04.png"} className="figure-img m-auto img-fluid d-block" width={100} alt="svgcanvas" />
                                        <p className="fs20 GrayComn semibold lh-sm pt-5 px-2">Search and browse for your favourite food, offered by our lovely Hosts. You may wish to search by location to find the nearest to you.</p>
                                    </Col>
                                    <Col sm="12" md="12" lg="4">
                                        <img src={process.env.REACT_APP_PUBLIC_URL+"assets/orderStep05.png"} className="figure-img m-auto img-fluid d-block" width={100} alt="svgcanvas" />
                                        <p className="fs20 GrayComn semibold lh-sm pt-5">Select your desired food and check if ingredient may contain any possible food allergen(Please note not all food content possible allergens are indicated, please check directly with your Hosts prior to ordering.)</p>
                                    </Col>
                                    <Col sm="12" md="12" lg="4">
                                        <img src={process.env.REACT_APP_PUBLIC_URL+"assets/orderStep06.png"} className="figure-img m-auto img-fluid d-block" width={100} alt="svgcanvas" />
                                        <p className="fs20 GrayComn semibold lh-sm pt-5 px-2">Once you are set and ready, proceed to checkout by selecting "Buy Now". In put your order details as shown on your screen. Check to ensure all your order 
                                        details are correct and confirm your order.</p>
                                    </Col>
                                </Row>
                                <Button variant="danger" className="fs26 my-5 px-5">Go To FAQ</Button>
                            </Col>
                        </Row>


                        </Col>                        
                    </Col>
                </Row>        
            </Container>
            <br /><br />
            <Footer />
        </div>
    )    
}
export default HostingLanding