import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { useState } from "react";
import { Row, Col, Form, Button, FloatingLabel } from "react-bootstrap";
import { FiMapPin } from "react-icons/fi";
import { addClass } from "../../../commanFunctions";

const HostPersonal07 = (params) => {
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [sameAddress, setSameAddress] = useState(true);

  const onNextpage = (event) => {
    event.preventDefault();
    let findData = params.outletData.find((d => d.address === params.address))
    if (params.address === "") {
      setErrorMessage({
        error: "empty address",
        message: "Please Enter Address",
      });
    } else if (findData?.address === params.address) {
      // console.log("Outlet not push in array because address data same ....")
      addClass()
      setTimeout(() => {
        params.setStepNo(11);
      }, 1400)
    } else {
      params.outletData.push({
        long: "30.5455",
        lat: "29.4545",
        address: params.address,
        outlet_property: "owner",
        verificationdoc: null,
      });
      addClass()
      setTimeout(() => {
        params.setStepNo(11);
      }, 1400)
    }
  };

  const nextOutlet = () => {
    let findData = params.outletData.find((d => d.address === params.address))
    if (findData?.address === params.address) {
      // console.log("Outlet not push in array because address data same ....")
      addClass();
      setTimeout(() => {
        params.setStepNo(8);
      }, 1400);
    } else {
      params.outletData.push({
        long: "30.5455",
        lat: "29.4545",
        address: params.address,
        outlet_property: "owner",
        verificationdoc: null,
      });
      addClass();
      setTimeout(() => {
        params.setStepNo(8);
      }, 1400);
    }
  };

  const goBack = () => {
    addClass();
    setTimeout(() => {
      params.setStepNo(6);
    }, 1400);
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  })

  const center = {
    lat: params?.latitude,
    lng: params?.longitude
  }

  const [map, setMap] = React.useState(null)
  const [showMap, setShowMap] = React.useState(false)



  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])
  
  const onUnmount = React.useCallback(function callback(map) {
    console.log("Map onUnmount", null)
    setMap(null)
  }, [])
  return (
    <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
      <div className="front">
        <div className="heading">
          <h2 className="small lh-1 fs-normal mb-2">Become A Host</h2>
          <p className="semibold m-0">
            Please tell us where your Crib Outlet will be.
          </p>
        </div>
        <Col as="section" className="stepsBar2 pt-4">
          <Col as="ul" className="list-unstyled">
            <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(100%/1.8)" }}>7</span></Col>
          </Col>
        </Col>
        <Col as="section" className="signpUser pt-0">
          <Form className="accForm">
            <div className="form-check ps-3 mb-3">
              <input name="group1" type="checkbox" id={`inline-checkbox-1`} checked={sameAddress} onChange={() => {
                setSameAddress(!sameAddress);
              }}
              />
              <label className="pt-1 lh-sm semibold" htmlFor={`inline-checkbox-1`}>
                Outlet address same as residential address.
              </label>
            </div>
            <Col as="div" className="position-static px-0 pb-2 pt-2 semibold">1st Outlet</Col>

            <FloatingLabel controlId="floatingInput" label="Address">
              <Form.Control
                type="text"
                placeholder="Address"
                value={params.address}
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrorMessage({
                      error: "empty address",
                      message: "Please Enter Address",
                    });
                  } else {
                    setErrorMessage(undefined);
                  }
                  params.setAddress(e.target.value);
                }}
                disabled={sameAddress ? true : false}
              />
              <FiMapPin

                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"

                role="button"
                className="calendarIcon position-absolute top-0 end-0 mt-4 me-3" size="20" color="var(--theme-color)" />
              {errorMessage && errorMessage.error === "empty address" && (
                <div className="invalid-feedback d-block">
                  {errorMessage.message}
                </div>
              )}
            </FloatingLabel>
            <Row>
              {/* <Col><img src={process.env.REACT_APP_PUBLIC_URL + "/mapOutlet.png"} className="img-fluid" alt="mapImage" /></Col> */}
              <Col className="collapse" id="collapseExample">
                <Col className="card card-body mt-4">
                  {
                    isLoaded ? <GoogleMap
                      mapContainerStyle={{
                        width: 'auto',
                        height: '250px',
                        borderRadius: "8px"
                      }}
                      center={{ center }}
                      zoom={10}
                      onClick={ev => {
                        console.log("latitide = ", ev.latLng.lat());
                        console.log("longitude = ", ev.latLng.lng());
                      }}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                    >
                      <Marker position={center} onClick={(e) => console.log("marker", e)} />
                    </GoogleMap> : ''
                  }
                </Col>
              </Col>
            </Row>
            <Row className="mt-2">
              <Button className="fs13 themeColor text-decoration-none signup-link text-start mb-3" variant="link" type="button" onClick={nextOutlet}>
                Add Another Outlet?
              </Button>
            </Row>
            <Row>
              <Col className="align-self-center">
                <Button
                  className="fs26 themeColor semibold text-decoration-none lh-1 p-0"
                  variant="link"
                  type="button"
                  onClick={goBack}
                >
                  <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  Previous
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                  variant="primary"
                  type="button"
                  onClick={onNextpage}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
    </section>
  )
}

export default HostPersonal07