import React, { useState } from 'react'
import { Container, Row, Col, Image, Card, Form, Button } from 'react-bootstrap';
import { FiEye, FiEyeOff } from "react-icons/fi";
// import "./account.css"
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import { useNavigate } from 'react-router';
import { loginUserResolver } from '../../../queries/queries';
import { useMutation } from '@apollo/client';

const MyAccount02 = (params) => {
    const navigate = useNavigate();
    const [inputtext, setinputtext] = useState({
        email: "",
        password: ""
    });

    const Login = loginUserResolver();
    const [loginUser] = useMutation(Login);

    const [warnpassword] = useState(false);
    const [seteye] = useState(true);
    const [type, settype] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [userData, SetUserData] = useState();
    const [isLoading, setLoading] = useState(false);


    const Eye = () => {
        if (params.password === "password") {
            params.setpassword("text");
            seteye(false);
            settype(true);
        }
        else {
            params.setpassword("password");
            seteye(true);
            settype(false);
        }
    }

    const handleInputChange = (e) => {
        let name = e.target.name;
        if (name === "email") {
            params.setEmail(e.target.value);
        }
        if (name === "password") {
            params.setpassword(e.target.value);
        }

        if (e.target.value === "") {
            setErrorMessage({
                error: name,
                message: `Please enter ${name}`,
            });
        } else {
            setErrorMessage(undefined);
        }
    };

    const handleLoginUser = async (e) => {
        e.preventDefault();
        setLoading(true);
        let data = await loginUser({
            variables: {
                text: params.email,
                password: params.password,
            }
        })
        setLoading(false);
        if (data?.data) {
            if (data?.data?.login_SendOTP?.status === "success") {
                // SetUserData(data?.data?.login_SendOTP?.user);
                params.setStepNo(3);
            } else if (data?.data?.login_SendOTP?.status === "error") {
                setErrorMessage({
                    message: data?.data?.login_SendOTP?.message,
                    error: data?.data?.login_SendOTP?.status,
                })
            }
        } else {
            setErrorMessage({
                error: "error",
                message: "something went wrong",
            });
        }
    }

    return (
        <Row className="ps-0 pinkgradiant pb-3">
            <Col lg="12" className="text-center mb-5"><Image src={process.env.PUBLIC_URL + "/assets/sadIcon.png"} alt="mainbanner" className="img-fluid" /></Col>
            <Col lg="12" className="text-center">
                <h2 className="fs60 text-white semibold mb-5">We’re Sad To See You Go!</h2>
                <Card style={{ maxWidth: '670px', paddingLeft: '5rem', paddingRight: '5rem' }} className="d-inline-block px-md-5 py-3 mb-5 border08 w-100" body>
                    <Card.Text className="fs30 GrayComn semibold">Please enter your Credentials</Card.Text>
                    <Form className="mt-5" onSubmit={(e) => handleLoginUser(e)}>
                        <Form.Group className="mb-3" controlId="ControlInput1">
                            <Form.Control className="border-0" type="text" placeholder="Email or Contact Number" name="email" onChange={handleInputChange}
                            />
                            {errorMessage &&
                                errorMessage.error === "email" && (
                                    <div className="invalid-feedback d-block d-flex align-item-start">
                                        {errorMessage.message}
                                    </div>
                                )}
                        </Form.Group>
                        <Form.Group controlId="ControlPassword" className="position-relative">
                            <Form.Control type={params.password} className={` ${warnpassword ? "warning" : ""} ${type ? "type_password" : ""} border-0`} placeholder="Password" name="password" onChange={handleInputChange}
                            />
                            <FiEye role="button" size="20" className="position-absolute end-0 top-0 mt-4 me-4 d-none openEye" onClick={Eye} />
                            <FiEyeOff role="button" size="20" className="position-absolute end-0 top-0 mt-4 me-4 closeEye" onClick={Eye} />
                            {errorMessage &&
                                (errorMessage.error === "password" || errorMessage.error === "error") && (
                                    <div className="invalid-feedback d-block d-flex align-item-start">
                                        {errorMessage.message}
                                    </div>
                                )}
                        </Form.Group>
                        <Form.Group className="mt-4 mb-5">
                            <Button className="w-100 py-2 lh-lg border-0 rounded-pill" variant="primary" type="submit">
                                {isLoading ? "Loading..." : "Confirm"}
                            </Button>
                        </Form.Group>
                    </Form>
                </Card>
            </Col>
        </Row>
    )
}
export default MyAccount02;