import React, { useState } from "react";
import { Col, Form, Button } from "react-bootstrap";
import { addClass } from "../../../commanFunctions";

const HostPersonal08 = (params) => {
  const [selectedSide, setSelectedSide] = useState("");  //LEFT RIGHT

  const onSelect = async (profile) => {
    if (profile === "tenant") {
      setSelectedSide("RIGHT")
    } else if (profile === "owner") {
      setSelectedSide("LEFT")
    } else {
      setSelectedSide('')
    }
    addClass();
    setTimeout(() => {
      params.setPropertyUser(profile);
      params.setStepNo(9);
    }, 1400);
  };

  const goBack = () => {
    addClass();
    setTimeout(() => {
      params.setStepNo(7);
    }, 1400);
  };
  return (
    <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
      <div className="front">
        <div className="heading">
          <h2 className="small lh-1 fs-normal mb-2">Become A Host</h2>
          <p className="semibold m-0">Please provide us your Crib Outlet details.</p>
        </div>
        <Col as="section" className="stepsBar2 pt-4">
          <Col as="ul" className="list-unstyled">
            <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(100%/1.55)" }}>8</span></Col>
          </Col>
        </Col>
        <Col as="section" className="signpUser pt-2">
          <Form>
            <Col
              as="section"
              className={`signpUser mb-3 row text-center`}
            >
              <Col className="dineUser" onClick={() => onSelect("owner")}>
                <div className={`iconleft cursor-pointer ${selectedSide === "LEFT" ? 'bgtheme' : null}`}>

                  <img src={process.env.REACT_APP_PUBLIC_URL + "assets/homeblack.png"} alt="myinfoticon" className="homeblack" />
                  <img src={process.env.REACT_APP_PUBLIC_URL + "assets/homewhite.png"} alt="myinfoticon" className="homewhite" />

                  <h4 className="GrayComn">Home Owner</h4>
                </div>
              </Col>
              <Col
                className="hostUser"
                onClick={() => onSelect("tenant")}
              >
                <div className={`iconright cursor-pointer ${selectedSide === "RIGHT" ? 'bgtheme' : null}`}>

                  <img src={process.env.REACT_APP_PUBLIC_URL + "assets/tenantblack.png"} alt="myinfoticon" className="tenantblack" />
                  <img src={process.env.REACT_APP_PUBLIC_URL + "assets/tenantwhite.png"} alt="myinfoticon" className="tenantwhite" />

                  <h4>Tenant</h4>
                </div>
              </Col>
            </Col>
            <Button
              className="fs26 themeColor semibold text-decoration-none lh-1 p-0"
              variant="link"
              type="button"
              onClick={goBack}
            >
              <svg
                width="9"
                height="16"
                className="align-baseline me-3"
                viewBox="0 0 9 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 15L1 8L8 1"
                  stroke="#C32148"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Previous
            </Button>
          </Form>
        </Col>
      </div>
    </section>
  )
}

export default HostPersonal08