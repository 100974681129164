import React, { useState } from 'react'
import { Col, Figure, Modal, Row, Form, Button } from "react-bootstrap";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";

const PaymentPopup = (params) => {
    const stripe = useStripe();
    const elements = useElements();
    const [succeeded, setSucceeded] = useState(false);

    const [errorMsg, setErrorMsg] = useState({
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null,
        cardHolder: null,
        other: null
    });
    const [processing, setProcessing] = useState('');
    const [holderName, setHolderName] = useState('');
    // const [secretKey, setSecretKey] = useState('')

    // const cartOrders = checkoutOrders()
    // const [CheckoutOrders] = useMutation(cartOrders)

    // const CheckoutOrdersApi = async (data) => {
    //     let result = await CheckoutOrders({
    //         variables: data
    //     })

    //     return ({ clientSecret: result?.data?.checkoutOrders?.paymentIntent?.client_secret })

    // }

    const handleChanges = ev => {
        let temp = { ...errorMsg };

        if (ev?.target) {
            if (ev?.target?.value === '') {
                temp['cardHolder'] = {
                    code: "card_holder_name",
                    message: "Card holder name is empty."
                }
                setErrorMsg(temp);
            } else {
                temp['cardHolder'] = null
                setErrorMsg(temp);
            }
            setHolderName(ev?.target?.value)
        } else if (ev?.elementType) {
            if (ev?.empty) {
                temp[ev.elementType] = {
                    code: "not_empty",
                    message: "Field value not empty."
                }
            } else if (!ev?.empty && !ev?.complete) {
                if (ev?.error?.type === "card_error" || ev?.error?.type === "validation_error") {
                    temp[ev.elementType] = ev?.error
                } else if (ev?.error === undefined) {
                    temp[ev.elementType] = null
                } else {
                    temp['other'] = {
                        code: "unexpected_error",
                        message: "An unexpected error occurred"
                    }
                }

            } else if (ev?.complete) {
                temp[ev.elementType] = null
            }
            setErrorMsg(temp);
        }

    }

    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);
        let temp = { ...errorMsg };
        // let secret_key = secretKey;

        // if (!secret_key || secret_key?.length === 0) {
        //     const { clientSecret } = await CheckoutOrdersApi(params?.data);
        //     secret_key = clientSecret;
        //     setSecretKey(clientSecret)
        // }

        if ((!params?.data?.card_id || params?.data?.card_id?.length === 0) && params?.clientSecret) {
            const payload = await stripe.confirmCardPayment(params?.clientSecret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: { name: "Jenny devi" }
                }
            });

            if (payload?.paymentIntent?.status === "succeeded") {
                params?.onSuccess()

                setSucceeded(true);
                setProcessing(false);
            } else if (payload?.error) {
                if (payload?.error?.code === "incomplete_number" || payload?.error?.code === "invalid_number") {
                    temp['cardNumber'] = {
                        code: payload?.error?.code,
                        message: payload?.error?.message
                    }
                } else if (payload?.error?.code === "incomplete_expiry" || payload?.error?.code === "invalid_expiry_year_past") {
                    temp['cardExpiry'] = {
                        code: payload?.error?.code,
                        message: payload?.error?.message
                    }
                } else if (payload?.error?.code === "incomplete_cvc") {
                    temp['cardCvc'] = {
                        code: payload?.error?.code,
                        message: payload?.error?.message
                    }
                } else {
                    temp['other'] = {
                        code: "unexpected_error",
                        message: "An unexpected error occurred."
                    }
                }
                setErrorMsg(temp);
                setProcessing(false);
            }

        } else {
            params?.onSuccess()
            setSucceeded(true);
            setProcessing(false);
        }
    };

    return (
        <Modal size="xl" centered show={params?.show || false}>
            <Modal.Body className=" d-flex flex-column justify-content-center align-items-center border08 py-5 ">
                <Row className="mb-4 text-center">
                    <p className="fs13 themeColor">( **{params?.title} error )</p>
                    <h1 className="fs46 semibold">{params?.title}</h1>
                </Row>
                <Row className="align-items-center w-100 mw-100 m-auto">
                    <Col className="listCard" md="5">
                        <Col as="div" className="cardPost">
                            <img src={process.env.PUBLIC_URL + "assets/redCard.png"} className="img-fluid" alt="food" />
                        </Col>
                    </Col>
                    <Col className="cardNumber ms-md-5">
                        <Form id="payment-form" onSubmit={handleSubmit}>
                            <Figure className="row mb-3">
                                <Col className="cardNoWrapp">
                                    <h6 className="semibold m-0">Card Number</h6>
                                    <CardNumberElement onChange={handleChanges} />
                                    {(errorMsg?.cardNumber?.code === "incomplete_number" || errorMsg?.cardNumber?.code === "invalid_number" || errorMsg?.cardNumber?.code === 'not_empty') &&
                                        <div className="invalid-feedback d-block themeColor" id="payment-card-number">
                                            {errorMsg?.cardNumber?.message}
                                        </div>}
                                </Col>
                            </Figure>
                            <Figure className="row mb-3">
                                <Col>
                                    <h6 className="semibold m-0">Expiry Date</h6>
                                    <CardExpiryElement onChange={handleChanges} />
                                    {(errorMsg?.cardExpiry?.code === "incomplete_expiry" || errorMsg?.cardExpiry?.code === "invalid_expiry_year_past" || errorMsg?.cardExpiry?.code === 'not_empty') && <div className="invalid-feedback d-block themeColor" id="payment-card-expiry">
                                        {errorMsg?.cardExpiry?.message}
                                    </div>}
                                </Col>
                                <Col>
                                    <h6 className="semibold m-0">CVV</h6>
                                    <CardCvcElement onChange={handleChanges} />
                                    {(errorMsg?.cardCvc?.code === "incomplete_cvc" || errorMsg?.cardCvc?.code === 'not_empty') && <div className="invalid-feedback d-block themeColor" id="payment-card-cvc">
                                        {errorMsg?.cardCvc?.message}
                                    </div>}
                                </Col>
                            </Figure>
                            <Figure className="row mb-3">
                                <Col>
                                    <h6 className="semibold m-0">Card Holder Name </h6>
                                    <Form.Control type="text" placeholder="Holder Name" value={holderName} onChange={handleChanges} />
                                    {errorMsg?.cardHolder?.code === "card_holder_name" && <div className="invalid-feedback d-block themeColor" id="payment-card-holder">
                                        {errorMsg?.cardHolder?.message}
                                    </div>}
                                </Col>
                            </Figure>
                            <Figure className="row pt-2 mb-0">
                                <Col>
                                    <Figure.Caption>
                                        <div className="form-check">
                                            <input className="form-check-input" id="flex04" type="checkbox" name="flexRadioDefault" />
                                            <label className="form-check-label" htmlFor="flex04"><span className="ps-2 gray82">Save Card to File</span></label>
                                        </div>
                                    </Figure.Caption>
                                </Col>
                            </Figure>
                            <Button id="submit" type="submit" className="text-white fs26 bgtheme rounded-pill py3 px-4 lh-sm mt-4">
                                <span id="button-text">
                                    {processing ? (<div className="spinner" id="spinner"></div>) : ("Pay now")}
                                </span>
                            </Button>
                            <div className="invalid-feedback d-block themeColor" id="payment-message">{errorMsg?.other?.code === "unexpected_error" ? errorMsg?.other?.message : ''}</div>
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default PaymentPopup

