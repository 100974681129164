import React, { useState } from 'react'
import { Container, Row, Col, Breadcrumb, Form, Button, FloatingLabel } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "./identity.css"
import AfterLoginHeader from '../../components/afterLoginHeader';
import Footer from '../../components/footer';
import ProfileMenu from '../../components/profilemenu';
import DatePicker from "react-datepicker";
import { BsCalendar2 } from 'react-icons/bs';
import { useNavigate } from "react-router-dom";


const IdentityStep03 = (params) => {
    console.log(params);

    const navigate = useNavigate();

    // const goBack = () => navigate(-1);
    
    const [errorMessage, setErrorMessage] = useState(undefined);
    const onNextpage = (event) => {
        console.log(event)
        event.preventDefault();
        if (params.fullname === "") {
            setErrorMessage({
                error: "empty full name",
                message: "Please Enter Full Name",
            });
        } else if (params.nricNumber === "") {
            setErrorMessage({
                error: "empty nric number",
                message: "Please Enter NRIC Number",
            });
        } else if (params.dateOfBirth === "") {
            setErrorMessage({
                error: "invalid date",
                message: "Invalid Date",
            });
        } else if (params.age <= "17") {
            setErrorMessage({
                error: "less age",
                message: "Age more then 18+",
            });
        } else {
            setTimeout(() => {
                params.setStepNo(4);
                // params.setPopup(true)
            }, 100)
        }
    };

    const handleChangeRaw = (value) => {
        // if (value === "tomorrow") {
        //   setStartDate(addDays(new Date(), 1));
        // }
    };
    const goBack = () => {
        setTimeout(() => {
            params.setStepNo(2);

        }, 100);
    };
    // const mydate = params.userData.user_profile.dob

    // console.log(mydate)

    // var currentDate = mydate;

    // var newDate = new Date(new Date(currentDate))

    // let currYear = new Date().getFullYear();

    // var y = newDate.getFullYear()
    // var m = newDate.getMonth() + 1
    // var d = newDate.getDate()

    // var date = d + "-" + m + "-" + y
    // // setAge(`${currYear - y}`);
    // var age =currYear -y
    // console.log(date)

    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0"><AfterLoginHeader /></Col>
                </Row>
                {/* <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col> */}
                <Row className="dblock">
                    <Col lg="3" className="ps-0 profilemenu outline-none" tabindex="-1" id="myprofile01" aria-labelledby="myprofile01Label"><ProfileMenu /></Col>
                    <Col lg="9" as="div" className="userAcc pt-4 pe-xl-5">
                        <h2 className="h2_large ms-md-4 mb-0 GrayComn">My Profile</h2>
                        <Breadcrumb className="ms-md-4 mb-5">
                            <Breadcrumb.Item href="/">Account Settings</Breadcrumb.Item>
                            <Breadcrumb.Item href="/">Manage  Payments</Breadcrumb.Item>
                        </Breadcrumb>
                        <Col as="section" className="stepWrap ms-md-4 pt-md-5">
                            <Row className="text-center justify-content-center pb-2">
                                <Col xs={12} lg="10" xl="7" className="text-start">
                                    <h2 className="fs22 GrayComn semibold">Please confirm your personal registration details.</h2>
                                    <Col lg="12" as="section" className="stepsBar2 pt-4">
                                        <Col as="ul" className="list-unstyled">
                                            <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(100%/1.3)" }}>3</span></Col>
                                        </Col>
                                    </Col>
                                </Col>
                            </Row>
                            <Row className="text-center justify-content-center">
                                <Col lg="7">
                                    <Row>
                                        <Col as="section" className="signpUser">
                                            <Form className='accForm text-start' onSubmit={onNextpage}>

                                                <FloatingLabel controlId="floatingInput" label="Full Name" className="mb-3">

                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Full Name"
                                                        value={params.fullname}
                                                        onChange={(e) => {
                                                            if (!e.target.value) {
                                                                setErrorMessage({
                                                                    error: "empty full name",
                                                                    message: "Please Enter Full Name",
                                                                });
                                                            } else {
                                                                setErrorMessage(undefined);
                                                            }
                                                            params.setFullname(e.target.value);
                                                        }}
                                                    />
                                                    {errorMessage &&
                                                        errorMessage.error === "empty full name" && (
                                                            <div className="invalid-feedback d-block">
                                                                {errorMessage.message}
                                                            </div>
                                                        )}
                                                </FloatingLabel>

                                                <FloatingLabel controlId="floatingInput" label="NRIC Number" className="mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="NRIC Number"
                                                        value={params?.nricNumber}
                                                        onChange={(e) => {
                                                            if (!e.target.value) {
                                                                setErrorMessage({
                                                                    error: "empty nric number",
                                                                    message: "Please Enter NRIC Number",
                                                                });
                                                            } else {
                                                                setErrorMessage(undefined);
                                                            }
                                                            params.setNricNumber(e.target.value);
                                                        }}
                                                    />
                                                    {errorMessage &&
                                                        errorMessage.error === "empty nric number" && (
                                                            <div className="invalid-feedback d-block">
                                                                {errorMessage.message}
                                                            </div>
                                                        )}
                                                </FloatingLabel>

                                                <Row className="mb-1">
                                                    <Col>
                                                        <FloatingLabel controlId="floatingInput" className="mb-3">
                                                            <DatePicker
                                                                selected={params.startDate}
                                                                className="form-control bg-white grayBdb"
                                                                placeholderText="Date of Birth"
                                                                maxDate={new Date()}
                                                                value={params.dateOfBirth}
                                                                onChange={(date) => params.handleDate(date)}
                                                                onChangeRaw={(event) => {
                                                                    if (event.target.value === "") {
                                                                        setErrorMessage({
                                                                            error: "invalid date",
                                                                            message: "Invalid Date",
                                                                        });
                                                                    } else {
                                                                        setErrorMessage(undefined);
                                                                    }
                                                                    handleChangeRaw(event.target.value);
                                                                }}
                                                            />

                                                            {errorMessage &&
                                                                errorMessage.error === "invalid date" && (
                                                                    <div className="invalid-feedback d-block">
                                                                        {errorMessage.message}
                                                                    </div>
                                                                )}
                                                            <BsCalendar2 className="calendarIcon mt-1 position-absolute top-0 end-0 mt-4 me-4" size="20" color="var(--theme-color)" />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col>
                                                        <FloatingLabel controlId="floatingInput" label="Age" className="mb-3">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Age"
                                                                value={params.age}
                                                                disabled
                                                            />
                                                            {errorMessage && errorMessage.error === "less age" && (
                                                                <div className="invalid-feedback d-block">
                                                                    {errorMessage.message}
                                                                </div>
                                                            )}
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>

                                                {params.registrationType === "nric" ? (
                                                    <>
                                                        <div className="mb-2 lh-1 form-radio">
                                                            <input
                                                                name="group1"
                                                                type="radio"
                                                                id={`inline-radio-1`}
                                                                checked={params.profileAmendment === true}
                                                                onChange={(e) => params.setProfileAmendment(true)}
                                                            // defaultChecked
                                                            />
                                                            <label className="ms-3 pt-1 lh-sm semibold themeColor" htmlFor={`inline-radio-1`}>
                                                                Information is correct. No amendment required
                                                            </label>
                                                        </div>

                                                        <div className="lh-1 form-radio">
                                                            <input
                                                                name="group1"
                                                                type="radio"
                                                                id={`inline-radio-2`}
                                                                checked={params.profileAmendment === false}
                                                                onChange={(e) => params.setProfileAmendment(false)}
                                                            />
                                                            <label className="ms-3 pt-1 lh-sm semibold themeColor" htmlFor={`inline-radio-2`}>
                                                                Information is incorrect. Proceed to amend
                                                            </label>
                                                        </div>
                                                    </>
                                                ) : null}

                                                <Row className="pt-3 mt-2">
                                                    <Col className="align-self-center mb-4">
                                                        <Button className="fs26 themeColor semibold text-decoration-none lh-1 p-0" variant="link" type="button" onClick={goBack}>
                                                            <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                                            Previous
                                                        </Button>
                                                    </Col>
                                                    <Col lg="4" className="mb-4">
                                                        <Button className="w-100 py-2 lh-lg border-0 hvr-float-shadow rounded-pill" variant="primary" type="submit" >Next</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}
export default IdentityStep03