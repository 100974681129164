export const outletPropertyOptions = [
    { label: 'Owner', value: 'owner' },
    { label: 'Tenant', value: 'tenant' },
];

export const genderOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Other', value: 'other' },
    { label: 'Do Not Disclose', value: 'do-not-disclose' },
];

export const statusOptions = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
];

export const authMethodOptions = [
    { label: 'Email', value: 'email' },
    { label: 'Mobile', value: 'mobile' },
];

export const registrationTypeOptions = [
    { label: 'Personal', value: 'personal' },
    { label: 'Business', value: 'business' },
];
export const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf"
];
export const registrationMethodOptions = [
    { label: 'NRIC', value: 'nric' },
    { label: 'ACRA', value: 'acra' },
    { label: 'MYINFO', value: 'myinfo' },
];

export const personalregistrationMethodOptions = [
    { label: 'NRIC', value: 'nric' },
    { label: 'MYINFO', value: 'myinfo' },
];

export const businessregistrationMethodOptions = [
    { label: 'ACRA', value: 'acra' },
    { label: 'MYINFO', value: 'myinfo' },
];

export const dinerstatusOptions = [
    { label: 'Pending', value: "pending" },
    { label: 'Active', value: "active" },
    { label: 'Suspend', value: "suspend" },
    { label: 'Close', value: "close" },
];

export const companyStatusOptions = [
    { label: 'Live', value: "live" },
    { label: 'Close', value: "close" },
];
export const allergiesOptions = [
    { label: "Seafood (Sellfish)", value: "seafood(sellfish)" },
    { label: "Seafood (Fish)", value: "seafood(fish)" },
    // { label: "Pescatarian", value: "pescatarian" },
    { label: "Dairy ", value: "dairy" },
    { label: "Nuts (Ground Nuts)", value: "nuts" },
    { label: "Soy Bean", value: "soy bean" },
    { label: "Wheat", value: "wheat" },
];

export const HostExpOptions = [
    { label: "Prime Chef", value: "prime" },
    { label: "Super Chef", value: "super" },
    { label: "Master Chef", value: "master" },
];

export const dietaryPrefrences = [
    { label: "Vegan", value: "vegan" },
    { label: "Vegetarian", value: "vegetarian" },
    { label: "Pescatarian", value: "pescatarian" },
    { label: "Halal ", value: "halal" }
];

export const CRIB_DAYS_KEY = {
    'sun': 'Sunday',
    'mon': 'Monday',
    'tue': 'Tuesday',
    'wed': 'Wednesday',
    'thur': 'Thursday',
    'fri': 'Friday',
    'sat': 'Saturday',
    'eve_of_public_holiday': 'Eve of Public Holiday',
    'public_holiday': 'Public Holiday'
};

export const CRIB_ACCOUNT_PENDING = 'pending';
export const CRIB_ACCOUNT_ACTIVE = 'active';
export const CRIB_ACCOUNT_CLOSE = 'close';
export const CRIB_ACCOUNT_SUSPEND = 'suspend';

export const CRIB_ACCOUNT_PUBLISH = { value: 'active-publish', label: "Approved and Published" };
export const CRIB_ACCOUNT_UNPUBLISH = { value: 'active-unpublish', label: "Pending Verification" };

export const HOST_USER_ROLE = 'host';
export const DINER_USER_ROLE = 'diner';
