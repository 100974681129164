import React, { useState, useRef, useEffect } from 'react'
import { Container, Row, Col, Button, Overlay, Tooltip, Modal, Card } from 'react-bootstrap';
import { FiMapPin, FiHeart, FiShare2 } from "react-icons/fi";
import { GoClock, GoPrimitiveDot } from "react-icons/go";
import { GiCutDiamond } from "react-icons/gi";
import { IoDiamond, IoFishSharp } from "react-icons/io5";
import { BsShop, BsFillCircleFill, BsXLg } from "react-icons/bs";
import { HiChevronDown, HiMap, HiOutlineClock } from "react-icons/hi";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import Tabs, { TabPane } from "rc-tabs";
import "rc-slider/assets/index.css";
import "./outletprofile.css"
import "rc-tabs/assets/index.css";
import { useParams } from 'react-router-dom'
import Header from "../../components/header";
import { gql, useQuery } from "@apollo/client";
import { GetMenuItemList, getMenuItems, GetMenuListByCrib, OutletDetails, OutletList } from '../../queries/queries'
import { useLazyQuery } from '@apollo/client';
import AfterLoginHeader from '../../components/afterLoginHeader';
import BeforeLoginHeader from '../../components/beforeLoginHeader';
import { Tabs as CustomTabs } from '../../components/custom-tabs/scrollable-tabs';
import moment from 'moment';

// const defaultTabKey = "1";

const PanelContent = ({ children }) => (
    <div style={{ height: 200, overflow: "auto" }}>{children}</div>
);

const OutletProfile = () => {
    const { id } = useParams();
    const [outlets, setOutlets] = useState([]);
    const [tabList, setTabList] = useState([
        {
            title: "One",
            id: "1",
        },
        {
            title: "Two",
            id: "2",
        },
        {
            id: "3",
            title: "Three",
        },
        {
            id: "4",
            title: "Four",
        },
        {
            id: "5",
            title: "Five",
        },
        // {
        //     id: "6",
        //     title: "Six",
        //     content: "tab 6 content.",
        //     visible: true
        // },
        // {
        //     id: "7",
        //     title: "Saven",
        //     content: "tab 7 content.",
        //     visible: true
        // },
        // {
        //     id: "8",
        //     title: "Eight",
        //     content: "tab 8 content.",
        //     visible: true
        // },
        // {
        //     id: "9",
        //     title: "Nine",
        //     content: "tab 9 content.",
        //     visible: true
        // },
        // {
        //     id: "10",
        //     title: "Ten",
        //     content: "tab 10 content.",
        //     visible: true
        // },
        // {
        //     id: "11",
        //     title: "Eleven",
        //     content: "tab 11 content.",
        //     visible: true
        // },
        // {
        //     id: "12",
        //     title: "Twelve",
        //     content: "tab 12 content.",
        //     visible: true
        // },
        // {
        //     id: "13",
        //     title: "Thirteen",
        //     content: "tab 13 content.",
        //     visible: true
        // },
        // {
        //     id: "14",
        //     title: "Fourteen",
        //     content: "tab 14 content.",
        //     visible: true
        // },
    ]);
    const [tabKey, setTabKey] = useState("One");
    const [selectedTabId, setSelectedTabId] = useState(1);
    const [tabMenuItem, setTabMenuItem] = useState([]);
    const [outletDetails, setOutletDetails] = useState(undefined);
    const [isActive, setActive] = useState("false");
    const [show, setShow] = useState(false);
    const [ottClass, setOttClass] = useState(false);

    const cribIdNull = outletDetails?.id === null || outletDetails?.id === undefined

    const details = OutletDetails();
    const { data, error } = useQuery(details, {
        variables: {
            "id": Number(id),
            "long": "102.4316785377697316",
            "lat": "105.75985026359558"
        }
    });

    const OutletsList = OutletList();
    const tabOutletList = useQuery(OutletsList, { skip: cribIdNull, variables: { crib_id: Number(id) } });

    const MenuList = GetMenuListByCrib();
    const MenuListByCrib = useQuery(MenuList, { skip: cribIdNull, variables: { crib_id: Number(id) } });

    const MenuItems = GetMenuItemList();
    const MenuItemList = useQuery(MenuItems, {
        skip: cribIdNull, variables: { cribId: Number(id), menuId: Number(selectedTabId) }
    })

    let menuDetails = getMenuItems(tabKey)
    const { menuData, menuError, refetch } = useQuery(menuDetails)

    // const onTabClick = (key) => {
    //     setTabKey(key)
    // }

    const onTabSelection = (tabId) => {
        setSelectedTabId(tabId);
    };

    // var callback = function (key) {
    //     console.log("callback-key", key)
    //     setTabKey(key)
    // };
    //const navRef = React.useRef(null);

    const handleToggle = () => {
        setActive(!isActive);
    };

    //tooptip
    const target = useRef(null);
    const setClass = () => {
        setShow(!show)
        if (!ottClass) {
            document.getElementById("bodyWrap").classList.add("openTT");
            setOttClass(true)
        } else {
            document.getElementById("bodyWrap").classList.remove("openTT");
            setOttClass(false)
        }
    }

    //modal02
    const [showOutlet, setShowOutlet] = useState(false);
    const handleClose = () => setShowOutlet(false);
    const handleShow = () => setShowOutlet(true);

    useEffect(() => {
        if (data?.outletDetails?.status === "success") {
            setOutletDetails(data.outletDetails.data)
            // getMenuItem()
        }
    }, [data, error])

    useEffect(() => {
        if (tabOutletList?.data?.outletList?.status === "success") {
            setOutlets(tabOutletList?.data?.outletList?.outlets)
        }
    }, [tabOutletList])

    useEffect(() => {
        if (MenuListByCrib?.data?.getMenuListByCrib?.status === "success") {
            setTabList(MenuListByCrib?.data?.getMenuListByCrib?.data)
        };
    }, [MenuListByCrib])

    useEffect(() => {
        if (MenuItemList?.data?.getMenuItemList?.status === "success") {
            setTabMenuItem(MenuItemList?.data?.getMenuItemList?.data)
        }
    }, [MenuItemList])

    useEffect(() => {
        menuDetails = getMenuItems(tabKey)
        refetch()
    }, [tabKey])
    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        {
                            localStorage.getItem("token") ?
                                <AfterLoginHeader /> :
                                <BeforeLoginHeader />
                        }
                    </Col>
                </Row>
                <br /><br />
                <Row>
                    <Col md={11} className="m-auto">
                        <div className="mx-sm-4 RestroAdd">
                            <Row>
                                <Col sm md="auto">
                                    <span className="rounded-circle border border-2 border-danger d-inline-block p-1"><img src="/black_scoop.png" /></span>
                                </Col>
                                <Col md>
                                    <div className="itemDetails pt-2">
                                        <Col as="div" className="itemHead">
                                            <h4 className="d-inline align-middle me-3">{outletDetails && outletDetails?.name}</h4>
                                            <GiCutDiamond size="30" color="var(--diamondBlue)" className="me-3" />
                                            <span ref={target} className="border100 openNow btn bgGreen text-white semibold text-decoration-none me-2">Open Now</span>
                                            <a heef="" variant="primary" onClick={() => setClass()} className="border100 calenderIcon bgLight text-black semibold text-decoration-none"><BsShop size="14" color="var(--theme-color)" /> 3 Outlets</a>
                                            <Overlay target={target.current} show={show} placement="bottom">
                                                {(props) => (
                                                    <Tooltip className="TTPopup" id="overlay-example" {...props}>
                                                        <ul className="listgroup listGRP p-0 mb-0">
                                                            {outlets && outlets.map((outlet, index) => (
                                                                <li key={index} className="listgroup d-flex align-items-center px-4 py-3" onClick={() => alert(outlet.id)}>
                                                                    <FiMapPin size="26" color="var(--bs-white)" className="me-3" />
                                                                    <p className="w-75 text-start m-0">{outlet && outlet.address}</p>
                                                                    <BsFillCircleFill size="11" color="var(--bs-greenDark)" className="ms-3" />
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        <Button className="shadow" onClick={setClass}>
                                                            <BsXLg size="14" color="var(--bs-white)" />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                        </Col>
                                        <Col as="div" className="itemaddr pt-1">
                                            <FiMapPin size="20" color="var(--bs-border)" className="me-1" />
                                            <p className="d-inline semibold align-middle me-1">{outletDetails && outletDetails?.address}</p>
                                            <HiMap size="18" color="var(--bs-border)" className="me-4" />
                                            <p className="d-inline semibold align-middle me-md-5">
                                                <HiOutlineClock size="20" color="var(--bs-border)" className="me4" />
                                                {Math.round(outletDetails?.distance * 10) / 10} km
                                            </p>
                                            {/* <p className="d-inline semibold align-middle"><img src="/rating.jpg" alt="icon" /></p> */}
                                            <p className="semibold align-middle mb-1 mt-1 openHour"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseWidthExample"
                                                aria-expanded="false"
                                                aria-controls="collapseWidthExample"><strong>Opening Hours : Sunday, </strong> <span className="lighTx">{moment(`${outletDetails?.outletsTimeSlots[0]?.time_slots[0]?.start_time}`, 'hh').format('LT')} to {moment(`${outletDetails?.outletsTimeSlots[0]?.time_slots[0]?.end_time}`, 'hh').format('LT')}</span> <HiChevronDown size="30" color="var(--theme-color)" /></p>
                                            <div className="collapse collapse-vertical pb-2 w-md-75" id="collapseWidthExample">
                                                <div className="card card-body">
                                                    <ul className="list-group list-group-flush">
                                                        {outletDetails?.outletsTimeSlots.map((slot, index) => {
                                                            let slotsTime = slot.time_slots;
                                                            var dayName = '';
                                                            switch (slot.day) {
                                                                case 'sun':
                                                                    dayName = 'Sunday'
                                                                    break;
                                                                case 'mon':
                                                                    dayName = 'Monday'
                                                                    break;
                                                                case 'tue':
                                                                    dayName = 'Tuesday'
                                                                    break;
                                                                case 'wed':
                                                                    dayName = 'Wednesday'
                                                                    break;
                                                                case 'thur':
                                                                    dayName = 'Thursday'
                                                                    break;
                                                                case 'fri':
                                                                    dayName = 'Friday'
                                                                    break;
                                                                case 'sat':
                                                                    dayName = 'Saturday'
                                                                    break;
                                                                case 'eve_of_public_holiday':
                                                                    dayName = null
                                                                    break;
                                                                case 'public_holiday':
                                                                    dayName = null
                                                                    break;
                                                                default:
                                                                    break;
                                                            }
                                                            return dayName !== null &&
                                                                <li key={index} className="list-group-item">
                                                                    <strong>{dayName}, </strong>
                                                                    {slotsTime && slotsTime.map((slotTime, index) => {
                                                                        if (index <= 2) {
                                                                            return <span key={index}>{moment(`${slotTime.start_time}`, 'hh').format('LT')} to {moment(`${slotTime.end_time}`, 'hh').format('LT')}
                                                                                {((index + 1) < slotsTime?.length ? ' || ' : '')}</span>
                                                                        } else {
                                                                            return <></>
                                                                        }
                                                                    })}
                                                                </li>
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* <div className="hotelType">
                                                <span className="htl01 d-inline-block me-1 text-center">
                                                    <img src="/halal_icon.png" alt="icon" />
                                                </span>
                                                <span className="htl01 d-inline-block text-center">
                                                    <IoFishSharp className="mt-1" size="20" />
                                                </span>
                                            </div> */}
                                            {/* <div className="perraBox border border-1 shadow-sm p-3 mt-4 w-md-75">
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div> */}
                                        </Col>
                                    </div>
                                </Col>
                                {/* <Col md="3" className="text-md-end text-center mt-md-4 mt-2">
                                    <button type="button" className="mt-md-5 btn btnLG btn-danger text-white lh-lg text-nowrap"><span className="px-4">See All Reviews</span></button>
                                </Col> */}
                            </Row>
                        </div>
                    </Col>
                </Row>
                <br className='d-none d-sm-inline' /><br /><br />
                <Row className="RestroTabs">
                    <Col md={11} className="m-auto">
                        <div className="WrapRestro border-0">
                            <div className="mx-4 RestroAdd">

                                <CustomTabs tabs={tabList} selectedTab={selectedTabId} onTabSelection={onTabSelection}>
                                    {tabList?.map((child, index) => {
                                        const { id } = child;
                                        return (id == selectedTabId) &&
                                            <div key={index} className="tabs-content">
                                                <Row>
                                                    <Col md={11} className="m-auto">
                                                        <div className="mx-4 mainWrapper">
                                                            {
                                                                <Col as="section" className="outletDeals row">
                                                                    {/* <div className="heading inner mt-5 mb-4 pb-3">
                                                                        <h2 className="m-0 d-inline-block">Mains</h2>
                                                                    </div> */}
                                                                    {tabMenuItem && tabMenuItem.map((tab, idx) => {
                                                                        return (
                                                                            <div key={idx} className="card col-md-2 border-0 mt-5 mb-4 pb-3">
                                                                                <div className="imgBox position-relative">
                                                                                    <img src="/img01.png" className="img-fluid w-100" alt="food image" />
                                                                                    {/* <span className="brandLogo share rounded-pill">
                                                                                        <FiShare2 size={22} color="var(--theme-color)" />
                                                                                    </span>
                                                                                    <span className="brandLogo rounded-pill">
                                                                                        <FiHeart size={22} color="var(--theme-color)" />
                                                                                    </span> */}
                                                                                </div>
                                                                                <span className="offerPrice">
                                                                                    {/* <span>20% <i>Off</i></span> */}
                                                                                    {/* <img src="/offerTag.png" className="img-fluid BGTag" alt="food image" /> */}
                                                                                </span>
                                                                                <div className="card-body pt-2 px-0 prod_decription">
                                                                                    <div className="cafeName">
                                                                                        <span className="cafeImage position-relative">
                                                                                            {/* <img src="/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe image" /> */}
                                                                                            {/* <IoDiamond className='diamondIcon' size="15" color='var(--diamond)' /> */}
                                                                                        </span>
                                                                                        {/* <span className="cafeTitle ms-2">Home Cafe</span> */}
                                                                                        {/* <span className="cafeRating"><img src="/rating.jpg" className="img-fluid float-end mt-2" alt="food icon" /></span> */}
                                                                                    </div>
                                                                                    <div className="cafeMenu mt-2 mb-3">
                                                                                        {tab?.allergies && tab?.allergies.map((allergy, id) => (
                                                                                            <span key={id} className="menuTag me-3">{allergy}</span>
                                                                                        ))}
                                                                                    </div>
                                                                                    <h5 className="card-title m-0 fw-bold">{tab?.name}</h5>
                                                                                    {/* <div className="greenIcon mt-1"><GoClock size={22} color="var(--bs-greenDark)" /> 30 Min <GoPrimitiveDot className='dotIcon' size={12} color="var(--bs-greenDark)" /> 0.1km</div> */}
                                                                                    <div className="priceBox d-flex justify-content-between mt-3">
                                                                                        <div className="priceTotal">
                                                                                            <h3>S$ {tab?.base_price}</h3>
                                                                                            {/* <h5>S$ 12.99</h5> */}
                                                                                        </div>
                                                                                        <div className="cartTotal"><img src="/shoppingCart.svg" className="img-fluid" alt="shoppingCart Icon" /></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </Col>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                    })}
                                </CustomTabs>

                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>
            <Container fluid>
                <Row>
                    <Col className='p-0 bgRed'>
                        <section className="container-fluid">
                            <div className="row align-items-end">
                                <div className="col-10 m-auto d-flex align-items-end">
                                    <div className="colleft"><img src="/appStoreImage.png" className="img-fluid" alt="food image" /></div>
                                    <div className="colright textDown">
                                        <h2 className="mb-0">Download the app</h2>
                                        <div className="storeIcons position-static mb-5 pb-5">
                                            <a href="#" className="me-2"><img src="/googlePlay.png" className="img-fluid" /></a>
                                            <a href="#" className="ms-1"><img src="/appStore.png" className="img-fluid" /></a>
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Col>
                </Row>
                <Row>
                    <div className="ftrBG">
                        <div className="container">
                            <footer className="row pt-5 border-top">
                                <div className="col-md-4">
                                    <a href="/" className="d-flex align-items-center mb-3 link-dark text-decoration-none">
                                        <img src="/logo_footer.png" className="img-fluid" alt="footer logo" />
                                    </a>

                                    <div className="followUs mt-5">
                                        <h4 className="fw-bold">Follow Us</h4>
                                        <div className="d-flex mt-4">
                                            <img src="/twitterIcon.svg" className="img-fluid twitter" alt="Twitter" />
                                            <img src="/facebookIcon.svg" className="img-fluid facebook" alt="Facebook" />
                                            <img src="/instaIcon.svg" className="img-fluid insta" alt="Instagram" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <h5>Home </h5>
                                    <ul className="nav flex-column">
                                        <li className="nav-item"><a href="#" className="ftrLink">Download App</a></li>
                                    </ul>
                                </div>
                                <div className="col">
                                    <h5>Company</h5>
                                    <ul className="nav flex-column">
                                        <li className="nav-item"><a href="#" className="ftrLink">About</a></li>
                                        <li className="nav-item"><a href="#" className="ftrLink">Media</a></li>
                                        <li className="nav-item"><a href="#" className="ftrLink">Career</a></li>
                                        <li className="nav-item"><a href="#" className="ftrLink">Contact</a></li>
                                    </ul>
                                </div>
                                <div className="col">
                                    <h5>Diner</h5>
                                    <ul className="nav flex-column">
                                        <li className="nav-item"><a href="#" className="ftrLink">Sign Up</a></li>
                                        <li className="nav-item"><a href="#" className="ftrLink">Loyalty</a></li>
                                        <li className="nav-item"><a href="#" className="ftrLink">Faq’s</a></li>
                                    </ul>
                                </div>
                                <div className="col">
                                    <h5>Host</h5>
                                    <ul className="nav flex-column">
                                        <li className="nav-item"><a href="#" className="ftrLink">Sign Up</a></li>
                                        <li className="nav-item"><a href="#" className="ftrLink">Become A Host</a></li>
                                        <li className="nav-item"><a href="#" className="ftrLink">Incentive</a></li>
                                        <li className="nav-item"><a href="#" className="ftrLink">Prestige</a></li>
                                    </ul>
                                </div>
                            </footer>
                        </div>
                        <p className="bglight copyRight text-center mb-0 py-4">All Right Reserved. Copyright by Cribbly Pvt Ltd</p>
                    </div>
                </Row>
            </Container>
            <div className="BGoverlay"></div>
            <Modal show={showOutlet} onHide={handleClose} animation={true} centered className="outLetPopUp">
                <Modal.Header closeButton className="CloseButton position-absolute p-0 justify-content-center">
                    <BsXLg size="14" color="var(--bs-white)" />
                </Modal.Header>
                <Modal.Body className="p-0 imgSec">
                    <Col as="div">
                        <Card.Img variant="top" src="/outletPP.png" alt="map" />
                    </Col>
                </Modal.Body>
                <Modal.Footer className="justify-content-start ftroutlet ps-5">
                    <p className="areaLocation m-0"><FiMapPin size="32" color="var(--bs-grayDark)" /> 10 Bayfront Ave, Singapore 018956</p>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default OutletProfile