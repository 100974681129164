import React, { useState } from 'react'
import { Container, Row, Col, Image, Card, Form, Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { client } from '../../..';
import { User_CloseAccount } from '../../../queries/queries';
import { useMutation } from '@apollo/client';

const MyAccount04 = (params) => {
    const navigate = useNavigate();

    const CloseAccount = User_CloseAccount();
    const [CloseAccountManagement] = useMutation(CloseAccount);
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const handleClose01 = () => setShow01(false);
    const [token, setToken] = useState(localStorage.getItem("token"));
    // const [show01, setShow01] = useState(false);

    const [show01, setShow01] = useState(false);
    const [isLoading, setLoading] = useState(false);

    // const handleCloseAccount = async () => {
    //     console.log(params.question);
    //     let data = await CloseAccountManagement({
    //         variables: {
    //             reasion: params.question,
    //             status: true
    //         }
    //     })
    //     params.setQuestion(data);
    // }

    // const handleClose01 = () => {
    //     setShow01(false);
    //     setTimeout(() => {
    //         localStorage.removeItem("token")
    //         client.cache.reset()
    //         navigate('/loginpage');
    //     }, 3000);
    // }

    const handleSubmit = async () => {
        setLoading(true);
        let data = await CloseAccountManagement({
            variables: {
                reasion: params.question,
                status: true
            }
        })
        params.setQuestion(data);
        setLoading(false);
        if (data?.data) {
            if (data?.data?.User_CloseAccount?.status === "success") {
                setShow01(true);
                setTimeout(() => {
                    localStorage.removeItem("token")
                    client.cache.reset()
                    navigate('/loginpage');
                }, 3000);
            }
        } else {
            console.log("error", data?.data?.User_CloseAccount?.status);
        }

    }

    return (
        <>
            <Row className="ps-0 pinkgradiant pb-3">
                <Col lg="12" className="text-center mb-5"><Image src={process.env.PUBLIC_URL + "/assets/sadIcon.png"} alt="mainbanner" className="img-fluid" /></Col>
                <Col lg="12" className="text-center">
                    <h2 className="fs60 text-white semibold mb-5">We’re Sad To See You Go!</h2>
                    <Modal.Body className='d-flex flex-column w-25 m-auto justify-content-center align-items-center border08 text-center py-5 bgtheme'>
                        <Col className="container">
                            <Row className="justify-content-center">
                                <Col md="11" className="modal-content-inner">
                                    <Col as="span" className="rounded-circle d-block pb-5">
                                        <svg width="94" height="86" viewBox="0 0 94 86" fill="none" xmlns="http://www.w3.org/2000/svg" className="my-4">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M36.3686 6.90288C41.0327 -1.38885 52.9712 -1.38885 57.6352 6.90288L91.6751 67.4184C96.2495 75.5509 90.3727 85.5995 81.0415 85.5995H12.9619C3.63103 85.5995 -2.24589 75.5509 2.32868 67.4184L36.3686 6.90288ZM53.1011 67.3001C53.1011 70.6691 50.3701 73.4001 47.0011 73.4001C43.6323 73.4001 40.9012 70.6691 40.9012 67.3001C40.9012 63.931 43.6323 61.2001 47.0011 61.2001C50.3701 61.2001 53.1011 63.931 53.1011 67.3001ZM47.0011 18.5001C43.6323 18.5001 40.9012 21.2312 40.9012 24.6001V42.9001C40.9012 46.269 43.6323 49.0001 47.0011 49.0001C50.3701 49.0001 53.1011 46.269 53.1011 42.9001V24.6001C53.1011 21.2312 50.3701 18.5001 47.0011 18.5001Z" fill="white" />
                                        </svg>
                                    </Col>
                                    <Col as="p" className="colorwhite semibol fs24 lh-sm mb-0">
                                        You are about to permanently close and delete your account. If you intend to close it temporarily, consider suspending your account instead.
                                        By clicking 'Proceed', your account will be permanently deleted and this action is irreversible.
                                    </Col>
                                </Col>
                            </Row>
                            <Modal.Footer className="border-0 mt-4 mb-5 p-0 d-block">
                                <Col md="auto" className='text-center m-0'>
                                    <Button onClick={handleSubmit} variant="light" className="text-nowrap w-75 rounded-pill bgwhite py-2 px-5 themeColor border-0 fs26 ">
                                        {isLoading ? "Loading..." : "Proceed"}    
                                    </Button>
                                </Col>
                            </Modal.Footer>
                        </Col>
                    </Modal.Body>
                </Col>
            </Row>
            <Modal
                className="order-response"
                backdropClassName="action1"
                dialogClassName="action2 successfullyPop"
                contentClassName="action3 bgtheme border-0 border12"
                show={show01}
                centered
                onHide={handleClose01}>
                <Modal.Header closeButton className="border-0 text-white closeB flex-row-reverse position-relative">
                    <svg width="18" height="18" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.11438 21.1144C0.0729837 22.1558 0.0729837 23.8442 1.11438 24.8856C2.15578 25.927 3.84422 25.927 4.88562 24.8856L1.11438 21.1144ZM24.8856 4.88562C25.927 3.84422 25.927 2.15578 24.8856 1.11438C23.8442 0.0729837 22.1558 0.0729837 21.1144 1.11438L24.8856 4.88562ZM4.88562 1.11438C3.84422 0.0729837 2.15578 0.0729837 1.11438 1.11438C0.0729837 2.15578 0.0729837 3.84422 1.11438 4.88562L4.88562 1.11438ZM21.1144 24.8856C22.1558 25.927 23.8442 25.927 24.8856 24.8856C25.927 23.8442 25.927 22.1558 24.8856 21.1144L21.1144 24.8856ZM4.88562 24.8856L24.8856 4.88562L21.1144 1.11438L1.11438 21.1144L4.88562 24.8856ZM1.11438 4.88562L21.1144 24.8856L24.8856 21.1144L4.88562 1.11438L1.11438 4.88562Z" fill="white" />
                    </svg>
                </Modal.Header>
                <Modal.Body className='modal-input text-center m-0'>
                    <Col className="container">
                        <Row className="justify-content-center">
                            <Col md="11" className="modal-content-inner">
                                <Col as="span" className="rounded-circle d-block pb-5">
                                    <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M48.9992 97.8002C75.9509 97.8002 97.7992 75.9518 97.7992 49.0002C97.7992 22.0487 75.9509 0.200195 48.9992 0.200195C22.0477 0.200195 0.199219 22.0487 0.199219 49.0002C0.199219 75.9518 22.0477 97.8002 48.9992 97.8002ZM71.6125 41.1136C73.9946 38.7313 73.9946 34.8691 71.6125 32.4868C69.2305 30.1047 65.368 30.1047 62.9859 32.4868L42.8992 52.5736L35.0126 44.6868C32.6304 42.3047 28.7681 42.3047 26.3858 44.6868C24.0037 47.0691 24.0037 50.9315 26.3858 53.3135L38.5858 65.5135C40.9681 67.8956 44.8304 67.8956 47.2126 65.5135L71.6125 41.1136Z" fill="white" />
                                    </svg>
                                </Col>
                                <Col as="p" className="colorwhite semibol fs24 lh-sm mb-0">Your account has been successfully closed and deleted.</Col>
                            </Col>
                        </Row>
                    </Col>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default MyAccount04