import React from 'react'
import { Container, Row, Col, Breadcrumb, Accordion, Button } from 'react-bootstrap';
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { FaCircle } from 'react-icons/fa';
import "react-pro-sidebar/dist/css/styles.css";
import "./dinerstyle.css"
import ProfileMenu from "../components/profilemenu";
import Header from "../components/header";
import Footer from '../components/footer';
import AfterLoginHeader from '../components/afterLoginHeader';

const Faq02 = () => {

      return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <AfterLoginHeader />
                    </Col>
                </Row>
                {/* <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col> */}
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <ProfileMenu />
                    </Col>
                    <Col lg="9" as="div" className="userAcc membership pt-5 pe-3">
                        <h2 className="fs52 semibold ms-md-4 mb-0">Frequently Asked Questions</h2>
                        <Breadcrumb className="ms-md-4 mb-5">
                            <Breadcrumb.Item href="/">Support</Breadcrumb.Item>
                            <Breadcrumb.Item>Frequently Asked Questions</Breadcrumb.Item>
                        </Breadcrumb>
                        <Col as="section">
                            <Col className="outerspace">
                                <Col as="section" className="innerspace noMargin me-md-5">
                                    <Row className="ms-md-4 me-md-1">
                                        <Col className="faq02_wrapper p-0">
                                            <Accordion defaultActiveKey="0" className="acc_wrapper">
                                                <Accordion.Item eventKey="0" activeKey className="border-0 mb-4">
                                                    <Accordion.Header>In-App User Guide for Diner 
                                                    <FiChevronDown className="icondown" size="40" /> 
                                                    <FiChevronUp className="iconup" size="40" />
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam csunt nostrud amet noon sequat sunt nostrud amet nostrud amet.</Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Mollit non deserunt ullamco est sit aliqua dolor do amet Exercitation veniam consequat.</Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Deserunt amet minim mollit no ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam aliqua dolordo amet ist amet veniam consequat sunt nostrud amet. Mollit non deserunt ullamco est sit aliqua dolor dont ullamco est sit aliqua dolor do ametsintduis enim Exercitation veniam.</Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Deserunt amet minim mollit no ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam aliqua dolordo amet ist amet veniam consequat sunt nostrud amet. Mollit non deserunt ullamco est sit aliqua dolor dont ullamco est sit aliqua dolor do ametsintduis enim Exercitation veniam.</Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam csunt nostrud amet noon sequat sunt nostrud amet nostrud amet.</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Serunt amet minim molli mollit no ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam aliqua dolordo amet ist amet ven.</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="text-end mt-4">
                                                                <Button variant="danger" className="fs22 rounded-pill px-4">Learn More</Button>
                                                            </Col>                                                        
                                                        </Row>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1" className="border-0 mb-4">
                                                    <Accordion.Header>
                                                        RMBL&#8217;s Platform Rules and Regulations, for Diner.
                                                        <FiChevronDown className="icondown" size="40" /> 
                                                        <FiChevronUp className="iconup" size="40" />
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam csunt nostrud amet noon sequat sunt nostrud amet nostrud amet.</Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Mollit non deserunt ullamco est sit aliqua dolor do amet Exercitation veniam consequat.</Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Deserunt amet minim mollit no ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam aliqua dolordo amet ist amet veniam consequat sunt nostrud amet. Mollit non deserunt ullamco est sit aliqua dolor dont ullamco est sit aliqua dolor do ametsintduis enim Exercitation veniam.</Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Deserunt amet minim mollit no ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam aliqua dolordo amet ist amet veniam consequat sunt nostrud amet. Mollit non deserunt ullamco est sit aliqua dolor dont ullamco est sit aliqua dolor do ametsintduis enim Exercitation veniam.</Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam csunt nostrud amet noon sequat sunt nostrud amet nostrud amet.</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Serunt amet minim molli mollit no ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam aliqua dolordo amet ist amet ven.</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="text-end mt-4">
                                                                <Button variant="danger" className="fs22 rounded-pill px-4">Learn More</Button>
                                                            </Col>                                                        
                                                        </Row>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2" className="border-0 mb-4">
                                                    <Accordion.Header>
                                                        Exercitation veniam aliqua dolor do amet.
                                                        <FiChevronDown className="icondown" size="40" /> 
                                                        <FiChevronUp className="iconup" size="40" />
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam csunt nostrud amet noon sequat sunt nostrud amet nostrud amet.</Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Mollit non deserunt ullamco est sit aliqua dolor do amet Exercitation veniam consequat.</Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Deserunt amet minim mollit no ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam aliqua dolordo amet ist amet veniam consequat sunt nostrud amet. Mollit non deserunt ullamco est sit aliqua dolor dont ullamco est sit aliqua dolor do ametsintduis enim Exercitation veniam.</Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Deserunt amet minim mollit no ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam aliqua dolordo amet ist amet veniam consequat sunt nostrud amet. Mollit non deserunt ullamco est sit aliqua dolor dont ullamco est sit aliqua dolor do ametsintduis enim Exercitation veniam.</Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam csunt nostrud amet noon sequat sunt nostrud amet nostrud amet.</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Serunt amet minim molli mollit no ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam aliqua dolordo amet ist amet ven.</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="text-end mt-4">
                                                                <Button variant="danger" className="fs22 rounded-pill px-4">Learn More</Button>
                                                            </Col>                                                        
                                                        </Row>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3" className="border-0 mb-4">
                                                    <Accordion.Header>
                                                        Deserunt amet minim mollit no ullamco est sit aliqua.
                                                        <FiChevronDown className="icondown" size="40" /> 
                                                        <FiChevronUp className="iconup" size="40" />
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam csunt nostrud amet noon sequat sunt nostrud amet nostrud amet.</Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Mollit non deserunt ullamco est sit aliqua dolor do amet Exercitation veniam consequat.</Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Deserunt amet minim mollit no ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam aliqua dolordo amet ist amet veniam consequat sunt nostrud amet. Mollit non deserunt ullamco est sit aliqua dolor dont ullamco est sit aliqua dolor do ametsintduis enim Exercitation veniam.</Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Deserunt amet minim mollit no ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam aliqua dolordo amet ist amet veniam consequat sunt nostrud amet. Mollit non deserunt ullamco est sit aliqua dolor dont ullamco est sit aliqua dolor do ametsintduis enim Exercitation veniam.</Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam csunt nostrud amet noon sequat sunt nostrud amet nostrud amet.</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="auto"><FaCircle size="12" /></Col>
                                                            <Col className="fs20 semibold gray82 lh-sm">Serunt amet minim molli mollit no ullamco est sit aliqua dolor do amet sintduis enim Exercitation veniam aliqua dolordo amet ist amet ven.</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="text-end mt-4">
                                                                <Button variant="danger" className="fs22 rounded-pill px-4">Learn More</Button>
                                                            </Col>                                                        
                                                        </Row>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Col>                                        
                                    </Row>
                                </Col>
                            </Col>
                        </Col>                        
                        <Col lg="12" className="p-lg-4">&nbsp;</Col>
                    </Col>
                </Row>        
            </Container>
            <br /><br />
            <Footer />
        </div>
    )    
}
export default Faq02