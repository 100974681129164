import { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form, FloatingLabel, ListGroup } from 'react-bootstrap';
import Slider from "react-slick";
import { FiChevronDown, FiEye } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import "../../hoststep.css";
import Footer from '../../../components/footer';
import AfterLoginHeader from '../../../components/afterLoginHeader';
import MenuSidebar from '../../../components/menusidebar';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HostProfile from "./hostprofile";
import { TiPlus, TiWarning } from "react-icons/ti";
import { AiFillCloseCircle } from "react-icons/ai";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { DeleteCribDoc, deleteCribTables, deleteCribTimeSlot, getCribDetails, GetLocationbySglocate, UpdateCrib, UpdateCribStatus } from "../../../queries/queries";
import * as Yup from "yup";
import { FieldArray, Formik, useFormik } from "formik";
import { CRIB_ACCOUNT_CLOSE, CRIB_ACCOUNT_PUBLISH, CRIB_ACCOUNT_UNPUBLISH, CRIB_DAYS_KEY } from "../../../utils/staticData";
import TimePicker from "rc-time-picker";
import moment from "moment";
import AlertPopup from "../../../components/alertPopup";
import { HiCheckCircle, HiOutlineTrash, HiOutlineUpload } from "react-icons/hi";
import { BsDot } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";
import { MdDelete } from "react-icons/md";
import { DefaultPdfIcon } from "../../../utils/defaultSvgIcons";


const HostCribTwo = () => {

    /*********** States & Constants  ********/
    const TimeSlotLimit = 3;
    const settings = {
        dots: true,
        infinite: true,
        swipeToSlide: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        easing: 'cubic',

        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    };
    const { id } = useParams();
    const [alpha, setAlpha] = useState();
    const [alpha01, setAlpha01] = useState();
    const [seatingSystem, setSeatingSystem] = useState(false);
    const [propertyOwner, setPropertyOwner] = useState(false);
    const [docUpload, setDocUpload] = useState(null);
    const [docImg, setDocImg] = useState('');
    const [docPdf, setDocPdf] = useState(null);
    const [edit, setEdit] = useState(true);
    const [crib, setCrib] = useState('');
    const [statusActive, setStatusActive] = useState(true);
    const [timeSlots, setTimeSlots] = useState({});
    const [outletTables, setOutletTables] = useState([]);
    const UpdateCribGql = UpdateCrib();
    const [updateCribmutate] = useMutation(UpdateCribGql);
    const [popupMessage, setPopupMessage] = useState({});
    const [onShow, setOnShow] = useState(false);
    const [cribStatus, setCribStatus] = useState('');
    const [removeTimeSlotId, setRemoveTimeSlotId] = useState(null);
    const [timeSlotssettings, settimeSlotssettings] = useState({});
    const [removeTablesId, setRemoveTablesId] = useState(null);
    const [tableDatasettings, setTableDatasettings] = useState({});
    const navigate = useNavigate();
    const [evePublicHours, setEvePublicHours] = useState(false);
    const [publicHours, setPublicHours] = useState(false);
    const cribsGql = getCribDetails();
    const statusGql = UpdateCribStatus();
    const [updateStatusmutate] = useMutation(statusGql);

    const cribTimeSlot = deleteCribTimeSlot();
    const [cribTimeSlotDelete] = useMutation(cribTimeSlot);

    const CribTables = deleteCribTables();
    const [CribTablesDelete] = useMutation(CribTables);

    const CribDocRemove = DeleteCribDoc();
    const [DocRemove] = useMutation(CribDocRemove);

    const { loading, data, error, refetch } = useQuery(cribsGql, {
        variables: {
            cribId: Number(id)
        }
    });


    const [isLoading, setLoading] = useState(false);

    const defaultSlots = {
        "sun": [{
            "start_time": "",
            "end_time": "",
        }],
        "mon": [{
            "start_time": "",
            "end_time": "",
        }],
        "tue": [{
            "start_time": "",
            "end_time": "",
        }],
        "wed": [{
            "start_time": "",
            "end_time": "",
        }],
        "thur": [{
            "start_time": "",
            "end_time": "",
        }],
        "fri": [{
            "start_time": "",
            "end_time": "",
        }],
        "sat": [{
            "start_time": "",
            "end_time": "",
        }],
        "eve_of_public_holiday": [{
            "start_time": "",
            "end_time": "",
        }],
        "public_holiday": [{
            "start_time": "",
            "end_time": "",
        }]
    };
    const defaultTables = [
        {
            "table_no": 1,
            "seats": 0
        },
        {
            "table_no": 2,
            "seats": 0
        }
    ];

    const initialValues = {
        "id": id,
        "name": !loading ? crib?.name ? crib?.name : "" : "Loading...",
        "crib_status": !loading ? crib?.status ? crib?.status : "" : "Loading...",
        "is_dinein": !loading ? crib?.is_dinein ? crib?.is_dinein : "" : "Loading...",
        "floor_no": !loading ? crib?.floor_no ? crib?.floor_no : "" : "Loading...",
        "unit_no": !loading ? crib?.unit_no ? crib?.unit_no : "" : "Loading...",
        "postal_code": !loading ? crib?.postal_code ? crib?.postal_code : "" : "Loading...",
        "pickup_collection_inst": !loading ? crib?.pickup_collection_inst ? crib?.pickup_collection_inst : '' : "Loading...",
        "dinein_collection_inst": !loading ? crib?.dinein_collection_inst ? crib?.dinein_collection_inst : '' : "Loading...",
        "is_pickup": !loading ? crib?.is_pickup ? crib?.is_pickup : "" : "Loading...",
        "is_verified": !loading ? crib?.is_verified : false,
        "address": !loading ? crib?.address ? crib?.address : "" : "Loading...",
        "country": !loading ? crib?.country ? crib?.country : "" : "Loading...",
        "outlet_tables": outletTables.length > 0 ? outletTables : defaultTables,
        "outletsTimeSlots": Object.keys(timeSlots).length > 0 ? timeSlots : defaultSlots,
        "verification_doc": crib?.verification_doc ? crib?.verification_doc : null,
        "building_name": crib?.building_name ? crib?.building_name : '',
        "seating_system": crib?.seating_system ? crib?.seating_system : false
    }

    /***************************Formik validation schema *******************/
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        floor_no: Yup.number().typeError("Floor must be a number").required("Floor Number is required"),
        unit_no: Yup.number().typeError("Unit must be a number").required("Unit Number is required"),
        postal_code: Yup.number().typeError("Postal code must be a number").required("Postal Code is required"),
        address: Yup.string().required("Address is required"),
        building_name: Yup.string().required("Building name is required"),
        country: Yup.string().required("Country is required"),
        outlet_tables: Yup.array().of(
            Yup.object().shape({
                seats: Yup.number()
                    .typeError("Must be a number")
                    .required("Seats is required"),
            })
        ).min(1, "Atleast two Option required"),
        outletsTimeSlots: Yup.object().shape({
            sun: Yup.array().of(
                Yup.object().shape({
                    start_time: Yup.string().required("Start time is required"),
                    end_time: Yup.string().required("End time is required")
                        .when('start_time', (start_time, schema) => {
                            return schema.test({
                                test: end_time => start_time < end_time,
                                message: "Please choose correct End time"
                            })
                        }),
                })),
            mon: Yup.array().of(
                Yup.object().shape({
                    start_time: Yup.string().required("Start time is required"),
                    end_time: Yup.string().required("End time is required")
                        .when('start_time', (start_time, schema) => {
                            return schema.test({
                                test: end_time => start_time < end_time,
                                message: "Please choose correct End time"
                            })
                        }),
                })),
            tue: Yup.array().of(
                Yup.object().shape({
                    start_time: Yup.string().required("Start time is required"),
                    end_time: Yup.string().required("End time is required")
                        .when('start_time', (start_time, schema) => {
                            return schema.test({
                                test: end_time => start_time < end_time,
                                message: "Please choose correct End time"
                            })
                        }),
                })),
            wed: Yup.array().of(
                Yup.object().shape({
                    start_time: Yup.string().required("Start time is required"),
                    end_time: Yup.string().required("End time is required")
                        .when('start_time', (start_time, schema) => {
                            return schema.test({
                                test: end_time => start_time < end_time,
                                message: "Please choose correct End time"
                            })
                        }),
                })),
            thur: Yup.array().of(
                Yup.object().shape({
                    start_time: Yup.string().required("Start time is required"),
                    end_time: Yup.string().required("End time is required")
                        .when('start_time', (start_time, schema) => {
                            return schema.test({
                                test: end_time => start_time < end_time,
                                message: "Please choose correct End time"
                            })
                        }),
                })),
            fri: Yup.array().of(
                Yup.object().shape({
                    start_time: Yup.string().required("Start time is required"),
                    end_time: Yup.string().required("End time is required")
                        .when('start_time', (start_time, schema) => {
                            return schema.test({
                                test: end_time => start_time < end_time,
                                message: "Please choose correct End time"
                            })
                        }),
                })),
            sat: Yup.array().of(
                Yup.object().shape({
                    start_time: Yup.string().required("Start time is required"),
                    end_time: Yup.string().required("End time is required")
                        .when('start_time', (start_time, schema) => {
                            return schema.test({
                                test: end_time => start_time < end_time,
                                message: "Please choose correct End time"
                            })
                        }),
                })),
        }),
    });
    /***************************Api Call delete ************************/
    const handlecribTimeSlot = async (e) => {
        try {
            timeSlotssettings?.options[timeSlotssettings?.day].splice(timeSlotssettings?.index, 1)
            timeSlotssettings?.setFieldValue('outletsTimeSlots', timeSlotssettings?.options)
            timeSlotssettings?.setFieldTouched(`outletsTimeSlots.${timeSlotssettings?.options.length}`, false, false)
            if (removeTimeSlotId !== null) {
                let data = await cribTimeSlotDelete({
                    variables: {
                        id: removeTimeSlotId,
                    }
                })
                if (data.data.deleteCribTimeSlot.status === "success") {
                    setPopupMessage({
                        status: data.data.deleteCribTimeSlot.status,
                        message: data.data.deleteCribTimeSlot.message
                    })

                } else {
                    setPopupMessage({
                        status: data.data.deleteCribTimeSlot.status,
                        message: data.data.deleteCribTimeSlot.message
                    })
                }
            }
        } catch (error) {
            console.log(error);
        }
        //   setTimeout(() => {
        setOnShow(false);
        //   }, 600)
    }

    const handleCribTablesDelete = async (e) => {
        try {
            let tempOptions = [...tableDatasettings?.options];
            tempOptions.splice(tableDatasettings?.index, 1)
            tableDatasettings?.setFieldValue('outlet_tables', tempOptions)
            tableDatasettings?.setFieldTouched(`outlet_tables.${tempOptions.length}`, false, false)
            if (removeTablesId !== null) {
                const deleteData = await CribTablesDelete({
                    variables: {
                        id: removeTablesId
                    }
                })
                if (deleteData.data.deleteCribTables.status === "success") {
                    setPopupMessage({
                        status: deleteData.data.deleteCribTables.status,
                        message: deleteData.data.deleteCribTables.message
                    })
                    setOnShow(true)
                    setTimeout(() => {
                        setOnShow(false);
                    }, 500)
                } else {
                    setPopupMessage({
                        status: deleteData.data.deleteCribTables.status,
                        message: deleteData.data.deleteCribTables.message
                    })
                    setOnShow(true)
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    /***************************Functions & Methods ************/
    const addMoreTables = (tableNo, options, setFieldValue) => {
        let tempOptions = [...options];
        tempOptions.push({ id: null, table_no: tableNo, seats: "" })
        setFieldValue('outlet_tables', tempOptions)
    }
    const removeTables = (index, id, options, setFieldValue, setFieldTouched) => {
        setPopupMessage({
            status: "remove-tables",
            message: "Are you sure you want to remove table data your Crib?",
        })
        setRemoveTablesId(id);
        setOnShow(true);
        setTableDatasettings({
            options,
            index,
            setFieldValue,
            setFieldTouched
        })
        // let tempOptions = [...options];
        // tempOptions.splice(index, 1)
        // setFieldValue('outlet_tables', tempOptions)
        // setFieldTouched(`outlet_tables.${tempOptions.length}`, false, false)
    }

    let addTimeFormFields = (day, options, setFieldValue) => {
        if (options[day].length > 0) {
            options[day].push({ id: null, start_time: "", end_time: "" })
        }
        setFieldValue('outletsTimeSlots', options)
    }
    const removeTimeOptions = (index, id, day, options, setFieldValue, setFieldTouched) => {
        setPopupMessage({
            status: "remove-time",
            message: "Are you sure you want to time slots delete data your Crib?",
        })
        setRemoveTimeSlotId(id);
        setOnShow(true);
        settimeSlotssettings({
            options,
            day,
            setFieldValue,
            setFieldTouched,
            index
        })
        // options[day].splice(index, 1)
        // setFieldValue('outletsTimeSlots', options)
        // setFieldTouched(`outletsTimeSlots.${options.length}`, false, false)
    }

    const checkday = (day) => {
        return CRIB_DAYS_KEY[day]
    }

    const getCheckedValue = (e) => {
        switch (e.target.name) {
            case "is_pickup":
                e.target.checked ? setAlpha("On") : setAlpha("Off")
                break;
            case "is_dinein":
                e.target.checked ? setAlpha01("On") : setAlpha01("Off")
                break;
            case "seating_system":
                e.target.checked ? setSeatingSystem(true) : setSeatingSystem(false)
                break;
            default:
        }
    }


    const handleOperatingHours = (e) => {
        switch (e.target.name) {
            case "eve_public_hours":
                e.target.checked ? setEvePublicHours(true) : setEvePublicHours(false)
                break;
            case "is_public_hours":
                e.target.checked ? setPublicHours(true) : setPublicHours(false)
                break;
            default:
        }
    }

    const handleSuspendAcc = async (e) => {
        e.preventDefault();
        setPopupMessage({
            status: "crib-status",
            message: `Are you sure you want to ${statusActive ? "Publish" : "Unpublish"} your Crib?`
        })
        if (statusActive) {
            setCribStatus(CRIB_ACCOUNT_PUBLISH.value)
        } else {
            setCribStatus(CRIB_ACCOUNT_UNPUBLISH.value)
        }
        setOnShow(true);
    }

    const handleDeleteAcc = async (e) => {
        e.preventDefault();
        setPopupMessage({
            status: "delete-crib",
            message: "Are you sure you want to Delete your Crib?"
        })
        setCribStatus(CRIB_ACCOUNT_CLOSE)
        setOnShow(true);
    }

    const handleConfirmStatus = async (e) => {
        try {
            const updateData = await updateStatusmutate({
                variables: {
                    outlet_id: Number(id),
                    crib_status: cribStatus,
                }
            })

            if (updateData.data.updateCribStatus.status === "success") {
                setPopupMessage({
                    status: updateData.data.updateCribStatus.status,
                    message: updateData.data.updateCribStatus.message
                })
                setStatusActive(!statusActive)
                setOnShow(true)
                setTimeout(() => {
                    navigate('/host/cribs');
                }, 3000)
            } else {
                setPopupMessage({
                    status: updateData.data.updateCribStatus.status,
                    message: updateData.data.updateCribStatus.message
                })
                setOnShow(true)
            }

        } catch (err) {
            console.log(err);
        }
    }


    const handelFormSubmit = async (values) => {
        const outlet_time_slots = [];
        Object.keys(values.outletsTimeSlots).forEach((element) => {
            outlet_time_slots.push({ day: element, time: values.outletsTimeSlots[element] })
        })
        try {
            setLoading(true);
            const updateData = await updateCribmutate({
                variables: {
                    id: Number(id),
                    name: values.name,
                    crib_status: values.crib_status,
                    is_dinein: values.is_dinein,
                    floor_no: values.floor_no,
                    unit_no: values.unit_no,
                    postal_code: values.postal_code,
                    pickup_collection_inst: values.pickup_collection_inst,
                    dinein_collection_inst: values.dinein_collection_inst,
                    is_pickup: values.is_pickup,
                    address: values.address,
                    country: values.country,
                    lat: "123.44",
                    long: "124.44",
                    outlet_time_slots: outlet_time_slots,
                    outlet_tables: values.outlet_tables,
                    building_name: values.building_name,
                    seating_system: seatingSystem,
                    verification_doc: (typeof docUpload ? docUpload : null),
                    outlet_property: String(propertyOwner)
                }
            })
            setLoading(false)

            if (updateData.data.updateCrib.status === "success") {
                setSeatingSystem(updateData?.data?.updateCrib?.data?.seating_system);
                setPropertyOwner(updateData?.data?.updateCrib?.data?.outlet_property)
                setPopupMessage({
                    status: updateData.data.updateCrib.status,
                    message: updateData.data.updateCrib.message
                })
                setOnShow(true)
                setTimeout(() => {
                    navigate('/host/cribs');
                }, 3000)
            } else {
                setPopupMessage({
                    status: updateData.data.updateCrib.status,
                    message: updateData.data.updateCrib.message
                })
                setOnShow(true)
            }
        } catch (err) {
            if (err.message == 'Failed to fetch') {
                setLoading(true);
                setPopupMessage({
                    status: 'error',
                    message: 'Failed to fetch'
                })
                setOnShow(true)
                setLoading(false);
            }
            else {
                setPopupMessage({
                    status: 'error',
                    message: "Something went wrong"
                })
                setOnShow(true)
            }
        }
    };

    /****************************Use Effects *************/
    useEffect(() => {
        if (data) {
            if (data?.getCribDetails?.status == "success") {
                setSeatingSystem(data?.getCribDetails.data?.seating_system);
                setPropertyOwner((data?.getCribDetails.data?.outlet_property) === "true");
                setDocImg(data?.getCribDetails.data?.verification_doc ? process.env.REACT_APP_BACKEND_IMAGE_URL + data?.getCribDetails.data?.verification_doc : null);
                let newdata = (data?.getCribDetails.data);
                setCrib(newdata);
                let cribStatus = newdata?.status === CRIB_ACCOUNT_PUBLISH.value ? false : true;
                setStatusActive(cribStatus)
                let slotsData = newdata?.outletsTimeSlots.length > 0 ? {
                    "sun": [],
                    "mon": [],
                    "tue": [],
                    "wed": [],
                    "thur": [],
                    "fri": [],
                    "sat": [],
                    "eve_of_public_holiday": [],
                    "public_holiday": []
                } : defaultSlots;
                newdata.outletsTimeSlots.map((element) => {
                    slotsData[element.day].push(
                        {
                            id: element.id,
                            start_time: element.start_time,
                            end_time: element.end_time
                        })
                })
                setTimeSlots(slotsData);
                const OutletTablesTypename = newdata?.outletTables.map(({ __typename, ...rest }) => {
                    return rest;
                });
                setOutletTables(OutletTablesTypename)
            }
        }
        if (error) {
            console.log("Something went wrong");
        }
    }, [data, error]);

    useEffect(() => {
        refetch();
    }, [])

    const backToCribbtn = () => {
        navigate(`/host/cribs`);
    }


    const getCheckedValueNew = (e) => {
        switch (e.target.name) {
            case "outlet_property":
                e.target.checked ? setPropertyOwner(true) : setPropertyOwner(false)
                break;
            default:
                break;
        }
    }

    const getUploadDocument = (e) => {
        if (e.target.files[0].type == "application/pdf") {
            let ImageUrl = URL.createObjectURL(e.target.files[0]);
            let ImageName = (e.target.files[0]);
            setDocUpload(ImageName)
            setDocPdf(ImageUrl)
            setDocImg(null)
        } else if (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
            let ImageUrl = URL.createObjectURL(e.target.files[0]);
            let ImageName = (e.target.files[0]);
            setDocUpload(ImageName)
            setDocImg(ImageUrl)
            setDocPdf(null)
        } else {
            console.log("file not supported")
        }
    }

    const formProps = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handelFormSubmit
    })

    const GetSglocate = GetLocationbySglocate()
    const [GetSglocateData] = useLazyQuery(GetSglocate)

    const handleAddressFields = async (fieldName, fieldValue) => {
        const result = await GetSglocateData({
            variables: {
                postcode: fieldName === "postal_code" ? fieldValue : null,
                streetName: fieldName === "address" ? fieldValue : fieldName === "unit_no" ? formProps?.values?.address : null,
                blockNumber: fieldName === "unit_no" ? fieldValue : fieldName === "address" ? formProps?.values?.unit_no : null
            }
        });
        const ResultData = result?.data?.getLocationbySglocate?.data
        if (ResultData?.IsSuccess === true && ResultData?.Postcodes.length !== 0) {
            let data = ResultData?.Postcodes[0]
            formProps.setFieldValue("address", data?.StreetName)
            formProps.setFieldValue("building_name", data?.BuildingName)
            formProps.setFieldValue("postal_code", data?.Postcode)
        } else {
            console.log({
                ErrorCode: ResultData.ErrorCode,
                ErrorMessage: ResultData.ErrorDetails
            })
        }
    }

    const handleGetSglocateAPI = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if ((name === "address" && value.length >= 3) && (formProps.values.unit_no.length != 0)) {
            handleAddressFields(name, value)
        } else if ((name === "unit_no" && value.length != 0) && (formProps.values.address.length >= 3)) {
            handleAddressFields(name, value)
        } else if (name === 'postal_code' && value.length >= 6) {
            handleAddressFields(name, value)
        }
    }

    const deleteCribDoc = async () => {
        if (docImg?.slice(0, 4) === "blob") {
            setDocImg(null)
        } else if (docPdf?.slice(0, 4) === "blob") {
            setDocPdf(null)
        } else {
            let result = await DocRemove({ variables: { "cribId": Number(id) } })
            console.log("doc deleted", result)
        }
    }

    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <AfterLoginHeader />
                    </Col>
                </Row>
                {/* <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col> */}
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu rightshadow outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <MenuSidebar />
                    </Col>

                    <Col lg="9">
                        <Formik>

                            <Form className="accForm px-lg-2 px-xl-0" onSubmit={formProps.handleSubmit}>
                                <Col as="section" className='page-heading-top my-4 py-1 px-0'>
                                    <Col as="div" className='container px-xl-5 px-0'>
                                        <Row className='align-items-center justify-content-between align-items-md-start'>
                                            <HostProfile />
                                            <Col md="auto" className='text-md-end py-md-4 mt-2 text-center'>
                                                {edit ?
                                                    <Button type="button" variant="light" className="text-nowrap rounded-pill bgtheme py-2 px-4 text-white border-0 fs20 " onClick={(e) => { e.preventDefault(); setEdit(false) }}>
                                                        <svg className='align-text-bottom me-2' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M22.4142 1.58577L22.4142 1.58582C23.1953 2.36684 23.1953 3.63313 22.4142 4.41418L22.4142 4.41421L11.3284 15.5H8.5V12.6716L19.5858 1.58579L19.5858 1.58577C20.3668 0.804745 21.6332 0.804745 22.4142 1.58577Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M3 5C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18C18.5523 22 19 21.5523 19 21V15C19 14.7238 19.2238 14.5 19.5 14.5C19.7762 14.5 20 14.7238 20 15V21C20 22.1046 19.1046 23 18 23H3C1.89542 23 1 22.1046 1 21V6C1 4.89543 1.89543 4 3 4H9C9.27614 4 9.5 4.22386 9.5 4.5C9.5 4.77614 9.27614 5 9 5H3Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        Edit Item
                                                    </Button>
                                                    :
                                                    <Button type="submit" variant="light" className="text-nowrap rounded-pill bgtheme py-2 px-4 text-white border-0 fs20 " disabled={isLoading}>
                                                        <svg className='align-text-bottom me-2' width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.75 21.1C17.3713 21.1 17.9683 20.8649 18.4097 20.4448C18.8511 20.025 19.1 19.4539 19.1 18.8572V7.20653C19.1 6.73443 18.9441 6.27474 18.6544 5.89324C18.1899 5.27873 17.2367 4.019 16.4389 2.96458C16.1118 2.53225 15.8108 2.13444 15.5803 1.82977L15.5802 1.82972C15.1843 1.30715 14.5757 0.975784 13.9123 0.911567L13.9244 0.9H13.6751H3.25005C1.95668 0.9 0.9 1.89957 0.9 3.14292V18.8572C0.9 20.1006 1.95674 21.1 3.25005 21.1H16.75ZM3.89696 2.52857L3.89703 4.5716C3.89703 5.81479 4.95372 6.81436 6.24692 6.81436H10.747C12.0402 6.81436 13.0969 5.81483 13.0969 4.5716V2.52869H13.675C13.8862 2.52869 14.082 2.62557 14.2035 2.78555L17.2784 6.84904L17.2785 6.84915C17.3577 6.95342 17.3999 7.07868 17.3999 7.20649V18.8572C17.3999 19.0182 17.3321 19.1745 17.2112 19.2897L17.2802 19.3621L17.2112 19.2897C17.0892 19.4059 16.9237 19.4714 16.7499 19.4714H3.24997C2.88575 19.4714 2.59991 19.1918 2.59991 18.8572V3.14292C2.59991 2.80831 2.88576 2.52857 3.24997 2.52857H3.89696ZM5.59704 2.52857H11.3972V4.57149C11.3972 4.90589 11.1106 5.18568 10.7473 5.18568H6.24717C5.88387 5.18568 5.59728 4.90587 5.59728 4.57149V4.57148L5.59704 2.52857ZM16.0968 13.143C16.0968 11.8996 15.0401 10.9 13.7469 10.9H6.24676C4.95355 10.9 3.89687 11.8996 3.89687 13.143V16.0001C3.89687 17.2435 4.95361 18.2429 6.24676 18.2429H13.7469C15.0401 18.2429 16.0968 17.2434 16.0968 16.0001V13.143ZM14.3968 13.143V16.0001C14.3968 16.3346 14.1102 16.6143 13.7469 16.6143H6.24676C5.88344 16.6143 5.59687 16.3346 5.59687 16.0001V13.143C5.59687 12.8084 5.88349 12.5286 6.24676 12.5286H13.7469C14.1102 12.5286 14.3968 12.8084 14.3968 13.143Z" fill="white" stroke="white" strokeWidth="0.2" />
                                                        </svg>
                                                        {isLoading ? "Loading..." : "Save Changes"}
                                                    </Button>
                                                }
                                                {edit ? <Button
                                                    variant="light"
                                                    className="text-nowrap rounded-pill menuTag py-2 px-4 text-black border-0 fs20 ms-2 bgLight"
                                                    onClick={backToCribbtn}>
                                                    <svg className="me-2" width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.43934 17.0607C8.02513 17.6464 8.97487 17.6464 9.56066 17.0607C10.1464 16.4749 10.1464 15.5251 9.56066 14.9393L7.43934 17.0607ZM2.56066 7.93934C1.97487 7.35355 1.02513 7.35355 0.43934 7.93934C-0.146447 8.52513 -0.146447 9.47487 0.43934 10.0607L2.56066 7.93934ZM0.43934 7.93934C-0.146447 8.52513 -0.146447 9.47487 0.43934 10.0607C1.02513 10.6464 1.97487 10.6464 2.56066 10.0607L0.43934 7.93934ZM9.56066 3.06066C10.1464 2.47487 10.1464 1.52513 9.56066 0.93934C8.97487 0.353553 8.02513 0.353553 7.43934 0.93934L9.56066 3.06066ZM1.5 7.5C0.671573 7.5 0 8.17157 0 9C0 9.82843 0.671573 10.5 1.5 10.5V7.5ZM19.5 10.5C20.3284 10.5 21 9.82843 21 9C21 8.17157 20.3284 7.5 19.5 7.5V10.5ZM9.56066 14.9393L2.56066 7.93934L0.43934 10.0607L7.43934 17.0607L9.56066 14.9393ZM2.56066 10.0607L9.56066 3.06066L7.43934 0.93934L0.43934 7.93934L2.56066 10.0607ZM1.5 10.5H19.5V7.5H1.5V10.5Z" fill="var(--bs-grayDark51)" />
                                                    </svg>
                                                    Back to My Crib
                                                </Button> :
                                                    <Button
                                                        variant="light"
                                                        className="text-nowrap rounded-pill menuTag py-2 px-4 text-black border-0 fs20 "
                                                        onClick={formProps.handleReset}
                                                    >
                                                        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M0.910046 0.511609C1.45679 -0.0351195 2.34321 -0.0351195 2.88995 0.511609L8.9 6.52167L14.9101 0.511609C15.4568 -0.0351195 16.3432 -0.0351195 16.8899 0.511609C17.4366 1.05835 17.4366 1.94477 16.8899 2.49152L10.8799 8.50156L16.8899 14.5116C17.4366 15.0583 17.4366 15.9448 16.8899 16.4915C16.3432 17.0382 15.4568 17.0382 14.9101 16.4915L8.9 10.4814L2.88995 16.4915C2.34321 17.0382 1.45679 17.0382 0.910046 16.4915C0.363318 15.9448 0.363318 15.0583 0.910046 14.5116L6.92011 8.50156L0.910046 2.49152C0.363318 1.94477 0.363318 1.05835 0.910046 0.511609Z" fill="#374151" />
                                                        </svg>&nbsp;
                                                        Discard Changes
                                                    </Button>}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Col>
                                <Col as="section" className='ms-0 ms-xl-5 ps-xl-5 mb-5 ps-0 '>
                                <Col className={`container px-xl-2 px-xxl-5 px-lg-4 px-0 ${edit ? 'opacity-50 not-allowed' : ''}`}>
                                        <Row>
                                            <Col md="6" xl="5" xxl="6">
                                                <Row className="align-items-end">
                                                    <Col>
                                                        <Col as="p" className='fs22 semibold GrayDark text-start mb-3 lh-sm'>Name</Col>
                                                        <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                            <FloatingLabel label="Name of Outlet">
                                                                <Form.Control
                                                                    className={edit ? 'bgLightGray' : ''}
                                                                    name="name"
                                                                    type="text"
                                                                    placeholder="Bayfront"
                                                                    disabled={edit}
                                                                    value={formProps.values.name}
                                                                    onChange={formProps.handleChange}
                                                                    onBlur={formProps.handleBlur}
                                                                    isInvalid={
                                                                        formProps.touched.name && formProps.errors.name
                                                                    }
                                                                />
                                                            </FloatingLabel>
                                                            <Form.Label className="char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto">8 / 20 Characters</Form.Label>
                                                            <Form.Control.Feedback type="invalid">
                                                                {formProps.errors.name}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="auto" className='fs16 semibold GrayComn pb-4 px-2 mx-md-0'><span className="d-sm-none d-md-inline">Crib</span></Col>
                                                </Row>
                                            </Col>
                                            <Col md="6" lg="6" xl="4" xxl="4">
                                                <Col as="p" className='fs22 semibold GrayDark text-start mb-3 lh-sm'>Crib Status</Col>
                                                <Form.Group className="position-relative approve-field" controlId="exampleForm.ControlInput1">
                                                    <FloatingLabel label="Current Status">
                                                        <Form.Control
                                                            className='bgLightGray'
                                                            name="crib_status"
                                                            type="text"
                                                            value={formProps.values.crib_status === CRIB_ACCOUNT_PUBLISH.value ? CRIB_ACCOUNT_PUBLISH.label : CRIB_ACCOUNT_UNPUBLISH.label}
                                                            placeholder="Approved and Published"
                                                            disabled />
                                                    </FloatingLabel>
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12" md="12" lg="12" xl="3" xxl="2" className='pt-4 pt-xl-0 pb-0'>
                                                <Row className='flex-xl-column row-cols-auto text-end'>
                                                    <Col md="auto">
                                                        <Button variant="light" onClick={handleSuspendAcc} className="w-100 text-nowrap rounded-pill bgLightGray py-1 px-4 mb-3 GrayComn border-0 fs20" disabled={formProps?.values?.is_verified === false ? true : false}>{!!statusActive ? "Published" : "Unpublished"}</Button>
                                                    </Col>
                                                    <Col md="auto">
                                                        <Button variant="light" onClick={handleDeleteAcc} className="w-100 text-nowrap rounded-pill bgtheme py-1 px-4 text-white border-0 fs20 ">Delete Crib</Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Col as="hr" className="my-4 graye0e"></Col>
                                        <Row>
                                            <Col md="6" className='mb-4'>
                                                <Col as="p" className='fs22 semibold GrayDark text-start mb-3 lh-sm'>Address</Col>
                                                <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                    <FloatingLabel label="Street Address">
                                                        <Form.Control
                                                            className={edit ? 'bgLightGray' : ''}
                                                            name="address"
                                                            type="text"
                                                            placeholder="10 Bayfront Ave"
                                                            disabled={edit}
                                                            value={formProps.values.address}
                                                            onChange={(e) => { formProps.handleChange(e); handleGetSglocateAPI(e) }}
                                                            onBlur={formProps.handleBlur}
                                                            isInvalid={
                                                                formProps.touched.address && formProps.errors.address
                                                            }
                                                        />
                                                    </FloatingLabel>
                                                    <Form.Control.Feedback type="invalid">
                                                        {formProps.errors.address}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                            <Col md="6" className='mb-4'>
                                                {formProps?.values?.is_verified ? <Col as="p" className='fs18 semibold textGreen text-end mb-3 lh-sm'>
                                                    <svg className='me-2' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L7.70711 9.29289ZM9 12L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L9 12ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L13.7071 8.70711ZM6.29289 10.7071L8.29289 12.7071L9.70711 11.2929L7.70711 9.29289L6.29289 10.7071ZM9.70711 12.7071L13.7071 8.70711L12.2929 7.29289L8.29289 11.2929L9.70711 12.7071ZM18 10C18 14.4183 14.4183 18 10 18V20C15.5229 20 20 15.5229 20 10H18ZM10 18C5.58172 18 2 14.4183 2 10H0C0 15.5229 4.47716 20 10 20V18ZM2 10C2 5.58172 5.58172 2 10 2V0C4.47716 0 0 4.47716 0 10H2ZM10 2C14.4183 2 18 5.58172 18 10H20C20 4.47716 15.5229 0 10 0V2Z" fill="#00676B" />
                                                    </svg>
                                                    Verified
                                                </Col> : <Col as="p" className='fs18 semibold themeColor text-end mb-3 lh-sm'>Not Verified</Col>}
                                                <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                    <FloatingLabel label="Building Name">
                                                        <Form.Control
                                                            className={edit ? 'bgLightGray' : ''}
                                                            name="building_name"
                                                            type="text"
                                                            placeholder="-"
                                                            disabled={edit}
                                                            value={formProps.values.building_name}
                                                            onChange={formProps.handleChange}
                                                            onBlur={formProps.handleBlur}
                                                            isInvalid={
                                                                formProps.touched.building_name && formProps.errors.building_name
                                                            }
                                                        />
                                                    </FloatingLabel>
                                                    <Form.Control.Feedback type="invalid">
                                                        {formProps.errors.building_name}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="6" md="2" lg="3" xxl="2" className='mb-4'>
                                                <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                    <FloatingLabel label="Floor Number">
                                                        <Form.Control
                                                            className={edit ? 'bgLightGray' : ''}
                                                            name="floor_no"
                                                            type="text"
                                                            placeholder="01"
                                                            disabled={edit}
                                                            value={formProps.values.floor_no || ""}
                                                            onChange={formProps.handleChange}
                                                            onBlur={formProps.handleBlur}
                                                            isInvalid={
                                                                formProps.touched.floor_no && formProps.errors.floor_no
                                                            } />
                                                    </FloatingLabel>
                                                    <Form.Control.Feedback type="invalid">
                                                        {formProps.errors.floor_no}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                            <Col xs="6" md="2" lg="3" xxl="2" className='mb-4'>
                                                <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                    <FloatingLabel label="Unit Number">
                                                        <Form.Control
                                                            className={edit ? 'bgLightGray' : ''}
                                                            name="unit_no"
                                                            type="text"
                                                            placeholder="01"
                                                            disabled={edit}
                                                            value={formProps.values.unit_no || ""}
                                                            onChange={(e) => { formProps.handleChange(e); handleGetSglocateAPI(e) }}
                                                            onBlur={formProps.handleBlur}
                                                            isInvalid={
                                                                formProps.touched.unit_no && formProps.errors.unit_no
                                                            }
                                                        />
                                                    </FloatingLabel>
                                                    <Form.Control.Feedback type="invalid">
                                                        {formProps.errors.unit_no}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col className='mb-4'>
                                                <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                    <FloatingLabel label="Country">
                                                        <Form.Control
                                                            className={edit ? 'bgLightGray' : ''}
                                                            name="country"
                                                            type="text"
                                                            disabled={edit}
                                                            placeholder="Country"
                                                            value={formProps.values.country}
                                                            onChange={formProps.handleChange}
                                                            onBlur={formProps.handleBlur}
                                                            isInvalid={
                                                                formProps.touched.country && formProps.errors.country
                                                            }
                                                        />
                                                    </FloatingLabel>
                                                    <Form.Control.Feedback type="invalid">
                                                        {formProps.errors.country}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col className='mb-4'>
                                                <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                    <FloatingLabel label="Postal Code">
                                                        <Form.Control
                                                            className={edit ? 'bgLightGray' : ''}
                                                            name="postal_code"
                                                            type="text"
                                                            disabled={edit}
                                                            placeholder="018956"
                                                            value={formProps.values.postal_code || ""}
                                                            onChange={(e) => { formProps.handleChange(e); handleGetSglocateAPI(e) }}
                                                            onBlur={formProps.handleBlur}
                                                            isInvalid={
                                                                formProps.touched.postal_code && formProps.errors.postal_code
                                                            }
                                                        />
                                                    </FloatingLabel>
                                                    <Form.Control.Feedback type="invalid">
                                                        {formProps.errors.postal_code}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className={`wanrWrap  ${edit ? 'bgLightGray py-3 border12' : ''}`}>
                                            <Col sm="auto"><TiWarning size="34" color="var(--theme-color)" /> <span className="fs20 bold themeColor">WARNING!</span></Col>
                                            <Col>
                                                <p className="fs20 semibold GrayDark">Changing your Crib Outlet address will unpublish this Crib. You will not be able to receive orders from this outlet. Any current or upcoming orders under this Outlet
                                                    will be cancelled and refunded to the Diner.</p>
                                                <Row className="switchWrap">
                                                    <Col>
                                                        <Form>
                                                            <Form.Check
                                                                className={`togglerBtn fs18 semibold GrayComn text-uppercase pt-1 togglerSize mb-5 mb-sm-0 w-auto ps-5 d-inline-block ${edit ? 'disableSwitch' : ''}`}
                                                                type="switch"
                                                                id="custom-switch011"
                                                                label={propertyOwner ? "Property Owner" : "Tenant"}
                                                                name="outlet_property"
                                                                onChange={getCheckedValueNew}
                                                                defaultChecked={propertyOwner}
                                                                checked={propertyOwner}
                                                                disabled={edit}
                                                            />
                                                            {/* <span className="fs22 semibold GrayDark ms-4 d-inline-block">Property Owner</span> */}
                                                        </Form>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3 ownWrap">
                                                    {propertyOwner ? <Col className={edit ? 'bgLightGray' : ''}>
                                                        <p className="fs20 semibold GrayDark mb-1">For Property Owner, please upload ONE of the following document as proof of ownership:</p>
                                                        <ListGroup as="ul" className="listinline">
                                                            <ListGroup.Item as="li" className={`p-0 border-0 fs20 semibold GrayDark ${edit ? 'bgLightGray' : ''} `}><GoPrimitiveDot size="10" className="align-baseline" /> Property Title Deed</ListGroup.Item>
                                                            <ListGroup.Item as="li" className={`p-0 border-0 fs20 semibold GrayDark ${edit ? 'bgLightGray' : ''} `}><GoPrimitiveDot size="10" className="align-baseline" /> Property Tax Bill (last 1 year)</ListGroup.Item>
                                                            <ListGroup.Item as="li" className={`p-0 border-0 fs20 semibold GrayDark ${edit ? 'bgLightGray' : ''} `}><GoPrimitiveDot size="10" className="align-baseline" /> Town Council Invoice (last 3 months)</ListGroup.Item>
                                                            <ListGroup.Item as="li" className={`p-0 border-0 fs20 semibold GrayDark ${edit ? 'bgLightGray' : ''} `}><GoPrimitiveDot size="10" className="align-baseline" /> MCST Invoice (last 3 months)</ListGroup.Item>
                                                        </ListGroup>

                                                        <p className="fs20 semibold textGreen mt-3 mb-3">
                                                            Acceptable files .jpg, .pdf, .png File size not exceeding 1MB.
                                                        </p>
                                                    </Col> :
                                                    
                                                    <Col className={edit ? 'bgLightGray' : ''}>
                                                        <p className="fs20 semibold GrayDark mb-1">For Tenant, please upload ONE of the following document as proof of tenancy:</p>
                                                        <ListGroup as="ul" className="listinline">
                                                            <ListGroup.Item as="li" className={`p-0 border-0 fs20 semibold GrayDark ${edit ? 'bgLightGray' : ''} `}><GoPrimitiveDot size="10" className="align-baseline" /> Lease or Rental Agreement</ListGroup.Item>
                                                            <ListGroup.Item as="li" className={`p-0 border-0 fs20 semibold GrayDark ${edit ? 'bgLightGray' : ''}`}><GoPrimitiveDot size="10" className="align-baseline" /> Authorized Tenant Confirmation Notice</ListGroup.Item>
                                                        </ListGroup>

                                                        <p className="fs20 semibold textGreen mt-3 mb-3">
                                                            Acceptable files .jpg, .pdf, .png File size not exceeding 1MB.
                                                        </p>
                                                    </Col>}
                                                    
                                                    <Col sm="auto" className={`text-center fileuploadWrap position-relative fs20 semibold themeColor${edit ? 'bgLightGray' : ''}`}>
                                                        {!!docImg && <>
                                                            <img src={docImg} alt="navimg" className="rounded" height={150} />
                                                            {!edit && <Row>
                                                                <Col className="text-end">
                                                                    <a href={docImg} target="_blank">
                                                                        <FiEye className="me-1" role="button" size="18" color="var(--theme-color)" />
                                                                    </a>
                                                                </Col>
                                                                <Col className="text-start">
                                                                    <HiOutlineTrash role="button" size="20" color="var(--theme-color)" onClick={deleteCribDoc} />
                                                                </Col>
                                                            </Row>}
                                                        </>}
                                                        {!!docPdf && <>
                                                            <DefaultPdfIcon width={120} height={120} />
                                                            {!edit && <Row>
                                                                <Col className="text-end"><a href={docPdf} target="_blank"><FiEye className="me-1" role="button" size="18" color="var(--theme-color)" /></a></Col>
                                                                <Col className="text-start"><HiOutlineTrash role="button" size="20" color="var(--theme-color)" onClick={deleteCribDoc} /></Col>
                                                            </Row>}
                                                        </>}
                                                        <span className="d-inline-block w-100 py-2 position-relative">
                                                            Upload Document <HiOutlineUpload size="26" color="var(--theme-color)" className="ms-2 align-top" />
                                                            <Form.Group controlId="formFile" className="h-auto" style={{opacity: "0"}}>
                                                                <Form.Control type="file" onChange={getUploadDocument} disabled={edit} />
                                                            </Form.Group>
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Col as="hr" className="my-4 mt-2 graye0e"></Col>
                                        <Col>
                                            <Col as="p" className='fs22 semibold GrayDark text-start mb-3 lh-sm'>Operating Hours</Col>

                                            {!edit || Object.keys(timeSlots).length > 0 ? Object.keys(formProps.values.outletsTimeSlots).map((oneKey, index) => {

                                                return (
                                                    <Row key={index}>
                                                        <Col md="2" className="pt-sm-1 pt-md-4 mb-sm-1 mb-md-4">
                                                            <Col as="span" className='fs16 semibold themeColor'>{checkday(oneKey)}</Col>
                                                        </Col>
                                                        <Col md="10">
                                                            <Row>
                                                                {formProps.values.outletsTimeSlots[oneKey] && formProps.values.outletsTimeSlots[oneKey].map((element, index) => {
                                                                    const Optionvalue = formProps.values.outletsTimeSlots[oneKey];
                                                                    const OptionErrors = formProps.errors.outletsTimeSlots && formProps.errors.outletsTimeSlots[oneKey] && formProps.errors.outletsTimeSlots[oneKey][index] ? formProps.errors.outletsTimeSlots[oneKey][index] : {};
                                                                    return (
                                                                        <FieldArray name={oneKey} key={index}>
                                                                            <Col md="auto" key={oneKey}>
                                                                                <Row className="position-relative crib02">
                                                                                    <Col xs={6} md="auto" className='mb-sm-2 mb-md-4'>
                                                                                        <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                                            <Form.Label>Opening Time {index + 1}</Form.Label><br />
                                                                                            <TimePicker
                                                                                                value={Optionvalue[index].start_time ? moment(Optionvalue[index].start_time, 'HH:mm') : moment()}
                                                                                                showSecond={false}
                                                                                                clearIcon
                                                                                                allowEmpty
                                                                                                name={`outletsTimeSlots.${oneKey}.[${index}].start_time`}
                                                                                                use12Hours
                                                                                                format={"hh:mm a"}
                                                                                                placeholder={(oneKey == "eve_of_public_holiday" && evePublicHours)
                                                                                                    || (oneKey == "public_holiday" && publicHours)
                                                                                                    ? "Normal hours" : "9:00 AM"}
                                                                                                onChange={(e) => {
                                                                                                    formProps.setFieldValue(`outletsTimeSlots.${oneKey}.[${index}].start_time`, e.format('HH:mm'))
                                                                                                }}
                                                                                                onBlur={formProps.handleBlur}
                                                                                                className={`${oneKey == "eve_of_public_holiday" && evePublicHours ? 'bgLightGray' : ''
                                                                                                    || oneKey == "public_holiday" && publicHours ? 'bgLightGray' : 'form-control'} form-control`}
                                                                                                disabled={(oneKey == "eve_of_public_holiday" && evePublicHours || edit) ? true : false
                                                                                                    || (oneKey == "public_holiday" && publicHours || edit) ? true : false} />
                                                                                            <p>{OptionErrors?.start_time}</p>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col xs={6} md="auto" className='mb-sm-2 mb-md-4'>
                                                                                        <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                                            <Form.Label>Closing Time {index + 1}</Form.Label><br />
                                                                                            <TimePicker
                                                                                                value={Optionvalue[index].end_time ? moment(Optionvalue[index].end_time, 'HH:mm') : moment()}
                                                                                                showSecond={false}
                                                                                                clearIcon
                                                                                                allowEmpty
                                                                                                use12Hours
                                                                                                name={`outletsTimeSlots.${oneKey}.[${index}].end_time`}
                                                                                                format={"hh:mm a"}
                                                                                                placeholder={(oneKey == "eve_of_public_holiday" && evePublicHours)
                                                                                                    || (oneKey == "public_holiday" && publicHours)
                                                                                                    ? "Normal hours" : "2:00 PM"}
                                                                                                onChange={(e) => { formProps.setFieldValue(`outletsTimeSlots.${oneKey}.[${index}].end_time`, e.format('HH:mm')) }}
                                                                                                onBlur={formProps.handleBlur}
                                                                                                className={`${oneKey == "eve_of_public_holiday" && evePublicHours ? 'bgLightGray' : ''
                                                                                                    || oneKey == "public_holiday" && publicHours ? 'bgLightGray' : 'form-control'} form-control`}
                                                                                                disabled={(oneKey == "eve_of_public_holiday" && evePublicHours || edit) ? true : false
                                                                                                    || (oneKey == "public_holiday" && publicHours || edit) ? true : false}
                                                                                            />
                                                                                            <p>
                                                                                                {OptionErrors?.end_time}
                                                                                            </p>

                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col md="auto" className='my-md-3 mb-sm-1 mb-md-4 pt-1 d-flex border-sapertor-line align-items-start'>
                                                                                        <Col as="span" className='border-end py-3'></Col>
                                                                                    </Col>
                                                                                    {
                                                                                        !edit && index ?
                                                                                            <AiFillCloseCircle size="25" color="var(--theme-color)" className="editOrderRemove position-absolute" onClick={() => removeTimeOptions(index, element.id, oneKey, formProps.values.outletsTimeSlots, formProps.setFieldValue, formProps.setFieldTouched)} />
                                                                                            : null
                                                                                    }
                                                                                </Row>
                                                                            </Col>
                                                                        </FieldArray>
                                                                    )
                                                                })}
                                                                {(edit || oneKey == "eve_of_public_holiday" && evePublicHours) || (edit || oneKey == "public_holiday" && publicHours) || (TimeSlotLimit != formProps.values.outletsTimeSlots[oneKey].length) &&
                                                                    <Col md="auto" className="d-flex pb-4">
                                                                        <Col as="button" onClick={(e) => {
                                                                            e.preventDefault()
                                                                            addTimeFormFields(oneKey, formProps.values.outletsTimeSlots, formProps.setFieldValue)
                                                                        }} className='p-0 border-0 colrtranparent fs16 semibold themeColor'>
                                                                            <TiPlus size={18} className="me-2" />Add Time Slot {formProps.values.outletsTimeSlots[oneKey].length + 1}
                                                                        </Col>
                                                                    </Col>
                                                                }
                                                            </Row>
                                                            {oneKey == "eve_of_public_holiday" &&
                                                                <Col as="section" className="mb-4 publick-holiday">
                                                                    <Row className="profilecheckbox">
                                                                        <Col md="12" className="main-col">
                                                                            <Col className="form-check position-relative">
                                                                                <input className="form-check-input" type="checkbox" name="eve_public_hours" id="mypro01" onClick={handleOperatingHours} />
                                                                                <label className="form-check-label top-0 fs16 GrayComn semibold text-truncate overflow-hidden ps-2" htmlFor="mypro01"><span>Same as Normal Operating Hours?</span></label>
                                                                            </Col>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>}
                                                            {oneKey == "public_holiday" &&
                                                                <Col as="section" className="mb-4 publick-holiday">
                                                                    <Row className="profilecheckbox">
                                                                        <Col md="12" className="main-col">
                                                                            <Col className="form-check position-relative">
                                                                                <input className="form-check-input" type="checkbox" name="is_public_hours" id="mypro02" onClick={handleOperatingHours} />
                                                                                <label className="form-check-label top-0 fs16 GrayComn semibold text-truncate overflow-hidden ps-2" htmlFor="mypro02"><span>Same as Normal Operating Hours?</span></label>
                                                                            </Col>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>}
                                                        </Col>
                                                    </Row>
                                                )
                                            }) : "Slots not found"}

                                        </Col>
                                        <Col as="hr" className="my-4 mt-2 graye0e"></Col>
                                        <Row>
                                            <Col md="12" className='mb-4'>
                                                <Col className='d-flex justify-content-between align-items-center mb-4 collection-switch'>
                                                    <Col as="p" className='fs22 semibold GrayDark text-start mb-0 lh-sm'>Pickup Collections</Col>
                                                    <Form.Check
                                                        className="togglerBtn fs18 semibold GrayComn text-uppercase float-end togglerSize disableSwitch"
                                                        type="switch"
                                                        id="custom-switch01"
                                                        label={alpha ? alpha : "Off"}
                                                        name="is_pickup"
                                                        disabled={edit}
                                                        defaultChecked={true}
                                                        onChange={(e) => { getCheckedValue(e); formProps.handleChange(String(e.target.checked)); }}
                                                    />
                                                </Col>
                                                <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                    <FloatingLabel label="Collection Instructions">
                                                        <Form.Control
                                                            className={edit ? 'bgLightGray' : ''}
                                                            type="text"
                                                            name="pickup_collection_inst"
                                                            placeholder="Please collect your order at ground floor lift lobby. Block 10, Lobby A."
                                                            value={formProps.values.pickup_collection_inst}
                                                            onChange={formProps.handleChange}
                                                            onBlur={formProps.handleBlur}
                                                            isInvalid={
                                                                formProps.touched.pickup_collection_inst && formProps.errors.pickup_collection_inst
                                                            }
                                                            disabled={edit}
                                                        />
                                                    </FloatingLabel>
                                                    <Form.Label className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>72 / 150 Characters</Form.Label>
                                                    <Form.Control.Feedback type="invalid">
                                                        {formProps.errors.pickup_collection_inst}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Col as="hr" className="my-4 mt-2 graye0e"></Col>
                                        <Row>
                                            <Col md="12" className='mb-4'>
                                                <Col className='d-flex justify-content-between align-items-center mb-4 collection-switch'>
                                                    <Col as="p" className='fs22 semibold GrayDark text-start mb-0 lh-sm'>Dine In Collections</Col>
                                                    <Form.Check
                                                        className={`togglerBtn fs18 semibold GrayComn text-uppercase float-end togglerSize ${edit ? 'disableSwitch' : ''}`}
                                                        type="switch"
                                                        id="custom-switch02"
                                                        label={alpha01 ? alpha01 : "Off"}
                                                        name="is_dinein"
                                                        onChange={(e) => { getCheckedValue(e); formProps.handleChange(String(e.target.checked)); }}
                                                        value={formProps.values.is_dinein}
                                                        onBlur={formProps.handleBlur}
                                                        isInvalid={
                                                            formProps.touched.is_dinein && formProps.errors.is_dinein
                                                        }
                                                        disabled={edit}
                                                    />
                                                </Col>
                                                <Form.Group className="position-relative" controlId="exampleForm.ControlInput1">
                                                    <FloatingLabel label="Collection Instructions">
                                                        <Form.Control
                                                            className={edit ? 'bgLightGray' : ''}
                                                            name="dinein_collection_inst"
                                                            type="text"
                                                            value={formProps.values.dinein_collection_inst}
                                                            onChange={formProps.handleChange}
                                                            onBlur={formProps.handleBlur}
                                                            isInvalid={
                                                                formProps.touched.dinein_collection_inst && formProps.errors.dinein_collection_inst
                                                            }
                                                            disabled={edit}
                                                            placeholder="Please ring the door bell and provide your Order ID when you arrive at the gate."
                                                        />
                                                    </FloatingLabel>
                                                    <Form.Label className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>80 / 150 Characters</Form.Label>
                                                    <Form.Control.Feedback type="invalid">
                                                        {formProps.errors.dinein_collection_inst}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col >
                                        </Row >
                                        <span className='crib01'><br /></span>
                                        <Row>

                                            <Col className='d-flex justify-content-between align-items-center mb-4 collection-switch'>
                                                <Form.Check
                                                    className={`togglerBtn fs18 semibold GrayComn float-end ${edit ? 'disableSwitch' : ''}`}
                                                    type="switch"
                                                    id="custom-switch03"
                                                    label={seatingSystem ? "Reserved Seating" : "Open Seating"}
                                                    name="seating_system"
                                                    onChange={(e) => {
                                                        getCheckedValue(e);
                                                    }}
                                                    value={seatingSystem}
                                                    checked={seatingSystem}
                                                    disabled={edit}
                                                />
                                            </Col>
                                        </Row>
                                        {seatingSystem ? <>
                                            <Row>
                                                <Col md="12">
                                                    <FieldArray name="outlet_tables">
                                                        <Row>
                                                            {!edit || outletTables?.length > 0 ?
                                                                <Col md="auto">
                                                                    {formProps.values.outlet_tables.map((element, index) => {
                                                                        const OptionErrors = formProps?.errors?.outlet_tables ? formProps.errors.outlet_tables[index] : {};
                                                                        const OptionTouched = formProps?.touched?.outlet_tables ? formProps.touched.outlet_tables[index] : {};
                                                                        return (
                                                                            <Row className="position-relative" key={index}>
                                                                                <Col md="auto" className='mb-4' >
                                                                                    <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                                        <FloatingLabel label="Table Number">
                                                                                            <Form.Control
                                                                                                className='bgLightGray'
                                                                                                name="table_no"
                                                                                                type="text"
                                                                                                placeholder={formProps.values.outlet_tables[index].table_no}
                                                                                                value={formProps.values.outlet_tables[index].table_no}
                                                                                                disabled
                                                                                            />
                                                                                        </FloatingLabel>
                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md="auto" className='mb-4 d-flex border-sapertor-line align-items-center'>
                                                                                    <Col as="span" className='border-end py-3'></Col>
                                                                                </Col>
                                                                                <Col md="auto" className='mb-4'>
                                                                                    <Form.Group className="position-relative mxwidth150" controlId="exampleForm.ControlInput1">
                                                                                        <FloatingLabel label="Number of Seats">
                                                                                            <Form.Control
                                                                                                type="number"
                                                                                                placeholder="6"
                                                                                                name={`outlet_tables.${index}.seats`}
                                                                                                value={formProps.values.outlet_tables[index].seats}
                                                                                                onChange={formProps.handleChange}
                                                                                                onBlur={formProps.handleBlur}
                                                                                                isInvalid={
                                                                                                    OptionTouched?.seats && OptionErrors?.seats
                                                                                                }
                                                                                                className={edit && 'bgLightGray'}
                                                                                                disabled={edit}
                                                                                            />
                                                                                        </FloatingLabel>
                                                                                    </Form.Group>
                                                                                    <Form.Control.Feedback type="invalid">
                                                                                        {OptionErrors?.seats}
                                                                                    </Form.Control.Feedback>
                                                                                </Col>
                                                                                {
                                                                                    !edit && index && index != 1 ?
                                                                                        <AiFillCloseCircle
                                                                                            size="25"
                                                                                            color="var(--theme-color)"
                                                                                            className="editOrderRemove position-absolute"
                                                                                            onClick={() => removeTables(index, element.id, formProps.values.outlet_tables, formProps.setFieldValue, formProps.setFieldTouched)} />
                                                                                        : null
                                                                                }
                                                                            </Row>
                                                                        )
                                                                    })}

                                                                    {!edit &&
                                                                        <Col as="button" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            addMoreTables(formProps.values.outlet_tables.length + 1, formProps.values.outlet_tables, formProps.setFieldValue)
                                                                        }} className='p-0 border-0 colrtranparent fs16 semibold themeColor'>
                                                                            <TiPlus size={18} className="me-2" />Add Table
                                                                        </Col>
                                                                    }

                                                                </Col>
                                                                : <Col sm="12" className="themeColor text-center rounded-pill lh-lg bold">Tables not found</Col>}
                                                        </Row>
                                                    </FieldArray>
                                                </Col>
                                            </Row>
                                        </> :
                                            <Row className="warningWrap text-center mt-4">
                                                <Col sm="12" className="pb-3">
                                                    <TiWarning size="65" color="var(--theme-color)" />
                                                </Col>
                                                <Col>
                                                    <p className="fs22 semibold GrayDark">
                                                        <span className="themeColor">Open Seating Arrangement</span> should only be selected for large food establishments where tables and seats are on a shared-tenancy basis.
                                                        They are not reserved solely for your business (e.g. Food Court, Cafeteria, Food Park...etc.).
                                                        For shared seating arrangements, Diners have access to tables and chairs on a First-Come-First-Serve basis.
                                                        If your Diner is unable to find a seat, they may request to change the order to Takeaway instead.
                                                        Please note that Open Seating Arrangement may lead to a lower Customer Service Satisfaction.
                                                    </p>
                                                </Col>
                                            </Row>}


                                        <Col as="hr" className="mt-4 mt-2 graye0e"></Col>
                                        <Col>
                                            <Col as="p" className='fs22 semibold GrayDark text-md-start text-center mb-3 mb-md-5 lh-sm'>Delivery Collections</Col>
                                            <Col className='text-center'>
                                                <Col as="h2" className='fs80vw semibold GrayComn'>Coming Soon</Col>
                                                <Col as="p" className='fs18 semibold GrayComn'>Stay tuned for more exciting features! We'd love to hear your <br className='d-none d-md-block ' /> feedback. Let us know what you think.</Col>
                                            </Col>
                                        </Col>
                                        <Col as="hr" className="my-4 mt-2 graye0e"></Col>
                                        <Col className='gallery-slider'>
                                            <Col className='d-flex justify-contnet-between'>
                                                <Col className='GrayComn fs22 semibold mb-3'>Gallery</Col>
                                                <Col className='text-end mb-3'>
                                                    <Link to="" className='themeColor fs22 semibold text-decoration-none'>View Gallery</Link>
                                                </Col>
                                            </Col>
                                            <Slider className="featureProd galleryProd" {...settings}>
                                                <Col className="card border-0">
                                                    <Col className="promo">
                                                        <img src={process.env.REACT_APP_PUBLIC_URL + "assets/gallery1.png"} className="img-fluid" alt="gallery01" />
                                                    </Col>
                                                </Col>
                                                <Col className="card border-0">
                                                    <Col className="promo">
                                                        <img src={process.env.REACT_APP_PUBLIC_URL + "assets/gallery3.png"} className="img-fluid" alt="gallery03" />
                                                    </Col>
                                                </Col>
                                                <Col className="card border-0">
                                                    <Col className="promo">
                                                        <img src={process.env.REACT_APP_PUBLIC_URL + "assets/gallery2.png"} className="img-fluid" alt="gallery02" />
                                                    </Col>
                                                </Col>
                                                <Col className="card border-0">
                                                    <Col className="promo">
                                                        <img src={process.env.REACT_APP_PUBLIC_URL + "assets/gallery1.png"} className="img-fluid" alt="gallery01" />
                                                    </Col>
                                                </Col>
                                            </Slider>
                                        </Col>
                                    </Col>
                                </Col>
                            </Form>

                        </Formik>
                    </Col >
                </Row >

            </Container >
            {onShow == true && popupMessage.status === "success" ? (
                <AlertPopup
                    show={onShow}
                    onHide={() => setOnShow(false)}
                    icon={<HiCheckCircle size="120" className='mt-5 mb-3' color="var(--bs-white)" />}
                    title="Success!"
                    description={popupMessage.message}
                />
            ) : onShow == true && (popupMessage.status === "crib-status" || popupMessage.status === "delete-crib") ?
                <AlertPopup
                    show={onShow}
                    onHide={() => setOnShow(false)}
                    icon={<svg width="94" height="86" viewBox="0 0 94 86" fill="none" className='mt-5 mb-3' xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M36.3676 6.90288C41.0317 -1.38885 52.9702 -1.38885 57.6343 6.90288L91.6741 67.4184C96.2485 75.5509 90.3717 85.5995 81.0406 85.5995H12.9609C3.63005 85.5995 -2.24687 75.5509 2.3277 67.4184L36.3676 6.90288ZM53.1001 67.3001C53.1001 70.6691 50.3692 73.4001 47.0001 73.4001C43.6314 73.4001 40.9003 70.6691 40.9003 67.3001C40.9003 63.931 43.6314 61.2001 47.0001 61.2001C50.3692 61.2001 53.1001 63.931 53.1001 67.3001ZM47.0001 18.5001C43.6314 18.5001 40.9003 21.2312 40.9003 24.6001V42.9001C40.9003 46.269 43.6314 49.0001 47.0001 49.0001C50.3692 49.0001 53.1001 46.269 53.1001 42.9001V24.6001C53.1001 21.2312 50.3692 18.5001 47.0001 18.5001Z" fill="white" />
                    </svg>}
                    title={popupMessage.status === "delete-crib" ? "Delete Crib" : statusActive ? "Publish Crib" : "Unpublish Crib"}
                    description={popupMessage.message}
                    button="Confirm"
                    onButton={handleConfirmStatus}
                    cancelButton="Cancel"
                    onCancelButton={() => { setOnShow(false); setCribStatus("") }}
                /> : onShow == true && popupMessage.status === "remove-tables" ?
                    <AlertPopup
                        show={onShow}
                        onHide={() => setOnShow(false)}
                        icon={<svg width="94" height="86" viewBox="0 0 94 86" fill="none" className='mt-5 mb-3' xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M36.3676 6.90288C41.0317 -1.38885 52.9702 -1.38885 57.6343 6.90288L91.6741 67.4184C96.2485 75.5509 90.3717 85.5995 81.0406 85.5995H12.9609C3.63005 85.5995 -2.24687 75.5509 2.3277 67.4184L36.3676 6.90288ZM53.1001 67.3001C53.1001 70.6691 50.3692 73.4001 47.0001 73.4001C43.6314 73.4001 40.9003 70.6691 40.9003 67.3001C40.9003 63.931 43.6314 61.2001 47.0001 61.2001C50.3692 61.2001 53.1001 63.931 53.1001 67.3001ZM47.0001 18.5001C43.6314 18.5001 40.9003 21.2312 40.9003 24.6001V42.9001C40.9003 46.269 43.6314 49.0001 47.0001 49.0001C50.3692 49.0001 53.1001 46.269 53.1001 42.9001V24.6001C53.1001 21.2312 50.3692 18.5001 47.0001 18.5001Z" fill="white" />
                        </svg>} title={popupMessage.status === "remove-tables"}
                        description={popupMessage.message}
                        button="Confirm"
                        onButton={handleCribTablesDelete}
                        cancelButton="Cancel"
                        onCancelButton={() => { setOnShow(false); setRemoveTablesId(""); }}
                    /> : onShow == true && popupMessage.status === "remove-time" ?
                        <AlertPopup
                            show={onShow}
                            onHide={() => setOnShow(false)}
                            icon={<svg width="94" height="86" viewBox="0 0 94 86" fill="none" className='mt-5 mb-3' xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M36.3676 6.90288C41.0317 -1.38885 52.9702 -1.38885 57.6343 6.90288L91.6741 67.4184C96.2485 75.5509 90.3717 85.5995 81.0406 85.5995H12.9609C3.63005 85.5995 -2.24687 75.5509 2.3277 67.4184L36.3676 6.90288ZM53.1001 67.3001C53.1001 70.6691 50.3692 73.4001 47.0001 73.4001C43.6314 73.4001 40.9003 70.6691 40.9003 67.3001C40.9003 63.931 43.6314 61.2001 47.0001 61.2001C50.3692 61.2001 53.1001 63.931 53.1001 67.3001ZM47.0001 18.5001C43.6314 18.5001 40.9003 21.2312 40.9003 24.6001V42.9001C40.9003 46.269 43.6314 49.0001 47.0001 49.0001C50.3692 49.0001 53.1001 46.269 53.1001 42.9001V24.6001C53.1001 21.2312 50.3692 18.5001 47.0001 18.5001Z" fill="white" />
                            </svg>} title={popupMessage.status === "remove-time" ? "Delete Time Slot" : ''}
                            description={popupMessage.message}
                            button="Confirm"
                            onButton={handlecribTimeSlot}
                            cancelButton="Cancel"
                            onCancelButton={() => { setOnShow(false); setRemoveTimeSlotId(""); }}
                        /> : onShow == true && popupMessage.status === "error" ?
                            <AlertPopup
                                show={onShow}
                                onHide={() => setOnShow(false)}
                                icon={<svg width="94" height="86" viewBox="0 0 94 86" fill="none" className='mt-5 mb-3' xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M36.3676 6.90288C41.0317 -1.38885 52.9702 -1.38885 57.6343 6.90288L91.6741 67.4184C96.2485 75.5509 90.3717 85.5995 81.0406 85.5995H12.9609C3.63005 85.5995 -2.24687 75.5509 2.3277 67.4184L36.3676 6.90288ZM53.1001 67.3001C53.1001 70.6691 50.3692 73.4001 47.0001 73.4001C43.6314 73.4001 40.9003 70.6691 40.9003 67.3001C40.9003 63.931 43.6314 61.2001 47.0001 61.2001C50.3692 61.2001 53.1001 63.931 53.1001 67.3001ZM47.0001 18.5001C43.6314 18.5001 40.9003 21.2312 40.9003 24.6001V42.9001C40.9003 46.269 43.6314 49.0001 47.0001 49.0001C50.3692 49.0001 53.1001 46.269 53.1001 42.9001V24.6001C53.1001 21.2312 50.3692 18.5001 47.0001 18.5001Z" fill="white" />
                                </svg>}
                                title="Error!"
                                description={popupMessage.message}
                            /> : null
            }
            <Footer />
        </div >
    )
}
export default HostCribTwo;