import React from 'react'
import { Container, Row, Col, Image, Card, ListGroup, Form, Button } from 'react-bootstrap';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import { useNavigate } from 'react-router';
import MyAccount02 from './myaccount02';
import MyAccount03 from './myaccount03';
import MyAccount04 from './myaccount04';
import MyAccount01 from './myaccount01';
import { useState } from 'react';
import AfterLoginHeader from '../../../components/afterLoginHeader';
import { FiChevronDown } from 'react-icons/fi';
import { User_CloseAccount } from '../../../queries/queries';
import { useMutation } from '@apollo/client';

const MyAccount = () => {
    const navigate = useNavigate();
    const [stepNo, setStepNo] = useState(1);
    const [question, setQuestion] = useState('');
    const [enable, setEnable] = useState(false);
    const [vaild, setVaild] = useState(false);
    const [password, setpassword] = useState('');
    const [email, setEmail] = useState('');
    const [otpMessage, setOtpMessage] = useState('');
    const [userData, SetUserData] = useState('');

    return (
        <div id="bodyWrap">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <AfterLoginHeader />
                    </Col>
                    <Col
                        as="span"
                        className="d-none proMenu"
                        data-bs-toggle="offcanvas"
                        href="#myprofile01"
                        role="button"
                        aria-controls="myprofile01"
                    >
                        {" "}
                        Profile Menu{" "}
                        <FiChevronDown
                            className="float-end"
                            size="28"
                            color="var(--bs-white)"
                        />
                    </Col>
                </Row>

                {stepNo === 1 && 
                <MyAccount01 
                    question={question}
                    setQuestion ={setQuestion}
                    stepNo={stepNo}
                    setStepNo={setStepNo}
                    password={password}
                    setpassword={setpassword}
                    email={email}
                    setEmail={setEmail}
                />
                }
                {stepNo === 2 && <MyAccount02 
                setStepNo={setStepNo} 
                question={question}
                password={password} 
                setpassword = {setpassword} 
                email={email}
                setEmail = {setEmail}
                otpMessage={otpMessage}
                setOtpMessage = {setOtpMessage}
                />}
                {stepNo === 3 && <MyAccount03 
                    setStepNo={setStepNo} 
                    email={email}
                    setEmail={setEmail}
                />}
                {stepNo === 4 && <MyAccount04 
                    question={question}
                    setQuestion={setQuestion}
                />}
            </Container>
            <Footer />
        </div>
    )
}
export default MyAccount