import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { HiOutlineUpload } from "react-icons/hi";
import { addClass } from "../../../commanFunctions";
import Tesseract from 'tesseract.js';

const HostPersonal03A = (params) => {
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [valid, setValid] = useState({ front: false, back: false });
  const [dataType, setDataType] = useState(false);
 
  //********************** how to scan image in ocr function ******************************
  const processImage = (docName, doc) => {
    try {
      Tesseract.recognize(doc, 'eng',
        {
          logger: (m) => {
            if (m.status === 'recognizing text') {
              setProgress(m.progress);
            }
          }
        }
      ).then(({ data: { text } }) => {
        // params.setResult(text);
        if (docName === "front") {
          params.setResult({ ...params?.result, front: text });
          setValid({ ...valid, front: true })
        } else if (docName === "back") {
          params.setResult({ ...params?.result, back: text });
          setValid({ ...valid, back: true })
        } else {
          console.log("error");
        }
      })
    } catch (error) {
      console.log("error", error);
    }
  }

// ********************* set text in auto fill form *************************************
  const handleImageProcess = () => {
    try {
      let tempArr = params?.result?.front?.split("\n");
      //  let cNum = tempArr[1].split(" ")[3];
      //   params.setNricNumber(cNum);

      let CfirstName = tempArr[2].split(" ")[1];
      let CLastName = tempArr[2].split(" ")[2];
      let CflName = CfirstName + " " + CLastName;
      params.setFullname(CflName);

      let date = tempArr[5].split(" ")[1];
      // params.handleDate(date);
      // params.setDateOfBirth(Cdob)
      params.compareAge(date);

      let CGender = tempArr[5].split(" ")[2];
      params.setGender(CGender);
      if (CGender.toLowerCase() === 'm' || CGender.toLowerCase() === "male") {
        params.setGender("male");
      } else if (CGender.toLowerCase() === 'f' || CGender.toLowerCase() === "female") {
        params.setGender("female");
      } else {
        params.setGender("other");
      }

      let Ccitizenship = tempArr[7].split()[0];
      params.setCitizenship(Ccitizenship);

      let tempArr2 = params?.result?.back?.split("\n");
      let address = tempArr2[8];
      let newAddress = address.replace(/[^a-zA-Z0-9 ]/g, "");
      params.setAddress(newAddress);

      let cNum = tempArr2[2].split(" ")[3];
      let CNewNum = cNum.replace(/[^a-zA-Z0-9 ]/g, "");

      params.setNricNumber(CNewNum);
      // throw Error("Oh no, an error");
    } catch (error) {
      console.log("error===========>", error);
    }

  }

// ********************* Validation set *************************************************
  const handleValidation = (fileType) => {
    switch (fileType) {
      case "image/jpeg":
        setDataType(false);
        break;
      case "image/png":
        setDataType(false);
        break;
      case "application/pdf":
        setDataType(false);
        break;
      default:
        setDataType(true);
    }
  }

  const onNextpage = (event) => {
    event.preventDefault();
    if (params?.nricFile?.front?.name === undefined) {
      setErrorMessage({
        error: "empty nric-front",
        message: "Please Upload jpg & png 1 MB file NRIC Front",
      });
    } else if (dataType === true) {
      setErrorMessage({
        error: "nric-front-data-type",
        message: "Please Upload jpg & png file"
      });
    } else if (params?.nricFile?.front?.size > 1000000) {
      setErrorMessage({
        error: "nric-front-size",
        message: "Please Upload 1 MB file"
      });
    } else if (params.nricFile?.back?.name === undefined) {
      setErrorMessage({
        error: "empty nric-back",
        message: "Please Upload jpg & png file 1 MB NRIC Back",
      });
    } else if (dataType === true) {
      setErrorMessage({
        error: "nric-back-data-type",
        message: "Please Upload jpg & png file"
      });
    } else if (params?.nricFile?.back?.size > 1000000) {
      setErrorMessage({
        error: "nric-back-size",
        message: "Please Upload 1 MB file"
      });
    } else {
      addClass()
      setTimeout(() => {
        handleImageProcess();
        params.setStepNo(4);
      }, 1400)
    }
  };

  const goBack = () => {
    addClass();
    setTimeout(() => {
      params.setStepNo(2);
    }, 1400);
  };

  return (
    <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
      <div className="front">
        <div className="heading">
          <h2 className="small lh-1 fs-normal mb-2">Become A Host</h2>
          <p className="semibold m-0">
            Please upload your NRIC.
          </p>
        </div>
        <Col as="section" className="stepsBar2 pt-4">
          <Col as="ul" className="list-unstyled">
            <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(100%/3.4)" }}>3</span></Col>
          </Col>
        </Col>
        <Col as="section" className="signpUser pt-2">

          <Form onSubmit={onNextpage}>
            <Row className="flex-wrap filebutton">
              <Col lg="12" className="pb-3">
                <Form.Group controlId="nric-front" className="mb-0">
                  <Form.Label className="nricFile fs20 semibold themeColor w-100 mb-0">
                    {params.nricFile?.front?.name.length >= 39 ? params.nricFile?.front?.name.substring(0, 35) + "...." : params.nricFile?.front?.name || "NRIC Front"}
                    <span role="button" className="px-3 pt-2 pb-3 lh-1 shadowMD border border08 d-inline-block bg-white float-end" >
                      <HiOutlineUpload size="24" color="var(--theme-color)" />
                    </span>
                  </Form.Label>
                  <Form.Control
                    name="nricFront"
                    type="file"
                    className="d-none"
                    accept=".png, .jpg"
                    onChange={(event) => {
                      if (event.target.files[0].name === undefined) {
                        setErrorMessage({
                          error: "empty nric-front",
                          message: "Please Upload jpg, png",
                        });
                      } else {
                        setErrorMessage(undefined);
                      }
                      params.setNricFile({
                        ...params.nricFile,
                        front: event.target.files[0],
                      });
                      processImage('front', event.target.files[0])
                      // handleDisableNext()
                      handleValidation(event.target.files[0].type);
                    }}
                  // disabled={progress > 0 && progress < 1 || valid.front ? true : false}
                  />

                  {errorMessage && (errorMessage.error === "empty nric-front" || errorMessage.error === "nric-front-data-type" || errorMessage.error === "nric-front-size") && (
                    <div
                      className="invalid-feedback d-block"
                      style={{ textAlign: "start" }}
                    >
                      {errorMessage.message}
                    </div>
                  )}

                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group controlId="nric-back" className="mb-4">
                  <Form.Label className="nricFile fs20 semibold themeColor w-100">
                    {params.nricFile?.back?.name.length >= 39 ? params.nricFile?.back?.name.substring(0, 35) + "...." : params.nricFile?.back?.name || "NRIC Back"}
                    <span role="button" className="px-3 pt-2 pb-3 lh-1 shadowMD border border08 d-inline-block bg-white float-end">
                      <HiOutlineUpload size="24" color="var(--theme-color)" />
                    </span>
                  </Form.Label>
                  <Form.Control
                    name="nricBack"
                    type="file"
                    className="d-none"
                    accept=".jpg, .png"
                    onChange={(event) => {
                      if (event.target.files[0].name === undefined) {
                        setErrorMessage({
                          error: "empty nric-back",
                          message: "Please Upload jpg, png",
                        });
                      } else {
                        setErrorMessage(undefined);
                      }
                      params.setNricFile({
                        ...params.nricFile,
                        back: event.target.files[0],
                      });
                      processImage('back', event.target.files[0])
                      handleValidation(event.target.files[0].type);
                      // handleDisableNext()
                    }}
                    disabled={progress == 1 && valid.front ? false : true}
                  />
                  {errorMessage && (errorMessage.error === "empty nric-back" || errorMessage.error === "nric-back-data-type" || errorMessage.error === "nric-back-size") && (
                    <div
                      className="invalid-feedback d-block"
                      style={{ textAlign: "start" }}
                    >
                      {errorMessage.message}
                    </div>
                  )}

                  {progress > 0 && progress < 1 ? <div
                    className="invalid-feedback d-block"
                    style={{ textAlign: "start" }}
                  >
                    Wait for processing....
                  </div> : null}

                </Form.Group>

              </Col>
            </Row>

            <p className="fs16 textGreen semibold mb-0">
              *Acceptable files .jpg, .png, <br className="d-none d-lg-inline" />.pdf. File size not exceeding 1MB.
            </p>
            <Row className="mt-4">
              <Col className="align-self-center">
                <Button
                  className="fs26 themeColor semibold text-decoration-none lh-1 p-0"
                  type="button"
                  variant="link"
                  onClick={goBack}
                >
                  <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  Previous
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                  variant="primary"
                  type="submit"
                  disabled={valid.front && valid.back ? false : true}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
    </section>
  )
}

export default HostPersonal03A