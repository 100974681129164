import React from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'

const InputModel = (params) => {
    return (
        <Modal
            backdropClassName="action1"
            dialogClassName="action2 successfullyPop"
            contentClassName="action3 bgtheme border-0 border12"
            className="order-response"
            centered
            show={params?.show || false}
            onHide={params?.onHide}>
            <Modal.Header closeButton className="border-0 text-white closeB flex-row-reverse position-relative">
                <svg width="18" height="18" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.11438 21.1144C0.0729837 22.1558 0.0729837 23.8442 1.11438 24.8856C2.15578 25.927 3.84422 25.927 4.88562 24.8856L1.11438 21.1144ZM24.8856 4.88562C25.927 3.84422 25.927 2.15578 24.8856 1.11438C23.8442 0.0729837 22.1558 0.0729837 21.1144 1.11438L24.8856 4.88562ZM4.88562 1.11438C3.84422 0.0729837 2.15578 0.0729837 1.11438 1.11438C0.0729837 2.15578 0.0729837 3.84422 1.11438 4.88562L4.88562 1.11438ZM21.1144 24.8856C22.1558 25.927 23.8442 25.927 24.8856 24.8856C25.927 23.8442 25.927 22.1558 24.8856 21.1144L21.1144 24.8856ZM4.88562 24.8856L24.8856 4.88562L21.1144 1.11438L1.11438 21.1144L4.88562 24.8856ZM1.11438 4.88562L21.1144 24.8856L24.8856 21.1144L4.88562 1.11438L1.11438 4.88562Z" fill="white" />
                </svg>
            </Modal.Header>
            <Modal.Body className='modal-input text-center m-0'>
                <Col className="container">
                    <Row className="justify-content-center">
                        <Col md="9" className="modal-content-inner">
                            <Col as="span" className="modal-icon-head bgwhite mx-auto mb-4  mt-2 d-flex align-items-center justify-content-center rounded-circle">
                                {params?.icon}
                            </Col>
                            {/* ----------TITLE AND SUBTITLE--------- */}
                            <Col as="h3" className="p-0 mb-1 fs32 semibold colorwhite">{params?.title}</Col>
                            <Col as="p" className="colorwhite semibol fs24 mb-3 lh-sm pb-1">{params?.subtitle}</Col>
                            {params.children}
                        </Col>
                    </Row>
                </Col>
            </Modal.Body>
            {params?.buttonName && <Modal.Footer className="border-0 justify-content-center mt-4 mb-5">
                <Col md="auto" className='text-center m-0'>
                    <Button variant="light" className="text-nowrap rounded-pill bgwhite py-2 px-5 themeColor border-0 fs26 " onClick={params?.buttonClick}>
                        {params?.buttonName}
                    </Button>
                </Col>
            </Modal.Footer>}
        </Modal>

    )
}

export default InputModel