import React from 'react'
import { Col, Modal } from 'react-bootstrap'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const MapModel = (params) => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    })
    const [map, setMap] = React.useState(null)

    const center = {
        lat: params?.latitude,
        lng: params?.longitude
    }

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds({
            lat: 1.4316785377697316,
            lng: 103.75985026359558
        });
        map.fitBounds(bounds);

        console.log("Map onLoad", map)

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        console.log("Map onUnmount", null)
        setMap(null)
    }, [])

    return (
        <Modal
            backdropClassName="action01"
            dialogClassName="modalSM"
            contentClassName="action03"
            centered
            show={params?.show || false}
            onHide={params?.onHide}>
            <Modal.Header closeButton className="border-0 closeB mapClose flex-row-reverse position-relative">
                <svg role="button" className="position-absolute top-0 end-0 me-3 mt-3" width="18" height="18" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.11438 21.1144C0.0729837 22.1558 0.0729837 23.8442 1.11438 24.8856C2.15578 25.927 3.84422 25.927 4.88562 24.8856L1.11438 21.1144ZM24.8856 4.88562C25.927 3.84422 25.927 2.15578 24.8856 1.11438C23.8442 0.0729837 22.1558 0.0729837 21.1144 1.11438L24.8856 4.88562ZM4.88562 1.11438C3.84422 0.0729837 2.15578 0.0729837 1.11438 1.11438C0.0729837 2.15578 0.0729837 3.84422 1.11438 4.88562L4.88562 1.11438ZM21.1144 24.8856C22.1558 25.927 23.8442 25.927 24.8856 24.8856C25.927 23.8442 25.927 22.1558 24.8856 21.1144L21.1144 24.8856ZM4.88562 24.8856L24.8856 4.88562L21.1144 1.11438L1.11438 21.1144L4.88562 24.8856ZM1.11438 4.88562L21.1144 24.8856L24.8856 21.1144L4.88562 1.11438L1.11438 4.88562Z" fill="black" />
                </svg>
            </Modal.Header>
            <Modal.Body className="">
                <Col>
                    {
                        isLoaded ? <GoogleMap
                            mapContainerStyle={{
                                width: 'auto',
                                height: '350px',
                                borderRadius: "8px"
                            }}
                            center={center}
                            zoom={10}
                            onClick={ev => {
                                console.log("latitide = ", ev.latLng.lat());
                                console.log("longitude = ", ev.latLng.lng());
                            }}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                        >
                            <Marker position={center} onClick={(e) => console.log("marker", e)} />
                        </GoogleMap> : ''
                    }
                </Col>
                {params?.children}
            </Modal.Body>
        </Modal>
    )
}

export default MapModel