import React, { useState } from 'react'
import { Col, Form, Button, Row } from 'react-bootstrap';
import { HiChevronRight } from 'react-icons/hi';
import { addClass } from '../../commanFunctions';

const DinerSignupEight = params => {
    const [pass, setPass] = useState('');

    const onNextPage = (event) => {
        event.preventDefault();
        addClass()
        setTimeout(() => {
            params.setStepNo(9)
        }, 1400)

    };

    const skipBtn = () => {
        addClass()
        setTimeout(() => {
            params.setStepNo(9)
        }, 1400)
    }

    const goBack = () => {
        addClass()
        setTimeout(() => {
            params.setStepNo(7)
        }, 1400)
    }

    return (
        <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
            <div className="front">
                <div className="heading">
                    <Row>
                        <Col>
                            <h2 className='small lh-1 fs-normal mb-2'>Signup</h2>
                        </Col>
                        <Col lg="auto" className="align-self-top text-start">
                            <Button className="fs18 themeColor text-decoration-none signup-link pe-0" variant="link" type="button" onClick={skipBtn}>
                                Skip <HiChevronRight />
                            </Button>
                        </Col>
                    </Row>
                    <p className="semibold m-0">Please input your referral code, if you have any. Otherwise <br /> press skip.</p>
                </div>
                <Col as="section" className="stepsBar pt-4">
                    <Col as="ul" className="d-flex justify-content-between list-unstyled">
                        <li className="p2 active first"><span>1</span></li>
                        <li className="p2 active"><span>2</span></li>
                        <li className="p2 active"><span>3</span></li>
                        <li className="p2 active"><span>4</span></li>
                        <li className="p2 last"><span>5</span></li>
                    </Col>
                </Col>
                <Form className="pt-3">
                    <Form.Group controlId="referralcode" label="Referral Code" className="mb-3 position-relative">
                        <Form.Control type='text' placeholder="Referral Code" onChange={(e) => setPass(e.target.value)} />
                    </Form.Group>

                    <Row className="mt-5">
                        <Col className="align-self-center">
                            <Button className="fs26 themeColor semibold text-decoration-none lh-1 p-0" variant="link" type="button" onClick={goBack}>
                                <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                Previous
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                                variant="primary"
                                type="button"
                                onClick={onNextPage}
                                disabled={pass.length > 0 ? false : true}>
                                Next
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </section>
    )
}
export default DinerSignupEight