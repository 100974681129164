import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Breadcrumb, Form, Button, FloatingLabel, Modal } from 'react-bootstrap';
import { FiChevronDown, FiMapPin } from "react-icons/fi";
import "./identity.css"
import AfterLoginHeader from '../../components/afterLoginHeader';
import Footer from '../../components/footer';
import ProfileMenu from '../../components/profilemenu';
import DatePicker from "react-datepicker";
import { BsCalendar2 } from 'react-icons/bs';
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {UserProfileVerification} from '../../queries/queries'


const IdentityStep04 = (params) => {

    console.log(params)

    const [errorMessage, setErrorMessage] = useState(undefined);

    const onNextpage = (event) => {
        event.preventDefault();
        if (params.gender === "" || params.gender === "not selected") {
            setErrorMessage({
                error: "empty gender",
                message: "Please Enter Gender",
            });
        } else if (params.citizenship === "") {
            setErrorMessage({
                error: "empty citizenship",
                message: "Please Enter Citizenship",
            });
        } else if (params.address === "") {
            setErrorMessage({
                error: "empty address",
                message: "Please Enter Address",
            });
        } else {
            setTimeout(() => {
                params.setPopup(true);
                // params.setStepNo(true);
            }, 1400);
        }
    };

    const goBack = () => {
        setTimeout(() => {
          params.setStepNo(3);
        }, 100);
      };

    const navigate = useNavigate();
    // const goBack = () => navigate(-1);

    const [show, setShow] = useState(false);
    const [show01, setShow01] = useState(false);
    const handleClose = () => setShow(false);
    const handleClose01 = () => setShow01(false);

    const profileVerification =UserProfileVerification()

    const [verification, { data, error }] = useMutation(profileVerification, {
        variables: {
            name: params.fullname,
            dob: params.dob,
            gender:params.gender,
            citizenship:params.citizenship,
            nric_no:params.nricNumber,
            nricfront:params.updateFrontFile,
            nricback:params.updateBackFile,
            address:params.address,
            profile_amendment:params.profileAmendment
        },
      });
      
    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0"><AfterLoginHeader /></Col>
                </Row>
                {/* <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col> */}
                <Row className="dblock">
                    <Col lg="3" className="ps-0 profilemenu outline-none" tabindex="-1" id="myprofile01" aria-labelledby="myprofile01Label"><ProfileMenu /></Col>
                    <Col lg="9" as="div" className="userAcc pt-4 pe-xl-5">
                        <h2 className="h2_large ms-md-4 mb-0 GrayComn">My Profile</h2>
                        <Breadcrumb className="ms-md-4 mb-5">
                            <Breadcrumb.Item href="/">Account Settings</Breadcrumb.Item>
                            <Breadcrumb.Item href="/">Manage  Payments</Breadcrumb.Item>
                        </Breadcrumb>
                        <Col as="section" className="stepWrap ms-md-4 pt-md-5">
                            <Row className="text-center justify-content-center pb-2">
                                <Col xs={12} lg="10" xl="7" className="text-start">
                                    <h2 className="fs22 GrayComn semibold">Please confirm your personal registration details.</h2>
                                    <Col lg="12" as="section" className="stepsBar2 pt-4">
                                        <Col as="ul" className="list-unstyled">
                                            <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(100%/1.03)" }}>4</span></Col>
                                        </Col>
                                    </Col>
                                </Col>
                            </Row>
                            <Row className="text-center justify-content-center">
                                <Col lg="7">
                                    <Row>
                                        <Col as="section" className="signpUser">
                                            <Form className='accForm text-start' onSubmit={onNextpage} >

                                                <FloatingLabel controlId="floatingInput" label="Gender" className="mb-3">
                                                    <Form.Select
                                                        className="semibold"
                                                        aria-label="Floating label select example"
                                                       
                                                        onChange={(e) => {
                                                            if (!e.target.value) {
                                                                setErrorMessage({
                                                                    error: "empty gender",
                                                                    message: "Please Enter Gender",
                                                                });
                                                            } else {
                                                                setErrorMessage(undefined);
                                                            }
                                                            params.setGender(e.target.value);
                                                        }}>
                                                        <option value="not selected"> {params.gender}</option>
                                                        <option value="MALE">Male</option>
                                                        <option value="FEMALE">Female</option>
                                                        <option value="OTHER">Other</option>
                                                        <option value="Do Not Disclose">Do Not Disclose</option>
                                                    </Form.Select>

                                                    {errorMessage && errorMessage.error === "empty gender" && (
                                                        <div className="invalid-feedback d-block">
                                                            {errorMessage.message}
                                                        </div>
                                                    )}
                                                </FloatingLabel>

                                                <FloatingLabel controlId="floatingInput" label="Citizenship" className="mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Citizenship"
                                                        value={params.citizenship}
                                                        onChange={(e) => {
                                                            if (!e.target.value) {
                                                                setErrorMessage({
                                                                    error: "empty citizenship",
                                                                    message: "Please Enter Citizenship",
                                                                });
                                                            } else {
                                                                setErrorMessage(undefined);
                                                            }
                                                            params.setCitizenship(e.target.value);
                                                        }}
                                                    />
                                                    {errorMessage &&
                                                        errorMessage.error === "empty citizenship" && (
                                                            <div className="invalid-feedback d-block">
                                                                {errorMessage.message}
                                                            </div>
                                                        )}
                                                </FloatingLabel>

                                                <FloatingLabel controlId="floatingInput" label="Address" className="mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Address"
                                                        value={params.address}
                                                        onChange={(e) => {
                                                            if (!e.target.value) {
                                                                setErrorMessage({
                                                                    error: "empty address",
                                                                    message: "Please Enter Address",
                                                                });
                                                            } else {
                                                                setErrorMessage(undefined);
                                                            }
                                                            params.setAddress(e.target.value);
                                                        }}
                                                    />
                                                    <FiMapPin className="calendarIcon position-absolute top-0 end-0 mt-4 me-3" size="20" color="var(--theme-color)" />
                                                    {errorMessage && errorMessage.error === "empty address" && (
                                                        <div className="invalid-feedback d-block">
                                                            {errorMessage.message}
                                                        </div>
                                                    )}
                                                </FloatingLabel>

                                                {params.methodValue === "nric" ? (
                                                    <>
                                                        <div className="mb-2 lh-1 form-radio">
                                                            <input
                                                                name="group1"
                                                                type="radio"
                                                                id={`inline-radio-1`}
                                                                checked={params.profileAmendment === true}
                                                                onChange={(e) => params.setProfileAmendment(true)}
                                                                // defaultChecked
                                                            />
                                                            <label className="ms-3 pt-1 lh-sm semibold themeColor" htmlFor={`inline-radio-1`}>
                                                                Information is correct. No amendment required
                                                            </label>
                                                        </div>

                                                        <div className="lh-1 form-radio">
                                                            <input
                                                                name="group1"
                                                                type="radio"
                                                                id={`inline-radio-2`}
                                                                checked={params.profileAmendment === false}
                                                                onChange={(e) => params.setProfileAmendment(false)}
                                                            />
                                                            <label className="ms-3 pt-1 lh-sm semibold themeColor" htmlFor={`inline-radio-2`}>
                                                                Information is incorrect. Proceed to amend
                                                            </label>
                                                        </div>
                                                    </>
                                                ) : null}

                                                <Row className="pt-3 mt-2">
                                                    <Col className="align-self-center mb-4">

                                                        <Button className="fs26 themeColor semibold text-decoration-none lh-1 p-0" variant="link" type="button" onClick={goBack}>
                                                            <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                                            Previous
                                                        </Button>
                                                    </Col>
                                                    <Col lg="4" className="mb-4">
                                                        <Button  className="w-100 py-2 lh-lg border-0 hvr-float-shadow rounded-pill" variant="primary"  type='submit' onClick={verification}>Complete</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}
export default IdentityStep04