import React from 'react'
import { Container, Row, Col, Button, Image, Table } from 'react-bootstrap';
import { HiOutlineLocationMarker } from "react-icons/hi";
import { GiBlackFlag } from 'react-icons/gi';
import "react-pro-sidebar/dist/css/styles.css";
import "./careers.css";
import Header from "../components/header";
import Footer from "../components/footer";
import { FaCircle } from 'react-icons/fa';
import AfterLoginHeader from '../components/afterLoginHeader';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';


const Career = () => { 
    const navigate = useNavigate();

    
    const RouteCareer =()=>{
        navigate("/career02")
    }
    
    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>                
                <Row>
                    <Col className="px-0">
                        <AfterLoginHeader />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Image src={process.env.PUBLIC_URL+"assets/careerBanner.png"} alt="mainbanner" className="img-fluid" />
                    </Col>
                </Row>
                <br />
                <Row className="mt-4">
                    <Col md={11} className="m-auto">
                        <div className="mx-md-4 checkoutAdd">
                        <br /><br /><br />
                            <Row>
                                <Col md="6" className=""><p className="fs30 semibold GrayComn lh-sm">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p></Col>
                                <Col md="6" className=""><p className="fs30 semibold GrayComn lh-sm">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p></Col>
                            </Row>
                            <Row className="mt-md-5 pt-md-5 pb-md-5 heighspace">
                                <Col md="6">
                                    <figure className="figure carFig position-relative">
                                        <img src={process.env.PUBLIC_URL+"assets/careerimg01.png"} className="border08 figure-img img-fluid" alt="careerpicture" />
                                        <figcaption className="figure-caption text-center text-white">
                                            <h2 className="fs52vw semibold">Mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim amet veniam consequat sunt nostrud amet.</h2>
                                            <Col as="p" className="pt-4 mt-4 mb-0">
                                                <Col as="span" className="cafeImage border border-2 border-danger rounded-pill p-1 bg-white d-inline-block">
                                                    <img src={process.env.PUBLIC_URL+"assets/black_scoop.png"} 
                                                    width="92" 
                                                    height="92" 
                                                    className="img-fluid border border-2 border-danger rounded-pill p-0"
                                                    alt="cafepic" />
                                                </Col>
                                                <Col as="strong" className="d-block semibold fs26 mt-1">Annette Black</Col>
                                                <Col as="span" className="d-block fs20 regular">Mollit non deserunt ullamco est sit aliqua</Col>
                                            </Col>
                                        </figcaption>
                                    </figure>
                                </Col>
                                <Col md="6">
                                    <figure className="figure carFig position-relative">
                                        <img src={process.env.PUBLIC_URL+"assets/careerimg02.png"} className="border08 figure-img img-fluid" alt="careerpicture" />
                                        <figcaption className="figure-caption text-center text-white">
                                            <h2 className="fs52vw semibold">Mollit non deserunt ullamco est sit aliqua dolor do amet sintduis enim amet veniam consequat sunt nostrud amet.</h2>
                                            <Col as="p" className="pt-4 mt-4 mb-0">
                                                <Col as="span" className="cafeImage border border-2 border-danger rounded-pill p-1 bg-white d-inline-block">
                                                    <img src={process.env.PUBLIC_URL+"assets/black_scoop.png"} 
                                                    width="92" 
                                                    height="92" 
                                                    className="img-fluid border border-2 border-danger rounded-pill p-0"
                                                    alt="cafepic" />
                                                </Col>
                                                <Col as="strong" className="d-block semibold fs26 mt-1">Annette Black</Col>
                                                <Col as="span" className="d-block fs20 regular">Mollit non deserunt ullamco est sit aliqua</Col>
                                            </Col>
                                        </figcaption>
                                    </figure>
                                </Col>
                            </Row>
                            <Row className="mt-md-5 pt-md-5 heighspace01">
                                <Col as="section" className="cartBox openrole offset-md-2">
                                    <Row>
                                        <Col className="textcenter ps-0">
                                            <figure className="figure position-relative">
                                                <img src={process.env.PUBLIC_URL+"assets/careerimg03.png"} className="border08 figure-img img-fluid d-block mb-5" alt="careerpicture" />
                                                <img src={process.env.PUBLIC_URL+"assets/Framemix.png"} className="userfigure figure-img img-fluid d-block position-absolute m-auto" alt="careerpicture" />
                                            </figure>
                                            <Button className="px-5 py-2 lh-lg border-0" variant="primary">See All Roles</Button>
                                        </Col>
                                        <Col md="8">
                                        <Table borderless className="table_role mb-0">
                                            <thead>
                                                <tr>
                                                    <th className='fs26 semibold GrayComn pt-0'>Open Roles</th>
                                                    <th className='fs26 semibold GrayComn pt-0 text-end text-nowrap'><HiOutlineLocationMarker size="20" color="var(--theme-color)" /> Location</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="border-color82" onClick={RouteCareer}>
                                                    <td className="pt-4">
                                                        <h2 className="fs30 semibold GrayComn">Annette Black Project Manager</h2>
                                                        <p className='fs16 semibold themeColor'>
                                                            <span className='profile_time pe-1'>Full-Time <FaCircle size="6" color="var(--theme-color)" /></span> 
                                                            Service Delivery, <span className='profile_name'>Project</span>
                                                        </p>
                                                    </td>
                                                    <td className="text-end pt-4">
                                                        <Col as="span" className="flagCircle rounded-pill bgLight d-inline-block">
                                                            <GiBlackFlag size="60" color="var(--theme-color)" />
                                                        </Col>
                                                    </td>
                                                </tr>
                                                <tr className="border-color82" onClick={RouteCareer}>  
                                                    <td className="pt-4">
                                                        <h2 className="fs30 semibold GrayComn">Annette Black Project Manager</h2>
                                                        <p className='fs16 semibold themeColor'>
                                                            <span className='profile_time pe-1'>Full-Time <FaCircle size="6" color="var(--theme-color)" /></span> 
                                                            Service Delivery, <span className='profile_name'>Project</span>
                                                        </p>
                                                    </td>
                                                    <td className="text-end pt-4">
                                                        <Col as="span" className="flagCircle rounded-pill bgLight d-inline-block">
                                                            <GiBlackFlag size="60" color="var(--theme-color)" />
                                                        </Col>
                                                    </td>
                                                </tr>
                                                <tr className="border-color82" onClick={RouteCareer}>
                                                    <td className="pt-4">
                                                        <h2 className="fs30 semibold GrayComn">Annette Black Project Manager</h2>
                                                        <p className='fs16 semibold themeColor'>
                                                            <span className='profile_time pe-1'>Full-Time <FaCircle size="6" color="var(--theme-color)" /></span> 
                                                            Service Delivery, <span className='profile_name'>Project</span>
                                                        </p>
                                                    </td>
                                                    <td className="text-end pt-4">
                                                        <Col as="span" className="flagCircle rounded-pill bgLight d-inline-block">
                                                            <GiBlackFlag size="60" color="var(--theme-color)" />
                                                        </Col>
                                                    </td>
                                                </tr>
                                                <tr className="border-color82" onClick={RouteCareer}>
                                                    <td className="pt-4">
                                                        <h2 className="fs30 semibold GrayComn">Annette Black Project Manager</h2>
                                                        <p className='fs16 semibold themeColor'>
                                                            <span className='profile_time pe-1'>Full-Time <FaCircle size="6" color="var(--theme-color)" /></span> 
                                                            Service Delivery, <span className='profile_name'>Project</span>
                                                        </p>
                                                    </td>
                                                    <td className="text-end pt-4">
                                                        <Col as="span" className="flagCircle rounded-pill bgLight d-inline-block">
                                                            <GiBlackFlag size="60" color="var(--theme-color)" />
                                                        </Col>
                                                    </td>
                                                </tr>
                                                <tr className="border-color83" onClick={RouteCareer}>
                                                    <td className="pt-4">
                                                        <h2 className="fs30 semibold GrayComn">Annette Black Project Manager</h2>
                                                        <p className='fs16 semibold themeColor'>
                                                            <span className='profile_time pe-1'>Full-Time <FaCircle size="6" color="var(--theme-color)" /></span> 
                                                            Service Delivery, <span className='profile_name'>Project</span>
                                                        </p>
                                                    </td>
                                                    <td className="text-end pt-4">
                                                        <Col as="span" className="flagCircle rounded-pill bgLight d-inline-block">
                                                            <GiBlackFlag size="60" color="var(--theme-color)" />
                                                        </Col>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <br /><br /><br />
            </Container>
            <br /><br />
            <Footer />
        </div>
    )    
}
export default Career