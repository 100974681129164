import React from 'react'
import { Container, Row, Col, Card, Figure, Nav } from 'react-bootstrap';
import "react-pro-sidebar/dist/css/styles.css";
import "./careers.css";
import Slider from "react-slick";
// import Header from "../components/header";
import AfterLoginHeader from '../components/afterLoginHeader';
import Footer from "../components/footer";


const AboutUs = () => {    
    
    //modal
    const MeetTheTeam = {
        dots: true,
        infinite: true,
        swipeToSlide: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: "0",
        centerMode: true,
        easing: 'cubic',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2
                }            
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    };
    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>                
                <Row>
                    <Col className="px-0">
                        <AfterLoginHeader />
                    </Col>
                </Row>
                <Row>
                    <Col className="aboutbanner p-0 position-relative">
                        <figure className="figure position-absolute">
                            <img src={process.env.PUBLIC_URL+"assets/aboutbg.png"} className="figure-img img-fluid d-block" alt="careerpicture" />
                        </figure>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={11} className="m-auto">
                        <div className="mx-md-4 checkoutAdd">
                            <Col as="section" className="abtUS">
                                <figure className="figure mainBanner position-relative text-center w-100">
                                    <h2 className="fs80vw semibold GrayDark lh-md">
                                        <span className="themeColor">Empowering</span> Businesses for <br />Greater thinks.
                                    </h2>
                                    <p className="fs30 semibold GrayDark pt-2 lh-sm mb-0 pb-4">WLorem ipsum dolor sit amet, consectetur adipiscing <br />elit, sed do eiusmod tempor incididunt ut labore et.</p>
                                    <img src={process.env.PUBLIC_URL+"assets/aboutpic.png"} className="border08 pt-5 figure-img img-fluid d-block" alt="careerpicture" />
                                </figure>
                                <Row className="rateGrayBox text-center mt-md-5 pt-md-5 pb-4 row-cols-sm-1 row-cols-md-3">
                                    <Col>
                                        <Card.Body className="d-inline-block avaiRes card-body py-5 px-4">
                                            <Card.Title className="fs82 semibold GrayDark card-title h5 lh-1 mb-0">&nbsp;500K <span className="themeColor position-relative">+</span></Card.Title>
                                            <Card.Text className="fs24 semibold GrayDark">Restaurent Available&nbsp;</Card.Text>                                            
                                        </Card.Body>
                                    </Col>
                                    <Col>
                                        <Card.Body className="d-inline-block avaiRes card-body py-5 px-4">
                                            <Card.Title className="fs82 semibold GrayDark card-title h5 lh-1 mb-0">&nbsp;500K <span className="themeColor position-relative">+</span></Card.Title>
                                            <Card.Text className="fs24 semibold GrayDark">Restaurent Available&nbsp;</Card.Text>                                            
                                        </Card.Body>
                                    </Col>
                                    <Col>
                                        <Card.Body className="d-inline-block avaiRes card-body py-5 px-4">
                                            <Card.Title className="fs82 semibold GrayDark card-title h5 lh-1 mb-0">&nbsp;500K <span className="themeColor position-relative">+</span></Card.Title>
                                            <Card.Text className="fs24 semibold GrayDark">Restaurent Available&nbsp;</Card.Text>                                            
                                        </Card.Body>
                                    </Col>
                                </Row>
                                <Col as="div" className="d-md-flex justify-content-center bd-highlight ourmission pt-2 mt-md-5 pt-md-5">
                                    <Col as="div" className="bd-highlight">
                                        <Figure className="figure">
                                           <Figure.Image src={process.env.PUBLIC_URL+"assets/cantelli-food.png"} className="border08 figure-img img-fluid" alt="careerpicture" />
                                            <Figure.Caption className="fs62 semibold GrayDark px-3">Our <span className="themeColor">Mission</span></Figure.Caption>
                                            <Figure.Caption className="fs20 semibold GrayDark lh-sm px-3 mb-4">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                                Ut enim ad minim veniam, quis nostrud exercitation ullamco Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco 
                                            </Figure.Caption>
                                        </Figure>
                                    </Col>
                                    <Col as="div" className="bd-highlight">
                                        <Figure className="figure d-md-flex flex-md-column-reverse">
                                            <Figure.Image src={process.env.PUBLIC_URL+"assets/boltneva-food.png"} className="mb-2 border08 figure-img img-fluid mb-md-4 mb-4" alt="careerpicture" />
                                            <Figure.Caption className="fs20 semibold GrayDark lh-sm px-3 pb-4">
                                            <Figure.Caption className="fs62 semibold GrayDark lh-1 pb-2 pb-md-4 ">Our <span className="themeColor">Vision</span></Figure.Caption>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                                Ut enim ad minim veniam, quis nostrud exercitation ullamco Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco 
                                            </Figure.Caption>
                                        </Figure>
                                    </Col>
                                </Col>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Col as="div" className="coreSection">
                <Col md={11} className="m-auto">
                    <Row className="mx-md-4">
                        <Col md="12" lg={6} className='fs62 bold GrayDark lh-sm vlBox'>
                            Our Five Core <br /><span className="themeColor">Values</span>
                            <p className='fs22 gray82 semibold w-75 mt-4'>Lorem ipsum dolor sit amet consectetur adipiscing elit tortor eu egestas morbi sem vulputate 
                            etiam facilisis pellentesque ut quis ipsum dolor sit amet.</p>
                        </Col>
                        <Col md="12" lg={6} className="vlBox02">
                            <Card className='shadow-sm border08 p-5 mb-5'>
                                <Card.Body>
                                    <Card.Text className='fs28 semibold GrayComn'>2019 <Figure.Image src={process.env.PUBLIC_URL+"assets/shield_Icon.svg"} className="figure-img img-fluid m-0 align-top ms-4" width={38} alt="scalablevectorgraphics" /></Card.Text>
                                    <Card.Title className='fs52 semibold GrayComn mb-5 lh-1'>Lorem Lipsum was Found</Card.Title>
                                    <Card.Text className='fs22 semibold gray82'>Lorem ipsum dolor sit amet consectetur adipiscing elit tortor eu egestas morbi sem vulputate etiam facilisis pellentesque ut quis ipsum dolor sit amet tortor is euconsectetur ut quis adipiscing elit tortor eu egestas morbi lorem ipsum dolor Lorem ipsum dolor sit amet consectetur adipiscing elit tortor eu egestas morbi sem vulputate etiam facilisis pellentesque ut quis euconsectetur adipiscing.</Card.Text>
                                </Card.Body>
                            </Card>
                            <Card className='shadow-sm border08 p-5 mb-5'>
                                <Card.Body>
                                    <Card.Text className='fs28 semibold GrayComn'>2019 <Figure.Image src={process.env.PUBLIC_URL+"assets/shield_Icon.svg"} className="figure-img img-fluid m-0 align-top ms-4" width={38} alt="scalablevectorgraphics" /></Card.Text>
                                    <Card.Title className='fs52 semibold GrayComn mb-5 lh-1'>Lorem Lipsum was Found</Card.Title>
                                    <Card.Text className='fs22 semibold gray82'>Lorem ipsum dolor sit amet consectetur adipiscing elit tortor eu egestas morbi sem vulputate etiam facilisis pellentesque ut quis ipsum dolor sit amet tortor is euconsectetur ut quis adipiscing elit tortor eu egestas morbi lorem ipsum dolor Lorem ipsum dolor sit amet consectetur adipiscing elit tortor eu egestas morbi sem vulputate etiam facilisis pellentesque ut quis euconsectetur adipiscing.</Card.Text>
                                </Card.Body>
                            </Card>
                            <Card className='shadow-sm border08 p-5 mb-5'>
                                <Card.Body>
                                    <Card.Text className='fs28 semibold GrayComn'>2019 <Figure.Image src={process.env.PUBLIC_URL+"assets/shield_Icon.svg"} className="figure-img img-fluid m-0 align-top ms-4" width={38} alt="scalablevectorgraphics" /></Card.Text>
                                    <Card.Title className='fs52 semibold GrayComn mb-5 lh-1'>Lorem Lipsum was Found</Card.Title>
                                    <Card.Text className='fs22 semibold gray82'>Lorem ipsum dolor sit amet consectetur adipiscing elit tortor eu egestas morbi sem vulputate etiam facilisis pellentesque ut quis ipsum dolor sit amet tortor is euconsectetur ut quis adipiscing elit tortor eu egestas morbi lorem ipsum dolor Lorem ipsum dolor sit amet consectetur adipiscing elit tortor eu egestas morbi sem vulputate etiam facilisis pellentesque ut quis euconsectetur adipiscing.</Card.Text>
                                </Card.Body>
                            </Card>
                            <Card className='shadow-sm border08 p-5'>
                                <Card.Body>
                                    <Card.Text className='fs28 semibold GrayComn'>2019 <Figure.Image src={process.env.PUBLIC_URL+"assets/shield_Icon.svg"} className="figure-img img-fluid m-0 align-top ms-4" width={38} alt="scalablevectorgraphics" /></Card.Text>
                                    <Card.Title className='fs52 semibold GrayComn mb-5 lh-1'>Lorem Lipsum was Found</Card.Title>
                                    <Card.Text className='fs22 semibold gray82'>Lorem ipsum dolor sit amet consectetur adipiscing elit tortor eu egestas morbi sem vulputate etiam facilisis pellentesque ut quis ipsum dolor sit amet tortor is euconsectetur ut quis adipiscing elit tortor eu egestas morbi lorem ipsum dolor Lorem ipsum dolor sit amet consectetur adipiscing elit tortor eu egestas morbi sem vulputate etiam facilisis pellentesque ut quis euconsectetur adipiscing.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>                
            </Col>
            <Col as="div" className="teamSection mt-5 pt-5 mb-md-5">
                <Col md={11} className="m-auto">
                    <Row>
                      <Col md="12" className='fs62 semibold GrayDark lh-sm mb-5 pb-3'>
                        Meet our <span className="themeColor">Management</span> Team
                        <p className='fs22 gray82 semibold w-75 mt-2 lh-1'>Lorem ipsum dolor sit amet consectetur adipiscing elit tortor eu egestas morbi sem vulputate</p>
                    </Col>
                    <Slider className="featureProd teamslider" {...MeetTheTeam}>
                        <Card className='avaiRes pt-5 px-5 w-items'>
                            <Card.Body className='p-0'>
                                <Card.Title className='fs32 semibold GrayDark mb-2 lh-1'>Kathryn Murphy</Card.Title>
                                <Card.Subtitle className='fs18 semibold themeColor'>VP of Project Manager</Card.Subtitle>
                                <Card.ImgOverlay className='teamBox position-static pb-0 pt-5'>
                                <Figure.Image src={process.env.PUBLIC_URL+"assets/menimg.png"} 
                                className="figure-img img-fluid m-0 align-top" alt="scalablevectorgraphics" />
                                </Card.ImgOverlay>
                                <Card.ImgOverlay className='socialDown socialicons start-auto p-0'>
                                
                                    <Nav.Link className="d-block facebook border-0 text-decoration-none me-0 mb-4 p-0" href="/">
                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                                    <circle cx="25" cy="25" r="25" fill="#1877F2"/>                                        
                                    <circle cx="25" cy="25" r="25" fill="#1877F2"/>                                        
                                    <g mask="url(#mask0_2255_128542)">
                                    <path d="M34.624 32.3301L35.7123 25.1456H28.9041V20.4833C28.9041 18.5178 29.8551 16.6019 32.9046 16.6019H36V10.4854C36 10.4854 33.1907 10 30.505 10C24.8977 10 21.2329 13.4408 21.2329 19.6699V25.1456H15V32.3301H21.2329V49.6981C22.4826 49.8966 23.7636 50 25.0685 50C26.3734 50 27.6543 49.8966 28.9041 49.6981V32.3301H34.624Z" fill="white"/>
                                    </g>
                                    </svg>
                                    </Nav.Link>
                                    <Nav.Link className="d-block linkedin border-0 text-decoration-none me-0 mb-4 p-0" href="/">
                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                                    <circle cx="25" cy="25" r="25" fill="#2867B2"/>
                                        <path d="M18.637 20.7227H13.3125V36.7386H18.637V20.7227Z" fill="white"/>
                                        <path d="M16.0119 13C14.1903 13 13 14.1976 13 15.7674C13 17.3041 14.1541 18.5348 15.9415 18.5348H15.9759C17.8324 18.5348 18.988 17.3041 18.988 15.7674C18.9536 14.1976 17.8326 13 16.0119 13Z" fill="white"/>
                                        <path d="M31.7049 20.3477C28.8806 20.3477 27.6155 21.9009 26.9071 22.9919V20.7243H21.584C21.6546 22.2266 21.584 36.7401 21.584 36.7401H26.9071V27.7957C26.9071 27.317 26.9415 26.8383 27.0826 26.4962C27.4668 25.5401 28.3433 24.5497 29.814 24.5497C31.7394 24.5497 32.5107 26.0189 32.5107 28.171V36.7401H37.8341V27.5557C37.8341 22.6362 35.2075 20.3477 31.7049 20.3477Z" fill="white"/>
                                    </svg>
                                    </Nav.Link>
                                    <Nav.Link className="d-block twitter border-0 text-decoration-none me-0 mb-4 p-0" href="/">
                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                                    <circle cx="25" cy="25" r="25" fill="#1DA1F2"/>
                                    <g clipPath="url(#clip0_2255_128547)">
                                    <path d="M20.1199 36.564C31.0634 36.564 37.049 27.4974 37.049 19.6349C37.049 19.3775 37.049 19.1211 37.0316 18.8659C38.196 18.0236 39.2012 16.9808 40 15.7861C38.9141 16.2673 37.7621 16.5827 36.5826 16.7222C37.8244 15.9788 38.7544 14.8088 39.1984 13.4313C38.0305 14.1243 36.7527 14.6127 35.4203 14.8755C34.2953 13.6792 32.7241 13 31.082 13C27.8149 13 25.1266 15.6884 25.1266 18.9554C25.1266 19.4087 25.1783 19.8605 25.2807 20.3019C20.4993 20.0623 16.0381 17.8006 13.0184 14.0855C11.4481 16.7887 12.2606 20.292 14.8605 22.028C13.9137 22 12.9873 21.7445 12.16 21.2833V21.3587C12.1608 24.1802 14.1677 26.6325 16.9334 27.1912C16.0575 27.43 15.1383 27.4649 14.2469 27.2933C15.0244 29.711 17.2664 31.3775 19.8056 31.4252C17.6991 33.0806 15.0955 33.9799 12.4164 33.9772C11.943 33.9763 11.47 33.9476 11 33.8913C13.7207 35.6373 16.8872 36.5636 20.1199 36.5593" fill="white"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_2255_128547">
                                    <rect width="29" height="29" fill="white" transform="translate(10 11)"/>
                                    </clipPath>
                                    </defs>
                                    </svg>
                                    </Nav.Link>                                        
                                
                                </Card.ImgOverlay>
                            </Card.Body>
                        </Card>
                        <Card className='avaiRes pt-5 px-5 w-items'>
                            <Card.Body className='p-0'>
                                <Card.Title className='fs32 semibold GrayDark mb-2 lh-1'>Kathryn Murphy</Card.Title>
                                <Card.Subtitle className='fs18 semibold themeColor'>VP of Project Manager</Card.Subtitle>
                                <Card.ImgOverlay className='teamBox position-static pb-0 pt-5'>
                                <Figure.Image src={process.env.PUBLIC_URL+"assets/menimg.png"} 
                                className="figure-img img-fluid m-0 align-top" alt="scalablevectorgraphics" />
                                </Card.ImgOverlay>
                                <Card.ImgOverlay className='socialDown socialicons start-auto p-0'>
                                
                                    <Nav.Link className="d-block facebook border-0 text-decoration-none me-0 mb-4 p-0" href="/">
                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                                    <circle cx="25" cy="25" r="25" fill="#1877F2"/>                                        
                                    <circle cx="25" cy="25" r="25" fill="#1877F2"/>                                        
                                    <g mask="url(#mask0_2255_128542)">
                                    <path d="M34.624 32.3301L35.7123 25.1456H28.9041V20.4833C28.9041 18.5178 29.8551 16.6019 32.9046 16.6019H36V10.4854C36 10.4854 33.1907 10 30.505 10C24.8977 10 21.2329 13.4408 21.2329 19.6699V25.1456H15V32.3301H21.2329V49.6981C22.4826 49.8966 23.7636 50 25.0685 50C26.3734 50 27.6543 49.8966 28.9041 49.6981V32.3301H34.624Z" fill="white"/>
                                    </g>
                                    </svg>
                                    </Nav.Link>
                                    <Nav.Link className="d-block linkedin border-0 text-decoration-none me-0 mb-4 p-0" href="/">
                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                                    <circle cx="25" cy="25" r="25" fill="#2867B2"/>
                                        <path d="M18.637 20.7227H13.3125V36.7386H18.637V20.7227Z" fill="white"/>
                                        <path d="M16.0119 13C14.1903 13 13 14.1976 13 15.7674C13 17.3041 14.1541 18.5348 15.9415 18.5348H15.9759C17.8324 18.5348 18.988 17.3041 18.988 15.7674C18.9536 14.1976 17.8326 13 16.0119 13Z" fill="white"/>
                                        <path d="M31.7049 20.3477C28.8806 20.3477 27.6155 21.9009 26.9071 22.9919V20.7243H21.584C21.6546 22.2266 21.584 36.7401 21.584 36.7401H26.9071V27.7957C26.9071 27.317 26.9415 26.8383 27.0826 26.4962C27.4668 25.5401 28.3433 24.5497 29.814 24.5497C31.7394 24.5497 32.5107 26.0189 32.5107 28.171V36.7401H37.8341V27.5557C37.8341 22.6362 35.2075 20.3477 31.7049 20.3477Z" fill="white"/>
                                    </svg>
                                    </Nav.Link>
                                    <Nav.Link className="d-block twitter border-0 text-decoration-none me-0 mb-4 p-0" href="/">
                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                                    <circle cx="25" cy="25" r="25" fill="#1DA1F2"/>
                                    <g clipPath="url(#clip0_2255_128547)">
                                    <path d="M20.1199 36.564C31.0634 36.564 37.049 27.4974 37.049 19.6349C37.049 19.3775 37.049 19.1211 37.0316 18.8659C38.196 18.0236 39.2012 16.9808 40 15.7861C38.9141 16.2673 37.7621 16.5827 36.5826 16.7222C37.8244 15.9788 38.7544 14.8088 39.1984 13.4313C38.0305 14.1243 36.7527 14.6127 35.4203 14.8755C34.2953 13.6792 32.7241 13 31.082 13C27.8149 13 25.1266 15.6884 25.1266 18.9554C25.1266 19.4087 25.1783 19.8605 25.2807 20.3019C20.4993 20.0623 16.0381 17.8006 13.0184 14.0855C11.4481 16.7887 12.2606 20.292 14.8605 22.028C13.9137 22 12.9873 21.7445 12.16 21.2833V21.3587C12.1608 24.1802 14.1677 26.6325 16.9334 27.1912C16.0575 27.43 15.1383 27.4649 14.2469 27.2933C15.0244 29.711 17.2664 31.3775 19.8056 31.4252C17.6991 33.0806 15.0955 33.9799 12.4164 33.9772C11.943 33.9763 11.47 33.9476 11 33.8913C13.7207 35.6373 16.8872 36.5636 20.1199 36.5593" fill="white"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_2255_128547">
                                    <rect width="29" height="29" fill="white" transform="translate(10 11)"/>
                                    </clipPath>
                                    </defs>
                                    </svg>
                                    </Nav.Link>                                        
                                
                                </Card.ImgOverlay>
                            </Card.Body>
                        </Card>
                        <Card className='avaiRes pt-5 px-5 w-items'>
                            <Card.Body className='p-0'>
                                <Card.Title className='fs32 semibold GrayDark mb-2 lh-1'>Kathryn Murphy</Card.Title>
                                <Card.Subtitle className='fs18 semibold themeColor'>VP of Project Manager</Card.Subtitle>
                                <Card.ImgOverlay className='teamBox position-static pb-0 pt-5'>
                                <Figure.Image src={process.env.PUBLIC_URL+"assets/menimg.png"} 
                                className="figure-img img-fluid m-0 align-top" alt="scalablevectorgraphics" />
                                </Card.ImgOverlay>
                                <Card.ImgOverlay className='socialDown socialicons start-auto p-0'>
                                
                                    <Nav.Link className="d-block facebook border-0 text-decoration-none me-0 mb-4 p-0" href="/">
                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                                    <circle cx="25" cy="25" r="25" fill="#1877F2"/>                                        
                                    <circle cx="25" cy="25" r="25" fill="#1877F2"/>                                        
                                    <g mask="url(#mask0_2255_128542)">
                                    <path d="M34.624 32.3301L35.7123 25.1456H28.9041V20.4833C28.9041 18.5178 29.8551 16.6019 32.9046 16.6019H36V10.4854C36 10.4854 33.1907 10 30.505 10C24.8977 10 21.2329 13.4408 21.2329 19.6699V25.1456H15V32.3301H21.2329V49.6981C22.4826 49.8966 23.7636 50 25.0685 50C26.3734 50 27.6543 49.8966 28.9041 49.6981V32.3301H34.624Z" fill="white"/>
                                    </g>
                                    </svg>
                                    </Nav.Link>
                                    <Nav.Link className="d-block linkedin border-0 text-decoration-none me-0 mb-4 p-0" href="/">
                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                                    <circle cx="25" cy="25" r="25" fill="#2867B2"/>
                                        <path d="M18.637 20.7227H13.3125V36.7386H18.637V20.7227Z" fill="white"/>
                                        <path d="M16.0119 13C14.1903 13 13 14.1976 13 15.7674C13 17.3041 14.1541 18.5348 15.9415 18.5348H15.9759C17.8324 18.5348 18.988 17.3041 18.988 15.7674C18.9536 14.1976 17.8326 13 16.0119 13Z" fill="white"/>
                                        <path d="M31.7049 20.3477C28.8806 20.3477 27.6155 21.9009 26.9071 22.9919V20.7243H21.584C21.6546 22.2266 21.584 36.7401 21.584 36.7401H26.9071V27.7957C26.9071 27.317 26.9415 26.8383 27.0826 26.4962C27.4668 25.5401 28.3433 24.5497 29.814 24.5497C31.7394 24.5497 32.5107 26.0189 32.5107 28.171V36.7401H37.8341V27.5557C37.8341 22.6362 35.2075 20.3477 31.7049 20.3477Z" fill="white"/>
                                    </svg>
                                    </Nav.Link>
                                    <Nav.Link className="d-block twitter border-0 text-decoration-none me-0 mb-4 p-0" href="/">
                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                                    <circle cx="25" cy="25" r="25" fill="#1DA1F2"/>
                                    <g clipPath="url(#clip0_2255_128547)">
                                    <path d="M20.1199 36.564C31.0634 36.564 37.049 27.4974 37.049 19.6349C37.049 19.3775 37.049 19.1211 37.0316 18.8659C38.196 18.0236 39.2012 16.9808 40 15.7861C38.9141 16.2673 37.7621 16.5827 36.5826 16.7222C37.8244 15.9788 38.7544 14.8088 39.1984 13.4313C38.0305 14.1243 36.7527 14.6127 35.4203 14.8755C34.2953 13.6792 32.7241 13 31.082 13C27.8149 13 25.1266 15.6884 25.1266 18.9554C25.1266 19.4087 25.1783 19.8605 25.2807 20.3019C20.4993 20.0623 16.0381 17.8006 13.0184 14.0855C11.4481 16.7887 12.2606 20.292 14.8605 22.028C13.9137 22 12.9873 21.7445 12.16 21.2833V21.3587C12.1608 24.1802 14.1677 26.6325 16.9334 27.1912C16.0575 27.43 15.1383 27.4649 14.2469 27.2933C15.0244 29.711 17.2664 31.3775 19.8056 31.4252C17.6991 33.0806 15.0955 33.9799 12.4164 33.9772C11.943 33.9763 11.47 33.9476 11 33.8913C13.7207 35.6373 16.8872 36.5636 20.1199 36.5593" fill="white"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_2255_128547">
                                    <rect width="29" height="29" fill="white" transform="translate(10 11)"/>
                                    </clipPath>
                                    </defs>
                                    </svg>
                                    </Nav.Link>                                        
                                
                                </Card.ImgOverlay>
                            </Card.Body>
                        </Card>
                        <Card className='avaiRes pt-5 px-5 w-items'>
                            <Card.Body className='p-0'>
                                <Card.Title className='fs32 semibold GrayDark mb-2 lh-1'>Kathryn Murphy</Card.Title>
                                <Card.Subtitle className='fs18 semibold themeColor'>VP of Project Manager</Card.Subtitle>
                                <Card.ImgOverlay className='teamBox position-static pb-0 pt-5'>
                                <Figure.Image src={process.env.PUBLIC_URL+"assets/menimg.png"} 
                                className="figure-img img-fluid m-0 align-top" alt="scalablevectorgraphics" />
                                </Card.ImgOverlay>
                                <Card.ImgOverlay className='socialDown socialicons start-auto p-0'>
                                
                                    <Nav.Link className="d-block facebook border-0 text-decoration-none me-0 mb-4 p-0" href="/">
                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                                    <circle cx="25" cy="25" r="25" fill="#1877F2"/>                                        
                                    <circle cx="25" cy="25" r="25" fill="#1877F2"/>                                        
                                    <g mask="url(#mask0_2255_128542)">
                                    <path d="M34.624 32.3301L35.7123 25.1456H28.9041V20.4833C28.9041 18.5178 29.8551 16.6019 32.9046 16.6019H36V10.4854C36 10.4854 33.1907 10 30.505 10C24.8977 10 21.2329 13.4408 21.2329 19.6699V25.1456H15V32.3301H21.2329V49.6981C22.4826 49.8966 23.7636 50 25.0685 50C26.3734 50 27.6543 49.8966 28.9041 49.6981V32.3301H34.624Z" fill="white"/>
                                    </g>
                                    </svg>
                                    </Nav.Link>
                                    <Nav.Link className="d-block linkedin border-0 text-decoration-none me-0 mb-4 p-0" href="/">
                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                                    <circle cx="25" cy="25" r="25" fill="#2867B2"/>
                                        <path d="M18.637 20.7227H13.3125V36.7386H18.637V20.7227Z" fill="white"/>
                                        <path d="M16.0119 13C14.1903 13 13 14.1976 13 15.7674C13 17.3041 14.1541 18.5348 15.9415 18.5348H15.9759C17.8324 18.5348 18.988 17.3041 18.988 15.7674C18.9536 14.1976 17.8326 13 16.0119 13Z" fill="white"/>
                                        <path d="M31.7049 20.3477C28.8806 20.3477 27.6155 21.9009 26.9071 22.9919V20.7243H21.584C21.6546 22.2266 21.584 36.7401 21.584 36.7401H26.9071V27.7957C26.9071 27.317 26.9415 26.8383 27.0826 26.4962C27.4668 25.5401 28.3433 24.5497 29.814 24.5497C31.7394 24.5497 32.5107 26.0189 32.5107 28.171V36.7401H37.8341V27.5557C37.8341 22.6362 35.2075 20.3477 31.7049 20.3477Z" fill="white"/>
                                    </svg>
                                    </Nav.Link>
                                    <Nav.Link className="d-block twitter border-0 text-decoration-none me-0 mb-4 p-0" href="/">
                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                                    <circle cx="25" cy="25" r="25" fill="#1DA1F2"/>
                                    <g clipPath="url(#clip0_2255_128547)">
                                    <path d="M20.1199 36.564C31.0634 36.564 37.049 27.4974 37.049 19.6349C37.049 19.3775 37.049 19.1211 37.0316 18.8659C38.196 18.0236 39.2012 16.9808 40 15.7861C38.9141 16.2673 37.7621 16.5827 36.5826 16.7222C37.8244 15.9788 38.7544 14.8088 39.1984 13.4313C38.0305 14.1243 36.7527 14.6127 35.4203 14.8755C34.2953 13.6792 32.7241 13 31.082 13C27.8149 13 25.1266 15.6884 25.1266 18.9554C25.1266 19.4087 25.1783 19.8605 25.2807 20.3019C20.4993 20.0623 16.0381 17.8006 13.0184 14.0855C11.4481 16.7887 12.2606 20.292 14.8605 22.028C13.9137 22 12.9873 21.7445 12.16 21.2833V21.3587C12.1608 24.1802 14.1677 26.6325 16.9334 27.1912C16.0575 27.43 15.1383 27.4649 14.2469 27.2933C15.0244 29.711 17.2664 31.3775 19.8056 31.4252C17.6991 33.0806 15.0955 33.9799 12.4164 33.9772C11.943 33.9763 11.47 33.9476 11 33.8913C13.7207 35.6373 16.8872 36.5636 20.1199 36.5593" fill="white"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_2255_128547">
                                    <rect width="29" height="29" fill="white" transform="translate(10 11)"/>
                                    </clipPath>
                                    </defs>
                                    </svg>
                                    </Nav.Link>                                        
                                
                                </Card.ImgOverlay>
                            </Card.Body>
                        </Card>
                        </Slider>
                    </Row>                    
                </Col>
            </Col>
            <br /><br />
            <Footer />
        </div>
    )    
}
export default AboutUs