import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FiHeart, FiShare2 } from "react-icons/fi";
import { GoClock, GoPrimitiveDot } from "react-icons/go";
import { IoDiamond } from "react-icons/io5";
import { useQuery } from "@apollo/client";
import "rc-slider/assets/index.css";
import "./meal.css";
import { useNavigate } from "react-router-dom";
import AfterLoginHeader from "../../components/afterLoginHeader";
import BeforeLoginHeader from "../../components/beforeLoginHeader";
import Footer from "../../components/footer";
import { allergiesOptions, dietaryPrefrences } from "../../utils/staticData";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { GetVendors, GetVendorsByLocation } from "../../queries/queries";
import { ItemDetailsGetApi } from "../../commanFunctions";
import CartModel from "../../components/Models/cartModel";
import AlertPopup from "../../components/alertPopup";

const FindMyMeal = (props) => {

  // useEffect(() => {
  //   document.getElementsByTagName('header')[0].classList.add('fixed-top')
  //   return () => {
  //     document.getElementsByTagName('header')[0].classList.remove('fixed-top')
  //   }
  // }, [])

  const navigation = useNavigate()
  const [selectedId, setSelectedId] = useState(null);
  const [map, setMap] = React.useState(null)
  const [showMap, setShowMap] = React.useState(false)
  const [show, setShow] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [switchMap, setSwitchMap] = useState('left')
  const [allvendors, setVendors] = useState([]);
  const [vendorData, setVendorData] = useState([])
  const [dietaryOptions, setDietaryOptions] = useState(dietaryPrefrences)
  const [allergyOptions, setAllergyOptions] = useState(allergiesOptions)
  const [initFilterValue, setInitFilterValue] = useState({
    address: '',
    lat: null,
    long: null,
    filter: {
      allergies: null,
      dietary_prefrence: null,
      price: null
    },
    sort: null,
    keyword: '',
    page: null,
    page_size: null,
    date: null
  })

  const vendors = GetVendors()
  const { data, error } = useQuery(vendors);
  const GetLocation = GetVendorsByLocation();
  const skipGetVendor = initFilterValue?.address === '' && (initFilterValue?.lat === null && initFilterValue?.long === null)
  const GetVendor = useQuery(GetLocation, { skip: skipGetVendor, variables: initFilterValue });
  const ItemDetails = ItemDetailsGetApi(Number(selectedId?.cribId), Number(selectedId?.itemId))
  const currentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      let tempFilterValue = { ...initFilterValue }
      tempFilterValue.lat = String(position.coords.latitude)
      tempFilterValue.long = String(position.coords.longitude)
      setInitFilterValue(tempFilterValue)

    })
  }

  useEffect(() => {
    currentLocation()
    if (data) {
      setVendors(data.getVendors);
    }
    if (error) {
      console.log(error)
    }
  }, [data, error]);

  useEffect(() => {
    if (GetVendor?.data?.getVendorsByLocation?.status) {
      setVendorData(GetVendor?.data?.getVendorsByLocation?.data)
    }
    if (GetVendor?.error) {
      console.log(GetVendor?.error)
    }
  }, [GetVendor?.data, GetVendor?.error])

  useEffect(() => {
    let tempFilterValue = { ...initFilterValue };

    if (dietaryOptions && dietaryOptions.length > 0) {
      let abc = [];
      dietaryOptions.map((item) => {
        if (item.checked && item.checked === true) {
          abc.push(item.value)
        }
      })
      tempFilterValue.filter.dietary_prefrence = abc.length > 0 ? abc : null;
      setInitFilterValue(tempFilterValue)
    }

    if (allergyOptions && allergyOptions.length > 0) {
      let abc = [];
      allergyOptions.map((item) => {
        if (item.checked && item.checked === true) {
          abc.push(item.value)
        }
      })
      tempFilterValue.filter.allergies = abc.length > 0 ? abc : null;
      setInitFilterValue(tempFilterValue)
    }

  }, [dietaryOptions, allergyOptions])

  const handleFilterCheckbox = (event, index) => {

    if (event.target.name === "dietPref") {
      let tempArray = [...dietaryOptions];
      tempArray[index].checked = event.target.checked;
      setDietaryOptions(tempArray);
      GetVendor.refetch({
        variables: initFilterValue
      })
    } else if (event.target.name === "allegies") {
      let tempArray = [...allergyOptions];
      tempArray[index].checked = event.target.checked;
      setAllergyOptions(tempArray);
      GetVendor.refetch({
        variables: initFilterValue
      })
    } else {
      console.log(event.target.checked)
    }
  }

  const heightMarks = {
    10: "$ Economy",
    20: "$$ Standard",
    30: "$$$ Plus",
    40: "$$$$ Premium",
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  })

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    slickGoTo: true,
  };
  const settings01 = {
    dots: false,
    infinite: false,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };

  const center = {
    lat: 26.854038,
    lng: 75.805001
  }

  //modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onToggleClick = () => {
    setSwitchMap("left")
  }
  const onToggleClick1 = () => {
    setSwitchMap("right")
  }

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds({
      lat: 1.4316785377697316,
      lng: 103.75985026359558
    });
    map.fitBounds(bounds);
    setMap(map)
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const handleCartModal = async (cribId, itemId) => {
    setSelectedId({ cribId: cribId, itemId: itemId })
    setShow(true)
  }

  const handleCart = async (data) => {
    if (data?.addItemCart?.status === "success") {
      setShow(false);
      setShowConfirmation(true)
      setTimeout(() => {
        setShowConfirmation(false)
      }, 2400);
    } else {
      setShowConfirmation(false)
      console.log(data?.addItemCart?.message)
    }
  }

  useEffect(() => {
    var element = document.getElementsByTagName("header");
    console.log("element=============>", element)
      element[0].classList.add("fix-class-used");
  }, []) 


  return (
    <div>
      {localStorage.getItem("token") ?
        <AfterLoginHeader /> :
        <BeforeLoginHeader />}
      <Container className="mealOne" fluid>
        <Row>
          <Col md="2" className="LeftSidebar px-0 h-100">
            <div className="h-100 overflow-auto pb-5">
              {/* ===============(This comment is useful, so don't remove it)=============== */}
              {/* <section className="FilterSection">
                <div className="headFilter">
                  <h2>Sort by</h2>
                  <p>Select which items to be displayed first.</p>
                </div>
                <div className="form-radio">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    value={"distance"}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Distance (Nearest)
                  </label>
                </div>
                <div className="form-radio">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    value={"price-lowest"}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                    Price (Lowest)
                  </label>
                </div>
                <div className="form-radio">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault3"
                    value={"price-lowest"}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault3">
                    Price (Lowest)
                  </label>
                </div>
                <div className="form-radio">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault4"
                    value={"price-lowest"}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault4">
                    Price (Lowest)
                  </label>
                </div>
              </section> */}

              {/* <section className="FilterSection">
                <div className="headFilter">
                  <h2>Host Experience</h2>
                  <p>Show only Hosts of this level.</p>
                </div>
                {HostExpOptions.map((element) => {
                  return (
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="hodtExp"
                        id={element.value}
                        value={element.value}
                      />
                      <label className="form-check-label" htmlFor={element.value}>
                        {element.label}
                      </label>
                    </div>
                  );
                })}
              </section> */}

              {/* <section className="FilterSection">
                <div className="headFilter">
                  <h2>Price Filter</h2>
                  <p>Economy to Plus</p>
                </div>
                <div className="form-range-slider">
                  <Slider00
                    defaultValue={40}
                    min={10}
                    max={40}
                    step={1}
                    marks={heightMarks}
                  />
                </div>
              </section> */}
              {/* ===============(This comment is useful, so don't remove it)=============== */}
              <section className="FilterSection">
                <div className="headFilter">
                  <h2>Dietary Preference</h2>
                  <p>Select dietary preference to be displayed.</p>
                </div>
                {dietaryOptions.map((element, index) => {
                  return (
                    <div key={`dietary${index}`} className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="dietPref"
                        id={`${element.value}`}
                        value={element.value}
                        onChange={(e) => handleFilterCheckbox(e, index)}
                      />
                      <label className="form-check-label" htmlFor={element.value}>
                        {element.label}
                      </label>
                    </div>
                  );
                })}
              </section>
              <section className="FilterSection">
                <div className="headFilter">
                  <h2>Food Allergens</h2>
                  <p>Select ingredients that you are allergic to</p>
                </div>
                {allergyOptions.map((element, index) => {
                  return (
                    <div key={`allergy${index}`} className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="allegies"
                        id={`${element.value}`}
                        value={element.value}
                        onChange={(e) => handleFilterCheckbox(e, index)}
                      />
                      <label className="form-check-label" htmlFor={element.value}>
                        {element.label}
                      </label>
                    </div>
                  );
                })}
              </section>
              <br />
            </div>
          </Col>
          <Col className={`RightSidebar ${switchMap === "right" ? "rightActive" : ""}`}>
            <Row>
              <Col className="pe-0 mb-5">
                <section className="me3">

                  <div className="calBox">
                    {
                      isLoaded ? <GoogleMap
                        mapContainerStyle={{
                          width: 'auto',
                          height: '550px',
                          borderRadius: "8px"
                        }}
                        // center={{ center }}
                        zoom={10}
                        onClick={ev => {
                          console.log("latitide = ", ev.latLng.lat());
                          console.log("longitude = ", ev.latLng.lng());
                        }}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                      >
                        <Marker position={center} onClick={(e) => console.log("marker", e)} />
                      </GoogleMap> : ''
                    }
                    {/* ===============(This comment is useful, so don't remove it)=============== */}
                    {/* <div className="grayBgScroll">
                      <span className="d-block text-center">
                        <b></b>
                      </span>
                      <span className="d-block text-center">
                        <b></b>
                      </span>
                    </div>
                    <Slider className="col-3 sliderReact" {...settings}>
                      <div className="card col-md-3 border-0 mb-4">
                        <div className="imgBox position-relative">
                          <img
                            src="/img01.png"
                            className="img-fluid w-100"
                            alt="food image"
                          />
                          <span className="brandLogo share rounded-pill">
                            <FiShare2 size={22} color="var(--theme-color)" />
                          </span>
                          <span className="brandLogo rounded-pill">
                            <FiHeart size={22} color="var(--theme-color)" />
                          </span>
                        </div>
                        <span className="offerPrice">
                          <span>
                            20% <i>Off</i>
                          </span>
                          <img
                            src="/offerTag.png"
                            className="img-fluid BGTag"
                            alt="food image"
                          />
                        </span>
                        <div className="card-body pt-2 px-0 prod_decription">
                          <div className="cafeName">
                            <span className="cafeImage position-relative">
                              <img
                                src="/home_cafe.png"
                                className="img-fluid border border-1 rounded-pill"
                                alt="cafe image"
                              />
                              <IoDiamond
                                className="diamondIcon"
                                size="15"
                                color="var(--diamond)"
                              />
                            </span>
                            <span className="cafeTitle ms-2">Home Cafe</span>
                            <span className="cafeRating">
                              <img
                                src="/rating.jpg"
                                className="img-fluid float-end mt-2"
                                alt="food icon"
                              />
                            </span>
                          </div>
                          <div className="cafeMenu mt-2 mb-3">
                            <span className="menuTag me-3">Eggs</span>
                            <span className="menuTag me-3">Fish</span>
                            <span className="menuTag">Shellfish</span>
                          </div>
                          <h5 className="card-title m-0 fw-bold">
                            Chiles en Nogada
                          </h5>
                          <div className="greenIcon mt-1">
                            <GoClock size={22} color="var(--bs-greenDark)" /> 30
                            Min{" "}
                            <GoPrimitiveDot
                              className="dotIcon"
                              size={12}
                              color="var(--bs-greenDark)"
                            />{" "}
                            0.1km
                          </div>
                          <div className="priceBox d-flex justify-content-between mt-3">
                            <div className="priceTotal">
                              <h3>S$ 10.99</h3>
                              <h5>S$ 12.99</h5>
                            </div>
                            <div className="cartTotal">
                              <img
                                src="/shoppingCart.svg"
                                className="img-fluid"
                                alt="shoppingCart Icon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card col-md-3 border-0 mb-4">
                        <div className="imgBox position-relative">
                          <img
                            src="/img01.png"
                            className="img-fluid w-100"
                            alt="food image"
                          />
                          <span className="brandLogo share rounded-pill">
                            <FiShare2 size={22} color="var(--theme-color)" />
                          </span>
                          <span className="brandLogo rounded-pill">
                            <FiHeart size={22} color="var(--theme-color)" />
                          </span>
                        </div>
                        <span className="offerPrice">
                          <span>
                            20% <i>Off</i>
                          </span>
                          <img
                            src="/offerTag.png"
                            className="img-fluid BGTag"
                            alt="food image"
                          />
                        </span>
                        <div className="card-body pt-2 px-0 prod_decription">
                          <div className="cafeName">
                            <span className="cafeImage position-relative">
                              <img
                                src="/home_cafe.png"
                                className="img-fluid border border-1 rounded-pill"
                                alt="cafe image"
                              />
                              <IoDiamond
                                className="diamondIcon"
                                size="15"
                                color="var(--diamond)"
                              />
                            </span>
                            <span className="cafeTitle ms-2">Home Cafe</span>
                            <span className="cafeRating">
                              <img
                                src="/rating.jpg"
                                className="img-fluid float-end mt-2"
                                alt="food icon"
                              />
                            </span>
                          </div>
                          <div className="cafeMenu mt-2 mb-3">
                            <span className="menuTag me-3">Eggs</span>
                            <span className="menuTag me-3">Fish</span>
                            <span className="menuTag">Shellfish</span>
                          </div>
                          <h5 className="card-title m-0 fw-bold">
                            Chiles en Nogada
                          </h5>
                          <div className="greenIcon mt-1">
                            <GoClock size={22} color="var(--bs-greenDark)" /> 30
                            Min{" "}
                            <GoPrimitiveDot
                              className="dotIcon"
                              size={12}
                              color="var(--bs-greenDark)"
                            />{" "}
                            0.1km
                          </div>
                          <div className="priceBox d-flex justify-content-between mt-3">
                            <div className="priceTotal">
                              <h3>S$ 10.99</h3>
                              <h5>S$ 12.99</h5>
                            </div>
                            <div className="cartTotal">
                              <img
                                src="/shoppingCart.svg"
                                className="img-fluid"
                                alt="shoppingCart Icon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card col-md-3 border-0 mb-4">
                        <div className="imgBox position-relative">
                          <img
                            src="/img01.png"
                            className="img-fluid w-100"
                            alt="food image"
                          />
                          <span className="brandLogo share rounded-pill">
                            <FiShare2 size={22} color="var(--theme-color)" />
                          </span>
                          <span className="brandLogo rounded-pill">
                            <FiHeart size={22} color="var(--theme-color)" />
                          </span>
                        </div>
                        <span className="offerPrice">
                          <span>
                            20% <i>Off</i>
                          </span>
                          <img
                            src="/offerTag.png"
                            className="img-fluid BGTag"
                            alt="food image"
                          />
                        </span>
                        <div className="card-body pt-2 px-0 prod_decription">
                          <div className="cafeName">
                            <span className="cafeImage position-relative">
                              <img
                                src="/home_cafe.png"
                                className="img-fluid border border-1 rounded-pill"
                                alt="cafe image"
                              />
                              <IoDiamond
                                className="diamondIcon"
                                size="15"
                                color="var(--diamond)"
                              />
                            </span>
                            <span className="cafeTitle ms-2">Home Cafe</span>
                            <span className="cafeRating">
                              <img
                                src="/rating.jpg"
                                className="img-fluid float-end mt-2"
                                alt="food icon"
                              />
                            </span>
                          </div>
                          <div className="cafeMenu mt-2 mb-3">
                            <span className="menuTag me-3">Eggs</span>
                            <span className="menuTag me-3">Fish</span>
                            <span className="menuTag">Shellfish</span>
                          </div>
                          <h5 className="card-title m-0 fw-bold">
                            Chiles en Nogada
                          </h5>
                          <div className="greenIcon mt-1">
                            <GoClock size={22} color="var(--bs-greenDark)" /> 30
                            Min{" "}
                            <GoPrimitiveDot
                              className="dotIcon"
                              size={12}
                              color="var(--bs-greenDark)"
                            />{" "}
                            0.1km
                          </div>
                          <div className="priceBox d-flex justify-content-between mt-3">
                            <div className="priceTotal">
                              <h3>S$ 10.99</h3>
                              <h5>S$ 12.99</h5>
                            </div>
                            <div className="cartTotal">
                              <img
                                src="/shoppingCart.svg"
                                className="img-fluid"
                                alt="shoppingCart Icon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card col-md-3 border-0 mb-4">
                        <div className="imgBox position-relative">
                          <img
                            src="/img01.png"
                            className="img-fluid w-100"
                            alt="food image"
                          />
                          <span className="brandLogo share rounded-pill">
                            <FiShare2 size={22} color="var(--theme-color)" />
                          </span>
                          <span className="brandLogo rounded-pill">
                            <FiHeart size={22} color="var(--theme-color)" />
                          </span>
                        </div>
                        <span className="offerPrice">
                          <span>
                            20% <i>Off</i>
                          </span>
                          <img
                            src="/offerTag.png"
                            className="img-fluid BGTag"
                            alt="food image"
                          />
                        </span>
                        <div className="card-body pt-2 px-0 prod_decription">
                          <div className="cafeName">
                            <span className="cafeImage position-relative">
                              <img
                                src="/home_cafe.png"
                                className="img-fluid border border-1 rounded-pill"
                                alt="cafe image"
                              />
                              <IoDiamond
                                className="diamondIcon"
                                size="15"
                                color="var(--diamond)"
                              />
                            </span>
                            <span className="cafeTitle ms-2">Home Cafe</span>
                            <span className="cafeRating">
                              <img
                                src="/rating.jpg"
                                className="img-fluid float-end mt-2"
                                alt="food icon"
                              />
                            </span>
                          </div>
                          <div className="cafeMenu mt-2 mb-3">
                            <span className="menuTag me-3">Eggs</span>
                            <span className="menuTag me-3">Fish</span>
                            <span className="menuTag">Shellfish</span>
                          </div>
                          <h5 className="card-title m-0 fw-bold">
                            Chiles en Nogada
                          </h5>
                          <div className="greenIcon mt-1">
                            <GoClock size={22} color="var(--bs-greenDark)" /> 30
                            Min{" "}
                            <GoPrimitiveDot
                              className="dotIcon"
                              size={12}
                              color="var(--bs-greenDark)"
                            />{" "}
                            0.1km
                          </div>
                          <div className="priceBox d-flex justify-content-between mt-3">
                            <div className="priceTotal">
                              <h3>S$ 10.99</h3>
                              <h5>S$ 12.99</h5>
                            </div>
                            <div className="cartTotal">
                              <img
                                src="/shoppingCart.svg"
                                className="img-fluid"
                                alt="shoppingCart Icon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card col-md-3 border-0 mb-4">
                        <div className="imgBox position-relative">
                          <img
                            src="/img01.png"
                            className="img-fluid w-100"
                            alt="food image"
                          />
                          <span className="brandLogo share rounded-pill">
                            <FiShare2 size={22} color="var(--theme-color)" />
                          </span>
                          <span className="brandLogo rounded-pill">
                            <FiHeart size={22} color="var(--theme-color)" />
                          </span>
                        </div>
                        <span className="offerPrice">
                          <span>
                            20% <i>Off</i>
                          </span>
                          <img
                            src="/offerTag.png"
                            className="img-fluid BGTag"
                            alt="food image"
                          />
                        </span>
                        <div className="card-body pt-2 px-0 prod_decription">
                          <div className="cafeName">
                            <span className="cafeImage position-relative">
                              <img
                                src="/home_cafe.png"
                                className="img-fluid border border-1 rounded-pill"
                                alt="cafe image"
                              />
                              <IoDiamond
                                className="diamondIcon"
                                size="15"
                                color="var(--diamond)"
                              />
                            </span>
                            <span className="cafeTitle ms-2">Home Cafe</span>
                            <span className="cafeRating">
                              <img
                                src="/rating.jpg"
                                className="img-fluid float-end mt-2"
                                alt="food icon"
                              />
                            </span>
                          </div>
                          <div className="cafeMenu mt-2 mb-3">
                            <span className="menuTag me-3">Eggs</span>
                            <span className="menuTag me-3">Fish</span>
                            <span className="menuTag">Shellfish</span>
                          </div>
                          <h5 className="card-title m-0 fw-bold">
                            Chiles en Nogada
                          </h5>
                          <div className="greenIcon mt-1">
                            <GoClock size={22} color="var(--bs-greenDark)" /> 30
                            Min{" "}
                            <GoPrimitiveDot
                              className="dotIcon"
                              size={12}
                              color="var(--bs-greenDark)"
                            />{" "}
                            0.1km
                          </div>
                          <div className="priceBox d-flex justify-content-between mt-3">
                            <div className="priceTotal">
                              <h3>S$ 10.99</h3>
                              <h5>S$ 12.99</h5>
                            </div>
                            <div className="cartTotal">
                              <img
                                src="/shoppingCart.svg"
                                className="img-fluid"
                                alt="shoppingCart Icon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Slider> */}
                    {/* ===============(This comment is useful, so don't remove it)=============== */}
                  </div>
                </section>
              </Col>
            </Row>
            {/* ===============(This comment is useful, so don't remove it)=============== */}
            {/* {!!allvendors && allvendors.length > 0 ? (
              <div className="mb-5">
                <div className="heading inner mb-4 pb-3 ms-md-5 me-2">
                  <h2 className="m-0 d-inline-block">Featured Near You</h2>
                  <section className="tobbleButton wAuto float-end me-2">
                    <div className="app left">
                      <span
                        onClick={onToggleClick}
                        className="leftButton small position-relative"
                      >
                        <BiGridSmall className="position-relative" size="38" />
                      </span>
                      <span
                        onClick={onToggleClick1}
                        className="rightButton small"
                      >
                        <FaMapMarkedAlt
                          className="position-relative"
                          size="20"
                        />
                      </span>
                    </div>
                  </section>
                </div>
                <Row className="ps-md-5 pe-3 me-2">
                  <section className="nearYou d-md-flex row border pt-4 pb-3 ms-2">
                    <Slider className="featureProd Prod01" {...settings01}>
                      {allvendors.map((item, id) => (
                        <div key={id} className="card col-md-3 border-0" >
                          <div className="imgBox position-relative">
                            <img
                              src="/img01.png"
                              className="img-fluid w-100"
                              alt="food image"
                            />
                            <span className="brandLogo share rounded-pill">
                              <FiShare2 size={22} color="var(--theme-color)" />
                            </span>
                            <span className="brandLogo rounded-pill">
                              <FiHeart size={22} color="var(--theme-color)" />
                            </span>
                          </div>
                          <span className="offerPrice">
                            <span>
                              20% <i>Off</i>
                            </span>
                            <img
                              src="/offerTag.png"
                              className="img-fluid BGTag"
                              alt="food image"
                            />
                          </span>
                          <div className="card-body pt-2 px-0 prod_decription">
                            <div className="cafeName">
                              <span className="cafeImage position-relative">
                                <img
                                  src="/home_cafe.png"
                                  className="img-fluid border border-1 rounded-pill"
                                  alt="cafe image"
                                />
                                <IoDiamond
                                  className="diamondIcon"
                                  size="15"
                                  color="var(--diamond)"
                                />
                              </span>
                              <span className="cafeTitle ms-2">
                                {item.name}
                              </span>
                              <span className="cafeRating">
                                <img
                                  src="/rating.jpg"
                                  className="img-fluid float-end mt-2"
                                  alt="food icon"
                                />
                              </span>
                            </div>
                            <div className="cafeMenu mt-2 mb-3">
                              {item &&
                                item.vendor && item.vendor.categories &&
                                item.vendor.categories.length > 0
                                ? item.vendor.categories.map((element) => (
                                  <span className="menuTag me-3">
                                    {element.category ? element.category.title : ''}
                                  </span>
                                ))
                                : ""}
                            </div>
                            <div onClick={() => { navigation(`/outletprofile/${item.id}`) }}>
                              <h5 className="card-title m-0 fw-bold">
                                {item.name}
                              </h5>
                            </div>
                            <div className="greenIcon mt-1">
                              <GoClock size={22} color="var(--bs-greenDark)" />{" "}
                              30 Min{" "}
                              <GoPrimitiveDot
                                className="dotIcon"
                                size={12}
                                color="var(--bs-greenDark)"
                              />{" "}
                              0.1km
                            </div>
                            <div className="priceBox d-flex justify-content-between mt-3">
                              <div className="priceTotal">
                                <h3>S$ {item.avg_rate}</h3>


                              </div>
                              <div className="cartTotal" onClick={() => handleCartModal(item.id)}>
                                <img
                                  src="/shoppingCart.svg"
                                  className="img-fluid"
                                  alt="shoppingCart Icon"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </section>
                </Row>
              </div>
            ) : (<div className="mb-5">
              <div className="heading inner mb-4 pb-3 ms-md-5 me-2">
                <h2 className="m-0 d-inline-block">Featured Near You</h2>
              </div>
              <div className="heading inner mb-4 pb-3 ms-md-5 me-2">
                <h5 className="m-0 d-inline-block">Data not found</h5>
              </div>
            </div>)} */}
            {/* ===============(This comment is useful, so don't remove it)=============== */}
            <Row className="FDcribs xtraFD ps-md-5 pe-3">
              <div className="heading inner mb-4 pb-3">
                <h2 className="m-0 d-inline-block">Food Cribs Near Your</h2>
              </div>
              {
                vendorData ? vendorData.map((item, index) => (
                  <div key={index} className="card col-md-3 border-0 mb-4">
                    <div className="imgBox position-relative">
                      <img
                        src={item.image ? process.env.REACT_APP_IMAGE_URL + item.image : "/img01.png"}
                        // className="img-fluid w-100"
                        width={327}
                        height={222}
                        alt="food image"
                        onClick={() => { navigation(`/outletprofile/${item?.crib_menu_items?.crib_id}`) }}
                      />
                      <span className="brandLogo share rounded-pill">
                        <FiShare2 size={22} color="var(--theme-color)" />
                      </span>
                      <span className="brandLogo rounded-pill">
                        <FiHeart size={22} color="var(--theme-color)" />
                      </span>
                    </div>
                    <span className="offerPrice">
                      <span> 20% <i>Off</i></span>
                      <img src="/offerTag.png" className="img-fluid BGTag" alt="food image" />
                    </span>
                    <div className="card-body pt-2 px-0 prod_decription">
                      <div className="cafeName">
                        <span className="cafeImage position-relative">
                          <img src="/home_cafe.png" className="img-fluid border border-1 rounded-pill" alt="cafe image" />
                          <IoDiamond className="diamondIcon" size="15" color="var(--diamond)" />
                        </span>
                        <span className="cafeTitle ms-2">{item?.crib_menu_items?.outlets?.name}</span>
                        <span className="cafeRating">
                          <span className="img-fluid float-end mt-2">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M7.20742 1.43922C7.45692 0.671464 8.54308 0.671464 8.79258 1.43922L10.0581 5.33402C10.1696 5.67737 10.4896 5.90984 10.8506 5.90984H14.9458C15.7531 5.90984 16.0888 6.94286 15.4357 7.41733L12.1225 9.8245C11.8304 10.0367 11.7083 10.4128 11.8198 10.7562L13.0853 14.651C13.3348 15.4187 12.456 16.0572 11.8029 15.5827L8.48983 13.1756C8.19775 12.9633 7.80225 12.9633 7.51017 13.1756L4.19706 15.5827C3.54397 16.0572 2.66523 15.4187 2.91469 14.651L4.18019 10.7562C4.29175 10.4128 4.16953 10.0367 3.87747 9.8245L0.564351 7.41733C-0.0887491 6.94286 0.246901 5.90984 1.05417 5.90984H5.14941C5.51043 5.90984 5.83039 5.67737 5.94195 5.33402L7.20742 1.43922Z" fill="#EF9309" />
                            </svg>
                            {item?.crib_menu_items?.outlets?.avg_rate}
                          </span>
                        </span>
                      </div>
                      <div className="cafeMenu mt-2 mb-3">
                        {item.allergies && item.allergies.map((allergy, idx) => (
                          <span key={idx} className="menuTag me-3">{allergy}</span>
                        ))}
                      </div>
                      <div onClick={() => { navigation(`/outletprofile/${item?.crib_menu_items?.crib_id}`) }}>
                        <h5 className="card-title m-0 fw-bold cursor-pointer">{item.name}</h5>
                      </div>
                      {item?.crib_menu_items?.outlets?.distance && <div className="greenIcon mt-1">
                        <GoClock size={22} color="var(--bs-greenDark)" /> {`${item?.preparation_time} Min `}
                        <GoPrimitiveDot
                          className="dotIcon"
                          size={12}
                          color="var(--bs-greenDark)"
                        />{Number(item?.crib_menu_items?.outlets?.distance).toFixed(1) + 'km'}
                      </div>}
                      <div className="priceBox d-flex justify-content-between mt-3">
                        <div className="priceTotal">
                          <h3>S$ {item.base_price} </h3>
                          <h5>S$ 12.99</h5>
                        </div>
                        <div className="cartTotal cursor-pointer" onClick={() => handleCartModal(item?.crib_menu_items?.crib_id, item?.crib_menu_items?.item_id)}>
                          <img src="/shoppingCart.svg" className="img-fluid" alt="shoppingCart Icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                )) : <div className="heading inner mb-4 pb-3">
                  <h5 className="m-0 d-inline-block">Data not found</h5>
                </div>
              }

            </Row>
          </Col>
        </Row>
      </Container>

      <Footer />

      <CartModel show={show} handleClose={handleClose} handleClick={handleCart} data={ItemDetails?.data?.getItemDetails?.data} />

      <AlertPopup
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        icon={
          <svg width="99" height="98" viewBox="0 0 99 98" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M49.4992 97.8C76.4509 97.8 98.2992 75.9516 98.2992 49C98.2992 22.0484 76.4509 0.199951 49.4992 0.199951C22.5477 0.199951 0.699219 22.0484 0.699219 49C0.699219 75.9516 22.5477 97.8 49.4992 97.8ZM72.1125 41.1133C74.4946 38.7311 74.4946 34.8688 72.1125 32.4866C69.7305 30.1044 65.868 30.1044 63.4859 32.4866L43.3992 52.5733L35.5126 44.6866C33.1304 42.3044 29.2681 42.3044 26.8858 44.6866C24.5037 47.0688 24.5037 50.9312 26.8858 53.3133L39.0858 65.5133C41.4681 67.8953 45.3304 67.8953 47.7126 65.5133L72.1125 41.1133Z" fill="white" />
          </svg>

        }
        title='Success!'
        description="Item added successfully in cart."
      />

    </div>
  );
};
export default FindMyMeal;
