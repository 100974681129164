import React, { useState, useEffect } from 'react'
import { Col, Form, Button, FloatingLabel, Modal } from 'react-bootstrap';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Link, useNavigate } from 'react-router-dom';
import './loginpage.css';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { verifyToken } from '../../queries/queries';
import { gapi } from "gapi-script";
import { addClass } from '../../commanFunctions';
import AuthPageLayout from './authpagelayout';
import { RiFacebookBoxFill } from 'react-icons/ri';
import { FaGooglePlusG } from 'react-icons/fa';
import { auth, loginWithEmailAndPassword, registerWithEmailAndPassword, db } from '../../firebase';
import { getAuth, onAuthStateChanged, signInWithCredential, signOut, FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";

gapi.load("client:auth2", () => {
    gapi.client.init({
        clientId:
            "*****.apps.googleusercontent.com",
        plugin_name: "chat",
    });
});

const LoginPage = () => {
    const [fullPhoneNo, setfullPhoneNo] = useState("");
    const [selected, setSelected] = useState(fullPhoneNo.split("-")[0] || "+65");
    const [mobileNo, setMobileNo] = useState(fullPhoneNo.split("-")[1] || "");
    const [loginType, setLoginType] = useState('email')
    const history = useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordType, setPasswordType] = useState(true)
    const [errorMessage, setErrorMessage] = useState(undefined);
    const SOCAIL_LOGIN_FACEBOOK_KEY = 'facebook';
    const SOCAIL_LOGIN_GOOGLE_KEY = 'google';
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);




    const [onShow, setOnShow] = useState(false)
    const login = gql`
    mutation LoginHost($email: String!, $password: String!){
        loginHost(loginData:{
          email:$email,
          password:$password
        }){
          status
          message
          accessToken
          user{
           email
            id
            first_name
            last_name
            job_title
            department
            user_role
            employee_number
            role{
              id
              name
            }
          }
        }
      }
        `;
    const socailLogin = gql`
    mutation loginWithSocialId(
        $socailLoginProvider:String!,
        $socialLoginId:String!,
        $email:String!,
        $data:JSON){
        loginWithSocialId(user: {
            social_login_provider: $socailLoginProvider,
            social_login_id: $socialLoginId,
            email: $email,
            data: $data,
        }) {
        status
        message
        accessToken
        user{
            id
            first_name
            last_name
            email
            social_login_id
            social_login_provider
        }
        }
    }
    `;

    const [loginUserr, { data, error }] = useMutation(login, { variables: { email: loginType === "email" ? email : fullPhoneNo, password: password } });
    const [socailloginUser] = useMutation(socailLogin);

    const countryName = parsePhoneNumber(String(fullPhoneNo));

    const responseGoogle = (res) => {
        console.log(res);
        if (res?.googleId) {
            sendRequest(res, SOCAIL_LOGIN_GOOGLE_KEY);
        }

    }
    const responseFacebook = (res) => {
        console.log(res);
        if (res?.id) {
            sendRequest(res, SOCAIL_LOGIN_FACEBOOK_KEY);
        }
    }
    const sendRequest = async (res, type) => {

        if (type == SOCAIL_LOGIN_FACEBOOK_KEY) {
            var SocailId = (res.id ? res?.id : res.userID);
            var socailProvider = type;
            var email = res?.email;
            var allData = res;
        }
        if (type == SOCAIL_LOGIN_GOOGLE_KEY) {
            var SocailId = res?.googleId;
            var socailProvider = type;
            var email = res?.profileObj?.email;
            var allData = res?.profileObj;
        }

        if (res) {
            if (SocailId) {
                const { data, errors } = await socailloginUser({
                    variables: {
                        socailLoginProvider: socailProvider,
                        socialLoginId: SocailId,
                        email: email,
                        data: allData,
                    }
                });
                if (data.loginWithSocialId.status === "success") {
                    localStorage.setItem("token", data.loginWithSocialId.accessToken);
                    const user = data.loginWithSocialId?.user;
                    console.log(user);
                    if (type == SOCAIL_LOGIN_FACEBOOK_KEY) {
                        checkFacebookLogin(res, user);
                    }
                    if (type == SOCAIL_LOGIN_GOOGLE_KEY) {
                        handleGoogleResponse(res, user)
                    }


                    history("/", { replace: true });
                }
                if (errors) {
                    console.log(errors);
                    setOnShow(true);
                    setErrorMessage('Failed to connect.');
                }
            } else {
                // console.log("i am here");
                setOnShow(true);
                setErrorMessage('Failed to connect.');
            }

        }
    }

    const isUserEqual = (facebookAuthResponse, firebaseUser) => {
        if (firebaseUser) {
            const providerData = firebaseUser.providerData;
            for (let i = 0; i < providerData.length; i++) {
                if (providerData[i].providerId === FacebookAuthProvider.PROVIDER_ID &&
                    providerData[i].uid === facebookAuthResponse.userID) {
                    // We don't need to re-auth the Firebase connection.
                    return true;
                }
            }
        }
        return false;
    }

    const handleGoogleResponse = (response,user) => {
        // Build Firebase credential with the Google ID token.
        const idToken = response.tokenId;
        const credential = GoogleAuthProvider.credential(idToken);

        // Sign in with credential from the Google user.
         signInWithCredential(auth, credential)
            .then(async(res) => {
                const docRef = doc(db, "users", res.user.uid);
                console.log("docRef", docRef);
                await setDoc(docRef, {
                    uid: res.user.uid,
                    name: user.first_name + " " + user.last_name,
                    authProvider: "google",
                    userid: user.id,
                    usertype: "diner",
                    ishost: false,
                    email:user?.email,
                    reportedUsers: [],
                    mutedUsers: [],
                    msg_timestamp: "0"
                }, {
                    merge: true
                }).then(() => console.log("Document updated"));
            })
            .catch((error) => {
                console.log("error google", error);

                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The credential that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
                console.log(credential);
            });
    }

    const checkFacebookLogin = (response,user) => {
        if (response) {
            // User is signed-in Facebook.
            const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
                unsubscribe();
                // Check if we are already signed-in Firebase with the correct user.
                if (!isUserEqual(response, firebaseUser)) {
                    // Build Firebase credential with the Facebook auth token.
                    const credential = FacebookAuthProvider.credential(
                        response.accessToken);

                    // Sign in with the credential from the Facebook user.
                    return signInWithCredential(auth, credential)
                        .then(async (res) => {
                            const docRef = doc(db, "users", res.user.uid);
                            console.log("docRef", docRef);
                            await setDoc(docRef, {
                                uid: res.user.uid,
                                name: user.first_name + " " + user.last_name,
                                authProvider: "facebook",
                                userid: user.id,
                                usertype: "diner",
                                ishost: false,
                                email:user.email,
                                reportedUsers: [],
                                mutedUsers: [],
                                msg_timestamp: "0"
                            }, {
                                merge: true
                            }).then(() => console.log("Document updated"));
                        })
                        .catch((error) => {
                            console.log("error", error);
                            // Handle Errors here.
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            // The email of the user's account used.
                            const email = error.customData.email;
                            // The AuthCredential type that was used.
                            const credential = FacebookAuthProvider.credentialFromError(error);
                            console.log("credential", credential);
                            // ...
                        });
                } else {
                    // User is already signed-in Firebase with the correct user.
                }
            });
        } else {
            // User is signed-out of Facebook.
            signOut(auth);
        }
    }

    const onUserLogin = (event) => {
        event.preventDefault();
        if (email === '') {
            setErrorMessage({
                error: 'invalid email',
                message: 'Please Enter Email or Phone number'
            })
        } else if (password === '') {
            setErrorMessage({
                error: 'empty password',
                message: 'Please Enter password'
            })
        } else {
            loginUserr();
        }
    };

    const tokenData = verifyToken();
    const [VerifyToken] = useLazyQuery(tokenData, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only'
    });


    useEffect(() => {
        async function verify(email) {
            const res = await VerifyToken();
            console.log(res);

            const firebaseuser = await loginWithEmailAndPassword(email, password);
            console.log(firebaseuser.code);
            switch (firebaseuser.code) {
                case 'auth/user-not-found':
                    let user = res?.data?.verifyToken?.user;
                    await registerWithEmailAndPassword(user.first_name + " " + user.last_name,
                        user?.email, password, user?.id)
                    break;
                default:
                    console.log(firebaseuser);
                    break;
            }

            if (res?.data) {
                if (res?.data?.verifyToken?.status === "success") {
                    history("/findmeal_one", { replace: true });
                }
                else {
                    setOnShow(true);
                    localStorage.removeItem("token")
                    setErrorMessage(res?.data?.verifyToken?.message)
                }
            }
            if (res?.error) {
                setOnShow(true);
                localStorage.removeItem("token")
                setErrorMessage(res?.error)
            }
        }

        if (data) {
            if (data.loginHost.status === "success") {
                let user = data.loginHost.user;

                if (data.loginHost.user.role.name == "host") {
                    localStorage.setItem("token", data.loginHost.accessToken)
                    verify(user?.email);
                }
                if (data.loginHost.user.role.name == "diner") {
                    localStorage.setItem("token", data.loginHost.accessToken)
                    verify(user?.email);
                }
            }

        }
        if (error) {
            setOnShow(true);
            if (error.message === 'Failed to fetch') {
                setErrorMessage('Failed to fetch')
            } else {
                const message = error?.message ? JSON.parse(error?.message).message : "Something went wrong";
                console.log(message);
                setErrorMessage(message);
            }
        }

    }, [data, error]);

    const handlePhoneNo = (event) => {
        if (event.target.value === "") {
            setErrorMessage({
                error: 'empty phone number',
                message: 'Please Enter Phone number'
            })
        } else {
            setErrorMessage({
                error: '',
                message: ''
            })
        }
        setMobileNo(event.target.value)
        // setfullPhoneNo(event.target.value)
        setfullPhoneNo(`${selected}-${event.target.value}`)

    }

    const loginWithPhone = () => {
        if (mobileNo === "") {
            setErrorMessage({
                error: 'empty phone number',
                message: 'Please Enter Phone number'
            })
        } else if (password === "") {
            setErrorMessage({
                error: 'empty password',
                message: 'Please Enter Password'
            })
        } else {
            setErrorMessage({
                error: '',
                message: ''
            })
            setfullPhoneNo(`${selected}-${mobileNo}`)
            loginUserr();
        }

    }

    const handleForgotPassword = () => {
        addClass()
        setTimeout(() => {
            history("/forgot_password")
        }, 1400)
    }

    return (
        <div>
            <AuthPageLayout
                title="Enjoy a cozy hot meal at your neighbour's crib."
                description={<>Join us and be rewarded whenever you eat with us. A <br className=" d-none d-lg-inline" /> good taste deserves more treats.</>}
            >
                <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
                    <div className="front">
                        <div className='heading mb-4'>
                            <h2 className='small lh-1 fs-normal'>Hi, Welcome Back</h2>
                        </div>
                        {errorMessage === 'Failed to fetch' ? <span style={{ color: 'red' }}>{errorMessage + ', Network error'}</span> : ''}
                        <Form onSubmit={onUserLogin} className="loginform">
                            <FloatingLabel controlId="email-address" label="Email" className="mb-3 pb-1">
                                <Form.Control type="email" placeholder="Email"
                                    onChange={(e) => {
                                        if (e.target.value === '') {
                                            setErrorMessage({
                                                error: 'invalid email',
                                                message: 'Please Enter Email'
                                            })
                                        } else {
                                            setErrorMessage({
                                                error: '',
                                                message: ''
                                            })
                                        }
                                        setEmail(e.target.value);
                                    }} />

                                {errorMessage && errorMessage.error === 'email' && (
                                    <div className="invalid-feedback d-block">
                                        {errorMessage.message}
                                    </div>
                                )}
                                {errorMessage && errorMessage.error === 'invalid email' && (
                                    <div className="invalid-feedback d-block">
                                        {errorMessage.message}
                                    </div>
                                )}
                            </FloatingLabel>

                            <FloatingLabel controlId="password" label="Password" className="mb-3 pb-1">
                                <Form.Control type={passwordType ? 'password' : 'text'} placeholder="Password" value={password} onChange={(e) => {
                                    if (e.target.value === "") {
                                        setErrorMessage({
                                            error: 'empty password',
                                            message: 'Please Enter Password'
                                        })
                                    } else {
                                        setErrorMessage({
                                            error: '',
                                            message: ''
                                        })
                                    }
                                    setPassword(e.target.value);

                                }} />
                                <span className="showPass" onClick={() => {
                                    setPasswordType(!passwordType)
                                }}>  {passwordType ? <FiEye size="20" color="var(--bs-border)" /> : <FiEyeOff size="20" color="var(--bs-border)" />}</span>



                                {errorMessage && errorMessage.error === 'empty password' && (
                                    <div className="invalid-feedback d-block">
                                        {errorMessage.message}
                                    </div>
                                )}
                            </FloatingLabel>

                            {errorMessage && errorMessage.error === 'role' && (
                                <div className="invalid-feedback d-block">
                                    {errorMessage.message}
                                </div>
                            )}

                            <Form.Group className="mb-4 mb-3 mt-2" controlId="exampleForm.ControlTextarea1">
                                <div className="form-check d-inline-block">
                                    <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault5" />
                                    <label className="form-check-label fw-bold pt-1 ps-1" htmlFor="flexRadioDefault5">Remember me</label>
                                </div>
                                <Form.Label className='float-end'><Link to="#" className="forgotPass text-decoration-none" onClick={handleForgotPassword}>Forgot Password?</Link></Form.Label>
                            </Form.Group>

                            <Button className="w-100 py-2 lh-lg border-0 mb-1" variant="primary" type="submit">Login</Button>
                        </Form>
                        <Col md={12} className="orText position-relative">
                            <span className="d-block text-center text-uppercase"><i>or</i></span>
                        </Col>
                        <Col md={12} className="d-flex justify-content-between socialButton">
                            <FacebookLogin
                                className="facebookplus fs-bold"
                                icon="fa-facebook-official me-2 fs26 align-text-top"
                                appId={process.env.REACT_APP_SOCAIL_LOGIN_FACEBOOK_APP_ID}
                                // autoLoad="{true}"
                                fields='name,email,picture'
                                // onClick={responseFacebook}
                                callback={responseFacebook}
                                cssClass="fbClassName"

                            />
                            <GoogleLogin
                                className="googleplus fw-bold justify-content-center"
                                clientId="1052999001814-e7sv6qm0rgupllaccdamvvtdqs9lb6to.apps.googleusercontent.com"
                                // clientId={process.env.REACT_APP_SOCAIL_LOGIN_GOOGLE_CLIENT_ID}
                                icon={false}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                // fetchBasicProfile={true}
                                cookiePolicy={'single_host_origin'}>
                                <FaGooglePlusG className='me-2 googlePlus' color='var(--theme-color)' /> Login with Google
                            </GoogleLogin>
                        </Col>
                        <Col md={12} className="signUpLink">
                            <p className="text-center mt-4">Login With <Button className="text-decoration-none" onClick={() => { addClass(); setLoginType("mobile") }} >Mobile Number</Button></p>
                            <p className="text-center mt-4">Don’t have an account? <Button className="text-decoration-none" onClick={() => history('/dinersignup')} >Sign up</Button></p>
                        </Col>
                    </div>
                    <div className="back">
                        <div className='heading mb-4'><h2 className='small lh-1 fs-normal'>Hi, Welcome Back</h2></div>
                        {errorMessage === 'Failed to fetch' ? <span style={{ color: 'red' }}>{errorMessage + ', Network error'}</span> : ''}
                        <Form className="loginform">
                            <Form.Group controlId="formBasicEmail" label="Email or Contact Number" className="mb-3 pb-1 d-flex justify-content-between">
                                <Col as="div" className="w-25 bg-white flagBox">
                                    <PhoneInput
                                        className="countryCode semibold"
                                        international
                                        defaultCountry={countryName ? countryName.country : 'SG'}
                                        value={selected}
                                        onChange={setSelected}
                                    />
                                </Col>
                                <FloatingLabel controlId="phone-number" label="Phone Number" className="w70">
                                    <Form.Control type="number" placeholder="9123 4567" value={mobileNo} onChange={handlePhoneNo} />
                                    {errorMessage && errorMessage.error === 'empty phone number' && (
                                        <div className="invalid-feedback d-block">
                                            {errorMessage.message}
                                        </div>
                                    )}
                                </FloatingLabel>
                            </Form.Group>

                            <FloatingLabel controlId="signup-password" label="Password" className="mb-2 pb-1">
                                <Form.Control type={passwordType ? 'password' : 'text'} placeholder="Password" value={password} onChange={(e) => {
                                    if (e.target.value === "") {
                                        setErrorMessage({
                                            error: 'empty password',
                                            message: 'Please Enter Password'
                                        })
                                    } else {
                                        setErrorMessage({
                                            error: '',
                                            message: ''
                                        })
                                    }
                                    setPassword(e.target.value);

                                }} />
                                <span className="showPass" onClick={() => {
                                    setPasswordType(!passwordType)
                                }}>  {passwordType ? <FiEye size="20" color="var(--bs-border)" /> : <FiEyeOff size="20" color="var(--bs-border)" />}</span>

                                {errorMessage && errorMessage.error === 'empty password' && (
                                    <div className="invalid-feedback d-block">
                                        {errorMessage.message}
                                    </div>
                                )}
                            </FloatingLabel>

                            <Form.Group className="mb-4 mb-3 mt-2" controlId="exampleForm.ControlTextarea1">
                                <div className="form-check d-inline-block">
                                    <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="back01" />
                                    <label className="form-check-label fw-bold pt-1 ps-1" htmlFor="back01">Remember me</label>
                                </div>
                                <Form.Label className='float-end'><Link to="#" className="forgotPass text-decoration-none" onClick={handleForgotPassword}>Forgot Password?</Link></Form.Label>
                            </Form.Group>

                            <Button className="w-100 py-2 lh-lg border-0 mb-1" variant="primary" type="button" onClick={loginWithPhone}>Login</Button>
                        </Form>
                        <Col md={12} className="orText position-relative">
                            <span className="d-block text-center text-uppercase"><i>or</i></span>
                        </Col>
                        <Col md={12} className="d-flex justify-content-between socialButton">
                            <FacebookLogin
                                className="facebookplus fs-bold"
                                icon="fa-facebook-official me-2 fs26 align-text-top"
                                appId={process.env.REACT_APP_SOCAIL_LOGIN_FACEBOOK_APP_ID}
                                // autoLoad="{true}"
                                fields='name,email,picture'
                                // onClick={responseFacebook}
                                callback={responseFacebook}
                                cssClass="fbClassName"
                            />
                            <GoogleLogin
                                className="googleplus fw-bold justify-content-center"
                                clientId="1052999001814-e7sv6qm0rgupllaccdamvvtdqs9lb6to.apps.googleusercontent.com"
                                // clientId={process.env.REACT_APP_SOCAIL_LOGIN_GOOGLE_CLIENT_ID}
                                icon={false}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                // fetchBasicProfile={true}
                                cookiePolicy={'single_host_origin'}>
                                <FaGooglePlusG className='me-2 googlePlus' color='var(--theme-color)' /> Login with Google
                            </GoogleLogin>
                        </Col>
                        <Col md={12} className="signUpLink">
                            <p className="text-center mt-4">Don’t have an account? <Button className="text-decoration-none" onClick={() => history('/dinersignup')} >Sign up</Button></p>
                        </Col>
                    </div>
                </section>
            </AuthPageLayout>

            {
                onShow == true ? (
                    <Modal dialogClassName="modalSM" centered show={onShow} onHide={() => setOnShow(false)} >
                        <Modal.Body className="d-flex flex-column justify-content-center align-items-center border08 text-center py-5 bgtheme">

                            <svg width="94" height="86" viewBox="0 0 94 86" fill="none" className='mt-5 mb-3' xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M36.3676 6.90288C41.0317 -1.38885 52.9702 -1.38885 57.6343 6.90288L91.6741 67.4184C96.2485 75.5509 90.3717 85.5995 81.0406 85.5995H12.9609C3.63005 85.5995 -2.24687 75.5509 2.3277 67.4184L36.3676 6.90288ZM53.1001 67.3001C53.1001 70.6691 50.3692 73.4001 47.0001 73.4001C43.6314 73.4001 40.9003 70.6691 40.9003 67.3001C40.9003 63.931 43.6314 61.2001 47.0001 61.2001C50.3692 61.2001 53.1001 63.931 53.1001 67.3001ZM47.0001 18.5001C43.6314 18.5001 40.9003 21.2312 40.9003 24.6001V42.9001C40.9003 46.269 43.6314 49.0001 47.0001 49.0001C50.3692 49.0001 53.1001 46.269 53.1001 42.9001V24.6001C53.1001 21.2312 50.3692 18.5001 47.0001 18.5001Z" fill="white" />
                            </svg>

                            <h1 className="text-white fs32 semibold">Error !</h1>
                            <p className="fs24 regular lh-sm mb-5 text-white">
                                {errorMessage}
                            </p>
                        </Modal.Body>
                    </Modal>
                ) : null
            }
        </div >
    )
}
export default LoginPage