import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Breadcrumb, Tabs, Tab, Button, Form } from 'react-bootstrap';
import { FiChevronDown, FiMapPin } from "react-icons/fi";
import { IoDiamond } from "react-icons/io5";
import { HiOutlineClock } from "react-icons/hi";
import { BsStarFill } from 'react-icons/bs';
import { AiFillCaretRight } from 'react-icons/ai';
import { FaCircle } from 'react-icons/fa';
import AfterLoginHeader from '../components/afterLoginHeader';
import Footer from '../components/footer';
import Scrollbars from 'react-custom-scrollbars-2';
import "./order.css"
import { GetCloseOrderList, GetCurrentOrderList } from '../queries/queries';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useNavigate } from 'react-router';

const MyOrderA = () => {
    const navigate = useNavigate();

    const [alpha01, setAlpha01] = useState(false);
    const [myOrderList, setMyOrderList] = useState([]);
    console.log("myOrderList", myOrderList)
    const [closeOrderList, setCloseOrderList] = useState([]);

    const currentOrderListApi = GetCurrentOrderList()
    const GetCurrentOrder = useQuery(currentOrderListApi)

    const closeOrderListApi = GetCloseOrderList();
    const GetCloseOrders = useQuery(closeOrderListApi)

    useEffect(() => {
        if (GetCurrentOrder?.data?.getCurrentOrderList?.status === "success") {
            setMyOrderList(GetCurrentOrder?.data?.getCurrentOrderList?.data)
        }
    }, [GetCurrentOrder?.data])

    useEffect(() => {
        if (GetCloseOrders?.data?.getCloseOrderList?.status === "success") {
            setCloseOrderList(GetCloseOrders?.data?.getCloseOrderList?.data)
        }
    }, [GetCloseOrders?.data])

    const getCheckedValue = (e) => {
        switch (e.target.name) {
            case "case02":
                e.target.checked ? setAlpha01("") : setAlpha01("")
                break;
            default:
        }
    }

    return (
        <div id="bodyWrap">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <AfterLoginHeader />
                    </Col>
                </Row>
                {/* {/* <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col> */}
                <Row className="dblock">
                    <Col as="div" className="userAcc pt-4">
                        <h2 className="fs52 semibold ms-md-3 mb-0">My Order</h2>
                        <Breadcrumb className="ms-md-3 mb-5">
                            <Breadcrumb.Item href="/">&nbsp;My Order</Breadcrumb.Item>
                        </Breadcrumb>
                        <Col className="tabsElite">
                            <Tabs
                                defaultActiveKey="first"
                                id="justify-tab-example"
                                className="myordertabs"
                                tab
                                justify>
                                <Tab tabClassName="w-auto m-auto tabinline fs24" eventKey="first" title="Current Order" className="pb-4 pt-5 tabRewards">
                                    <Scrollbars
                                        className="cpnlist"
                                        autoHideTimeout={1000}
                                        autoHideDuration={1000}
                                        autoHeight
                                        autoHeightMax={800}
                                        thumbMinSize={300}
                                        universal={true}>
                                        <Row className="justify-content-center">
                                            <Col lg="11">
                                                <Col as="div" className="cartHeader bg-transparent mh-auto p-0">
                                                    {myOrderList && myOrderList?.map((order, index) => {
                                                        console.log("order==========>", order);
                                                        let crib_details = order?.crib_details
                                                        let order_items = order?.order_items
                                                        let order_date = moment(order?.time).format("Do MMMM YYYY")
                                                        let order_time = moment(order?.time).format('LT');
                                                        return (
                                                            <Row key={index} className="border-bottom mb-5 pb-5 firstHead">
                                                                <Col xl="12" xxl="auto" className="text-nowrap mb-xl-4 mb-lg-4 mb-md-4 mb-xxl-0">
                                                                    <Col as="span" className="cafeImage position-relative float-start me-3">
                                                                        <img src="./assets/black_scoop.png" width="56" height="56" className="img-fluid border border-2 rounded-pill" alt="cafepic" />
                                                                        <IoDiamond className='diamondIcon' size="15" color='var(--diamondBlue)' />
                                                                    </Col>
                                                                    <Col as="div" className="itemHead text-start">
                                                                        <h4 className="d-inline align-middle me-3">{crib_details?.name}</h4>
                                                                        <span className="rating"><BsStarFill size="18" color="var(--bs-border)" /> <strong className="position-relative">4.5</strong></span>
                                                                    </Col>
                                                                    <Col as="div" className="itemaddr text-start">
                                                                        <FiMapPin size="16" color="var(--bs-border)" className="me-1" />
                                                                        <p className="d-inline semibold GrayComn align-middle me-1">{crib_details?.address}</p>
                                                                        <p className="d-inline semibold align-middle me-md-5 ms-3">
                                                                            {order?.distance && <><HiOutlineClock size="20" color="var(--bs-border)" className="me4" /> {order?.distance} KM</>}
                                                                        </p>
                                                                    </Col>
                                                                </Col>
                                                                <Col className="text-center takehead take02">
                                                                    <div className="d-inline-block text-start">
                                                                        <h4 className="fs22 semibold GrayComn m-0">Order ID</h4>
                                                                        <p className="fs16 semibold gray82 mb-2">{order?.order_number}</p>
                                                                    </div>
                                                                </Col>
                                                                <Col className="text-center take03">
                                                                    <div className="d-inline-block text-start">
                                                                        <h4 className="fs22 semibold GrayComn lh-1 mt-2 mb-1">Transaction Number</h4>
                                                                        <p className="fs16 semibold gray82 mb-2">{order?.payment_intent_id}</p>
                                                                    </div>
                                                                </Col>
                                                                <Col className="text-center take04">
                                                                    <div className="d-inline-block text-start">
                                                                        <h4 className="fs22 semibold GrayComn lh-1 mt-2 mb-1">Collection Time</h4>
                                                                        <p className="fs16 semibold gray82 mb-2">{order_date + ', ' + order_time}</p>
                                                                    </div>
                                                                </Col>
                                                                <Col className="text-center take05">
                                                                    <div className="d-inline-block text-start">
                                                                        <h4 className="fs22 semibold GrayComn lh-1 mt-2 mb-1">Order Status</h4>
                                                                        <p className="fs16 semibold yellow mb-2">{order?.order_status}</p>
                                                                    </div>
                                                                </Col>
                                                                <Col className="text-center text-md-end text-xs-end take06">
                                                                    <Button className="text-nowrap rounded-pill fs24 semibold py-1 px-4 mb-3 align-top" variant="primary" onClick={() => {
                                                                            navigate(`/tracking-order/${order?.id}`);
                                                                        }}
                                                                    >Track Order</Button>
                                                                    <Button className="text-nowrap rounded-pill fs24 semibold py-2 px-3 grayf2 mt-0" variant="light">Cancel Order</Button>

                                                                </Col>
                                                                <Col lg="auto" className="mx-width">
                                                                    <Col as="a"
                                                                        className="btn p-0 themeColor outline-none shadow-none chevronAro border-0"
                                                                        data-bs-toggle="collapse"
                                                                        href="#order01"
                                                                        role="button"
                                                                        aria-expanded="false"
                                                                        aria-controls="order01">
                                                                        Hide Details <FiChevronDown size="18" color="var(--theme-color)" />
                                                                    </Col>
                                                                    <Col as="div" className="mt-3 collapse hide" id="order01">
                                                                        <Col as="div" className="card border-0 px-0">
                                                                            <Col as="div" className="cartList shadow01">
                                                                                {order_items && order_items.map((item, num) => (
                                                                                    <Row key={num} className="justify-space-between">
                                                                                        <Col md={7} className="cartItemInfo d-md-flex">
                                                                                            <Col className="col-auto cartImage me-3"><img src={item?.item_details?.image ? process.env.REACT_APP_IMAGE_URL + item?.item_details?.image : './assets/cartItem.png'} alt='carpic' /></Col>
                                                                                            <Col md className="cartDetail">
                                                                                                <h2>{item?.item_details?.name} <span className="discountBadge ms-2">SAVE S$2.00 <AiFillCaretRight size="33" color="var(--bs-greenDark)" /></span></h2>
                                                                                                {item?.item_details?.allergies && item?.item_details?.allergies.map((allergy, key1) => (
                                                                                                    <span key={key1} className="menuTag me-3">{allergy}</span>
                                                                                                ))
                                                                                                }
                                                                                                <ul className="listBullet list-unstyled mt-2">
                                                                                                    {item?.item_details?.item_selections && item?.item_details?.item_selections.map((food, key2) => (
                                                                                                        <li key={key2} className="d-inline-block text-nowrap">&nbsp;&nbsp;<FaCircle size="7" color="var(--bs-red9d9)" /> {food?.name}&nbsp;</li>
                                                                                                    ))}

                                                                                                </ul>
                                                                                                <Col as="span" className="spcRequest mt-2">
                                                                                                    <span>Special Request</span>
                                                                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br />incididunt ut labore et dolore magna aliqua.
                                                                                                </Col>
                                                                                            </Col>
                                                                                        </Col>
                                                                                        <Col className="cartItemQTY trackOdr text-center">
                                                                                            <div className="d-inline-block text-start">
                                                                                                <p className="fs12 semibold GrayComn m-0">Quantity</p>
                                                                                                <h4 className="fs30 semibold GrayComn m-0">{item?.quantity}</h4>
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col md="auto" className="cartItemPrice trackOdr">
                                                                                            <div className="text-start">
                                                                                                <span>Price</span>
                                                                                                <strong className="d-inline-block pt-1">S$ {item?.price}</strong>
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col md={12}>
                                                                                            <hr className="separator mb-4" />
                                                                                        </Col>
                                                                                    </Row>
                                                                                ))}
                                                                                <Row className="justify-space-between pt-3 pb-4">
                                                                                    <Col>
                                                                                        <h4 className="fs24 semibold GrayComn m-0">Disposable Cutleries</h4>
                                                                                        <p className="fs18 semibold GrayComn m-0 lh-1">Lets do our part to save the earth!</p>
                                                                                    </Col>
                                                                                    <Col md="auto" className="cartItemPrice">
                                                                                        <Form.Check
                                                                                            className="togglerBtn fs0 fs16 mt-2 scale15 semibold GrayComn text-uppercase pt-1 cursor-pointer"
                                                                                            type="switch"
                                                                                            id="custom-switch01"
                                                                                            label={alpha01 ? alpha01 : ""}
                                                                                            name="case02"
                                                                                            checked={order?.cutleries}
                                                                                            onChange={getCheckedValue}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Col>
                                                                    </Col>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })}
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Scrollbars>
                                </Tab >
                                <Tab eventKey="second" title="Closed Order" className="pb-4 pt-5 tabRewards">
                                    <Scrollbars
                                        /** Merge code lokesh sir  */
                                        // </Tab>
                                        // <Tab tabClassName="w-auto m-auto tabinline fs24" eventKey="second" title="Completed Order" className="pb-4 pt-5 tabRewards">
                                        // <Scrollbars
                                        className="cpnlist"
                                        autoHideTimeout={1000}
                                        autoHideDuration={1000}
                                        autoHeight
                                        autoHeightMax={800}
                                        thumbMinSize={300}
                                        universal={true}>
                                        <Row className="justify-content-center">
                                            <Col lg="11">
                                                <Col as="div" className="cartHeader bg-transparent mh-auto p-0">
                                                    {
                                                        closeOrderList && closeOrderList.map((order, index) => {
                                                            let crib_details = order?.crib_details
                                                            let order_items = order?.order_items
                                                            let order_date = moment(order?.time).format("Do MMMM YYYY")
                                                            let order_time = moment(order?.time).format('LT');
                                                            return (
                                                                <Row className="border-bottom mb-5 pb-5 firstHead">
                                                                    <Col xl="12" xxl="auto" className="text-nowrap mb-xl-4 mb-lg-4 mb-md-4 mb-xxl-0">
                                                                        <Col as="span" className="cafeImage position-relative float-start me-3">
                                                                            <img src="./assets/black_scoop.png" width="56" height="56" className="img-fluid border border-2 rounded-pill" alt="cafepic" />
                                                                            <IoDiamond className='diamondIcon' size="15" color='var(--diamondBlue)' />
                                                                        </Col>
                                                                        <Col as="div" className="itemHead text-start">
                                                                            <h4 className="d-inline align-middle me-3">{crib_details?.name}</h4>
                                                                            <span className="rating"><BsStarFill size="18" color="var(--bs-border)" /> <strong className="position-relative">4.5</strong></span>
                                                                        </Col>
                                                                        <Col as="div" className="itemaddr text-start">
                                                                            <FiMapPin size="16" color="var(--bs-border)" className="me-1" />
                                                                            <p className="d-inline semibold GrayComn align-middle me-1">{crib_details?.address}</p>
                                                                            <p className="d-inline semibold align-middle me-md-5 ms-3">
                                                                                {order?.distance && <><HiOutlineClock size="20" color="var(--bs-border)" className="me4" /> {order?.distance} KM</>}
                                                                            </p>
                                                                        </Col>
                                                                    </Col>
                                                                    <Col className="text-center takehead take02">
                                                                        <div className="d-inline-block text-start">
                                                                            <h4 className="fs22 semibold GrayComn m-0">Order ID</h4>
                                                                            <p className="fs16 semibold gray82 mb-2">{order?.order_number}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="text-center take03">
                                                                        <div className="d-inline-block text-start">
                                                                            <h4 className="fs22 semibold GrayComn lh-1 mt-2 mb-1">Transaction Number</h4>
                                                                            <p className="fs16 semibold gray82 mb-2">#{order?.payment_intent_id}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="text-center take04">
                                                                        <div className="d-inline-block text-start">
                                                                            <h4 className="fs22 semibold GrayComn lh-1 mt-2 mb-1">Collection Time</h4>
                                                                            <p className="fs16 semibold gray82 mb-2">{order_date + ', ' + order_time}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="text-center take05">
                                                                        <div className="d-inline-block text-start">
                                                                            <h4 className="fs22 semibold GrayComn lh-1 mt-2 mb-1">Order Status</h4>
                                                                            <p className="fs16 semibold textGreen mb-2">{order?.order_status}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="text-center text-md-end text-xs-end take06">
                                                                        <Button className="text-nowrap rounded-pill fs24 semibold py-1 px-4" variant="primary">View Invoice</Button>
                                                                    </Col>
                                                                    <Col lg="auto" className="mx-width">
                                                                        <Col as="a"
                                                                            className="btn p-0 themeColor outline-none shadow-none chevronAro"
                                                                            data-bs-toggle="collapse"
                                                                            href="#order03"
                                                                            role="button"
                                                                            aria-expanded="false"
                                                                            aria-controls="order03">
                                                                            Hide Details <FiChevronDown size="18" color="var(--theme-color)" />
                                                                        </Col>
                                                                        <Col as="div" className="mt-3 collapse hide" id="order03">
                                                                            <Col as="div" className="card border-0 px-0">
                                                                                <Col as="div" className="cartList shadow01">
                                                                                    {order_items && order_items.map((item, num) => (
                                                                                        <Row key={num} className="justify-space-between">
                                                                                            <Col md={7} className="cartItemInfo d-md-flex">
                                                                                                <Col className="col-auto cartImage me-3"><img src={item?.item_details?.image ? process.env.REACT_APP_IMAGE_URL + item?.item_details?.image : './assets/cartItem.png'} alt='carpic' /></Col>
                                                                                                <Col md className="cartDetail">
                                                                                                    <h2>{item?.item_details?.name} <span className="discountBadge ms-2">SAVE S$2.00 <AiFillCaretRight size="33" color="var(--bs-greenDark)" /></span></h2>
                                                                                                    {item?.item_details?.allergies && item?.item_details?.allergies.map((allergy, key1) => (
                                                                                                        <span key={key1} className="menuTag me-3">{allergy}</span>
                                                                                                    ))}

                                                                                                    <ul className="listBullet list-unstyled mt-2">
                                                                                                        {item?.item_details?.item_selections && item?.item_details?.item_selections.map((food, key2) => (
                                                                                                            <li key={key2} className="d-inline-block text-nowrap">&nbsp;&nbsp;<FaCircle size="7" color="var(--bs-red9d9)" /> {food?.name}&nbsp;</li>
                                                                                                        ))}
                                                                                                    </ul>
                                                                                                    <Col as="span" className="spcRequest mt-2">
                                                                                                        <span>Special Request</span>
                                                                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br />incididunt ut labore et dolore magna aliqua.
                                                                                                    </Col>
                                                                                                </Col>
                                                                                            </Col>
                                                                                            <Col className="cartItemQTY trackOdr text-center">
                                                                                                <div className="d-inline-block text-start">
                                                                                                    <p className="fs12 semibold GrayComn m-0">Quantity</p>
                                                                                                    <h4 className="fs30 semibold GrayComn m-0">{item?.quantity}</h4>
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col md="auto" className="cartItemPrice trackOdr">
                                                                                                <div className="text-start">
                                                                                                    <span>Price</span>
                                                                                                    <strong className="d-inline-block pt-1">S$ {item?.price}</strong>
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col md={12}>
                                                                                                <hr className="separator mb-4" />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    ))}
                                                                                    <Row className="justify-space-between pt-3 pb-4">
                                                                                        <Col>
                                                                                            <h4 className="fs24 semibold GrayComn m-0">Disposable Cutleries</h4>
                                                                                            <p className="fs18 semibold GrayComn m-0 lh-1">Lets do our part to save the earth!</p>
                                                                                        </Col>
                                                                                        <Col md="auto" className="cartItemPrice">
                                                                                            <Form.Check
                                                                                                className="togglerBtn fs0 fs16 mt-2 scale15 semibold GrayComn text-uppercase pt-1 cursor-pointer"
                                                                                                type="switch"
                                                                                                id="custom-switch01"
                                                                                                label={alpha01 ? alpha01 : ""}
                                                                                                name="case02"
                                                                                                checked={order?.cutleries}
                                                                                                onChange={getCheckedValue}
                                                                                                disabled
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                            </Col>
                                                                        </Col>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })}



                                                    {/* <Row className="border-bottom mb-5 pb-5 firstHead">
                                                        <Col xl="12" xxl="auto" className="text-nowrap mb-xl-4 mb-lg-4 mb-md-4 mb-xxl-0">
                                                            <Col as="span" className="cafeImage position-relative float-start me-3">
                                                                <img src="./assets/black_scoop.png" width="56" height="56" className="img-fluid border border-2 rounded-pill" alt="cafepic" />
                                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamondBlue)' />
                                                            </Col>
                                                            <Col as="div" className="itemHead text-start">
                                                                <h4 className="d-inline align-middle me-3">{crib_details?.name}</h4>
                                                                <span className="rating"><BsStarFill size="18" color="var(--bs-border)" /> <strong className="position-relative">4.5</strong></span>
                                                            </Col>
                                                            <Col as="div" className="itemaddr text-start">
                                                                <FiMapPin size="16" color="var(--bs-border)" className="me-1" />
                                                                <p className="d-inline semibold GrayComn align-middle me-1">10 Bayfront Ave, #01-01 Singapore 018956</p>
                                                                <p className="d-inline semibold align-middle me-md-5 ms-3">
                                                                    <HiOutlineClock size="20" color="var(--bs-border)" className="me4" /> 1.2 KM
                                                                </p>
                                                            </Col>
                                                        </Col>
                                                        <Col className="text-center takehead take02">
                                                            <div className="d-inline-block text-start">
                                                                <h4 className="fs22 semibold GrayComn m-0">Order ID</h4>
                                                                <p className="fs16 semibold gray82 mb-2">A17725GN6CX</p>
                                                            </div>
                                                        </Col>
                                                        <Col className="text-center take03">
                                                            <div className="d-inline-block text-start">
                                                                <h4 className="fs22 semibold GrayComn lh-1 mt-2 mb-1">Transaction Number</h4>
                                                                <p className="fs16 semibold gray82 mb-2">#05821330450</p>
                                                            </div>
                                                        </Col>
                                                        <Col className="text-center take04">
                                                            <div className="d-inline-block text-start">
                                                                <h4 className="fs22 semibold GrayComn lh-1 mt-2 mb-1">Collection Time</h4>
                                                                <p className="fs16 semibold gray82 mb-2">15 June 2022, 12:30 AM</p>
                                                            </div>
                                                        </Col>
                                                        <Col className="text-center take05">
                                                            <div className="d-inline-block text-start">
                                                                <h4 className="fs22 semibold GrayComn lh-1 mt-2 mb-1">Order Status</h4>
                                                                <p className="fs16 semibold textGreen mb-2">Completed</p>
                                                            </div>
                                                        </Col>
                                                        <Col className="text-center text-md-end text-xs-end take06">
                                                            <Button className="text-nowrap rounded-pill fs24 semibold py-1 px-4" variant="primary">View Invoice</Button>
                                                        </Col>
                                                        <Col lg="auto" className="mx-width">
                                                            <Col as="a"
                                                                className="btn p-0 themeColor outline-none shadow-none chevronAro"
                                                                data-bs-toggle="collapse"
                                                                href="#order04"
                                                                role="button"
                                                                aria-expanded="false"
                                                                aria-controls="order04">
                                                                Hide Details <FiChevronDown size="18" color="var(--theme-color)" />
                                                            </Col>
                                                            <Col as="div" className="mt-3 collapse hide" id="order04">
                                                                <Col as="div" className="card border-0 px-0">
                                                                    <Col as="div" className="cartList shadow01">
                                                                        <Row className="justify-space-between">
                                                                            <Col md={7} className="cartItemInfo d-md-flex">
                                                                                <Col className="col-auto cartImage me-3"><img src='./assets/cartItem.png' alt='carpic' /></Col>
                                                                                <Col md className="cartDetail">
                                                                                    <h2>Chiles en Nogada <span className="discountBadge ms-2">SAVE S$2.00 <AiFillCaretRight size="33" color="var(--bs-greenDark)" /></span></h2>
                                                                                    <span className="menuTag me-3">Dairy</span>
                                                                                    <span className="menuTag me-3">Wheat</span>
                                                                                    <ul className="listBullet list-unstyled mt-2">
                                                                                        <li className="d-inline-block text-nowrap">Black Pepper Beef &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">French Fries &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">Green Salad &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">Pepsi(250ml)</li>
                                                                                    </ul>
                                                                                    <Col as="span" className="spcRequest mt-2">
                                                                                        <span>Special Request</span>
                                                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br />incididunt ut labore et dolore magna aliqua.
                                                                                    </Col>
                                                                                </Col>
                                                                            </Col>
                                                                            <Col className="cartItemQTY trackOdr text-center">
                                                                                <div className="d-inline-block text-start">
                                                                                    <p className="fs12 semibold GrayComn m-0">Quantity</p>
                                                                                    <h4 className="fs30 semibold GrayComn m-0">1</h4>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md="auto" className="cartItemPrice trackOdr">
                                                                                <div className="text-start">
                                                                                    <span>Price</span>
                                                                                    <strong className="d-inline-block pt-1">S$11.99</strong>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <hr className="separator mb-4" />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="justify-space-between">
                                                                            <Col md={7} className="cartItemInfo d-md-flex">
                                                                                <Col className="col-auto cartImage me-3"><img src='./assets/cartItem.png' alt='carpic' /></Col>
                                                                                <Col md className="cartDetail">
                                                                                    <h2>Chiles en Nogada <span className="discountBadge ms-2">SAVE S$2.00 <AiFillCaretRight size="33" color="var(--bs-greenDark)" /></span></h2>
                                                                                    <span className="menuTag me-3">Dairy</span>
                                                                                    <span className="menuTag me-3">Wheat</span>
                                                                                    <ul className="listBullet list-unstyled mt-2">
                                                                                        <li className="d-inline-block text-nowrap">Black Pepper Beef &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">French Fries &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">Green Salad &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">Pepsi(250ml)</li>
                                                                                    </ul>
                                                                                </Col>
                                                                            </Col>
                                                                            <Col className="cartItemQTY trackOdr text-center">
                                                                                <div className="d-inline-block text-start">
                                                                                    <p className="fs12 semibold GrayComn m-0">Quantity</p>
                                                                                    <h4 className="fs30 semibold GrayComn m-0">1</h4>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md="auto" className="cartItemPrice trackOdr">
                                                                                <div className="text-start">
                                                                                    <span>Price</span>
                                                                                    <strong className="d-inline-block pt-1">S$11.99</strong>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <hr className="separator mb-4" />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="justify-space-between pt-3 pb-4">
                                                                            <Col>
                                                                                <h4 className="fs24 semibold GrayComn m-0">Disposable Cutleries</h4>
                                                                                <p className="fs18 semibold GrayComn m-0 lh-1">Lets do our part to save the earth!</p>
                                                                            </Col>
                                                                            <Col md="auto" className="cartItemPrice">
                                                                                <Form.Check
                                                                                    className="togglerBtn fs0 fs16 mt-2 scale15 semibold GrayComn text-uppercase pt-1"
                                                                                    type="switch"
                                                                                    id="custom-switch01"
                                                                                    label={alpha01 ? alpha01 : ""}
                                                                                    name="case02"
                                                                                    onChange={getCheckedValue}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Col>
                                                            </Col>
                                                        </Col>
                                                    </Row> */}

                                                    {/* 
                                                    <Row className="border-bottom mb-5 pb-5 firstHead">
                                                        <Col xl="12" xxl="auto" className="text-nowrap mb-xl-4 mb-lg-4 mb-md-4 mb-xxl-0">
                                                            <Col as="span" className="cafeImage position-relative float-start me-3">
                                                                <img src="./assets/black_scoop.png" width="56" height="56" className="img-fluid border border-2 rounded-pill" alt="cafepic" />
                                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamondBlue)' />
                                                            </Col>
                                                            <Col as="div" className="itemHead text-start">
                                                                <h4 className="d-inline align-middle me-3">City Home Cafe</h4>
                                                                <span className="rating"><BsStarFill size="18" color="var(--bs-border)" /> <strong className="position-relative">4.5</strong></span>
                                                            </Col>
                                                            <Col as="div" className="itemaddr text-start">
                                                                <FiMapPin size="16" color="var(--bs-border)" className="me-1" />
                                                                <p className="d-inline semibold GrayComn align-middle me-1">10 Bayfront Ave, #01-01 Singapore 018956</p>
                                                                <p className="d-inline semibold align-middle me-md-5 ms-3">
                                                                    <HiOutlineClock size="20" color="var(--bs-border)" className="me4" /> 1.2 KM
                                                                </p>
                                                            </Col>
                                                        </Col>
                                                        <Col className="text-center takehead take02">
                                                            <div className="d-inline-block text-start">
                                                                <h4 className="fs22 semibold GrayComn m-0">Order ID</h4>
                                                                <p className="fs16 semibold gray82 mb-2">A17725GN6CX</p>
                                                            </div>
                                                        </Col>
                                                        <Col className="text-center take03">
                                                            <div className="d-inline-block text-start">
                                                                <h4 className="fs22 semibold GrayComn lh-1 mt-2 mb-1">Transaction Number</h4>
                                                                <p className="fs16 semibold gray82 mb-2">#05821330450</p>
                                                            </div>
                                                        </Col>
                                                        <Col className="text-center take04">
                                                            <div className="d-inline-block text-start">
                                                                <h4 className="fs22 semibold GrayComn lh-1 mt-2 mb-1">Collection Time</h4>
                                                                <p className="fs16 semibold gray82 mb-2">15 June 2022, 12:30 AM</p>
                                                            </div>
                                                        </Col>
                                                        <Col className="text-center take05">
                                                            <div className="d-inline-block text-start">
                                                                <h4 className="fs22 semibold GrayComn lh-1 mt-2 mb-1">Order Status</h4>
                                                                <p className="fs16 semibold textGreen mb-2">Completed</p>
                                                            </div>
                                                        </Col>
                                                        <Col className="text-center text-md-end text-xs-end take06">
                                                            <Button className="text-nowrap rounded-pill fs24 semibold py-1 px-4" variant="primary">Track Order</Button>
                                                        </Col>
                                                        <Col lg="auto" className="mx-width">
                                                            <Col as="a" 
                                                                className="btn p-0 themeColor outline-none shadow-none chevronAro border-0" 
                                                                data-bs-toggle="collapse" 
                                                                href="#order05" 
                                                                role="button" 
                                                                aria-expanded="false" 
                                                                aria-controls="order05">
                                                                Hide Details <FiChevronDown size="18" color="var(--theme-color)" />
                                                            </Col>
                                                            <Col as="div" className="mt-3 collapse hide" id="order05">
                                                                <Col as="div" className="card border-0 px-0">
                                                                    <Col as="div" className="cartList shadow01">
                                                                        <Row className="justify-space-between">
                                                                            <Col md={7} className="cartItemInfo d-md-flex">
                                                                                <Col className="col-auto cartImage me-3"><img src='./assets/cartItem.png' alt='carpic' /></Col>
                                                                                <Col md className="cartDetail">
                                                                                    <h2>Chiles en Nogada <span className="discountBadge ms-2">SAVE S$2.00 <AiFillCaretRight size="33" color="var(--bs-greenDark)" /></span></h2>
                                                                                    <span className="menuTag me-3">Dairy</span>
                                                                                    <span className="menuTag me-3">Wheat</span>
                                                                                    <ul className="listBullet list-unstyled mt-2">
                                                                                        <li className="d-inline-block text-nowrap">Black Pepper Beef &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">French Fries &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">Green Salad &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">Pepsi(250ml)</li>
                                                                                    </ul>
                                                                                    <Col as="span" className="spcRequest mt-2">
                                                                                        <span>Special Request</span>
                                                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br />incididunt ut labore et dolore magna aliqua.
                                                                                    </Col>
                                                                                </Col>
                                                                            </Col>
                                                                            <Col className="cartItemQTY trackOdr text-center">
                                                                                <div className="d-inline-block text-start">
                                                                                    <p className="fs12 semibold GrayComn m-0">Quantity</p>
                                                                                    <h4 className="fs30 semibold GrayComn m-0">1</h4>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md="auto" className="cartItemPrice trackOdr">
                                                                                <div className="text-start">
                                                                                    <span>Price</span>
                                                                                    <strong className="d-inline-block pt-1">S$11.99</strong>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <hr className="separator mb-4" />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="justify-space-between">
                                                                            <Col md={7} className="cartItemInfo d-md-flex">
                                                                                <Col className="col-auto cartImage me-3"><img src='./assets/cartItem.png' alt='carpic' /></Col>
                                                                                <Col md className="cartDetail">
                                                                                    <h2>Chiles en Nogada <span className="discountBadge ms-2">SAVE S$2.00 <AiFillCaretRight size="33" color="var(--bs-greenDark)" /></span></h2>
                                                                                    <span className="menuTag me-3">Dairy</span>
                                                                                    <span className="menuTag me-3">Wheat</span>
                                                                                    <ul className="listBullet list-unstyled mt-2">
                                                                                        <li className="d-inline-block text-nowrap">Black Pepper Beef &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">French Fries &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">Green Salad &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">Pepsi(250ml)</li>
                                                                                    </ul>
                                                                                </Col>
                                                                            </Col>
                                                                            <Col className="cartItemQTY trackOdr text-center">
                                                                                <div className="d-inline-block text-start">
                                                                                    <p className="fs12 semibold GrayComn m-0">Quantity</p>
                                                                                    <h4 className="fs30 semibold GrayComn m-0">1</h4>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md="auto" className="cartItemPrice trackOdr">
                                                                                <div className="text-start">
                                                                                    <span>Price</span>
                                                                                    <strong className="d-inline-block pt-1">S$11.99</strong>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <hr className="separator mb-4" />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="justify-space-between pt-3 pb-4">
                                                                            <Col>
                                                                                <h4 className="fs24 semibold GrayComn m-0">Disposable Cutleries</h4>
                                                                                <p className="fs18 semibold GrayComn m-0 lh-1">Lets do our part to save the earth!</p>
                                                                            </Col>
                                                                            <Col md="auto" className="cartItemPrice">
                                                                                <Form.Check
                                                                                    className="togglerBtn fs0 fs16 mt-2 scale15 semibold GrayComn text-uppercase pt-1"
                                                                                    type="switch"
                                                                                    id="custom-switch01"
                                                                                    label={alpha01 ? alpha01 : ""}
                                                                                    name="case02"
                                                                                    onChange={getCheckedValue}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Col>
                                                            </Col>
                                                        </Col>
                                                    </Row> */}

                                                    {/* 
                                                    <Row className="border-bottom mb-5 pb-5 firstHead">
                                                        <Col xl="12" xxl="auto" className="text-nowrap mb-xl-4 mb-lg-4 mb-md-4 mb-xxl-0">
                                                            <Col as="span" className="cafeImage position-relative float-start me-3">
                                                                <img src="./assets/black_scoop.png" width="56" height="56" className="img-fluid border border-2 rounded-pill" alt="cafepic" />
                                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamondBlue)' />
                                                            </Col>
                                                            <Col as="div" className="itemHead text-start">
                                                                <h4 className="d-inline align-middle me-3">City Home Cafe</h4>
                                                                <span className="rating"><BsStarFill size="18" color="var(--bs-border)" /> <strong className="position-relative">4.5</strong></span>
                                                            </Col>
                                                            <Col as="div" className="itemaddr text-start">
                                                                <FiMapPin size="16" color="var(--bs-border)" className="me-1" />
                                                                <p className="d-inline semibold GrayComn align-middle me-1">10 Bayfront Ave, #01-01 Singapore 018956</p>
                                                                <p className="d-inline semibold align-middle me-md-5 ms-3">
                                                                    <HiOutlineClock size="20" color="var(--bs-border)" className="me4" /> 1.2 KM
                                                                </p>
                                                            </Col>
                                                        </Col>
                                                        <Col className="text-center takehead take02">
                                                            <div className="d-inline-block text-start">
                                                                <h4 className="fs22 semibold GrayComn m-0">Order ID</h4>
                                                                <p className="fs16 semibold gray82 mb-2">A17725GN6CX</p>
                                                            </div>
                                                        </Col>
                                                        <Col className="text-center take03">
                                                            <div className="d-inline-block text-start">
                                                                <h4 className="fs22 semibold GrayComn lh-1 mt-2 mb-1">Transaction Number</h4>
                                                                <p className="fs16 semibold gray82 mb-2">#05821330450</p>
                                                            </div>
                                                        </Col>
                                                        <Col className="text-center take04">
                                                            <div className="d-inline-block text-start">
                                                                <h4 className="fs22 semibold GrayComn lh-1 mt-2 mb-1">Collection Time</h4>
                                                                <p className="fs16 semibold gray82 mb-2">15 June 2022, 12:30 AM</p>
                                                            </div>
                                                        </Col>
                                                        <Col className="text-center take05">
                                                            <div className="d-inline-block text-start">
                                                                <h4 className="fs22 semibold GrayComn lh-1 mt-2 mb-1">Order Status</h4>
                                                                <p className="fs16 semibold textGreen mb-2">Completed</p>
                                                            </div>
                                                        </Col>
                                                        <Col className="text-center text-md-end text-xs-end take06">
                                                            <Button className="text-nowrap rounded-pill fs24 semibold py-1 px-4" variant="primary">Track Order</Button>
                                                        </Col>
                                                        <Col lg="auto" className="mx-width">
                                                            <Col as="a"
                                                                className="btn p-0 themeColor outline-none shadow-none chevronAro"
                                                                data-bs-toggle="collapse"
                                                                href="#order06"
                                                                role="button"
                                                                aria-expanded="false"
                                                                aria-controls="order06">
                                                                Hide Details <FiChevronDown size="18" color="var(--theme-color)" />
                                                            </Col>
                                                            <Col as="div" className="mt-3 collapse hide" id="order06">
                                                                <Col as="div" className="card border-0 px-0">
                                                                    <Col as="div" className="cartList shadow01">
                                                                        <Row className="justify-space-between">
                                                                            <Col md={7} className="cartItemInfo d-md-flex">
                                                                                <Col className="col-auto cartImage me-3"><img src='./assets/cartItem.png' alt='carpic' /></Col>
                                                                                <Col md className="cartDetail">
                                                                                    <h2>Chiles en Nogada <span className="discountBadge ms-2">SAVE S$2.00 <AiFillCaretRight size="33" color="var(--bs-greenDark)" /></span></h2>
                                                                                    <span className="menuTag me-3">Dairy</span>
                                                                                    <span className="menuTag me-3">Wheat</span>
                                                                                    <ul className="listBullet list-unstyled mt-2">
                                                                                        <li className="d-inline-block text-nowrap">Black Pepper Beef &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">French Fries &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">Green Salad &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">Pepsi(250ml)</li>
                                                                                    </ul>
                                                                                    <Col as="span" className="spcRequest mt-2">
                                                                                        <span>Special Request</span>
                                                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br />incididunt ut labore et dolore magna aliqua.
                                                                                    </Col>
                                                                                </Col>
                                                                            </Col>
                                                                            <Col className="cartItemQTY trackOdr text-center">
                                                                                <div className="d-inline-block text-start">
                                                                                    <p className="fs12 semibold GrayComn m-0">Quantity</p>
                                                                                    <h4 className="fs30 semibold GrayComn m-0">1</h4>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md="auto" className="cartItemPrice trackOdr">
                                                                                <div className="text-start">
                                                                                    <span>Price</span>
                                                                                    <strong className="d-inline-block pt-1">S$11.99</strong>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <hr className="separator mb-4" />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="justify-space-between">
                                                                            <Col md={7} className="cartItemInfo d-md-flex">
                                                                                <Col className="col-auto cartImage me-3"><img src='./assets/cartItem.png' alt='carpic' /></Col>
                                                                                <Col md className="cartDetail">
                                                                                    <h2>Chiles en Nogada <span className="discountBadge ms-2">SAVE S$2.00 <AiFillCaretRight size="33" color="var(--bs-greenDark)" /></span></h2>
                                                                                    <span className="menuTag me-3">Dairy</span>
                                                                                    <span className="menuTag me-3">Wheat</span>
                                                                                    <ul className="listBullet list-unstyled mt-2">
                                                                                        <li className="d-inline-block text-nowrap">Black Pepper Beef &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">French Fries &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">Green Salad &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">Pepsi(250ml)</li>
                                                                                    </ul>
                                                                                </Col>
                                                                            </Col>
                                                                            <Col className="cartItemQTY trackOdr text-center">
                                                                                <div className="d-inline-block text-start">
                                                                                    <p className="fs12 semibold GrayComn m-0">Quantity</p>
                                                                                    <h4 className="fs30 semibold GrayComn m-0">1</h4>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md="auto" className="cartItemPrice trackOdr">
                                                                                <div className="text-start">
                                                                                    <span>Price</span>
                                                                                    <strong className="d-inline-block pt-1">S$11.99</strong>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <hr className="separator mb-4" />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="justify-space-between pt-3 pb-4">
                                                                            <Col>
                                                                                <h4 className="fs24 semibold GrayComn m-0">Disposable Cutleries</h4>
                                                                                <p className="fs18 semibold GrayComn m-0 lh-1">Lets do our part to save the earth!</p>
                                                                            </Col>
                                                                            <Col md="auto" className="cartItemPrice">
                                                                                <Form.Check
                                                                                    className="togglerBtn fs0 fs16 mt-2 scale15 semibold GrayComn text-uppercase pt-1"
                                                                                    type="switch"
                                                                                    id="custom-switch01"
                                                                                    label={alpha01 ? alpha01 : ""}
                                                                                    name="case02"
                                                                                    onChange={getCheckedValue}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Col>
                                                            </Col>
                                                        </Col>
                                                    </Row> */}

                                                    {/* <Row className="pb-5 firstHead">
                                                        <Col xl="12" xxl="auto" className="text-nowrap mb-xl-4 mb-lg-4 mb-md-4 mb-xxl-0">
                                                            <Col as="span" className="cafeImage position-relative float-start me-3">
                                                                <img src="./assets/black_scoop.png" width="56" height="56" className="img-fluid border border-2 rounded-pill" alt="cafepic" />
                                                                <IoDiamond className='diamondIcon' size="15" color='var(--diamondBlue)' />
                                                            </Col>
                                                            <Col as="div" className="itemHead text-start">
                                                                <h4 className="d-inline align-middle me-3">City Home Cafe</h4>
                                                                <span className="rating"><BsStarFill size="18" color="var(--bs-border)" /> <strong className="position-relative">4.5</strong></span>
                                                            </Col>
                                                            <Col as="div" className="itemaddr text-start">
                                                                <FiMapPin size="16" color="var(--bs-border)" className="me-1" />
                                                                <p className="d-inline semibold GrayComn align-middle me-1">10 Bayfront Ave, #01-01 Singapore 018956</p>
                                                                <p className="d-inline semibold align-middle me-md-5 ms-3">
                                                                    <HiOutlineClock size="20" color="var(--bs-border)" className="me4" /> 1.2 KM
                                                                </p>
                                                            </Col>
                                                        </Col>
                                                        <Col className="text-center takehead take02">
                                                            <div className="d-inline-block text-start">
                                                                <h4 className="fs22 semibold GrayComn m-0">Order ID</h4>
                                                                <p className="fs16 semibold gray82 mb-2">A17725GN6CX</p>
                                                            </div>
                                                        </Col>
                                                        <Col className="text-center take03">
                                                            <div className="d-inline-block text-start">
                                                                <h4 className="fs22 semibold GrayComn lh-1 mt-2 mb-1">Transaction Number</h4>
                                                                <p className="fs16 semibold gray82 mb-2">#05821330450</p>
                                                            </div>
                                                        </Col>
                                                        <Col className="text-center take04">
                                                            <div className="d-inline-block text-start">
                                                                <h4 className="fs22 semibold GrayComn lh-1 mt-2 mb-1">Collection Time</h4>
                                                                <p className="fs16 semibold gray82 mb-2">15 June 2022, 12:30 AM</p>
                                                            </div>
                                                        </Col>
                                                        <Col className="text-center take05">
                                                            <div className="d-inline-block text-start">
                                                                <h4 className="fs22 semibold GrayComn lh-1 mt-2 mb-1">Order Status</h4>
                                                                <p className="fs16 semibold textGreen mb-2">Completed</p>
                                                            </div>
                                                        </Col>
                                                        <Col className="text-center text-md-end text-xs-end take06">
                                                            <Button className="text-nowrap rounded-pill fs24 semibold py-1 px-4" variant="primary">Track Order</Button>
                                                        </Col>
                                                        <Col lg="auto" className="mx-width">
                                                            <Col as="a"
                                                                className="btn p-0 themeColor outline-none shadow-none chevronAro"
                                                                data-bs-toggle="collapse"
                                                                href="#order07"
                                                                role="button"
                                                                aria-expanded="false"
                                                                aria-controls="order07">
                                                                Hide Details <FiChevronDown size="18" color="var(--theme-color)" />
                                                            </Col>
                                                            <Col as="div" className="mt-3 collapse hide" id="order07">
                                                                <Col as="div" className="card border-0 px-0">
                                                                    <Col as="div" className="cartList shadow01">
                                                                        <Row className="justify-space-between">
                                                                            <Col md={7} className="cartItemInfo d-md-flex">
                                                                                <Col className="col-auto cartImage me-3"><img src='./assets/cartItem.png' alt='carpic' /></Col>
                                                                                <Col md className="cartDetail">
                                                                                    <h2>Chiles en Nogada <span className="discountBadge ms-2">SAVE S$2.00 <AiFillCaretRight size="33" color="var(--bs-greenDark)" /></span></h2>
                                                                                    <span className="menuTag me-3">Dairy</span>
                                                                                    <span className="menuTag me-3">Wheat</span>
                                                                                    <ul className="listBullet list-unstyled mt-2">
                                                                                        <li className="d-inline-block text-nowrap">Black Pepper Beef &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">French Fries &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">Green Salad &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">Pepsi(250ml)</li>
                                                                                    </ul>
                                                                                    <Col as="span" className="spcRequest mt-2">
                                                                                        <span>Special Request</span>
                                                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br />incididunt ut labore et dolore magna aliqua.
                                                                                    </Col>
                                                                                </Col>
                                                                            </Col>
                                                                            <Col className="cartItemQTY trackOdr text-center">
                                                                                <div className="d-inline-block text-start">
                                                                                    <p className="fs12 semibold GrayComn m-0">Quantity</p>
                                                                                    <h4 className="fs30 semibold GrayComn m-0">1</h4>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md="auto" className="cartItemPrice trackOdr">
                                                                                <div className="text-start">
                                                                                    <span>Price</span>
                                                                                    <strong className="d-inline-block pt-1">S$11.99</strong>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <hr className="separator mb-4" />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="justify-space-between">
                                                                            <Col md={7} className="cartItemInfo d-md-flex">
                                                                                <Col className="col-auto cartImage me-3"><img src='./assets/cartItem.png' alt='carpic' /></Col>
                                                                                <Col md className="cartDetail">
                                                                                    <h2>Chiles en Nogada <span className="discountBadge ms-2">SAVE S$2.00 <AiFillCaretRight size="33" color="var(--bs-greenDark)" /></span></h2>
                                                                                    <span className="menuTag me-3">Dairy</span>
                                                                                    <span className="menuTag me-3">Wheat</span>
                                                                                    <ul className="listBullet list-unstyled mt-2">
                                                                                        <li className="d-inline-block text-nowrap">Black Pepper Beef &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">French Fries &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">Green Salad &nbsp;<FaCircle size="7" color="var(--bs-red9d9)" />&nbsp;&nbsp;</li>
                                                                                        <li className="d-inline-block text-nowrap">Pepsi(250ml)</li>
                                                                                    </ul>
                                                                                </Col>
                                                                            </Col>
                                                                            <Col className="cartItemQTY trackOdr text-center">
                                                                                <div className="d-inline-block text-start">
                                                                                    <p className="fs12 semibold GrayComn m-0">Quantity</p>
                                                                                    <h4 className="fs30 semibold GrayComn m-0">1</h4>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md="auto" className="cartItemPrice trackOdr">
                                                                                <div className="text-start">
                                                                                    <span>Price</span>
                                                                                    <strong className="d-inline-block pt-1">S$11.99</strong>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <hr className="separator mb-4" />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="justify-space-between pt-3 pb-4">
                                                                            <Col>
                                                                                <h4 className="fs24 semibold GrayComn m-0">Disposable Cutleries</h4>
                                                                                <p className="fs18 semibold GrayComn m-0 lh-1">Lets do our part to save the earth!</p>
                                                                            </Col>
                                                                            <Col md="auto" className="cartItemPrice">
                                                                                <Form.Check
                                                                                    className="togglerBtn fs0 fs16 mt-2 scale15 semibold GrayComn text-uppercase pt-1"
                                                                                    type="switch"
                                                                                    id="custom-switch01"
                                                                                    label={alpha01 ? alpha01 : ""}
                                                                                    name="case02"
                                                                                    onChange={getCheckedValue}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Col>
                                                            </Col>
                                                        </Col>
                                                    </Row> */}
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Scrollbars>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}
export default MyOrderA