import React, { useState } from 'react'
import { Container, Row, Col, Breadcrumb, Card, Form, Button } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "./identity.css"
import { useNavigate } from "react-router-dom";

import AfterLoginHeader from '../../components/afterLoginHeader';
import Footer from '../../components/footer';
import ProfileMenu from '../../components/profilemenu';
import { HiOutlineUpload } from 'react-icons/hi';
import IdentityStep03 from './identitystep03';


const IdentityStep02 = (params) => {

    console.log(params);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [stepNo, setStepNo] = useState(2);
    const [userHostType, setUserHostType] = useState('personal');
    const [methodValue, setMethodValue] = useState('')




    const navigate = useNavigate();

    // const goBack = () => navigate(-1);

    const onNextpage = (event) => {
        event.preventDefault();
        console.log(event)
        if (params.updateFrontFile === null && params?.nricFrontFile === null) {
            setErrorMessage({
                error: "empty nric-front",
                message: "Please Upload NRIC Front",
            });
        }
        else if (params.updateBackFile === null && params?.nricBackFile === null) {
            setErrorMessage({
                error: "empty nric-back",
                message: "Please Upload NRIC Back",
            });
        }
        else {
            setTimeout(() => {
                params.setStepNo(3);
            }, 1400)
        }
    };


    const goBack = () => {
        setTimeout(() => {
            params.setStepNo(1);
        }, 100);
    };

    const handleNext = () => {
        navigate('/identitystep03')

    }

    return (
        <>

            <div id="bodyWrap" className="">
                <Container className="mealOne" fluid>
                    <Row>
                        <Col className="px-0"><AfterLoginHeader /></Col>
                    </Row>
                    {/* <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col> */}
                    <Row className="dblock">
                        <Col lg="3" className="ps-0 profilemenu outline-none" tabindex="-1" id="myprofile01" aria-labelledby="myprofile01Label"><ProfileMenu /></Col>
                        <Col lg="9" as="div" className="userAcc pt-4 pe-xl-5">
                            <h2 className="h2_large ms-md-4 mb-0 GrayComn">My Profile</h2>
                            <Breadcrumb className="ms-md-4 mb-5">
                                <Breadcrumb.Item href="/">Account Settings</Breadcrumb.Item>
                                <Breadcrumb.Item href="/">Manage  Payments</Breadcrumb.Item>
                            </Breadcrumb>
                            <Col as="section" className="stepWrap ms-md-4 pt-md-5">
                                <Row className="text-center justify-content-center pb-4">
                                    <Col xs={12} lg="10" xl="7" className="text-start">
                                        <h2 className="fs22 GrayComn semibold">Please upload your NRIC.</h2>
                                        <Col lg="12" as="section" className="stepsBar2 pt-4">
                                            <Col as="ul" className="list-unstyled">
                                                <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(100%/2)" }}>2</span></Col>
                                            </Col>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row className="text-center justify-content-center">
                                    <Col lg="7">
                                        <Row>
                                            <Col as="section" className="signpUser pt-2">
                                                <Form className="text-start" onSubmit={onNextpage}>
                                                    <Row className="flex-wrap mt-5">
                                                        <Col lg="12" className="pb0">
                                                            <Form.Group controlId="nric-front" className="mb-4">
                                                                <Form.Label className="nricFile fs20 semibold themeColor w-100 mb-0 form-label">
                                                                    {
                                                                        params?.updateFrontFile !== null && !params?.nricFrontFile ? params?.updateFrontFile?.name : params?.updateBackFile === null && params.nricFrontFile ? params.nricFrontFile : <span className="pt-2 d-inline-block"> {"NRIC FRONT"}</span>
                                                                    }

                                                                    <span role="button" className="px-3 pt-2 pb-3 lh-1 shadowMD border border08 d-inline-block bg-white float-end">
                                                                        <HiOutlineUpload size="24" color="var(--bs-grayDark)" />
                                                                    </span>
                                                                </Form.Label>

                                                                {/* <Form.Control name="nricFront" type="file" className="d-none" accept=".png, .jpg, .pdf" /> */}
                                                                <Form.Control
                                                                    name="updateFrontFile"
                                                                    type="file"
                                                                    className="d-none"
                                                                    accept=".png, .jpg, .pdf"
                                                                    // value ={"params.nricFile"}
                                                                    onChange={(event) => {
                                                                        if (event.target.files[0].name === undefined) {
                                                                            setErrorMessage({
                                                                                error: "empty nric-front",
                                                                                message: "Please Upload NRIC Front",
                                                                            });
                                                                        } else {
                                                                            setErrorMessage(undefined);
                                                                        }
                                                                        params.setUpdateFrontFile(event.target.files[0]);
                                                                    }}
                                                                />
                                                                {errorMessage &&
                                                                    errorMessage.error === "empty nric-front" && (
                                                                        <div
                                                                            className="invalid-feedback d-block"
                                                                            style={{ textAlign: "start" }}
                                                                        >
                                                                            {errorMessage.message}
                                                                        </div>
                                                                    )}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="12">
                                                            <Form.Group controlId="nric-back" className="mb-4 pt-2">
                                                                <Form.Label className="nricFile fs20 semibold themeColor w-100 form-label">
                                                                    {/* <span className="pt-2 d-inline-block">{params.nricFile?.back?.name || "NRIC Back"}</span> */}

                                                                    {
                                                                        params?.updateBackFile !== null && !params?.nricBackFile ? params?.updateBackFile?.name : params?.updateBackFile === null && params.nricBackFile ? params.nricBackFile : <span className="pt-2 d-inline-block"> {"NRIC Back"}</span>
                                                                    }


                                                                    <span role="button" className="px-3 pt-2 pb-3 lh-1 shadowMD border border08 d-inline-block bg-white float-end">
                                                                        <HiOutlineUpload size="24" color="var(--bs-grayDark)" />
                                                                    </span>
                                                                </Form.Label>
                                                                {/* <Form.Control name="nricBack" type="file" className="d-none" accept=".jpg, .png, .pdf" /> */}
                                                                <Form.Control
                                                                    name="nricBack"
                                                                    type="file"
                                                                    className="d-none"
                                                                    accept=".png, .jpg, .pdf"
                                                                    onChange={(event) => {
                                                                        if (event.target.files[0].name === undefined) {
                                                                            setErrorMessage({
                                                                                error: "empty nric-front",
                                                                                message: "Please Upload NRIC Back",
                                                                            });
                                                                        } else {
                                                                            setErrorMessage(undefined);
                                                                        }
                                                                        // {console.log(event.target.files[0]);}
                                                                        params.setUpdateBackFile(event.target.files[0]);
                                                                    }}
                                                                />
                                                                {errorMessage &&
                                                                    errorMessage.error === "empty nric-back" && (
                                                                        <div
                                                                            className="invalid-feedback d-block"
                                                                            style={{ textAlign: "start" }}
                                                                        >
                                                                            {errorMessage.message}
                                                                        </div>
                                                                    )}
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <p className="fs16 textGreen semibold mb-0">*Acceptable files .jpg, .png, .pdf. File size not exceeding 1MB.</p>
                                                    <Row className="mt-5">
                                                        <Col className="align-self-center">
                                                            <Button className="fs26 themeColor semibold text-decoration-none lh-1 p-0" type="button" variant="link" onClick={goBack}>
                                                                <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                Previous
                                                            </Button>
                                                        </Col>
                                                        <Col className="text-end">
                                                            <Button className="py-2 lh-lg border-0 border08 rounded-pill w-75" variant="primary" type="submit" >Next</Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>


        </>

    )
}
export default IdentityStep02