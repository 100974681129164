import React, { useState } from "react";
import { Row, Col, Form, Button, FloatingLabel } from "react-bootstrap";
import { addClass } from "../../../commanFunctions";
import { BsCircleFill } from "react-icons/bs";

const HostBusiness12 = (params) => {
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [postalCode, setPostalCode] = useState('');
  const [floorNumber, setFloorNumber] = useState('');
  const [unitNumber, setUnitNumber] = useState('');
  const [propertyFile, setPropertyFile] = useState(null);

  const onNextpage = (event) => {
    event.preventDefault();
    let address = floorNumber + unitNumber + postalCode
    let f = params.outletData.find((d => d.address === address))

    if (postalCode === '') {
      setErrorMessage({
        error: "empty postal code",
        message: "Please Enter Postal code",
      });
    } else if (floorNumber === '') {
      setErrorMessage({
        error: "empty floor number",
        message: "Please Enter Floor Number",
      });
    } else if (unitNumber === '') {
      setErrorMessage({
        error: "empty unit number",
        message: "Please Enter Unit Number",
      });
    } else if (propertyFile === null || propertyFile.name === undefined) {
      setErrorMessage({
        error: "document not upload",
        message: "Please Upload Document",
      });
    } else if (f?.address === address) {
      // console.log("Outlet not push in array because address data same ....")
      addClass()
      setTimeout(() => {
        params.setStepNo(13);
        params.setPushAddress(true);
      }, 1400)
    } else {
      addClass();
      setTimeout(() => {
        params.setStepNo(13);
        params.setPushAddress(true);
        params.outletData.push({
          long: "30.5455",
          lat: "29.4545",
          address: floorNumber + unitNumber + postalCode,
          outlet_property: params.propertyUser,
          verificationdoc: propertyFile,
        });
      }, 1400);
    }
  };

  const goBack = () => {
    addClass();
    setTimeout(() => {
      params.setStepNo(11);
    }, 1400);
  };

  return (
    <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
      <div className="front">
        <div className="heading">
          <h2 className="small lh-1 fs-normal mb-2">Add Outlet</h2>
          <p className="semibold m-0">Please provide us your Crib Outlet details.</p>
        </div>
        <Col as="section" className="stepsBar2 pt-4">
          <Col as="ul" className="list-unstyled">
            <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(100%/1.2)" }}>12</span></Col>
          </Col>
        </Col>
        <Col as="section" className="signpUser pt-0">
          <Col as="div" className="text-start semibold pb-2">{params.outletData.length + 1 === 2 ? "2nd" : params.outletData.length + 1 === 3 ? "3rd" : `${params.outletData.length + 1}th`} Outlet</Col>
          <Form onSubmit={onNextpage} className="accForm">
            <Col as="section" className={`signpUser mb-4 row text-center`}>
              <Col lg="12" className="dineUser">
                <FloatingLabel controlId="floatingInput" label="Postal Code" className="mb-3 text-start">
                  <Form.Control
                    type="text"
                    placeholder="Postal Code"
                    value={postalCode}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setErrorMessage({
                          error: "empty postal code",
                          message: "Please Enter Postal code",
                        });
                      } else {
                        setErrorMessage(undefined);
                      }
                      setPostalCode(e.target.value);
                    }}
                  />
                  {errorMessage &&
                    errorMessage.error === "empty postal code" && (
                      <div className="invalid-feedback d-block text-start">
                        {errorMessage.message}
                      </div>
                    )}
                </FloatingLabel>
              </Col>
              <Col className="dineUser">
                <FloatingLabel controlId="floatingInput" label="Floor Number" className="mb-3 text-start">
                  <Form.Control
                    type="text"
                    placeholder="Floor Number"
                    value={floorNumber}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setErrorMessage({
                          error: "empty floor number",
                          message: "Please Enter Floor Number",
                        });
                      } else {
                        setErrorMessage(undefined);
                      }
                      setFloorNumber(e.target.value);
                    }}
                  />
                  {errorMessage &&
                    errorMessage.error === "empty floor number" && (
                      <div className="invalid-feedback d-block text-start">
                        {errorMessage.message}
                      </div>
                    )}
                </FloatingLabel>
              </Col>
              <Col className="hostUser">
                <FloatingLabel controlId="floatingInput" label="Unit Number" className="mb-3 text-start">
                  <Form.Control
                    type="text"
                    placeholder="Unit Number"
                    value={unitNumber}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setErrorMessage({
                          error: "empty unit number",
                          message: "Please Enter Unit Number",
                        });
                      } else {
                        setErrorMessage(undefined);
                      }
                      setUnitNumber(e.target.value);
                    }}
                  />
                  {errorMessage &&
                    errorMessage.error === "empty unit number" && (
                      <div className="invalid-feedback d-block text-start">
                        {errorMessage.message}
                      </div>
                    )}
                </FloatingLabel>
              </Col>
              <Row className="m-0 mt-2">
                <Col className="text-start ps-0">
                  {params.propertyUser === "owner" ? (
                    <ul className="list-unstyled">
                      <li className="semibold"><BsCircleFill className="me-2" color="var(--bs-grayDark)" size="4" /> Property Title Deed</li>
                      <li className="semibold"><BsCircleFill className="me-2" color="var(--bs-grayDark)" size="4" /> Property Tex Bill (last 1 year)</li>
                      <li className="semibold"><BsCircleFill className="me-2" color="var(--bs-grayDark)" size="4" /> Town Council Invoice (last 3 Months)</li>
                      <li className="semibold"><BsCircleFill className="me-2" color="var(--bs-grayDark)" size="4" /> MCST Invoice (last 3 months)</li>
                    </ul>
                  ) : params.propertyUser === "tenant" ? (
                    <>
                      <label className="themeColor semibold">
                        Please upload One of the following documents:
                      </label>
                      <ul className="list-unstyled">
                        <li><BsCircleFill className="me-2" color="var(--bs-grayDark)" size="4" /> Lease or Rental Agreement</li>
                        <li><BsCircleFill className="me-2" color="var(--bs-grayDark)" size="4" /> Authorized Tenant Confirmation Notice</li>
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col lg="auto" className="pe-0 text-end">
                  <Form.Group controlId="nric-front" className="mb-3">
                    <Form.Label className="nricFile justify-content-end position-static mt-4">
                      <span role="button" className="px-3 pt-2 pb-3 shadowMD border border08 d-inline-block bg-white">
                        <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" color="var(--theme-color)" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"></path>
                        </svg>
                      </span>
                    </Form.Label>
                    <Form.Control
                      name="nricFront"
                      type="file"
                      className="d-none"
                      accept=".png, .jpg, .pdf"
                      onChange={(event) => {
                        if (event.target.files[0].name === undefined) {
                          setErrorMessage({
                            error: "document not upload",
                            message: "Please Upload Doument",
                          });
                        } else {
                          setErrorMessage(undefined);
                        }
                        setPropertyFile(event.target.files[0]);
                      }}
                    />
                    {errorMessage &&
                      errorMessage.error === "document not upload" && (
                        <div
                          className="invalid-feedback d-block text-start"
                          style={{ textAlign: "start" }}
                        >
                          {errorMessage.message}
                        </div>
                      )}
                  </Form.Group>
                </Col>
              </Row>
              <Col className="text-start">
                <label className="textGreen semibold">
                  Acceptable files .jpg, .png, <br className="d-none d-lg-inline" />.pdf File Size not
                  exceedding 1MB.
                </label>
              </Col>
            </Col>
            <Row>
              <Col className="align-self-center">
                <Button
                  className="fs26 themeColor semibold text-decoration-none lh-1 p-0"
                  variant="link"
                  type="button"
                  onClick={goBack}
                >
                  <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  Previous
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                  variant="primary"
                  type="submit"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
    </section>
  )
}

export default HostBusiness12