import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { HiOutlineUpload } from "react-icons/hi";
import { addClass } from "../../../commanFunctions";

const HostBusiness03A = (params) => {
  const [errorMessage, setErrorMessage] = useState(undefined);
  const onNextpage = (event) => {
    event.preventDefault();
    if (params.acradoc?.name === undefined) {
      setErrorMessage({
        error: "empty acra-profile",
        message: "Please Upload ACRA Profile",
      });
    } else {
      addClass()
      setTimeout(() => {
        params.setStepNo(4);
      }, 1400)
    }
  };

  const goBack = () => {
    addClass();
    setTimeout(() => {
      params.setStepNo(2);
    }, 1400);
  };

  return (
    <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
      <div className="front">
        <div className="heading">
          <h2 className="small lh-1 fs-normal mb-2">Become A Host</h2>
          <p className="semibold m-0">Please upload your NRIC.</p>
        </div>
        <Col as="section" className="stepsBar2 pt-4">
          <Col as="ul" className="list-unstyled">
            <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(100%/3.7)" }}>3</span></Col>
          </Col>
        </Col>
        <Col as="section" className='signpUser pt-4'>
          <Form onSubmit={onNextpage}>
            <Col as="section" className={`signpUser mb-1 text-center`}>
              <Row>
                <Col>
                  <Form.Group controlId="acra-profile" className="mb-3">
                    <Form.Label className="nricFile fs20 semibold themeColor w-100 text-start">
                      {params.acradoc?.name ||
                        "ACRA Profile (Last 3 Months)"}{" "}
                      <span role="button" className="px-3 pt-2 pb-3 lh-1 shadowMD border border08 d-inline-block bg-white float-end">
                        <HiOutlineUpload color="var(--theme-color)" />
                      </span>
                    </Form.Label>
                    <Form.Control
                      name="acradoc"
                      type="file"
                      className="d-none"
                      accept=".png, .jpg, .pdf"
                      onChange={(event) => {
                        if (event.target.files[0].name === undefined) {
                          setErrorMessage({
                            error: "empty acra-profile",
                            message: "Please Upload ACRA Profile",
                          });
                        } else {
                          setErrorMessage(undefined);
                        }
                        params.setAcraDoc(event.target.files[0]);
                      }}
                    />
                    {errorMessage &&
                      errorMessage.error === "empty acra-profile" && (
                        <div
                          className="invalid-feedback d-block"
                          style={{ textAlign: "start" }}
                        >
                          {errorMessage.message}
                        </div>
                      )}
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <p className="sm-p textGreen semibold">
              *Acceptable files .jpg, .png, <br className="d-none d-lg-inline" />.pdf. File size not exceeding
              1MB.
            </p>
            <Row className="pt-3">
              <Col className="align-self-center">
                <Button
                  className="fs26 themeColor semibold text-decoration-none lh-1 p-0"
                  variant="link"
                  type="button"
                  onClick={goBack}
                >
                  <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  Previous
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                  variant="primary"
                  type="submit"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
    </section>
  )
}

export default HostBusiness03A