import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { addClass } from "../../../commanFunctions";

const HostBusiness03B = (params) => {
  const onNextpage = () => {
    addClass();
    setTimeout(() => {
      params.setStepNo(4);
    }, 1400);
  }
  const goBack = () => {
    addClass();
    setTimeout(() => {
      params.setStepNo(2);
    }, 1400);
  }
  return (
    <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
      <div className="front">
        <div className="heading">
          <h2 className="small lh-1 fs-normal mb-2">Become A Host</h2>
          <p className="semibold m-0">
            Please choose your method of registration type.
          </p>
        </div>
        <Col as="section" className="stepsBar2 pt-4">

          <Col as="ul" className="list-unstyled">
            <Col as="li" className="p2 active first"><span className="fs14">3</span></Col>
          </Col>

        </Col>
        <Col as="section" className="signpUser pt-3">
          <Form>
            <Col as="section" className={`signpUser mb-4 row text-center `}></Col>
            <h1 className="mb-5">QR Code Screen</h1>
            <Row>
              <Col className="align-self-center">
                <Button
                  className="fs26 themeColor semibold text-decoration-none lh-1 p-0"
                  variant="link"
                  type="button"
                  onClick={goBack}
                >
                  <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  Previous
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                  variant="primary"
                  type="button"
                  onClick={onNextpage}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
    </section>
  )
}

export default HostBusiness03B