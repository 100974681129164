import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { Col, Form, Button } from 'react-bootstrap';
import { BsClock } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { addClass } from '../../../commanFunctions';
import CountdownTimer from '../../../components/CountdownTimer';
import { getSelectedQuestions, SendOtpByEmail, verifyAnswer, verifyOTP } from '../../../queries/queries';


const OtpAndQnaPage = (params) => {
    console.log("params------------->", params);
    const [switchBtn, setSwitchBtn] = useState('otp')
    const [otpone, setOtpOne] = useState('');
    const [otptwo, setOtpTwo] = useState('');
    const [otpthree, setOtpThree] = useState('');
    const [otpfour, setOtpFour] = useState('');
    const [otpfive, setOtpFive] = useState('');
    const [otpsix, setOtpSix] = useState('');
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [securityQnA, setSecurityQnA] = useState([]);
    const [securityAnswer, setSecurityAnswer] = useState({ question_id: '', answer: "" });
    const [ResetCountdown, setResetCountdown] = useState(false);
   
    const SendOTPByEmail = SendOtpByEmail();
    const [OTPData] = useMutation(SendOTPByEmail);
    const verifyOtp = verifyOTP();
    const [verifyOTPData] = useMutation(verifyOtp);

    const getQuestions = getSelectedQuestions();
    const [SelectedQuestions] = useMutation(getQuestions);
    const VerifyAnswer = verifyAnswer();
    const [VerifyQnA] = useMutation(VerifyAnswer);

    const OTPSend = async () => {
        const dataOtp = await OTPData({ variables: { text: params.email } })
        if (dataOtp?.data?.sendOtpToEmailOrPhone?.status === "success") {
            console.log("Otp successfully send on your email")
        }
    }

    useEffect(() => {
        OTPSend();
    }, [])

    const myQuestion = async () => {
        const que = await SelectedQuestions({ variables: { text: params.email } })
        let questions = que?.data?.getSelectedQuestions?.security_question_by_user
        setSecurityQnA(questions)
        setSecurityAnswer({ ...securityAnswer, question_id: questions[0].question_id })
    }

    const inputfocus = (elmnt) => {
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        } else if (elmnt.key === "Tab") {
            const next = elmnt.target.tabIndex - 1;
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        } else {
            const next = elmnt.target.tabIndex;
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        }
    }

    const handleSwitch = event => {
        if (event.currentTarget.classList.length === 3) {
            setSwitchBtn("otp")
        } else {
            setSwitchBtn("qna")
            myQuestion()
        }
        event.currentTarget.classList.toggle('switchActive');
    };

    const onResend = async () => {
        setErrorMessage({
            error: 'resend otp',
            message: 'Resend OTP on your email.'
        })
        setOtpOne('')
        setOtpTwo('')
        setOtpThree('')
        setOtpFour('')
        setOtpFive('')
        setOtpSix('')

        OTPSend();
        setResetCountdown(true)
    }

    const handleNext = async () => {
        let otp = Number(otpone + otptwo + otpthree + otpfour + otpfive + otpsix);
        const dataVerify = await verifyOTPData({ variables: { otp: otp, text: params.email } })
        if (dataVerify?.data?.verifyOTP?.status === "success") {
            addClass()
            setTimeout(() => {
                params.setStepNo(3)
            }, 1400)
        } else {
            setErrorMessage({
                error: 'resend otp',
                message: dataVerify?.data?.verifyOTP?.message
            })
        }
    }
    const NextClick = async () => {
        let Apidata = await VerifyQnA({
            variables: {
                "text": params.email,
                "question_id": Number(securityAnswer?.question_id),
                "answer": securityAnswer?.answer,
            }
        })
        if (Apidata?.data?.verifyAnswer?.status === "success") {
            addClass()
            setTimeout(() => {
                params.setStepNo(3)
            }, 1400)
        } else {
            setErrorMessage({
                error: 'error qna',
                message: Apidata?.data?.verifyAnswer?.message
            })
        }
    }

    return (
        <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
            <div className="front">
                <div className='heading mb-4'>
                    <h2 className='small lh-1 fs-normal mb-0'>User Verification</h2>
                    {/* <p className="semibold">A 6-Digit Code has been send your number +61 XXXX X567</p> */}
                    <p className="semibold">A 6-Digit Code has been send your email or number {params?.email}</p>
                </div>

                <div className="reactSwitch">
                    <Col as="div" className="switchButton" onClick={handleSwitch}>
                        <input type="checkbox" id="input1" />
                        <label className="valueOne">OTP</label>
                        <label className="valueTwo">Security Question</label>
                    </Col>
                    <Form>
                        {switchBtn === "otp" ? <><Form.Group controlId="formBasicName" className="mb-3 pb-1 row otpScreem">
                            <Col md={2}><input type="text" inputMode="numeric" id="input1" placeholder="." value={otpone} onChange={(e) => {
                                setOtpOne(e.target.value)
                            }} maxLength="1" tabIndex="1" onKeyUp={e => inputfocus(e)} /></Col>

                            <Col md={2}><input type="text" inputMode="numeric" id="input2" placeholder="." value={otptwo} onChange={(e) => {
                                setOtpTwo(e.target.value)
                            }} maxLength="1" tabIndex="2" onKeyUp={e => inputfocus(e)} /></Col>

                            <Col md={2}><input type="text" inputMode="numeric" id="input3" placeholder="." value={otpthree} onChange={(e) => {
                                setOtpThree(e.target.value)
                            }} maxLength="1" tabIndex="3" onKeyUp={e => inputfocus(e)} /></Col>

                            <Col md={2}><input type="text" inputMode="numeric" id="input4" placeholder="." value={otpfour} onChange={(e) => {
                                setOtpFour(e.target.value)
                            }} maxLength="1" tabIndex="4" onKeyUp={e => inputfocus(e)} /></Col>

                            <Col md={2}><input type="text" inputMode="numeric" id="input5" placeholder="." value={otpfive} onChange={(e) => {
                                setOtpFive(e.target.value)
                            }} maxLength="1" tabIndex="5" onKeyUp={e => inputfocus(e)} /></Col>

                            <Col md={2}><input type="text" id="input6" placeholder="." value={otpsix} onChange={(e) => {
                                // onAutoSubmit(e.target.value)
                                setOtpSix(e.target.value)
                            }} maxLength="1" tabIndex="6" inputMode="numeric" onKeyUp={e => inputfocus(e)} /></Col>
                            {errorMessage && (errorMessage.error === 'invalid otp' || errorMessage.error === 'resend otp') && (
                                <div className="invalid-feedback d-block">
                                    {errorMessage.message}
                                </div>
                            )}
                        </Form.Group>
                            <Form.Group className="mt-4 mb-4 pb-4" controlId="exampleForm.ControlTextarea1">
                                <Form.Label className='float-start'><Link to="#" className="forgotPass text-decoration-none" onClick={onResend}>Resend?</Link></Form.Label>
                                <Form.Label className='float-end'>
                                    <CountdownTimer countdown={120} ResetCountdown={ResetCountdown} setResetCountdown={setResetCountdown} />
                                </Form.Label>
                            </Form.Group>
                            <Button className="w-100 py-2 lh-lg border-0 hvr-float-shadow" variant="primary" type="button" onClick={handleNext}>Continue</Button>
                        </>
                            : <>
                                <Form.Group controlId="formBasicName" className="mb-3 pb-1">
                                    {securityQnA.length == 0 ? <p className="forgotPass semibold">No question available</p> : <>
                                        <Form.Select
                                            className="semibold selectControl mb-2"
                                            aria-label="Floating label select example"
                                            value={securityAnswer?.question_id}
                                            onChange={(e) => {
                                                setSecurityAnswer({ ...securityAnswer, question_id: e.target.value });
                                            }}
                                        >{
                                                securityQnA && securityQnA?.map((item, index) => (
                                                    <option key={index} value={item?.question_id}>{item?.questions?.question}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        <Form.Control type="text" placeholder="Your Answer" value={securityAnswer.answer} onChange={(e) => setSecurityAnswer({ ...securityAnswer, answer: e.target.value })} />
                                    </>}
                                    {(errorMessage?.error === 'error qna') && (
                                        <div className="invalid-feedback d-block">
                                            {errorMessage.message}
                                        </div>
                                    )}
                                </Form.Group>
                                <Button className="w-100 py-2 lh-lg border-0 hvr-float-shadow" variant="primary" type="button" onClick={NextClick}>Continue</Button>
                            </>
                        }
                    </Form>
                </div>
            </div>
        </section>
    )
}
export default OtpAndQnaPage