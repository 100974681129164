import React, { useEffect } from "react";
// import { Stripe, CardElement, injectStripe, Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, PaymentElement, PaymentRequestButtonElement, CardElement, ElementsConsumer, paymentMethods } from '@stripe/react-stripe-js';
import { useState } from "react";
import { Card } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { GetUserById } from "../../../queries/queries";


const SaveCardForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [cardInfo, setCardInfo] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        // const { stripe, elements } = this.props;
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });
        console.log("paymentMethod", paymentMethod)
        setCardInfo(paymentMethod?.card);
    };

    const  getPaymentMethods = async (event) =>  {
        event.preventDefault();
          const paymentMethods = await stripe.paymentMethods.list({
            customer: 'cus_NA6lYePfEagw0g',
            type: 'card',
          });
        console.log("paymentMethods", paymentMethods);
      }
      
    return (
        <form onSubmit={handleSubmit}>
            <CardElement />
            {/* <PaymentElement /><br/> */}
            <button type="submit" disabled={!stripe}>
                Save Card Data
            </button>

            <button type="submit" disabled={!stripe} onClick={getPaymentMethods}>
              Get Card
            </button>
        </form>
    );
}


export default SaveCardForm;