import { gql } from "@apollo/client";
export const queries = (id) => {
  return gql`
    query outletDetails {
      outletDetails(id: ${id}) {
        status
        message
        data {
          name
          id
          address
          cuisines {
            title
            id
          }
          vendor {
            categories {
              category {
                title
              }
            }
          }
        }
      }
    }
  `;
};

export const OutletDetails = () => (
  gql`
  query outletDetails(
    $id:Float!,
   $long:String,
    $lat:String,
    
  ) {
    outletDetails(
      id:$id,
          lat:$lat,
      long:$long
    ) {
      status
      message
      data {
        name
        id
        address
        status
        distance
        outletsTimeSlots{
          outlet_id
          day
         time_slots{
  start_time
          end_time
        }
        }
      }
    }
  }`
)

export const getMenuItems = (id) => {
  return gql`
    query menuDetails {
      menuDetails(cuisine_id: 1) {
        status
        message
        data {
          id
          title
        }
      }
    }
  `;
};

export const checkUserExist = (email) => {
  return gql`
  query checkUserExist($email:String!){
      checkUserExist(email:$email)
      {
        status
        message
      }
    }
`;
};

export const hostSignup = () => {
  return gql`
  mutation registeredHost(
    $nricfront: Upload
    $nricback: Upload
    $acradoc: Upload
    $registration_type: String!
    $dob: DateTime!
    $nric_no: String!
    $age: String!
    $gender: String!
    $citizenship: String!
    $address: String!
    $user_role: Float!
    $company_name: String!
    $registration_no: String!
    $date_inc: DateTime!
    $company_status: String!
    $primary_business: String!
    $sec_business: String!
    $share_capital: String!
    $designation: String!
    $currency: String!
    $user_host_type: String!
    $rep_citizenship: String!
    $outlets: [OutletRegistrationInput!]!
    $representative: String!
    $rep_nric_no: String!
    $company_amendement: Boolean!
    $profile_amendment: Boolean!
    $company_address: String!
  ) {
    registeredHost(
      registerdData: {
        registration_type: $registration_type
        dob: $dob
        nric_no: $nric_no
        age: $age
        gender: $gender
        citizenship: $citizenship
        address: $address
        user_role: $user_role
        nricfront: $nricfront
        nricback: $nricback
        acradoc: $acradoc
        company_name: $company_name
        registration_no: $registration_no
        date_inc: $date_inc
        company_status: $company_status
        primary_business: $primary_business
        sec_business: $sec_business
        share_capital: $share_capital
        designation: $designation
        currency: $currency
        user_host_type: $user_host_type
        outlets: $outlets
        representative_citizenship: $rep_citizenship
        representative:$representative
        rep_nric_no: $rep_nric_no
        amendement: $company_amendement
        profile_amendment: $profile_amendment
        company_address: $company_address
      }
    ) {
      status
      message
      user {
        id
        email
        last_login
        is_host

      }
    }
  }    
  `;
};

export const dinerSignup = () => {
  return (
    gql`
    mutation AddUser($firstName: String!,
        $lastName: String!,
        $email: String!,$phoneNumber: String!,
        $jobTitle: String!,$department: String!,
        $password: String!, $empolyeeNumber: String!,
        $userRole: Float!) {
        addUser(user:{
          email:$email,
          phoneNumber:$phoneNumber
          firstName: $firstName
          lastName: $lastName
          jobTitle: $jobTitle
          department: $department
          password: $password
          employeeNumber: $empolyeeNumber
          userRole: $userRole
        }){
          status
          message
          accessToken
          user{
           email
            id
            first_name
            last_name
            job_title
            department
            user_role
            employee_number
            phone_number
            role{
              id
              name
            }
          }
        }
      }
    `
  )
}

export const getGeoLoc = () => {
  return gql`
  query getLatLong($address: String!) {
    getLatLong(address:$address)
    {
      latitude
      longitude
      formattedAddress
    }
  }
  `;
};

export const SendOtpByEmail = () => {
  return (
    gql`mutation CommonResolver($text: String!) {
      sendOtpToEmailOrPhone(text:$text){
        status
        message
    }
  }`
  )
}

export const verifyOTP = () => {
  return (
    gql`
    mutation CommonResolver($otp: Float!,$text: String!) {
      verifyOTP(otp:$otp, text:$text){
    	status
    	message
  	}
}`
  )
}

export const getSelectedQuestions = () => {
  return (
    gql`
  mutation CommonResolver($text: String!){
    getSelectedQuestions(text:$text){
    	status
    	message
      security_question_by_user{
        user_id
        question_id
        answer
        id
        questions{
          question
          id
        }
      }
  	}
}`
  )
}

export const verifyAnswer = () => {
  return (
    gql`
    mutation CommonResolver(
  $answer: String!,
  $question_id: Float!, 
  $text: String!
){
  verifyAnswer(answer:$answer, 
    question_id:$question_id, 
    text:$text
  ){
    	status
    	message
  	}
}`
  )
}

export const genrateNewPassword = () => {
  return (
    gql`
  mutation CommonResolver(
  $text: String!,
  $password: String!, 
  $confirm_password: String!
){
  genrateNewPassword(text:$text, 
    password:$password, 
    confirm_password:$confirm_password
  ){
    	status
    	message
  	}
}`
  )
}

export const SignUp_EmailPhoneVerification = () => {
  return (
    gql`
    mutation UserOtpVerificationResolver($text:String!){
    SignUp_EmailPhoneVerification(text:$text){
    status
    message
  }
}`
  )
}

export const SignUp_OTPVerification = () => {
  return (
    gql`
    mutation UserOtpVerificationResolver($otp:Float!, $text:String!){
  SignUp_OTPVerification(otp:$otp, text:$text){
    status
    message
  }
}`
  )
}
export const GetUserById = () => {
  return (
    gql`query GetUserById($userId: Float!){ 
    getUserById(userId:$userId)
    {
      status
      message
      user{
        id
        employee_number
        status
        first_name
        last_name
        email
        job_title
        department
        user_role
        phone_number
        auth_login
        auth_order
        auth_account_changes
        auth_method
        is_verified
        is_warn_me
        show_quick_view
        role
        {
          id
          name
        }
        user_profile{
          id
          gender
          dob
          account_verified
          profile_image
          is_allergic
          allergies
          dietary_prefrence
          current_address
          home_address
        }
        emergency_contact{
          id
          first_name
          last_name
          relationship
          phone_number
        }
        security_question{
          question_id
          user_id
          answer
          id
        }
      }
    }
  }
   `)
}

export const GetHostById = () => {
  return (
    gql`query GetUserById($userId: Float!){ 
      getUserById(userId:$userId)
      {
        status
        message
        user{
          id
          employee_number
          first_name
          last_name
          email
          job_title
          department
          user_role
          phone_number
          auth_login
          auth_order
          auth_account_changes
          auth_method
          user_host_type
          is_host
          last_login
          is_warn_me
          show_quick_view
          role
          {
            id
            name
          }
          user_profile{
            id
            gender
            dob
            account_verified
            profile_image
            is_allergic
            allergies
            dietary_prefrence	
            current_address
            home_address
            citizenship
          }
          emergency_contact{
            id
            first_name
            last_name
            relationship
            phone_number
          }
          security_question{
            question_id
            user_id
            answer
            id
          }

         company_details{
          registration_no
          rep_nric_no        
        }
          user_document{
            nric_no
            nric_front
            nric_back
            acra_doc
            is_verified
            busniess_certificate
          }
        }
      }
    }
   `)

}

export const GetUsersData = () => {
  return (
    gql`query getUsersdata($userIds:[Float!])
    {
      getUsersdata(userIds:$userIds)
      {
        status
        message
        user
        {
          id
          first_name
          last_name
          email
          user_profile
          {
            profile_image
          }
        }
      }
    }
   `)

}
export const verifyToken = () => {
  return (gql`
  query verifyToken {
    verifyToken {
      status
      user {
        id
        first_name
        last_name
        is_host
        last_login
        email
        phone_number
        user_profile
        {
         profile_image 
        }
      }
      message
    }
  }
`)
}

export const GetCribByUser = () => {
  return (gql`
  query getCribByuser {
    getCribByuser{
      status
      message
      outlets {
        id
        name
        address
        lat
        long
        geolocation
        avg_rate
        preparation_time
        is_verified
        verification_doc
        outlet_property
        vendor_id
      }
      total_pages
    }
  }
  
`)
}

export const getCribDetails = () => {
  return (gql`
  query getCribDetails($cribId: Float) {
    getCribDetails(crib_id: $cribId) {
      status
      message
      data {
        status
        id
        name
        address
        lat
        long
        geolocation
        avg_rate
        preparation_time
        is_verified
        verification_doc
        outlet_property
        floor_no
        unit_no
        country
        postal_code
        vendor_id
        building_name
        seating_system
        outlet_property
        outletsTimeSlots {
          id
          outlet_id
          day
          start_time
          end_time
          __typename
        }
        outletTables {
          id
          outlet_id
          table_no
          seats
          __typename
        }
        pickup_collection_inst
        dinein_collection_inst
        is_pickup
        is_dinein
        __typename
      }
      __typename
    }
  }
`)
}

export const GetCompanyDetails = () => {
  return (gql`
  query getComDetails{
    getCompanyDetails{
      status
      message
      company_details{
        id
        user_id
        company_name
        registration_no
        date_inc   
        company_status
        primary_business
        sec_business
        address
        share_capital
        currency
        representative
        citizenship
        designation
        amendment
        rep_nric_no
        created_at
        updated_at
      }
    }
  }
  
  
`)
}

export const editDiner = () => {
  return (gql`
  mutation editDiner( 
    $id:Float!,
    $firstName: String!, 
    $lastName: String!,
    $email: String!,
    $phoneNumber: String,
    $password: String!, 
    $userRole: String!,
    $dob: DateTime!, 
    $gender: String!, 
    $dietary_prefrence: [String!],
    $allergies: [String!], 
    $profile_image: Upload, 
    $emergency_contact: [EmergencyContactInput!] !,
    $auth_method: String!, 
    $auth_login: Boolean!, 
    $status: String!, 
    $auth_account_changes: Boolean!,
    $auth_order: Boolean!,
  ) 
  { editDiner(
    user: {
      id: $id,
      email: $email, 
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName 
      password: $password
      userRole: $userRole 
      dob: $dob
      gender: $gender 
      dietary_prefrence: $dietary_prefrence, 
      allergies: $allergies
      profile_image: $profile_image
      auth_login: $auth_login 
      auth_account_changes: $auth_account_changes 
      auth_order: $auth_order 
      auth_method: $auth_method 
      status: $status 
      emergency_contact: $emergency_contact 
    })
    { 
      status 
      message
      user {
        email 
        id
        first_name
        last_name
        job_title 
        department
        user_role
        password 
        employee_number
        role { 
          id 
          name
        } 
        status
        user_profile
        {
          gender 
          dob UpdateHostProfile
          dietary_prefrence 
        } 
      }
    }
  }`)
}

export const switchProfile = () => {
  return (gql`
mutation switchProfile($id: Float!, $profile: String!){
  switchProfile(userId:$id, profile:$profile)
  {
    status
    message
    user{
      id
      first_name
      last_name
      email
      department
      job_title
      employee_number
      phone_number
      auth_method
      user_host_type
      auth_account_changes
      auth_order
      auth_login
      status
      social_login_id
      social_login_provider
      role{
        id
        name
      }
      user_role
      is_host
      last_login
    }
  }
}
`)
}
export const UpdateHostProfile = () => {
  return gql`
    mutation updateHostProfile(
      $first_name: String!
      $last_name: String!
      $email: String!
      $phone_number: String!
      $gender: String!
      $dob: DateTime!
      $current_address: String!
      $auth_method: String!
      $auth_login: Boolean!
      $auth_order: Boolean!
      $auth_account_changes: Boolean!
      $allergies: [String!]
      $dietary_prefrence: [String!]
      $nric_no: String!
      $nric_front: Upload
      $nric_back: Upload
      $busniess_certificate: Upload
      $profile_image: Upload
      $emergency_contact: [EmergencyContactInput!]!
      $questions: [QuestionByUser!]!
      $is_warn_me: Boolean!
      $show_quick_view: Boolean!
    ) {
      updateHostProfile(
        user: {
          first_name: $first_name
          last_name: $last_name
          email: $email
          phone_number: $phone_number
          gender: $gender
          dob: $dob
          current_address: $current_address
          auth_method: $auth_method
          auth_login: $auth_login
          auth_order: $auth_order
          auth_account_changes: $auth_account_changes
          allergies: $allergies
          dietary_prefrence: $dietary_prefrence
          nric_no: $nric_no
          nric_front: $nric_front
          nric_back: $nric_back
          busniess_certificate: $busniess_certificate
          profile_image: $profile_image
          emergency_contact: $emergency_contact
          questions_by_user: $questions
          is_warn_me:$is_warn_me
          show_quick_view:$show_quick_view
        }
      ) {
        status
        message
        user {
          id
          first_name
          last_name
          is_warn_me
          show_quick_view
          user_profile {
            profile_image
          }
        }
      }
    }
  `;
}

export const AddnewCrib = () => {
  return gql`
  mutation addNewCrib( 
    $name: String!
    $crib_status: String!
    $address: String!
    $lat: String!
    $long: String!
    $floor_no: String!
    $unit_no: String!
    $country: String!
    $postal_code: String!
    $outlet_time_slots: [OutletTimeSlotsInput!]!
    $pickup_collection_inst: String!
    $dinein_collection_inst: String!
    $is_pickup: String!
    $is_dinein: String!
    $outlet_tables: [OutletTablesInput!]
    $verification_doc:Upload
    $building_name : String!
    $seating_system:Boolean!
    $outlet_property:String!
  
  ) 
  { addNewCrib(
    hostOutletData: {
      name: $name, 
      crib_status: $crib_status
      address: $address
      lat: $lat
      
      long: $long
      floor_no: $floor_no 
      
      unit_no: $unit_no
      country: $country 
      postal_code: $postal_code, 
      
      outlet_time_slots: $outlet_time_slots
      pickup_collection_inst: $pickup_collection_inst
      
      dinein_collection_inst: $dinein_collection_inst 
      is_pickup: $is_pickup 
      is_dinein: $is_dinein 
      outlet_tables: $outlet_tables 
      building_name: $building_name
      seating_system: $seating_system
      verificationdoc:$verification_doc
      outlet_property: $outlet_property
    })
    { 
      status 
      message
      data{
        
        lat
        long
        vendor_id
        name
        avg_rate
        address
        preparation_time
        geolocation
        outlet_property
        seating_system
        verification_doc
      }
    }
  }
  
  `;
}

export const UpdateCrib = () => {
  return gql`
  mutation updateCrib( 
    $id: Float!
    $name: String!
    $crib_status: String!
    $address: String!
    $lat: String!
    $long: String!
    $floor_no: String!
    $unit_no: String!
    $country: String!
    $postal_code: String!
    $outlet_time_slots: [OutletTimeSlotsInput!]!
    $pickup_collection_inst: String!
    $dinein_collection_inst: String!
    $is_pickup: String!
    $is_dinein: String!
    $outlet_tables: [OutletTablesInput!]!
    $verification_doc:Upload
    $building_name : String!
    $seating_system:Boolean!
    $outlet_property:String!
  
  ) 
  { updateCrib(
    hostOutletData: {
      id:$id,
      name: $name, 
      crib_status: $crib_status
      address: $address
      lat: $lat
      
      long: $long
      floor_no: $floor_no 
      
      unit_no: $unit_no
      country: $country 
      postal_code: $postal_code, 
      
      outlet_time_slots: $outlet_time_slots
      pickup_collection_inst: $pickup_collection_inst
    
      dinein_collection_inst: $dinein_collection_inst 
      is_pickup: $is_pickup 
      is_dinein: $is_dinein 
      outlet_tables: $outlet_tables
      building_name: $building_name
      seating_system: $seating_system
      verificationdoc:$verification_doc
      outlet_property: $outlet_property
   
    })
    { 
      status 
      message
      data{
        
        lat
        long
        vendor_id
        name
        avg_rate
        address
        preparation_time
        geolocation
        seating_system
        outlet_property
        verification_doc
      }
    }
  }
  
  `;
}

export const UpdateCribStatus = () => {
  return (gql`
  mutation updateCribStatus( 
    $outlet_id: Float!
    $crib_status: String!
  ) 
  { updateCribStatus(
    outlet_id:$outlet_id,
    crib_status:$crib_status
  )
    { 
      status 
      message
      data{
        id
        name
        lat
        long
        vendor_id
        avg_rate
        address
        preparation_time
        geolocation
        status
      }
    }
  }`
  )
}

export const UserChangePassword = () => {
  return (gql`
    mutation changePassword(
      $current_password :String!
      $password:String!
      $confirm_password:String!
    ){
      changePassword(
        user:{
          current_password:$current_password
          password:$password
          confirm_password:$confirm_password
        }
      ){
        status
        message
        user{
          id
        }
      }
    }`
  )
}

export const ManageAccountStatus = () => {
  return (gql`
     mutation manageAccountStatus($userId: Float!, $status: String!){
    manageAccountStatus(userId:$userId, status:$status)
    {
      status
      message
      user{
        first_name
        last_name
        email
        department
        job_title
        employee_number
        phone_number
        auth_method
        user_host_type
        auth_account_changes
        auth_order
        auth_login
        status
        social_login_id
        social_login_provider
        role{
          id
          name
        }
        user_role
      }
    }
  }`
  )
}

export const HostUserResolver = () => {
  return (gql`
  query HostUserResolver{
    getQuestions{
    status
    message
    security_question{
    question
    status
    id
    }
    }
    }
  `)
}

export const UserAccountResolver = () => {
  return (gql`
  mutation UserAccountResolver($account_suspend_date:DateTime!, $status: Boolean! ){
    User_SuspendAccount(account_suspend_date:$account_suspend_date, status:$status ){
      status
      message
    }
  }
  `)
}

export const User_SuspendOtpVerification = () => {
  return (gql`
  mutation User_SuspendOtpVerification($otp:Float! ){
    User_SuspendOtpVerification(otp:$otp, ){
      status
      message
    }
    }
  `)
}

export const User_CloseAccount = () => {
  return (gql`
    mutation User_CloseAccount($reasion:String!, $status: Boolean! ){
      User_CloseAccount(reasion:$reasion, status:$status ){
        status
        message
      }
    }
`)
}


export const loginUserResolver = () => {
  return (gql`
  mutation loginUserResolver($text: String!, $password:String!){
    login_SendOTP(text: $text, password:$password){
      status
      message
      user{
        first_name
        last_name
        email
      }
    }
  }
  `)
}

export const login_OTPVerification = () => {
  return (gql`
  mutation login_OTPVerification($otp:Float!, $text: String!,){
    login_OTPVerification(text:$text, otp: $otp){
      status
      message
    }
  }
  `)
}

export const User_UnSuspendAccount = () => {
  return (gql`
  mutation User_UnSuspendAccount($status:Boolean! ){
    User_UnSuspendAccount(status:$status, ){
      status
      message
    }
  }
  `)
}

// export const GetMenuCribList = () => (
//   gql`
//   query getMenuCribList{
//   getMenuCribList{
//     status
//     message
//     outlets{
//       id
//       name
//       status
//       outlet_menu{
//         id
//         menu_id
//         publish
//         end_date
//         sold_out_status
//       }
//     }
//   }
// }
// `
// )

export const GetMenuCribList = () => (
  gql`
  query getMenuCribList($menu_id:Float!){
    getMenuCribList(
      menu_id:$menu_id
    ){
      status
      message
      outlets{
        id
        name
        status
        outlet_menu{
          id
          menu_id
          publish
          end_date
          sold_out_status
        }
      }
    }
  }
  `
)

export const GetMenuByCrib = () => (
  gql`
  query getMenuByCrib{
  getMenuByCrib {
    status
    message
		data{
      id
      title
      price
      image
      description
      status
    }
    total_pages
  }
}
  `
)

export const GetMenuDetails = () => (
  gql`
  query getMenuDetails($menuId:Float!) {
  getMenuDetails(
    menu_id:$menuId) {
    status
    message
		data{
      id
      title
      price
      image
      description
      status
      menu_outlets{
        id
        publish
        end_date
        sold_out_status
        outlets{
          name
          id
          status
        }
      }
      menu_items{
        id
        publish
        end_date
        daily_stock_quantity
        sold_out_status  
        items{
          id
          name
        }
      }
    }
  }
}
`
)

export const editMenuDetails = () => (
  gql`
  mutation editMenu(
  $id:Float!,
  $title:String!,
  $status:Boolean!,
  $description:String!
){
  editMenu(menuInput:{
    id:$id,
    title:$title,
    status:$status,
    description:$description,
  }){
    status
    message
     data{
        id
        title
        price
        description
        status
      }
    }
  }`
)

export const addNewMenu = () => (
  gql`
  mutation addMenu(
  $title:String!,
  $status:Boolean!,
  $description:String!
){
  addMenu(menuInput:{
    title:$title,
    status:$status,
    description:$description,
  }){
    status
    message
     data{
        id
        title
        price
        description
        status
      }
    }
  }
`
)

export const SaveItemTemplate = () => (
  gql`
  mutation SaveItemTemplate(
  $name:String!,
  $require_type:String!,
  $select_up_to_choice:String!,
  $item_selections:[ItemsSeclectionsTemplateInput!]
){
  SaveItemTemplate(templateData:{
    name:$name,
    require_type:$require_type,
    select_up_to_choice:$select_up_to_choice,
    item_selections:$item_selections
  }){
    status
    message
    itemOptions{
      id
      user_id
      name
      require_type
      select_up_to_choice
      item_selections{
        key
       price
        name
      }
    }
  }
}
`
)

export const GetMenuItems = () => (
  gql`
  query getItemByMenu($menuId:Float! ,$itemId:Float!){
  getItemByMenu(
    menu_id:$menuId,
    item_id:$itemId) {
    status
    message
		data{
      id
      menu_id
      items{
        name
        description
        image
        base_price
        preparation_time
        dietary_prefrence
        allergies
        item_options{
          id
          name
          item_id
          require_type
          select_up_to_choice
          item_selections{
            id
           item_id
            name
            item_option_id
            price
            
          }
        }
      }
    }
  }
                      
}`
)

export const AddMenuItems = () => (
  gql`
  mutation addNewItems( 
  $name: String!
  $description: String!
  $image: Upload
  $base_price: Float!
  $allergies: [String!]!
  $dietary_prefrence: [String!]!
  $items_options: [ItemsOptionsInput!]!
  $menu_id:Float!
  $preparation_time: Float!
) 
{ addNewItems(
	hostMenuItemData:{
  menu_id:$menu_id,
  name:$name,
  description :$description,
  image:$image,
  base_price:$base_price,
  allergies:$allergies,
  dietary_prefrence:$dietary_prefrence,
  items_options:$items_options,
  preparation_time:$preparation_time
  }
)
  { 
    status 
    message
    data{
      id
      name
      image
      description
      base_price
      preparation_time
    }
  }
}`
)

export const UpdateMenuItems = () => (
  gql`
  mutation updateItems( 
    $id:Float!,
    $name: String!
    $description: String!
    $image: Upload
    $base_price: Float!
    $allergies: [String!]!
    $dietary_prefrence: [String!]!
    $item_options: [UpdateItemsOptionsInput!]!
    $menu_id:Float!
    $preparation_time:Float!
  ) 
  { updateItems(
    hostMenuItemData:{
      id:$id,
      menu_id:$menu_id,
      name:$name,
      description :$description,
      image:$image,
      base_price:$base_price,
      allergies:$allergies,
      dietary_prefrence:$dietary_prefrence,
      item_options:$item_options,
      preparation_time:$preparation_time
    }
  )
    { 
      status 
      message
      data{
        id
        name
        image
        description
        base_price
        preparation_time
      }
    }
  }`
)


export const UserProfileVerification = () => {
  return (gql`
  mutation UserProfileVerification(
    $name:String!,
    $dob:DateTime!,
    $gender:String!,
    $citizenship:String!,
    $nric_no:String!,
    $nricfront:Upload,
    $nricback:Upload,
    $address:String!,
    $profile_amendment:Boolean!
  ){
    Update_VerifyMyIdentity(
      userIdentityInput:{
        name:$name,
        dob:$dob,
        gender:$gender,
        citizenship:$citizenship,
        nric_no:$nric_no,
        nricfront:$nricfront,
        nricback:$nricback,
        address:$address,
        profile_amendment:$profile_amendment,
      }
    ){
      status
      message
    }
  }
  `)
}

export const hostGetAllTemplates = () => (
  gql`
  query host_getAllTemplates{
  host_getAllTemplates{
    status
    message
    itemOptions{
      id
      user_id
      name
    }
  }
}
`
)
export const host_createNewTemplate = () => {
  return (gql`
  mutation host_createNewTemplate(
    $name:String!,
    $require_type:String!,
    $select_up_to_choice:String!,
    $item_selections:[ItemsSeclectionsTemplateInput!]
  ){
    host_createNewTemplate(itemInput:{
      name:$name,
      require_type:$require_type,
      select_up_to_choice:$select_up_to_choice,
      item_selections:$item_selections
    }){
      status
      message
    }
  }
  `)
}

export const host_getAllTemplates = () => {
  return (gql`
  query host_getAllTemplates{
    host_getAllTemplates{
    status
    message
    itemOptions{
    id
    user_id
    name
    require_type
    select_up_to_choice
    item_selections{
    key
    name
    price
    }
    }
    }
    }
  `)
}


export const host_findTemplateById = () => {
  return (gql`
  mutation host_findTemplateById( $id:Float! ){
    host_findTemplateById( id:$id ){
      status
      message
      itemOptions{
    id
    user_id
    name
    require_type
    select_up_to_choice
    item_selections{
      key
      name
      price
    }
  }
}
}
`)
}


export const host_updateTemplateById = () => {
  return (gql`
  mutation host_updateTemplateById(
    $id:Float!,
    $name:String!,
    $require_type:String!,
    $select_up_to_choice:String!,
    $item_selections:[ItemsSeclectionsTemplateInput!]
    ){
    host_updateTemplateById(itemInput:{
    id:$id,
    name:$name,
    require_type:$require_type,
    select_up_to_choice:$select_up_to_choice,
    item_selections:$item_selections
    }){
    status
    message
    }
    }
  `)
}

export const host_deleteTemplateById = () => {
  return (gql`
  mutation host_deleteTemplateById( $id:Float! ){
    host_deleteTemplateById(itemInput:{ id:$id }){
    status
    message
    }
    }
  `)
}

export const DeleteSelectionItem = () => (
  gql`
mutation deleteSelectionItem($item_selection_id: Float,$item_option_id: Float){
  deleteSelectionItem(
        item_selection_id:$item_selection_id,
item_option_id:$item_option_id
)
{
  status
  message
}
}
`
)

export const host_deleteItemOptionsById = () => {
  return (gql`
  mutation host_deleteItemOptionsById(
    $id:Float!,
    $key:Float!
    ){
    host_deleteItemOptionsById(
    id:$id,
    key:$key
    ){
    status
    message
    itemOptions{
    id
    }
    }
    }
  
  `)
}

export const deleteCribTimeSlot = () => {
  return (gql`
  mutation deleteCribTimeSlot(
    $id:Float!
  ){
    deleteCribTimeSlot(
      id:$id
    ){
      status
      message
      }
    }
  `)

}


export const deleteCribTables = () => {
  return (gql`
  mutation deleteCribTables(
    $id:Float!
  ){
    deleteCribTables(
      id:$id
    ){
      status
      message
      }
    }
  
  `)
}

export const addMenu = () => {
  return (gql`
  mutation addMenu(
    $title:String!,
    $status:Boolean!,
    $description:String!
  ){
    addMenu(menuInput:{
      title:$title,
      status:$status,
      description:$description,
    }){
      status
      message
       data{
          id
          title
          price
          description
          status
        }
      }
    }
  `)
}


export const updateMenuCribStatus = () => {
  return (gql`
  mutation updateMenuCribStatus(
    $menu_id:Float!,
    $crib_id:Float!,
    $status:String!,
    $date:String
  ){
    updateMenuCribStatus(updateInput:{
      menu_id:$menu_id,
      crib_id:$crib_id,
      status:$status,
      date:$date,
    }){
      status
      message
       data{
          id
        }
      }
    }

  `)
}

export const getMenuCribItemList = () => {
  return (gql`
  query getMenuCribItemList( 
    $cribId:Float!,
    $menuId:Float!
  ){
    getMenuCribItemList(
      crib_id:$cribId,
      menu_id:$menuId
    ){
      status
      message
      data{
        id
        name 			     
        crib_menu_items{
          menu_id
          crib_id
          item_id
          publish
          end_date
          sold_out_status
          daily_stock_quantity
          avaliable_stock_quantity
          sold_out_date
        }
      }
    }
  }
  `)
}

export const updateMenuCribItemStatus = () => {
  return (gql`
  mutation updateMenuCribItemStatus(
    $menu_id:Float!,
    $crib_id:Float!,
    $item_id:Float!,
    $status:Boolean!,
  ){
    updateMenuCribItemStatus(updateInput:{
      menu_id:$menu_id,
      crib_id:$crib_id,
      item_id:$item_id,
      status:$status,
    }){
      status
      message
       data{
          id
        }
      }
    }
  `)
}

export const refreshMenuItems = () => {
  return (gql`
  mutation refreshMenuItems(
    $menu_id:Float!,
    $crib_id:Float!,
    $crib_items_slots:[RefreshCribItemsSlotsInput!]!
  ){
    refreshMenuItems(updateInput:{
      menu_id:$menu_id,
      crib_id:$crib_id,
      crib_items_slots:$crib_items_slots,
    }){
      status
      message
       data{
          id
        menu_id
        crib_id
        item_id
        publish
        end_date
        sold_out_status     
        
        }
      }
    }
  `)
}





export const markMenuItemsStockStatus = () => {
  return (gql`
  mutation markMenuItemsStockStatus(
    $menu_id:Float!,
    $crib_id:Float!,
    $item_id:Float!,
    $status:String!,  
  ){
    markMenuItemsStockStatus(updateInput:{
      menu_id:$menu_id,
      crib_id:$crib_id,
      item_id:$item_id,
      status:$status    
    }){
      status
      message
       data{
        id
        menu_id
        crib_id
        publish
        end_date
        sold_out_status     
        }
      }
    }
  `)
}

export const hostFindTemplateById = () => (
  gql`
  mutation host_findTemplateById( $id:Float! ){
  host_findTemplateById( id:$id ){
    status
    message
    itemOptions{
      id
      user_id
      name
      require_type
      select_up_to_choice
      item_selections{
        key
        name
        price
      }
    }
  }
}
`
)
export const resetStockCount = () => {
  return (gql`
  mutation resetStockCount(
    $menu_id:Float!,
    $crib_id:Float!,
    $item_id:Float!
  ){
    resetStockCount(updateInput:{
      menu_id:$menu_id,
      crib_id:$crib_id,
      item_id:$item_id    
    }){
      status
      message
       data{
        id
        menu_id
        crib_id
        publish
        end_date
        sold_out_status
        daily_stock_quantity
        avaliable_stock_quantity
        sold_out_date
        
        }
      }
    }
  `)
}

export const deleteItems = () => {
  return (gql`
  mutation deleteItems(
    $item_id:Float!
  ){
    deleteItems(
      item_id:$item_id
    ){
      status
      message
      }
    }
  `)
}


export const cloneMenu = () => {
  return (gql`
  mutation cloneMenu(
    $menu_id:Float!,
    $title:String!
  ){
    cloneMenu(
      menuInput:{
        menu_id:$menu_id,
        title:$title
      }
    ){
      status
      message
      data{
        id
        title
        price
        image
        description
        status
        menu_outlets
        {
          id
          menu_id
          outlet_id
          publish
          end_date
          sold_out_status       
        }
        crib_menu_items{
          id
          crib_id
          item_id
          menu_id
          publish
          end_date
          daily_stock_quantity
          sold_out_status
          items{
            id
            name
            description
            image
            base_price          
          }
        }
      }
      }
    }
  `)
}


export const updateMenuStatus = () => {
  return (gql`
  mutation updateMenuStatus( 
    $menu_id: Float!
    $status: Boolean!
  ) 
  { updateMenuStatus(
    id:$menu_id,
    status:$status
  )
    { 
      status 
      message
      data{
        id
        title
        price
        image
        description
        status
      }
    }
  }
  `)
}


export const getWalletDetail = () => {
  return (gql`
  query {
    getWalletDetail{
      id
      user_id
      name
      description
      balance
    }
  }
  `)
}



export const takeFromWallet = () => {
  return (gql`
  mutation($amount:Float!) {
    takeFromWallet(amount:$amount){
        status
        message
        data{
          id
          type
          amount
          created_at
          updated_at
          wallet{
              id
              balance
              created_at
              updated_at
          }
        }
    }
  }
  `)
}

export const GetVendorsByLocation = () => (
  gql`query getVendorsByLocation(
    $address:String!,
    $filter:FilterInput!,
    $keyword:String,
    $date:DateTime,
    $page_size:Float,
    $page:Float,
    $sort:String,
    $lat:String,
    $long:String,
  
  ){
      getVendorsByLocation(
        location:$address,
        filter:$filter,
        keyword:$keyword,
        date:$date,
        page_size:$page_size,
        page:$page,
        sort:$sort,
        lat:$lat,
        long:$long,
      ){
      status
      message
      data{
        id
        name
        description
        image
        base_price
        dietary_prefrence
        allergies
        preparation_time
        crib_menu_items{
          id
          menu_id
          crib_id
          item_id
          publish
          outlets{
            name    
            id
            lat
            long
            avg_rate
            distance
          }
        }
      }
      }
    }
  `
)

export const AddItemCart = () => (
  gql`
  mutation addItemCart( 
    $crib_id: Float!
    $item_id: Float!
    $type:String!
    $quantity:String!
    $item_selection_ids:[Float!]!
    $description:String!
    
  ) 
  { 
    addItemCart(
      cart_items:{
      crib_id:$crib_id,
      item_id:$item_id,
      type:$type,
      quantity:$quantity,
      item_selection_ids:$item_selection_ids,
      description:$description
     }
  )
    { 
      status 
      message
     data{
        cart_key
        user_id
        crib_id
        item_id
        type
        quantity
        description
        item_option_ids
        item_selection_ids
      }
    }
  }
  `
)

export const GetLocationbySglocate = () => (
  gql`
  query getLocationbySglocate(
    $postcode: String
    $streetName: String
    $blockNumber: String
  ) {
    getLocationbySglocate(
      postcode: $postcode
      streetName: $streetName
      blockNumber: $blockNumber
    )
  }`
)

export const DeleteCribDoc = () => (
  gql`
  mutation deleteCribDoc($cribId:Float!){
  deleteCribDoc(cribId:$cribId){
    status
    message
    data{
     id
      name
      lat
      long
      vendor_id
      name
      avg_rate
      address
      preparation_time
      geolocation
      is_verified
      verification_doc
    }
  }
}`
)

export const GetVendors = () => (
  gql`
  query getVendors{
    getVendors{
      name
      id
      avg_rate
      vendor{
        id
        categories{
          category{
            title
          }
        }
      }
    }
  }
  `
)


export const OutletList = () => (
  gql`
  query outletList($crib_id:Float!) {
    outletList(crib_id: $crib_id) {
      status
      message
      outlets {
        name
        id
        address  
      }      
    }
  }
  `
)

export const GetMenuListByCrib = () => (
  gql`
  query getMenuListByCrib(
    $crib_id:Float!
  ){
    getMenuListByCrib(
      crib_id:$crib_id
    ) {
      status
      message
      data{
        id
        title
      }
    }
  }`
)

export const GetMenuItemList = () => (
  gql`
  query getMenuItemList( 
    $cribId:Float!,
    $menuId:Float!
  ){
    getMenuItemList(
      crib_id:$cribId,
      menu_id:$menuId
    ){
      status
      message
      data{
        id
        name 
        description
        image
        base_price
        dietary_prefrence
        allergies
        
      }
    }
  }`
)

export const GetCartItemList = () => (
  gql`
  query getCartItemList($long:String,$lat:String,$dateTime:DateTime!,$type:String!){
    getCartItemList(long:$long,lat:$lat,dateTime:$dateTime,type:$type){
      status
      message
      data {
        crib_details{
          id
          name
          address
          floor_no
          unit_no
          postal_code
          country
          avg_rate
          distance
          preparation_time
          mode
          time
        }
        cart_item_details{
          id
          cart_key
          type
          quantity
          description
          total_price
          itemDetails{
          id
          name
          description
          image
          base_price
          dietary_prefrence
          allergies
          item_selections{
            id
            name
            price
          }
        }
        }
        res_details{
          item_purchase
          seat_allowed
          available_seat
        }
        diner_details{
          first_name
          last_name
          email
          phone_number
        }
        collection_detail
        cart_details  
      }
    }
  }
  `
)

export const DeleteItem = () => (
  gql`
  mutation deleteItem( 
    $cart_id: Float!
  ) 
  { 
    deleteItem(
      cart_id:$cart_id
    )
    { 
      status 
      message
    }
  }
  `
)
export const UpdateItemQuantity = () => (
  gql`
  mutation updateItemQuantity( 
    $cart_id: Float!
    $quantity: Float!
  ) 
  { 
    updateItemQuantity(
      quantity:$quantity,
      cart_id:$cart_id
    )
    { 
      status 
      message
        data{
        cart_key
        user_id
        crib_id
        item_id
        type
        quantity
        description
        item_option_ids
        item_selection_ids
      }
    }
  }
  `
)
export const GetItemDetails = () => (
  gql`
  query getItemDetails( 
    $crib_id: Float!
    $item_id: Float!
    
  ) {
    getItemDetails(
      crib_id:$crib_id,
      item_id:$item_id
  ){
      status
      message
      data {
  outlets{
          id
          name
          address
          preparation_time
          avg_rate
        }
        items{
          id
          name
          description
          image
          base_price
          dietary_prefrence
          allergies
          item_options{
            id
            user_id
            name
            require_type
            select_up_to_choice
            item_selections{
              id
              name
              price
            }
          }
        }
      }
    }
  }`
)

export const UpdateAccountTwoSetup = () => {
  return (gql`
  mutation updateAccountTwoSetup($auth_method: String!, $phone_number: String) {
    updateAccountTwoSetup(
      phone_number: $phone_number
      auth_method: $auth_method
    ) {
      status
      message
      user {
        first_name
        last_name
      }
    }
  }
  `)
}

export const deleteItemImage = () => {
  return (gql`
  mutation deleteItemImage($itemId:Float!)
  {
    deleteItemImage(itemId:$itemId)
    {
      status
      message
      data
      {
        id
        image
      }
    }
  }
  
  `)
}

export const checkoutOrders = () => {
  return (gql`
  mutation checkoutOrders( 
    $crib_id: Float!
    $discount:Float!
    $cutleries:Boolean!
    $delivery_charges:Float!
    $tax:Float!
    $total_price:Float!
    $grand_total:Float!
    $time:DateTime!
    $card_id:String!
  ) 
  { 
    checkoutOrders(
      order_input:{
          crib_id:$crib_id,
          discount:$discount,
          cutleries:$cutleries,
          delivery_charges:$delivery_charges,
          tax:$tax,
          total_price:$total_price,
          grand_total:$grand_total,
          time:$time,
          card_id:$card_id
      }
    )
  }
  `)
}

export const GetCardList = () => (
  gql` query getCardList{
  getCardList
}`)

export const CheckFoodWarning = () => (
  gql`query checkFoodWarning{
    checkFoodWarning
  }`
)

export const GetCartCheckoutDetails = () => (
  gql`query getCartCheckoutDetails($long:String,$lat:String,$dateTime:DateTime!,$type:String!){
    getCartCheckoutDetails(long:$long,lat:$lat,dateTime:$dateTime,type:$type)
    {
      status
      message
      data{
         cart_item_details{
          cart_key
          type
          quantity
          description
          total_price
          itemDetails{
          id
          name
          description
          image
          base_price
          dietary_prefrence
          allergies
          item_selections{
            id
            name
            price
          }
        }
        }
        cart_summary
        collection_detail
        diner_details{
          first_name
          last_name
          email
          phone_number
          user_profile{
            current_floor_no
            current_unit
            current_address
            current_pincode          
          }
        }  
        crib_details{
          id
          name
          address
          preparation_time
        }
      }
    }
  }
  `
)
export const ProceedToCheckout = () => (
  gql`
  mutation proceedToCheckout( 
    $crib_id: Float!
    $discount:Float!
    $cutleries:Boolean!
    $delivery_charges:Float!
    $tax:Float!
    $total_price:Float!
    $grand_total:Float!
    $seat:Float!
    $distance:Float!
  ) 
  { 
    proceedToCheckout(
      checkout_inputs:{
          crib_id:$crib_id,
          discount:$discount,
          cutleries:$cutleries,
          delivery_charges:$delivery_charges,
          tax:$tax,
          total_price:$total_price,
          grand_total:$grand_total,
          seat:$seat,
        distance:$distance,
      }
    )
  }`
)
export const GetCurrentOrderList = () => (
  gql`
  query getCurrentOrderList{
    getCurrentOrderList{
      status
      message
      data{
        id
        user_id
        crib_id
        order_number
        payment_status
        payment_intent_id
        time
        distance
        order_status
        type
        cutleries
        crib_details{
          id
          name
          address
        }
        order_items{
          id
            order_id
          item_id
          name
          price
          quantity
          image
          description
          item_details{
            id
            image
            name
            base_price
            dietary_prefrence
            allergies
            preparation_time
            item_selections{
              id
              name
              price
              }
            
          }
        }
      }
    
    }
  }`
)

export const GetCloseOrderList = () => (
  gql`
  query getCloseOrderList{
    getCloseOrderList{
    status
      message
      data{
        id
        user_id
        crib_id
        order_number
        payment_status
        payment_intent_id
        time
        
        order_status
        type
        cutleries
        crib_details{
          id
          name
          address
        }
        order_items{
          id
            order_id
          item_id
          name
          price
          image
          description
          item_details{
            id
            image
            name
            base_price
            dietary_prefrence
            allergies
            preparation_time
            item_selections{
              id
              name
              price
              }
          }
        }
      }
    
    }
  }
  `
)

export const getTrackOrderDetails = () => (
  gql`
  query getTrackOrderDetails($order_id:Float!){
    getTrackOrderDetails(order_id:$order_id){
      status
      message
      data{
        id
        user_id
        crib_id
        order_number
        payment_status
        payment_intent_id
        time
        distance
        order_status
        type
        cutleries
        crib_details{
          id
          name
          address
          unit_no
          floor_no
          postal_code
        }
        order_items{
          id
            order_id
          item_id
          name
          price
          image
          description
          item_details{
            id
            image
            name
            base_price
            dietary_prefrence
            allergies
            preparation_time
            item_selections{
              id
              name
              price
              }
          }
          
        }
        order_tracking{
          id
          order_id
          status
          time
          message
        }
        
      }
    
    }
  }

  `
)