import React from "react";
import { Row, Col, Form, Button, FloatingLabel } from "react-bootstrap";
import { HiChevronRight } from "react-icons/hi";
import { addClass } from "../../../commanFunctions";

const HostPersonal11 = (params) => {
  const onNextpage = (event) => {
    event.preventDefault();
    addClass()
    setTimeout(() => {
      params.setStepNo(12);
    }, 1400)
  };

  const goBack = () => {
    let sno = params.outletData.length
    addClass();
    setTimeout(() => {
      params.setStepNo(sno > 1 ? 10 : 7);
    }, 1400);
  };

  return (
    <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
      <div className="front">
        <div className="heading">
          <Row>
            <Col>
              <h2 className="small lh-1 fs-normal mb-2">Become A Host</h2>
            </Col>
            <Col lg="auto" className="align-self-top text-start">
              <Button
                className="fs18 themeColor text-decoration-none signup-link pe-0"
                variant="link"
                type="button"
                onClick={() => {
                  addClass()
                  setTimeout(() => {
                    params.setStepNo(12);
                  }, 1400)
                }}>
                Skip
                <HiChevronRight />
              </Button>
            </Col>
          </Row>
          <p className="semibold m-0">Please input your referral code, if you have any. Otherwise press skip.</p>
        </div>
        <Col as="section" className="stepsBar2 pt-4">
          <Col as="ul" className="list-unstyled">
            <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(100%/1.15)" }}>11</span></Col>
          </Col>
        </Col>
        <Col as="section" className="signpUser pt-0">
          <Form className="accForm" onSubmit={onNextpage}>
            <FloatingLabel controlId="floatingInput" label="Referral Code" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Referral Code"
                value={params.referralcode}
                onChange={(e) => params.setReferralcode(e.target.value)}
              />
            </FloatingLabel>
            <Row className="mt-4 pt-2">
              <Col className="align-self-center">
                <Button
                  className="fs26 themeColor semibold text-decoration-none lh-1 p-0"
                  variant="link"
                  type="button"
                  onClick={goBack}>
                  <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  Previous
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                  variant="primary"
                  type="submit"
                  disabled={params.referralcode.length > 0 ? false : true}>Next</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
    </section>
  )
}

export default HostPersonal11