import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { client } from '../..';
import { verifyToken } from '../../queries/queries';


const useAuth = () => {
  const token = localStorage.getItem('token')
  if (token) {
    return true
  } else {
    return false
  }
}

const DinerAppLayout = () => {
  const [dataSet, setDataSet] = useState({});
  const [headerData, setHeaderData] = useState({})
  const [isDiner, setisDiner] = useState(true);
  const [isHost, setisHost] = useState(true);
  const [isData, setisData] = useState(true);
  const location = useLocation();
  const getUserData = async () => {
    const tokenData = verifyToken();
    const { data, error } = await client.query({
      query: tokenData
    });
    if (data) {
      console.log(data, "1234")
      if (data.verifyToken.status === "success") {
        setDataSet(data.verifyToken.user);
        setisDiner(data.verifyToken.user.last_login === "diner");
        setisHost(data.verifyToken.user.is_host);
        setisData(data.verifyToken.user !== null)
      }
      else {
        localStorage.removeItem("token");
        setDataSet(null);
      }
    }
    if (error != undefined) {
      console.log("error", error)
      localStorage.removeItem("token");
      setDataSet(null);
    }
  }
  useEffect(() => {
    getUserData();
  }, []);
  // console.log(isData);
  const auth = useAuth();
  let result = location.pathname.includes("host");

  // if (!result) {
  return auth && isData && isDiner ? <Outlet context={{ user: dataSet, setUser: setDataSet, header: headerData, setHeader: setHeaderData }} /> : <Navigate to="/loginpage" />
  // }
  // else{
  //   return auth && isData && isDiner && isHost ?  <Navigate to="/diner" /> : <Outlet context={{ user: dataSet, setUser: setDataSet }} />

  // }
};

export default DinerAppLayout;
