import React, { useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { client } from '../..';
import { verifyToken } from '../../queries/queries';


const useAuth = () => {
  const token = localStorage.getItem('token')
  if (token) {
    return true
  } else {
    client.cache.reset()
    return false
  }
}

const AuthLayout = () => {
  const auth = useAuth()
  return auth ? <Navigate to="/" /> : <Outlet />
}

export default AuthLayout;
