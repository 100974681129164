import React, { useState } from "react";
import { Row, Col, Form, Button, FloatingLabel } from "react-bootstrap";
import { addClass } from "../../../commanFunctions";

const HostBusiness06 = (params) => {
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [checkbox, setCheckbox] = useState(false)

  const onNextpage = (event) => {
    event.preventDefault();
    if (params.representative === "") {
      setErrorMessage({
        error: "empty authorized representative",
        message: "Please Enter Authorized Representative",
      });
    } else if (params.repCitizenship === "") {
      setErrorMessage({
        error: "empty nationality citizenship",
        message: "Please Enter Nationality / Citizenship",
      });
    } else if (params.repNricNO === "") {
      setErrorMessage({
        error: "empty nric number",
        message: "Please Enter NRIC Number",
      });
    } else if (params.designation === "") {
      setErrorMessage({
        error: "empty designation",
        message: "Please Enter Designation",
      });
    } else if (checkbox === false && params.registrationType === "acra") {
      setErrorMessage({
        error: "empty check box",
        message: "Please Click on Checkbox",
      });
    } else {
      addClass();
      params.registrationType === "acra" ?
        setTimeout(() => { params.setStepNo(7) }, 1400) : setTimeout(() => { params.setStepNo(10) }, 1400);
    }
  };

  const goBack = () => {
    addClass();
    setTimeout(() => {
      params.setStepNo(5);
    }, 1400);
  };

  return (
    <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
      <div className="front">
        <div className="heading">
          <h2 className="small lh-1 fs-normal mb-2">Become A Host</h2>
          <p className="semibold m-0">Please confirm your personal registration details.</p>
        </div>
        <Col as="section" className="stepsBar2 pt-4">
          <Col as="ul" className="list-unstyled">
            <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(100%/2.3)" }}>6</span></Col>
          </Col>
        </Col>
        <Col as="section" className="signpUser pt-2">
          <Form onSubmit={onNextpage} className="accForm">

            <FloatingLabel controlId="floatingInput" label="Authorized Representative" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Authorized Representative"
                value={params.representative}
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrorMessage({
                      error: "empty authorized representative",
                      message: "Please Enter Authorized Representative",
                    });
                  } else {
                    setErrorMessage(undefined);
                  }
                  params.setRepresentative(e.target.value);
                }}
              />
              {errorMessage &&
                errorMessage.error ===
                "empty authorized representative" && (
                  <div className="invalid-feedback d-block">
                    {errorMessage.message}
                  </div>
                )}
            </FloatingLabel>

            <FloatingLabel controlId="floatingInput" label="Nationality / Citizenship" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Nationality / Citizenship"
                value={params.repCitizenship}
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrorMessage({
                      error: "empty nationality citizenship",
                      message: "Please Enter Nationality Citizenship",
                    });
                  } else {
                    setErrorMessage(undefined);
                  }
                  params.setrepCitizenship(e.target.value);
                }}
              />
              {errorMessage &&
                errorMessage.error ===
                "empty nationality citizenship" && (
                  <div className="invalid-feedback d-block">
                    {errorMessage.message}
                  </div>
                )}
            </FloatingLabel>
            <Row>
              <Col>
                <FloatingLabel controlId="floatingInput" label="NRIC / ID Number" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="NRIC / ID Number"
                    value={params.repNricNO}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setErrorMessage({
                          error: "empty nric number",
                          message: "Please Enter Nric Number",
                        });
                      } else {
                        setErrorMessage(undefined);
                      }
                      params.setRepNricNO(e.target.value);
                    }}
                  />
                  {errorMessage &&
                    errorMessage.error === "empty nric number" && (
                      <div className="invalid-feedback d-block">
                        {errorMessage.message}
                      </div>
                    )}
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingInput" label="Designation" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Designation"
                    value={params.designation}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setErrorMessage({
                          error: "empty designation",
                          message: "Please Enter designation",
                        });
                      } else {
                        setErrorMessage(undefined);
                      }
                      params.setDesignation(e.target.value);
                    }}
                  />
                  {errorMessage &&
                    errorMessage.error === "empty designation" && (
                      <div className="invalid-feedback d-block">
                        {errorMessage.message}
                      </div>
                    )}
                </FloatingLabel>
              </Col>
            </Row>

            {params.registrationType === "acra" &&
              <Form.Group className="pb-3 mb-3" controlId="exampleForm.ControlTextarea1">
                <div className="form-check d-inline-block">
                  <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="host01"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setErrorMessage({
                          error: "empty check box",
                          message: "Please Click on Checkbox",
                        });
                      } else {
                        setCheckbox(e.target.checked)
                      }
                    }} />
                  <label className="form-check-label semibold ps-1 fs15 themeColor" htmlFor="host01">
                    I hereby confirm that I am the above-mentioned authorized representative and details provided are accurate.
                  </label>
                </div>
                {errorMessage &&
                  errorMessage.error === "empty check box" && (
                    <div className="invalid-feedback d-block">
                      {errorMessage.message}
                    </div>
                  )}
              </Form.Group>
            }

            <Row>
              <Col className="align-self-center">
                <Button
                  className="fs26 themeColor semibold text-decoration-none lh-1 p-0"
                  variant="link"
                  type="button"
                  onClick={goBack}
                >
                  <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  Previous
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                  variant="primary"
                  type="submit"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
    </section>
  )
}

export default HostBusiness06