import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { addClass } from '../../commanFunctions';
import CountdownTimer from '../../components/CountdownTimer';
import { SignUp_EmailPhoneVerification, SignUp_OTPVerification } from '../../queries/queries';

const DinerSignupFour = params => {
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [otpone, setOtpOne] = useState('');
    const [otptwo, setOtpTwo] = useState('');
    const [otpthree, setOtpThree] = useState('');
    const [otpfour, setOtpFour] = useState('');
    const [otpfive, setOtpFive] = useState('');
    const [otpsix, setOtpSix] = useState('');

    const [ResetCountdown, setResetCountdown] = useState(false);
    const SendOTPByEmail = SignUp_EmailPhoneVerification();
    const [OTPData] = useMutation(SendOTPByEmail);
    const verifyOtp = SignUp_OTPVerification();
    const [verifyOTPData] = useMutation(verifyOtp);

    const OTPSend = async () => {
        const dataOtp = await OTPData({ variables: { text: params.email } })
        if (dataOtp?.data?.SignUp_EmailPhoneVerification?.status === "success") {
            console.log("OTP successfully send on your email")
        }
    }

    useEffect(() => {
        OTPSend();
    }, [])

    const inputfocus = (elmnt) => {
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        } else if (elmnt.key === "Tab") {
            const next = elmnt.target.tabIndex - 1;
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        } else {
            const next = elmnt.target.tabIndex;
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        }
    }

    const onAutoSubmit = async (otpsix) => {
        const otp = otpone + otptwo + otpthree + otpfour + otpfive + otpsix;
        const dataVerify = await verifyOTPData({ variables: { otp: Number(otp), text: params.email } })
        if (dataVerify?.data?.SignUp_OTPVerification?.status === "success") {
            addClass()
            setTimeout(() => {
                params.setStepNo(5)
            }, 1400)
        } else {
            console.log(dataVerify?.data?.SignUp_OTPVerification?.message)
            setErrorMessage({
                error: 'invalid otp',
                message: dataVerify?.data?.SignUp_OTPVerification?.message
            })
        }
    };

    const backOnEmail = () => {
        addClass()
        setTimeout(() => {
            params.setStepNo(3)
        }, 1400)
    }

    const onResend = () => {
        setErrorMessage({
            error: 'resend otp',
            message: 'Resend OTP on your email.'
        })
        setOtpOne('')
        setOtpTwo('')
        setOtpThree('')
        setOtpFour('')
        setOtpFive('')
        setOtpSix('')

        OTPSend();
        setResetCountdown(true)
    }

    return (
        <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
            <div className="front overflow-visible">
                <div className="heading">
                    <h2 className='small lh-1 fs-normal mb-2'>Signup</h2>
                    <p className="semibold m-0">Please key in the OTP sent to your email.</p>
                </div>
                <Col as="section" className="stepsBar pt-4">
                    <Col as="ul" className="d-flex justify-content-between list-unstyled">
                        <li className="p2 active first"><span>1</span></li>
                        <li className="p2 active"><span>2</span></li>
                        <li className="p2 active"><span>3</span></li>
                        <li className="p2" ><span>4</span></li>
                        <li className="p2 last"><span>5</span></li>
                    </Col>
                </Col>
                <Col as="section" className="signpUser pt-3">
                    <Form>
                        <Form.Group controlId="formEmailOtp" className="mb-1 pb-1 row otpScreem">
                            <Col md={2}><input type="text" inputMode="numeric" id="input1" placeholder="." value={otpone} onChange={(e) => {
                                setOtpOne(e.target.value)
                            }} maxLength="1" tabIndex="1" onKeyUp={e => inputfocus(e)} /></Col>

                            <Col md={2}><input type="text" inputMode="numeric" id="input2" placeholder="." value={otptwo} onChange={(e) => {
                                setOtpTwo(e.target.value)
                            }} maxLength="1" tabIndex="2" onKeyUp={e => inputfocus(e)} /></Col>

                            <Col md={2}><input type="text" inputMode="numeric" id="input3" placeholder="." value={otpthree} onChange={(e) => {
                                setOtpThree(e.target.value)
                            }} maxLength="1" tabIndex="3" onKeyUp={e => inputfocus(e)} /></Col>

                            <Col md={2}><input type="text" inputMode="numeric" id="input4" placeholder="." value={otpfour} onChange={(e) => {
                                setOtpFour(e.target.value)
                            }} maxLength="1" tabIndex="4" onKeyUp={e => inputfocus(e)} /></Col>

                            <Col md={2}><input type="text" inputMode="numeric" id="input5" placeholder="." value={otpfive} onChange={(e) => {
                                setOtpFive(e.target.value)
                            }} maxLength="1" tabIndex="5" onKeyUp={e => inputfocus(e)} /></Col>

                            <Col md={2}><input type="text" id="input6" placeholder="." value={otpsix} onChange={(e) => {
                                onAutoSubmit(e.target.value)
                                setOtpSix(e.target.value)
                            }} maxLength="1" tabIndex="6" inputMode="numeric" onKeyUp={e => inputfocus(e)} /></Col>
                            {errorMessage && (errorMessage.error === 'invalid otp' || errorMessage.error === 'resend otp') && (
                                <div className="invalid-feedback d-block">
                                    {errorMessage.message}
                                </div>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-4 pb-4" controlId="exampleForm.ControlTextarea1">
                            <Form.Label className='float-start'>
                                <Link to="#" className="forgotPass text-decoration-none" onClick={onResend}>Resend</Link>
                                <span className="text-black px-1">or</span>
                                <Link to="#" className="forgotPass text-decoration-none" onClick={backOnEmail}>Change Email</Link>
                            </Form.Label>
                            <Form.Label className='float-end mt-1'>
                                <CountdownTimer countdown={120} ResetCountdown={ResetCountdown} setResetCountdown={setResetCountdown} />
                            </Form.Label>
                        </Form.Group>
                        {/* <Button type='button' className="w-100 py-2 lh-lg border-0 hvr-float-shadow1" variant="primary" onClick={onNextPage} >Continue</Button> */}
                        <br /><br />
                    </Form>
                </Col>
            </div>
        </section>
    )
}
export default DinerSignupFour