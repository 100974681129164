// import { loadStripe } from '@stripe/stripe-js';
// import {
//     CardElement,
//     Elements,
// } from '@stripe/react-stripe-js';
// import { PaymentElement } from '@stripe/react-stripe-js';


// const stripePromise = loadStripe("pk_test_51M01OZSB6Jxnm07lMmnJ8ypTM4Ns8O7TNXEdY8EfvFDOIeL3PWIJGFAssSjQ1TpZVzJudD2Bxo0aZHmHPnGYoUEs00h406hAX7");

// const StripPayment = () => {

//     // const handleSubmit = (stripe, elements) => async () => {
//     //     const cardElement = elements.getElement(CardElement);

//     //     const { error, paymentMethod } = await stripe.createPaymentMethod({
//     //         type: 'card',
//     //         card: cardElement,
//     //     });

//     //     if (error) {
//     //         console.log('[error]', error);
//     //     } else {
//     //         console.log('[PaymentMethod]', paymentMethod);
//     //         // ... SEND to your API server to process payment intent
//     //     }
//     // };

//     // const stripe = useStripe();
//     // const elements = useElements();
//     return (
//         <>
//             <h1>stripe form</h1>
//             <Elements stripe={stripePromise}>
//                             <CardElement />
//                             <button>pay</button>
//                         </Elements> 
//             {/* <form>
//                 <PaymentElement />
//                 <button>Submit</button>
//             </form> */}

//         </>

//     );
// }

// export default StripPayment

// import {CardElement, Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';
// import CheckoutForm from './CheckoutForm';


// const stripePromise = loadStripe('pk_test_51MFFPjSCjX2vSiWiZ8BnKC67KsOVnIlbxwc1GfAu7KenRijqgLcLhDktLi8vWDicARNNkhf1ZcEtIzZNMNcoURGk00iCHL3JhW');

// const StripPayment = () => {
//   const options = {
//     // passing the client secret obtained from the server
//     clientSecret: 'sk_test_51MFFPjSCjX2vSiWiYM8S1ELvAsTj7t1d1mDbbU3Jh0E0Z2OrYTSy1lYIuNO9fwURmo2dcsveroWkFUJhkaSHouTK00iFnIkFyb'
//   };

//   return (
//     <Elements stripe={stripePromise} options={options}>
//         <CheckoutForm />
//     </Elements>
//   );
// };

// export default StripPayment


import React from 'react'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from './PaymentForm';
function Stripe() {
  const stripePromise = loadStripe("pk_test_51MFFPjSCjX2vSiWiZ8BnKC67KsOVnIlbxwc1GfAu7KenRijqgLcLhDktLi8vWDicARNNkhf1ZcEtIzZNMNcoURGk00iCHL3JhW")
  return (
   <div>
    <Elements stripe={stripePromise}>
        <PaymentForm />
    </Elements>
   </div>
   )
}

export default Stripe