import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Form, FloatingLabel, Modal, Dropdown, Badge, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import { AiFillCloseCircle } from "react-icons/ai"
import { TiPlus } from "react-icons/ti"
import { HiCheckCircle, HiDotsVertical } from "react-icons/hi";
import "react-pro-sidebar/dist/css/styles.css";

import { DatePicker } from 'react-responsive-datepicker'
import 'react-responsive-datepicker/dist/index.css'

import "./hoststep.css";
import HostProfile from "../cribs/hostprofile";
import ProfileMenu from "../../../components/profilemenu";
import Footer from '../../../components/footer';
import AfterLoginHeader from "../../../components/afterLoginHeader";
import { addMenu, editMenuDetails, GetMenuCribList, GetMenuDetails } from "../../../queries/queries";
import { useMutation, useQuery } from "@apollo/client";
import { Formik, useFormik } from "formik";
import * as yup from 'yup';
import AlertPopup from "../../../components/alertPopup";
import MenuSidebar from "../../../components/menusidebar";


const HostMenuAdd = () => {

    // --------------------API Variable--------------------

    const addHostMenu = addMenu();
    const [AddHostMenuDetails] = useMutation(addHostMenu);

    const navigate = useNavigate();
    const [show01, setShow01] = useState(false);
    const [show, setShow] = useState(false);
    const [onShow, setOnShow] = useState(false);
    const [popupMessage, setPopupMessage] = useState({});
    const [isLoading, setLoading] = useState(false);

    const validationSchema = yup.object().shape({
        title: yup.string().required("title is required"),
        description: yup.string().required("description is required"),
    });

    const initialValues = {
        "title": "",
        "description": "",
        "status": true,
    }
    // ------------------------Function------------------------------

    const handelFormSubmit = async (values) => {
        try {
            setLoading(true);
            let data = await AddHostMenuDetails({
                variables: {
                    title: values.title,
                    description: values.description,
                    status:values.status == "false" ? false : true   
                }
            })
            setLoading(false);
            if (data?.data?.addMenu?.status === "success") {
                setPopupMessage({
                    status: data?.data?.addMenu?.status,
                    message: data?.data?.addMenu?.message
                });
                setOnShow(true);
                setTimeout(() => {
                    navigate('/host/menu');
                }, 1000)
            } else {
                setPopupMessage({
                    status: data?.data?.addMenu?.status,
                    message: data?.data?.addMenu?.message
                });
                setOnShow(true);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const tooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            title is required
        </Tooltip>
    );

    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <AfterLoginHeader />
                    </Col>
                </Row>
                {/* <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col> */}
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <MenuSidebar />
                    </Col>
                    <Col lg="9">
                        <Col as="section" className='page-heading-top my-4 py-1 px-0'>
                            <Col as="div" className='container px-xl-5 px-0'>
                                <Row className='align-items-center justify-content-between align-items-md-start'>
                                    <HostProfile />
                                   
                                </Row>
                            </Col>
                        </Col>
                        <Col as="section" className='ms-0 ms-xl-5 ps-xl-5 mb-5 ps-0 '>
                            <Col className='container px-xl-5 px-lg-4 px-0'>
                                <Formik
                                    enableReinitialize
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handelFormSubmit}>
                                    {(formProps) => (
                                        <Form className="menuform px-lg-2 px-xl-0" onSubmit={formProps.handleSubmit}>
                                            <Row>
                                                <Col md="6" className={`position-relative ${formProps.errors.title ? "emptyfield" : ""}`}>
                                                    <Col as="p" className='fs18 semibold GrayDark text-start mb-3 lh-sm'>Name</Col>
                                                    <FloatingLabel label="Name of Menu" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control
                                                            name='title'
                                                            type="text"
                                                            className="form-control p-3 border-0"
                                                            placeholder="Chiles en Nogada"
                                                            value={formProps?.values?.title}
                                                            onBlur={formProps.handleBlur}
                                                            onChange={(e) => {
                                                                formProps.handleChange(e);
                                                            }}
                                                            isInvalid={
                                                                formProps.touched.title && formProps.errors.title
                                                            }
                                                        />
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>16 / 30 Characters</Col>
                                                        <OverlayTrigger className="positionabsolute" placement="top" overlay={tooltip}>
                                                            <Button variant="dark" className="fs10 p-0 lh-1 position-absolute bottom-0 end-0 infoTooltip">
                                                                <Form.Control.Feedback type="invalid">
                                                                    {formProps.errors.title}
                                                                </Form.Control.Feedback>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </FloatingLabel> 
                                                    

                                                </Col>
                                                <Col md="6">
                                                    <Col as="p" className='fs18 semibold GrayDark text-start mb-3 lh-sm'>Menu Status</Col>
                                                    <FloatingLabel label="Current Status" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Select
                                                            name='status'
                                                            className='border-0 border08 shadowMD fs18 semibold GrayDark'
                                                            aria-label="select"
                                                            value={formProps?.values.status}
                                                            onChange={formProps?.handleChange}
                                                            onBlur={formProps?.handleBlur}
                                                            isInvalid={
                                                                formProps?.touched?.status && formProps?.errors?.status
                                                            }>
                                                            <option value={null}>Please Select Menu Status</option>
                                                            <option value={true}>Publish</option>
                                                            <option value={false}>Unpublish</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {formProps.errors?.status}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md="12">
                                                    <Col as="p" className='fs18 semibold GrayDark text-start mb-3 lh-sm'>Description</Col>
                                                    <FloatingLabel label="Short Description of Your Menu" className="semibold grayBdb lh-lg" controlId="exampleForm.ControlInput1">
                                                        <Form.Control
                                                            name="description"
                                                            type="text"
                                                            className="form-control p-3 border-0"
                                                            placeholder="Chiles en Nogada"
                                                            value={formProps.values.description}
                                                            onBlur={formProps.handleBlur}
                                                            onChange={(e) => {
                                                                formProps.handleChange(e);
                                                            }}
                                                            isInvalid={
                                                                formProps.touched.description && formProps.errors.description
                                                            }
                                                        />
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>72 / 150 Characters</Col>
                                                        <Form.Control.Feedback type="invalid">
                                                            {formProps.errors.description}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md="12" className="text-end mt-4" type="submit">
                                                    <Button variant="light" className="bgLightGray GrayDark fs24 rounded-pill py-2 px-4 lh-sm" type="submit" disabled={isLoading}><svg className="align-text-top me-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M22.4142 1.58577L22.4142 1.58582C23.1953 2.36684 23.1953 3.63313 22.4142 4.41418L22.4142 4.41421L11.3284 15.5H8.5V12.6716L19.5858 1.58579L19.5858 1.58577C20.3668 0.804745 21.6332 0.804745 22.4142 1.58577Z" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M3 5C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18C18.5523 22 19 21.5523 19 21V15C19 14.7238 19.2238 14.5 19.5 14.5C19.7762 14.5 20 14.7238 20 15V21C20 22.1046 19.1046 23 18 23H3C1.89542 23 1 22.1046 1 21V6C1 4.89543 1.89543 4 3 4H9C9.27614 4 9.5 4.22386 9.5 4.5C9.5 4.77614 9.27614 5 9 5H3Z" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                   
                                                    {isLoading ? "Loading..." : "Save Menu"}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </Col>
                        </Col>
                    </Col>
                </Row>
            </Container>

            {onShow == true && popupMessage.status === "success" ? (
                <AlertPopup
                    show={onShow}
                    onHide={() => setOnShow(false)}
                    icon={<HiCheckCircle size="120" className='mt-5 mb-3' color="var(--bs-white)" />}
                    title="Success!"
                    description={popupMessage.message}
                />
            ) : onShow == true && popupMessage.status === "error" ?
                <AlertPopup
                    show={onShow}
                    onHide={() => setOnShow(false)}
                    icon={<HiCheckCircle size="120" className='mt-5 mb-3' color="var(--bs-white)" />}
                    title="Error"
                    description={popupMessage.message}
                    // button="Confirm"
                    onButton={popupMessage?.button}
                    // cancelButton="Cancel"
                    onCancelButton={() => setOnShow(false)}
                /> : onShow == true && popupMessage.status === "error" ?
                    <AlertPopup
                        show={onShow}
                        onHide={() => setOnShow(false)}
                        icon={<svg width="94" height="86" viewBox="0 0 94 86" fill="none" className='mt-5 mb-3' xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clip-rule="evenodd" d="M36.3676 6.90288C41.0317 -1.38885 52.9702 -1.38885 57.6343 6.90288L91.6741 67.4184C96.2485 75.5509 90.3717 85.5995 81.0406 85.5995H12.9609C3.63005 85.5995 -2.24687 75.5509 2.3277 67.4184L36.3676 6.90288ZM53.1001 67.3001C53.1001 70.6691 50.3692 73.4001 47.0001 73.4001C43.6314 73.4001 40.9003 70.6691 40.9003 67.3001C40.9003 63.931 43.6314 61.2001 47.0001 61.2001C50.3692 61.2001 53.1001 63.931 53.1001 67.3001ZM47.0001 18.5001C43.6314 18.5001 40.9003 21.2312 40.9003 24.6001V42.9001C40.9003 46.269 43.6314 49.0001 47.0001 49.0001C50.3692 49.0001 53.1001 46.269 53.1001 42.9001V24.6001C53.1001 21.2312 50.3692 18.5001 47.0001 18.5001Z" fill="white" />
                        </svg>}
                        title="Error!"
                        description={popupMessage.message}
                    /> : null}
            <br />
            <br />
            <Footer />
        </div>
    )
}
export default HostMenuAdd;