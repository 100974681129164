import React, { useState } from 'react'
import { Container, Row, Col, Button, Form, FloatingLabel } from 'react-bootstrap';
import { FiChevronDown } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import "../../hoststep.css";
import AfterLoginHeader from '../../../components/afterLoginHeader';
import ProfileMenu from '../../../components/profilemenu';
import Footer from '../../../components/footer';
import HostProfile from '../cribs/hostprofile';
import { useNavigate } from 'react-router-dom';
import { allergiesOptions, dietaryPrefrences } from '../../../utils/staticData';

const HostMenuthree = () => {
    const history = useNavigate();

    const [Formdata, setFormdata] = useState({
        foodName: "Food One",
        menuItem: 2,
        description: "Testing Food",
        dietaryList: dietaryPrefrences,
        allergyList: allergiesOptions,
        picture: "/assets/item-picture.jpg",
        basePrice: "200"

    })


    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        setActive(!isActive);
    };
    const [isActive01, setActive01] = useState("false");
    const handleToggle01 = () => {
        setActive01(!isActive01);
    };
    const [isActive02, setActive02] = useState("false");
    const handleToggle02 = () => {
        setActive02(!isActive02);
    };

    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0">
                        <AfterLoginHeader />
                    </Col>
                </Row>
                {/* <Col as="span" className="d-none proMenu" data-bs-toggle="offcanvas" href="#myprofile01" role="button" aria-controls="myprofile01">Profile Menu <FiChevronDown className="float-end" size="28" color="var(--bs-white)" /></Col> */}
                <Row className="dblock">
                    <Col lg="3" className="px-0 profilemenu outline-none" tabIndex="-1" id="myprofile01" aria-labelledby="myprofile01Label">
                        <ProfileMenu />
                    </Col>
                    <Col lg="9">
                        <Col as="section" className='page-heading-top my-4 py-1 px-0'>
                            <Col as="div" className='container px-xl-2 px-xxl-5 px-lg-2 px-0'>
                                <Row className='align-items-center justify-content-between align-items-md-start'>
                                    <HostProfile />
                                    <Col md="auto" className='text-center pt-4 mt-2'>
                                        <Button variant="light" className="text-nowrap rounded-pill bgtheme py-2 px-4 text-white border-0 fs20 " onClick={() => history("/host/edit-menu-item")}>
                                            <svg className='align-text-bottom me-2' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M22.4142 1.58577L22.4142 1.58582C23.1953 2.36684 23.1953 3.63313 22.4142 4.41418L22.4142 4.41421L11.3284 15.5H8.5V12.6716L19.5858 1.58579L19.5858 1.58577C20.3668 0.804745 21.6332 0.804745 22.4142 1.58577Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M3 5C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18C18.5523 22 19 21.5523 19 21V15C19 14.7238 19.2238 14.5 19.5 14.5C19.7762 14.5 20 14.7238 20 15V21C20 22.1046 19.1046 23 18 23H3C1.89542 23 1 22.1046 1 21V6C1 4.89543 1.89543 4 3 4H9C9.27614 4 9.5 4.22386 9.5 4.5C9.5 4.77614 9.27614 5 9 5H3Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            Edit Item
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        <Col as="section" className='ms-0 ms-xl-0 ms-xxl-5 ps-xl-0 ps-xxl-5 mb-5 ps-0'>
                            <Col className='container px-xl-2 px-xxl-5 px-lg-4 px-0'>
                                <Form className="menuform px-lg-2 px-xl-0">
                                    <Row>
                                        <Col md="6">
                                            <Col as="p" className='fs18 semibold GrayDark text-start mb-3 lh-sm'>Name</Col>
                                            <FloatingLabel label="Name of Food / Drink" className="semibold grayBdb lh-lg mb-4" controlId="foodName">
                                                <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" value={Formdata?.foodName} />
                                                <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>16 / 30 Characters</Col>
                                            </FloatingLabel>
                                        </Col>

                                        <Col md="6">
                                            <Col as="p" className='fs18 semibold GrayDark text-start mb-3 lh-sm'>Menu</Col>
                                            <FloatingLabel controlId="menuItem " label="Attached to" className="menu-select mb-4 semibold grayBdb lh-lg">
                                                <Form.Select disabled className='border-0 border08 shadowMD fs18 semibold GrayDark' aria-label="select" value={Formdata?.menuItem}>
                                                    <option>Bundled Deals</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md="12">
                                            <Col as="p" className='fs18 semibold GrayDark text-start mb-3 lh-sm'>Description</Col>
                                            <FloatingLabel label="Short Description of Your Item" className="semibold grayBdb lh-lg" controlId="description">
                                                <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" value={Formdata?.description} />
                                                <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>16 / 30 Characters</Col>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Col as="hr" className="mt-5 mb-4 graye0e opacity-100"></Col>
                                    <Col as="section">
                                        <Row className='pt-2'>
                                            <Col className="mb-2 pb-1">
                                                <Col as="h2" className="fs18 m-0 semibold GrayDark">Dietary Category</Col>
                                                <Col as="p" className="fs12 m-0 semibold GrayComn">Please select the applicable dietary category(ies) for this item.</Col>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {Formdata?.dietaryList?.map((item, index) => (
                                                    <Col key={index} as="span" className="border100 themeColor lightred py-1 px-3 me-3 semibold fs16 d-inline-block" value={item.value}>{item.label}</Col>
                                                ))}

                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col as="hr" className="mt-5 mb-4 graye0e opacity-100"></Col>
                                    <Col as="section">
                                        <Row className='pt-2'>
                                            <Col className="mb-2 pb-1">
                                                <Col as="h2" className="fs18 m-0 semibold GrayDark">Food Allergen</Col>
                                                <Col as="p" className="fs12 m-0 semibold GrayComn">Please select the applicable food allergen(s) for this item.</Col>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {Formdata?.allergyList?.map((item, index) => (
                                                    <Col key={index} as="span" className="border100 themeColor lightred py-1 px-3 me-3 semibold fs16 d-inline-block" value={item.value}>{item.label}</Col>
                                                ))}

                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col as="hr" className="mt-5 mb-4 graye0e opacity-100"></Col>
                                    <Col as="section">
                                        <Row className='pt-2'>
                                            <Col md="6">
                                                <Col as="p" className="fs18 mb-2 semibold GrayDark">Item Picture</Col>
                                                <Col>
                                                    <img src={process.env.PUBLIC_URL + Formdata?.picture} className="img-fluid border08" alt="Img" />
                                                </Col>
                                            </Col>
                                            <Col md="6" className="col-md-6 mt-4 mt-md-0">
                                                <Col as="p" className="fs18 mb-2 semibold GrayDark">Base Price</Col>
                                                <FloatingLabel label="Name of Food / Drink" className="semibold grayBdb lh-lg mb-3" controlId="basePrice">
                                                    <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" value={Formdata?.basePrice} />
                                                    <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>16 / 30 Characters</Col>
                                                </FloatingLabel>
                                                <Col as="span" className="fs16 regular gray82 d-block text-end">Promotion currently in progress</Col>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col as="hr" className="mt-2 mt-md-5 mb-4 graye0e opacity-100"></Col>
                                    <Col as="section">
                                        <Row className='pt-2'>
                                            <Col className="mb-2 pb-1">
                                                <Col as="h2" className="fs18 m-0 semibold GrayDark">Item Options</Col>
                                                <Col as="p" className="fs12 m-0 semibold GrayComn">Set up your Diner's options.</Col>
                                            </Col>
                                        </Row>
                                        <Col>
                                            <Col md="auto" className="tobbleButton bundle-switch semibold d-inline-block mt-2 mb-4 pb-2">
                                                <div className={`app ${isActive ? "left" : "right"}`}>
                                                    <span className="leftButton px-2" onClick={handleToggle}>Mandatory</span>
                                                    <span className="rightButton" onClick={handleToggle}>Optional</span>
                                                </div>
                                            </Col>
                                            <Row>
                                                <Col md="5">
                                                    <FloatingLabel label="Name of Selection Category" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>16 / 30 Characters</Col>
                                                    </FloatingLabel>
                                                    <Col className="custom-radio col-md-12 mb-4 mb-md-0">
                                                        <Col className="form-radio mb-3 d-flex">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flex01" />
                                                            <label className="ps-3 form-check-label fs16 semibold GrayDark" htmlFor="flex01">Allow Diner to select up to 1 choice.</label>
                                                        </Col>
                                                        <Col className="form-radio mb-3 d-flex">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flex02" />
                                                            <label className="ps-3 form-check-label fs16 semibold GrayDark" htmlFor="flex02">Allow Diner to select up to
                                                                <Col as="span" className="shadowMD border08 py-2 px-3 mx-1">2</Col>choice.</label>
                                                        </Col>
                                                        <Col className="form-radio d-flex">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flex03" />
                                                            <label className="ps-3 form-check-label fs16 semibold GrayDark" htmlFor="flex03">Allow Diner to select up to all available choices.</label>
                                                        </Col>
                                                    </Col>
                                                </Col>
                                                <Col md="5">
                                                    <FloatingLabel label="Selection 1" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>16 / 30 Characters</Col>
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Selection 2" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>16 / 30 Characters</Col>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md="2">
                                                    <FloatingLabel label="Additional Price (S$)" className="semibold grayBdb lh-lg mb-4 label-turncate w-100" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Additional Price (S$)" className="semibold label-turncate w-100 grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="my-md-4 my-0 py-3">
                                            <Col md="auto" className="tobbleButton bundle-switch semibold d-inline-block mt-2 mb-4 pb-2">
                                                <div className={`app ${isActive01 ? "left" : "right"}`}>
                                                    <span className="leftButton px-2" onClick={handleToggle01}>Mandatory</span>
                                                    <span className="rightButton" onClick={handleToggle01}>Optional</span>
                                                </div>
                                            </Col>
                                            <Row>
                                                <Col md="5">
                                                    <FloatingLabel label="Name of Selection Category" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>16 / 30 Characters</Col>
                                                    </FloatingLabel>
                                                    <Col className="custom-radio col-md-12 mb-4 mb-md-0">
                                                        <Col className="form-radio mb-3 d-flex">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flex04" />
                                                            <label className="ps-3 form-check-label fs16 semibold GrayDark" htmlFor="flex04">Allow Diner to select up to 1 choice.</label>
                                                        </Col>
                                                        <Col className="form-radio mb-3 d-flex">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flex05" />
                                                            <label className="ps-3 form-check-label fs16 semibold GrayDark" htmlFor="flex05">Allow Diner to select up to
                                                                <Col as="span" className="shadowMD border08 py-2 px-3 mx-1">2</Col>choice.</label>
                                                        </Col>
                                                        <Col className="form-radio d-flex">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flex06" />
                                                            <label className="ps-3 form-check-label fs16 semibold GrayDark" htmlFor="flex06">Allow Diner to select up to all available choices.</label>
                                                        </Col>
                                                    </Col>
                                                </Col>
                                                <Col md="5">
                                                    <FloatingLabel label="Selection 1" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>16 / 30 Characters</Col>
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Selection 2" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>16 / 30 Characters</Col>
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Selection 3" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>16 / 30 Characters</Col>
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Selection 4" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>16 / 30 Characters</Col>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md="2">
                                                    <FloatingLabel label="Additional Price (S$)" className="semibold grayBdb lh-lg mb-4 label-turncate w-100" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Additional Price (S$)" className="semibold label-turncate w-100 grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Additional Price (S$)" className="semibold label-turncate w-100 grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Additional Price (S$)" className="semibold label-turncate w-100 grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="my-md-4 my-0 py-0 my-md-3">
                                            <Col md="auto" className="tobbleButton bundle-switch semibold d-inline-block mt-2 mb-4 pb-2">
                                                <div className={`app ${isActive02 ? "left" : "right"}`}>
                                                    <span className="leftButton px-2" onClick={handleToggle02}>Mandatory</span>
                                                    <span className="rightButton" onClick={handleToggle02}>Optional</span>
                                                </div>
                                            </Col>
                                            <Row>
                                                <Col md="5">
                                                    <FloatingLabel label="Name of Selection Category" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>16 / 30 Characters</Col>
                                                    </FloatingLabel>
                                                    <Col className="custom-radio col-md-12 mb-4 mb-md-0">
                                                        <Col className="form-radio mb-3 d-flex">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flex07" />
                                                            <label className="ps-3 form-check-label fs16 semibold GrayDark" htmlFor="flex07">Allow Diner to select up to 1 choice.</label>
                                                        </Col>
                                                        <Col className="form-radio mb-3 d-flex">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flex08" />
                                                            <label className="ps-3 form-check-label fs16 semibold GrayDark" htmlFor="flex08">Allow Diner to select up to
                                                                <Col as="span" className="shadowMD border08 py-2 px-3 mx-1">2</Col>choice.</label>
                                                        </Col>
                                                        <Col className="form-radio d-flex">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flex09" />
                                                            <label className="ps-3 form-check-label fs16 semibold GrayDark" htmlFor="flex09">Allow Diner to select up to all available choices.</label>
                                                        </Col>
                                                    </Col>
                                                </Col>
                                                <Col md="5">
                                                    <FloatingLabel label="Selection 1" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>12 / 40 Characters</Col>
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Selection 2" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>12 / 40 Characters</Col>
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Selection 3" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>36 / 40 Characters</Col>
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Selection 4" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>24 / 40 Characters</Col>
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Selection 5" className="semibold grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                        <Col as="span" className='char-limit position-absolute p-0 m-0 fs13 grayBdb lh-1 semibold h-auto'>25 / 40 Characters</Col>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md="2">
                                                    <FloatingLabel label="Additional Price (S$)" className="semibold grayBdb lh-lg mb-4 label-turncate w-100" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Additional Price (S$)" className="semibold label-turncate w-100 grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Additional Price (S$)" className="semibold label-turncate w-100 grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Additional Price (S$)" className="semibold label-turncate w-100 grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Additional Price (S$)" className="semibold label-turncate w-100 grayBdb lh-lg mb-4" controlId="exampleForm.ControlInput1">
                                                        <Form.Control disabled type="text" className="form-control p-3 border-0" placeholder="Chiles en Nogada" />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Col>
                                </Form>
                            </Col>
                        </Col>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}
export default HostMenuthree;